import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  CircularProgress,
  Grid,
  useMediaQuery,
  Pagination,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentPage } from "redux/redux-slice";
import { IEquipment, IStoreValue } from "utils/types";
import stripesLong from "assets/images/stripes_long.png";
import EquipmentItem from "components/equipment-item/EquipmentItem";
import "./styles.scss";
import InfoCard from "./MapView/InfoCard";
import { FixedSizeGrid as List } from "react-window";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import CardSkeleton from "./CardSkeleton";

interface FetchParams {
  radius?: number;
  maxLat?: number | null;
  minLat?: number | null;
  maxLng?: number | null;
  minLng?: number | null;
  lat?: string | null;
  lng?: string | null;
}
interface IProps {
  next: number;
  handleLikeEquipments: (a: {
    element: HTMLImageElement;
    equipId: number;
    likeType: string;
    listOfClasses: unknown[];
  }) => void;
  callApi: React.MutableRefObject<boolean>;
  pageNo: React.MutableRefObject<number>;
  fetchData: (params: FetchParams) => void;
}

interface CardItemData {
  userId: string;
  id: number;
  equipListType: string;
}

const EquipmentsView = ({
  next,
  handleLikeEquipments,
  callApi,
  fetchData,
  pageNo,
}: IProps) => {
  const equipments = useSelector(
    (state: IStoreValue) => state.equipmentReducer.equipments
  );
  const mapEquipments = useSelector(
    (state: IStoreValue) => state.equipmentReducer.mapEquipments
  );
  const isLoadingEquipments = useSelector(
    (state: IStoreValue) => state.equipmentReducer.isLoadingEquipments
  );
  const matchesDesktop = useMediaQuery("(min-width:1200px)");
  const matchesTablet = useMediaQuery(
    "(max-width:1199px) and (min-width:769px)"
  );
  const matchesMobile = useMediaQuery("(max-width:768px)");
  const matchesMobileX = useMediaQuery("(max-width:425px)");
  const dispatch = useDispatch();
  // const [page, setPage] = React.useState(1);

  const handleChange = (event, value) => {
    dispatch(setCurrentPage(value));
    // setPage(value);
  };

  const handleCardClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>, data: CardItemData) => {
      const targetElement = event.target as HTMLElement;
      if (
        event.target instanceof HTMLImageElement &&
        event.target.tagName === "IMG"
      ) {
        const listOfClasses = Array.from(event.target.classList);
        if (listOfClasses?.includes("likeIcon")) {
          handleLikeEquipments({
            element: event.target,
            equipId: data?.id,
            likeType: data?.equipListType,
            listOfClasses,
          });
        } else {
          window.open(
            `/detail/${data?.userId}/${data?.id}/${data?.equipListType}`,
            "_blank"
          );
        }
      } else if (targetElement.closest(".text-view-item")) {
        window.open(
          `/detail/${data?.userId}/${data?.id}/${data?.equipListType}`,
          "_blank"
        );
      }
    },
    [equipments,mapEquipments]
  );
  const memoizedEquipments = useMemo(() => equipments, [equipments]);
  const columnCount = matchesDesktop ? 3 : matchesTablet ? 2 : 1;
  const columnWidth = matchesDesktop ? 365 : matchesTablet ? 340 : 290;
  const columnGap = matchesDesktop ? 20 : matchesTablet ? 10 : 0;
  const gridRef = useRef(null);
  const maxWidth = matchesDesktop ? 1200 : matchesTablet ? 800 : 300;
  let maxHeight = 800;
  if (memoizedEquipments?.length <= 3) {
    maxHeight /= 2;
  }
  const rowGap = 40;
  return (
    <Box
      className="equipments-wrapper"
      sx={{
        overflowX: "hidden", // Prevent horizontal scrolling
        maxWidth: "100%", // Ensure content does not exceed viewport width
        margin: "0 auto", // Center horizontally
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "25px", // Ensure it stays centered in the viewport
      }}
    >
      {!isLoadingEquipments && memoizedEquipments.length > 0 ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%", // Allow it to adjust
            maxWidth: maxWidth, // Ensure it doesn't exceed set width
          }}
        >
          <MemoizedEquipmentList
            columnCount={columnCount}
            columnWidth={columnWidth}
            columnGap={columnGap}
            rowGap={rowGap}
            maxHeight={maxHeight}
            handleCardClick={handleCardClick}
            memoizedEquipments={memoizedEquipments}
            gridRef={gridRef}
            callApi={callApi}
            fetchData={fetchData}
            pageNo={pageNo}
          />
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "1rem",
            flexDirection: "column",
            minHeight: "auto",
            py: "10rem",
          }}
        >
          {/* <PrecisionManufacturingIcon
            sx={{
              color: "#faa61a",
              fontSize: "90px",
            }}
          /> */}
          <Typography
            variant="h6"
            sx={{
              color: "#fff",
              fontSize: "30px",
              fontFamily: "GothamBold !important",
              textAlign: "center",
            }}
          >
            No equipment found.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const EquipmentList = ({
  columnCount,
  columnWidth,
  columnGap,
  rowGap,
  maxHeight,
  handleCardClick,
  memoizedEquipments,
  gridRef,
  callApi,
  fetchData,
  pageNo,
}) => {
  const totalResult = useSelector(
    (state: IStoreValue) => state.equipmentReducer.count
  );
  const boundingBox = useSelector(
    (state: IStoreValue) => state.equipmentReducer.boundingBox
  );
  const maxPage = useMemo(() => {
    if (+totalResult <= 20) {
      return 0;
    } else {
      return Math.ceil(totalResult / 20);
    }
  }, [totalResult]);
  // const pageNo = useRef<number>(0);
  // const callApi = useRef<boolean>(true);
  const previousOffset = useRef<number>(0);
  // const [lisEquipData, setListEquipData] = useState<Array<IEquipment>>([]);

  const handlePagination = (onScrollObject: { scrollTop: number }) => {
    const scrollingHeight = onScrollObject.scrollTop;
    if (scrollingHeight < previousOffset.current) {
      previousOffset.current = onScrollObject.scrollTop;
      return;
    }
    previousOffset.current = onScrollObject.scrollTop;
    const callApiAfter = (Math.ceil(memoizedEquipments.length / 6) - 2) * 800;
    // console.log("callApi.current", callApiAfter);
    if (
      callApiAfter > 0 &&
      scrollingHeight > callApiAfter &&
      callApi.current &&
      +totalResult > 20 &&
      +totalResult > memoizedEquipments.length &&
      maxPage > pageNo.current
    ) {
      callApi.current = false;
      pageNo.current += 1;
      fetchData({
        ...boundingBox,
        page: pageNo.current,
      });
    }
  };
  let rowCount = Math.ceil(memoizedEquipments.length / columnCount);
  const hasMore = +totalResult > memoizedEquipments.length;
  if (hasMore) {
    rowCount += 1;
  }
  return (
    <List
      ref={gridRef}
      columnCount={columnCount}
      rowCount={rowCount}
      columnWidth={columnWidth + columnGap} // Adjust width dynamically
      rowHeight={350 + rowGap} // Fixed height per row
      width={columnCount * (columnWidth + columnGap)} // Keep it centered dynamically
      height={maxHeight} // Set viewport height
      itemData={memoizedEquipments} // Pass the full data
      style={{
        margin: "0 auto",
        overflowX: "hidden",
      }} // Ensure List is centered
      onScroll={handlePagination}
    >
      {({ columnIndex, rowIndex, style }) => {
        const index = rowIndex * columnCount + columnIndex;
        if (index >= memoizedEquipments.length) {
          if (hasMore) {
            return (
              <Box
                style={{
                  ...style,
                  // display: "flex",
                  // justifyContent: "center",
                  // alignItems: "center",
                  width: columnWidth, // Set actual width
                  marginBottom: rowGap,
                }}
              >
                <CardSkeleton />
              </Box>
            );
          } else {
            return null;
          }
        }
        const equip = memoizedEquipments[index];
        // console.log("style",style)
        return (
          <Box
            style={{
              ...style,
              width: columnWidth, // Set actual width
              marginBottom: rowGap,
              paddingTop:"8px",
              paddingLeft:"8px"
            }}
            className="equipment-grid-item"
            onClick={(event) =>
              handleCardClick(event, {
                id: equip.id,
                userId: equip?.user_id,
                equipListType: equip?.equip_list_type,
              })
            }
          >
            <InfoCard
              pricePerDay={equip.price_day || 0}
              pricePerMonth={equip.price_month || 0}
              pricePerWeek={equip.price_per_week || 0}
              equipTitle={equip.make || "N/A"}
              equipModal={equip.model || "N/A"}
              equipLocation={`${
                !equip.city && !equip.state
                  ? "N/A"
                  : !equip.city
                  ? "N/A, " + equip.state
                  : equip.city + ", " + equip.state
              }`}
              thumbnail={equip.thumbnail}
              isBorder={false}
              likeStatus={equip?.like_status}
              equipListType={"rent"}
              salePrice={equip?.equip_sale_price || 0}
              rpoAvailable={equip?.rpo_available}
              obo={equip?.obo}
              images={equip?.images?.split(",")}
              listView={true}
            />
          </Box>
        );
      }}
    </List>
  );
};

const MemoizedEquipmentList = React.memo(EquipmentList);

export default EquipmentsView;
