import { Box, Typography } from "@mui/material";
// import locationIcon from "assets/images/icons_equipmentlocation-footer.png";
import locationIcon from "assets/images/GPS/red-location-marker.png";
import shareIcon from "assets/images/GPS/export.png";
import { FiShare } from "react-icons/fi";
import CurrencyFormat from "../../common/CurrenyFormat";
import React from "react";
const NewGPSCard = ({
  image,
  price,
  priceUnit,
  title,
  subTitle,
  location,
  serialNumber,
  onUnassign,
  onShareLocation,
  onGetDirections,
  onClickHandle,
  deviceStatusLabel,
  isBorder,
  isLoactionDisable,
}) => {
  return (
    <Box
      onClick={onClickHandle}
      sx={{
        outline: isBorder ? "2px solid #faa61a" : "3px solid rgba(0,0,0,0)",
        "&:hover": {
          outline: "2px solid #faa61a",
          boxShadow: isBorder
            ? "0px 0px 10px 0px rgba(233, 147, 35, 0.9)"
            : "0px 0px 10px 0px rgba(233, 147, 35, 0.9)",
        },
        // outlineOffset: "5px",
        // boxShadow: isBorder
        //   ? "0px 0px 10px 0px #faa61a"
        //   : "0 0 0 0 rgba(0,0,0,0)",
        padding: {
          xs: "10px",
          md: "17px",
        },
        borderRadius: "10px",
        // mb:2,
        // mt:1
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexWrap: "nowrap",
          // borderRadius: "10px",
          // backgroundColor: "#3A3A3B",
          padding: "0px",
          overflow: "hidden",
          boxSizing: "border-box", // Ensures padding and borders are included in the card size
          maxWidth: "100%", // Prevents overflow in smaller widths
        }}
      >
        {/* Image Section */}
        <Box
          sx={{
            position: "relative",
            width: { xs: "35%", sm: "200px", md: "250px", lg: "180px" },
            maxHeight: { xs: "120px", sm: "170px", md: "200px", lg: "170px" },
            flexShrink: 0,
            boxSizing: "border-box",
          }}
        >
          <Box
            component={"img"}
            src={image}
            // src="https://i.pinimg.com/474x/85/0e/d9/850ed9aa272e199ecbec87774a18dee1.jpg"
            alt={title}
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              // borderTopLeftRadius: "20px",
              // borderBottomLeftRadius: "20px",
            }}
          />
          <Box
            sx={{
              backgroundColor: "#231F20",
              position: "absolute",
              right: "10px",
              bottom: "10px",
              padding: "2px 5px",
              borderRadius: "5px",
              boxSizing: "border-box",
            }}
          >
            <Typography
              sx={{
                color: "#fff",
                fontSize: { xs: "10px", sm: "14px", md: "16px", lg: "18px" },
                fontFamily: "GothamBold !important",
              }}
            >
              $<CurrencyFormat Currency={price} />
              <Typography
                component={"span"}
                sx={{
                  color: "#faa61a",
                  fontFamily: "Gotham !important",
                  fontSize: { xs: "9px", sm: "11px", md: "12px", lg: "13px" },
                }}
              >
                /{priceUnit}
              </Typography>
            </Typography>
          </Box>
        </Box>

        {/* Text Section */}
        <Box
          sx={{
            flex: 1,
            padding: "10px 20px 10px 15px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            boxSizing: "border-box",
          }}
        >
          <Typography
            sx={{
              fontFamily: "GothamBold",
              color: "#fff",
              fontSize: { xs: "12px", sm: "18px", md: "20px", lg: "20px" },
              fontWeight: "700",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              fontFamily: "GothamBold",
              color: "#faa61a",
              fontSize: { xs: "10px", sm: "16px", md: "17px", lg: "18px" },
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {subTitle}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Gotham",
              color: "#faa61a",
              fontSize: { xs: "10px", sm: "14px", md: "14px" },
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              mt: "3px",
            }}
          >
            {location}
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "baseline",
              justifyContent: "space-between",
              gap: "1rem",
              boxSizing: "border-box",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Gotham !important",
                color: isLoactionDisable ? "#ababab" : "#fff",
                fontSize: { xs: "8px", sm: "13px", md: "15px" },
                marginBottom: "5px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              GPS IMEI #{serialNumber}
            </Typography>
            <Typography
              sx={{
                color: "#faa61a",
                textDecoration: "underline",
                cursor: "pointer",
                fontFamily: "Gotham !important",
                fontWeight: "500",
                fontSize: { xs: "10px", sm: "14px" },
              }}
              onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                event.stopPropagation();
                onUnassign(event);
              }}
            >
              {deviceStatusLabel}
            </Typography>
          </Box>

          <Box
            sx={{
              marginTop: "5px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "1rem",
              boxSizing: "border-box",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: { xs: "3px", md: "5px" },
              }}
            >
              <Box
                component={"img"}
                src={shareIcon}
                sx={{
                  width: {
                    xs: "16px",
                    sm: "18px",
                    md: "20px",
                    lg: "20px",
                    xl: "20px",
                  },
                  height: "auto",
                  // filter: "invert(1)",
                }}
              />
              <Typography
                sx={{
                  color: "#faa61a",
                  textDecoration: isLoactionDisable ? "none" : "underline",
                  cursor: isLoactionDisable ? "default" : "pointer",
                  fontFamily: "Gotham !important",
                  fontSize: { xs: "9px", sm: "14px" },
                  filter: `grayscale(${isLoactionDisable ? 1 : 0})`,
                }}
                onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                  event.stopPropagation();
                  onShareLocation(event);
                }}
              >
                Share Location
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: { xs: "3px", md: "5px" },
              }}
            >
              <Box
                component={"img"}
                src={locationIcon}
                sx={{
                  width: {
                    xs: "20px",
                    sm: "20px",
                    md: "24px",
                    lg: "24px",
                    xl: "24px",
                  },
                  height: "auto",
                }}
              />
              <Typography
                sx={{
                  color: "#faa61a",
                  textDecoration: isLoactionDisable ? "none" : "underline",
                  cursor: isLoactionDisable ? "default" : "pointer",
                  fontFamily: "Gotham !important",
                  fontSize: { xs: "9px", sm: "14px" },
                  filter: `grayscale(${isLoactionDisable ? 1 : 0})`,
                }}
                onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                  event.stopPropagation();
                  onGetDirections(event);
                }}
              >
                Get Directions
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NewGPSCard;
