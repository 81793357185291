import React, { useState } from "react";
import { Box } from "@mui/material";

const LazyImage = ({ src, placeholder, listView }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: listView ? "240px" : "170px",
      }}
    >
      {/* Placeholder Image */}
      <Box
        component="img"
        src={placeholder}
        alt="Placeholder Image"
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          transition: "opacity 0.3s ease-in-out",
          opacity: isLoaded ? 0 : 1, // Hide when actual image loads
        }}
      />

      {/* Actual Image */}
      <Box
        component="img"
        src={src}
        alt="Equipment Image"
        onLoad={() => setIsLoaded(true)}
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          transition: "opacity 0.3s ease-in-out",
          opacity: isLoaded ? 1 : 0, // Show when loaded
        }}
      />
    </Box>
  );
};

export default LazyImage;
