import { useContext, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  // TextField,
  Typography,
} from "@mui/material";
import calendarIcon from "../../../assets/images/calendar-icon.png";
import Satellite from "assets/images/GPS/satellite.png";
import chatIcon from "../../../assets/images/chat-icon.png";
import locationIcon from "../../../assets/images/location-white-icon.png";
import cameraIcon from "../../../assets/images/camera-icon.png";
import orderPlaced from "assets/images/statusbar_orderplaced_sm.png";
import pickedUp from "assets/images/statusbar_pickedup_sm.png";
import returned from "assets/images/statusbar_returned_sm.png";
import securityDeposit from "assets/images/statusbar_securityrdeposit_sm.png";
import { useSelector } from "react-redux";
import { IStoreValue, OrderDetails } from "utils/types";
import moment from "moment";
import ConfirmationModal from "./ConfirmationModal";
import SecurityRefundModal from "./SecurityRefundModal";
import gemArrow from "assets/images/gem-arrow.png";
import ExtendRentalPopup from "./ExtendRentalPopup";
import EquipmentCalender from "../../Calendar/EquipmentCalender";
import PickupDropoffImages from "./PickupDropoffImagesUpload";
import PickupDropoffImagesView from "./PickupDropoffImagesView";
import { convertTimeZoneShortName } from "utils/commonFxns";
import momentTimezone from "moment-timezone";
import UsCurrencyFormat from "./UsCurrencyFormat";
import FilesViewerPopup from "./FilesViewerPopup";
import { orderContext } from "./EquipmentOrders";
const ActiveRentals = ({
  handleDetailModalOpen,
  activeRentalsData,
  handleDetailModalData,
  handleNotificationRedirection,
  handleModalConfirmation,
  setActionType,
  loading,
  isConfirmationOpen,
  handleConfirmationOpen,
  handleConfirmationClose,
  handleSecurityCompletion,
  handlePagination,
  onExtendConfirmation,
  loadMore,
  handleMapRedirection,
  extendRentalEquipment,
  pageNo,
  setPageNo,
  handleRentalYardRedirection,
}) => {
  const { handleGPSNavigation } = useContext(orderContext);
  const AuthUserId = useSelector(
    (state: IStoreValue) => state.equipmentReducer.Authuser
  );
  const [confirmationMessage, setConfirmationMessage] = useState<string>("");
  // const [pageNo, setPageNo] = useState<number>(1);
  const [isSecurityModalOpen, setIsSecurityModalOpen] =
    useState<boolean>(false);
  const [refundableSecurityAmount, setRefundableSecurityAmount] =
    useState<number>(0);
  const [orderId, setOrderId] = useState<number>(0);
  const handleSecurityModalOpen = () => setIsSecurityModalOpen(true);
  const handleSecurityModalClose = () => setIsSecurityModalOpen(false);
  const [extendRentalPopup, setExtendRentalPopup] = useState<boolean>(false);
  const handleExtendPopupOpen = () => setExtendRentalPopup(true);
  const handleExtendPopupClose = () => setExtendRentalPopup(false);

  const [isCalendarPopupOpen, setIsCalendarPopupOpen] =
    useState<boolean>(false);
  const [activeEquip, setActiveEquip] = useState<any>({});
  const handleCalenderPopupOpen = () => setIsCalendarPopupOpen(true);
  const handleCalenderPopupClose = () => setIsCalendarPopupOpen(false);

  const [pickupDropoffpopup, setPickupDropoffpopup] = useState<boolean>(false);
  const handlePickupDropoffClose = () => setPickupDropoffpopup(false);
  const handlePickupDropoffOpen = () => setPickupDropoffpopup(true);

  const [pickupDropoffReturnPopup, setPickupDropoffReturnPopup] =
    useState<boolean>(false);
  const handleRenterPickupImagesClose = () =>
    setPickupDropoffReturnPopup(false);
  const handleRenterPickupImagesOpen = () => setPickupDropoffReturnPopup(true);
  const [photoVideoPopup, setPhotoVideoPopup] = useState<boolean>(false);
  const handlePhotoVideoPopupOpen = () => setPhotoVideoPopup(true);
  const handlePhotoVideoPopupClose = () => setPhotoVideoPopup(false);
  const [activeEquipment, setActiveEquipment] = useState<any>({});

  const [extendOrderEquipMent, setExtendOrderEquipMent] = useState<any>({});

  const HandleRentAgain = (ActiveEquip: any) => {
    window.location.href =
      "/detail/" + ActiveEquip["owner_id"] + "/" + ActiveEquip["equip_id"];
  };
  return (
    Object.entries(activeRentalsData).length > 0 &&
    activeRentalsData.data.length > 0 && (
      <Box>
        <Box>
          <Typography
            variant="h6"
            sx={{
              color: "#faa61a !important",
              fontFamily: "GothamBold !important",
              mb: 1,
              mt: 2,
              fontSize: {
                xs: "20px",
                sm: "26px",
                md: "32px",
                lg: "36px",
                xl: "40px",
              },
              textTransform: "uppercase",
            }}
          >
            {activeRentalsData?.title}
          </Typography>
        </Box>
        <Box
          sx={{
            // mt:4,
            display: "grid",
            gridTemplateColumns: {
              xs: "1fr",
              sm: "1fr",
              md: "repeat(2, 1fr)",
              lg: "repeat(2, 1fr)",
              xl: "repeat(2, 1fr)",
              gap: "1.5rem",

              alignItems: "center",
            },
          }}
        >
          {activeRentalsData.data.length > 0 &&
            activeRentalsData.data.map((element: OrderDetails) => (
              <Box
                sx={{
                  background: "#484840",
                  borderRadius: "10px",
                  boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.5)",
                  padding: "1rem",
                  position: "relative",
                  height: {
                    xs: "auto",
                    sm: "auto",
                    md: "100%",
                    lg: "100%",
                    xl: "100%",
                  },
                  boxSizing: "border-box",
                  width: "100%",
                }}
                key={element?.order_id}
              >
                {element?.order_extend?.length > 0 && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: "-15px",
                      right: "20px",
                      padding: "5px 10px",
                      color: "#fff",
                      background: "green",
                      fontFamily: "Montserrat !important",
                      fontWeight: "600",
                      borderRadius: "7px",
                      fontSize: "14px",
                      textTransform: "uppercase",
                    }}
                  >
                    Extended
                  </Box>
                )}
                {/* Image Section */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "1rem",
                    // minHeight: "100px",
                  }}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "1rem" }}
                  >
                    <Box
                      component={"img"}
                      src={element?.thumbnail}
                      sx={{
                        width: {
                          xs: "55px",
                          sm: "70px",
                          md: "75px",
                          lg: "80px",
                          xl: "85px",
                        },
                        height: {
                          xs: "45px",
                          sm: "60px",
                          md: "65px",
                          lg: "70px",
                          xl: "75px",
                        },
                        objectFit: "cover",
                        borderRadius: "5px",
                        border: "2px solid #faa61a",
                        boxShadow: "0px 0px 8px 0px rgba(0,0,0,0.5)",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (AuthUserId === element?.owner_id) {
                          handleRentalYardRedirection(element?.equip_id);
                        } else {
                          HandleRentAgain(element);
                        }
                      }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#faa61a",
                          fontWeight: "700",
                          fontSize: {
                            xs: "14px",
                            sm: "18px",
                            md: "20px",
                            lg: "23px",
                            xl: "24px",
                          },
                          fontFamily: "Montserrat !important",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (AuthUserId === element?.owner_id) {
                            handleRentalYardRedirection(element?.equip_id);
                          } else {
                            HandleRentAgain(element);
                          }
                        }}
                      >
                        {element?.make
                          ? element?.make.length > 15
                            ? element?.make.slice(0, 15) + "..."
                            : element?.make
                          : "N/A"}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: {
                            xs: "12px",
                            sm: "13px",
                            md: "13px",
                            lg: "14px",
                            xl: "14px",
                          },
                        }}
                      >
                        Order ID #{element?.order_id}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#faa61a",
                          textDecorationLine: "underline",
                          textDecorationColor: "#faa61a",
                          fontSize: {
                            xs: "12px",
                            sm: "13px",
                            md: "13px",
                            lg: "13px",
                            xl: "14px",
                          },
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleDetailModalData(element, "active");
                          handleDetailModalOpen();
                        }}
                      >
                        Rental Details
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1px",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#fff",
                        fontFamily: "Montserrat !important",
                        fontSize: {
                          xs: "12px",
                          sm: "15px",
                          md: "16px",
                          lg: "17px",
                          xl: "18px",
                        },
                        fontWeight: "500",
                      }}
                    >
                      {AuthUserId === element?.owner_id
                        ? "You'll Earn"
                        : "Pending"}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#fff",
                        fontFamily: "Montserrat !important",
                        fontSize: {
                          xs: "15px",
                          sm: "20px",
                          md: "22px",
                          lg: "24px",
                          xl: "26px",
                        },
                        fontWeight: "700",
                      }}
                    >
                      {AuthUserId === element?.owner_id ? (
                        <UsCurrencyFormat
                          amount={parseFloat(element?.owner_total_price)}
                        />
                      ) : (
                        <UsCurrencyFormat
                          amount={parseFloat(element?.renter_total_price)}
                        />
                      )}
                    </Typography>
                  </Box>
                </Box>

                {/* New Buttons */}
                <Box
                  sx={{
                    width: { xs: "100%", md: "90%" },
                    margin: "0 auto",
                    display: "grid",
                    gridTemplateColumns: `repeat(${
                      element?.connected_status === "1" &&
                      AuthUserId === element?.owner_id
                        ? 3
                        : 2
                    }, 1fr)`,

                    // display:'flex',
                    // alignItems:'center',
                    justifyContent: "center",
                    gap: {
                      xs: "0.5rem",
                      md: "1rem",
                    },
                    mt: 2,
                    mb: 1,
                  }}
                >
                  {/* Message Renter */}
                  <Button
                    sx={{
                      // display: "flex",
                      // alignItems: "center",
                      // gap: "8px",

                      border: "none",
                      background: "#139246",
                      backgroundColor: "#139246",
                      color: "#fff",
                      textTransform: "unset",
                      fontFamily: "Montserrat !important",
                      fontWeight: "600",
                      px: {
                        xs: 1,
                        md: 2,
                      },
                      borderRadius: "7px",
                      fontSize: {
                        xs: "8px",
                        sm:'11px',
                        md: "13px",
                      },
                      // textDecoration: "underline",
                      // textDecorationColor: "#faa61a",
                      // mt: 0.5,
                      "&:hover": {
                        color: "#fff",
                        background: "#006001",
                        // textDecorationColor: "#faa61a",
                        // textDecoration: "underline",
                      },
                    }}
                    onClick={() => handleNotificationRedirection(element)}
                  >
                    Message{" "}
                    {AuthUserId === element?.owner_id ? "Renter" : "Owner"}
                  </Button>

                  {/* Photos  &  Videos */}
                  <Button
                    sx={{
                      // display: "flex",
                      // alignItems: "flex-start",
                      // gap: "8px",
                      fontSize: {
                        xs: "8px",
                        sm:'11px',
                        md: "13px",
                      },
                      border: "none",
                      background: "#139246",
                      backgroundColor: "#139246",
                      color: "#fff",
                      textTransform: "unset",
                      fontFamily: "Montserrat !important",
                      fontWeight: "600",
                      px: {
                        xs: 1,
                        md: 2,
                      },
                      borderRadius: "7px",
                      // textDecoration: "underline",
                      // textDecorationColor: "#faa61a",
                      // mt: 0.5,
                      "&:hover": {
                        color: "#fff",
                        background: "#006001",
                        // textDecorationColor: "#faa61a",
                        // textDecoration: "underline",
                      },
                    }}
                    onClick={() => {
                      setActiveEquipment(element);
                      // if (element?.owner_id === AuthUserId) {
                      //   handleRenterPickupImagesOpen();
                      // } else {
                      //   handlePickupDropoffOpen();
                      // }
                      handlePhotoVideoPopupOpen();
                    }}
                  >
                    {/* <Box
                            component={"img"}
                            src={cameraIcon}
                            alt=""
                            sx={{
                              width: {
                                xs: "12px",
                                sm: "15px",
                                md: "18px",
                                lg: "20px",
                                xl: "20px",
                              },
                              mt: "2px",
                            }}
                          /> */}
                    Photos/Videos
                  </Button>

                  {/* GPS Locate  */}
                  {element?.connected_status === "1" &&
                    AuthUserId === element?.owner_id && (
                      <Button
                        component="a" // Makes the Button render as an <a> tag
                        href={`/myGps/${element?.equip_id}`} // Replace with the desired URL
                        onClick={(event) => {
                          event.preventDefault();
                          handleGPSNavigation(element);
                        }}
                        // startIcon={
                        //   <Box
                        //     component="img"
                        //     src={Satellite}
                        //     alt="Satellite"
                        //     sx={{
                        //       width: {
                        //         xs: "12px",
                        //         sm: "15px",
                        //         md: "18px",
                        //         lg: "20px",
                        //         xl: "20px",
                        //       },
                        //       height: "auto",
                        //       filter: "brightness(0) invert(1)",
                        //     }}
                        //   />
                        // }
                        sx={{
                          border: "none",
                          background: "#139246",
                          backgroundColor: "#139246",
                          color: "#fff",
                          textTransform: "unset",
                          fontFamily: "Montserrat !important",
                          fontWeight: "600",
                          px: {
                            xs: 1,
                            md: 2,
                          },
                          fontSize: {
                            xs: "8px",
                            sm:'11px',
                            md: "13px",
                          },
                          borderRadius: "7px",
                          // textDecoration: "underline",
                          // textDecorationColor: "#faa61a",
                          // mt: 0.5,
                          "&:hover": {
                            color: "#fff",
                            background: "#006001",
                            // textDecorationColor: "#faa61a",
                            // textDecoration: "underline",
                          },
                        }}
                      >
                        {/* View GPS */}
                        GPS Locate
                      </Button>
                    )}
                </Box>

                {/* Detail Section */}
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: "1rem",
                    padding: "0.8rem 0.2rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: {
                        xs: "0.5rem",
                        sm: "0.5rem",
                        md: "0.5rem",
                        lg: "0.5rem",
                        xl: "0.5rem",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <Box
                          component={"img"}
                          src={calendarIcon}
                          alt=""
                          sx={{
                            width: {
                              xs: "12px",
                              sm: "15px",
                              md: "18px",
                              lg: "20px",
                              xl: "20px",
                            },
                            // mt: "2px",
                          }}
                        />
                        <Typography
                          sx={{
                            color: "#fff",
                            fontSize: {
                              xs: "10px",
                              sm: "12px",
                              md: "14px",
                              lg: "16px",
                              xl: "16px",
                            },
                            fontFamily: "Arial !important",
                          }}
                        >
                          Rental Duration
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "5px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "baseline",
                            gap: "4px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#fff",
                              fontSize: {
                                xs: "10px",
                                sm: "12px",
                                md: "13px",
                                lg: "14px",
                                xl: "14px",
                              },
                              cursor: "pointer",
                              fontFamily: "Arial !important",
                            }}
                            onClick={() => {
                              setActiveEquip(element);
                              handleCalenderPopupOpen();
                            }}
                          >
                            Start:
                          </Typography>
                          <Typography
                            sx={{
                              color: "#faa61a",
                              textDecoration: "underline",
                              textDecorationColor: "#faa61a",
                              fontSize: {
                                xs: "10px",
                                sm: "12px",
                                md: "13px",
                                lg: "14px",
                                xl: "14px",
                              },
                              fontFamily: "Arial !important",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setActiveEquip(element);
                              handleCalenderPopupOpen();
                            }}
                          >
                            {/* {moment(
                              `${element.start_date} ${element.start_time}`
                            ).format("MM/DD hh:mm a")} */}
                            {element?.timezone
                              ? convertTimeZoneShortName(
                                  momentTimezone
                                    .tz(
                                      `${element.start_date} ${element.start_time}`,
                                      element?.timezone
                                    )
                                    .format("MM/DD hh:mm a (z)")
                                )
                              : moment(
                                  `${element.start_date} ${element.start_time}`
                                ).format("MM/DD hh:mm a (UTC)")}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "baseline",
                            gap: "4px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#fff",
                              fontSize: {
                                xs: "10px",
                                sm: "12px",
                                md: "13px",
                                lg: "14px",
                                xl: "14px",
                              },
                              fontFamily: "Arial !important",
                            }}
                          >
                            End:
                          </Typography>
                          <Typography
                            sx={{
                              color: "#faa61a",
                              textDecoration: "underline",
                              textDecorationColor: "#faa61a",
                              fontSize: {
                                xs: "10px",
                                sm: "12px",
                                md: "13px",
                                lg: "14px",
                                xl: "14px",
                              },
                              fontFamily: "Arial !important",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setActiveEquip(element);
                              handleCalenderPopupOpen();
                            }}
                          >
                            {/* {moment(
                              `${element.end_date} ${element.end_time}`
                            ).format("MM/DD hh:mm a")} */}
                            {element?.timezone
                              ? convertTimeZoneShortName(
                                  momentTimezone
                                    .tz(
                                      `${element.end_date} ${element.end_time}`,
                                      element?.timezone
                                    )
                                    .format("MM/DD hh:mm a (z)")
                                )
                              : moment(
                                  `${element.end_date} ${element.end_time}`
                                ).format("MM/DD hh:mm a (UTC)")}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    {/* <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <Box
                        component={"img"}
                        src={chatIcon}
                        alt=""
                        sx={{
                          width: {
                            xs: "12px",
                            sm: "15px",
                            md: "18px",
                            lg: "20px",
                            xl: "20px",
                          },
                          mt: "-1px",
                        }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "5px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "baseline",
                            gap: "4px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#faa61a",
                              textDecoration: "underline",
                              textDecorationColor: "#faa61a",
                              fontSize: {
                                xs: "10px",
                                sm: "12px",
                                md: "13px",
                                lg: "14px",
                                xl: "14px",
                                cursor: "pointer",
                              },
                            }}
                            onClick={() =>
                              handleNotificationRedirection(element)
                            }
                          >
                            Message{" "}
                            {AuthUserId === element?.owner_id
                              ? "Renter"
                              : "Owner"}
                          </Typography>
                        </Box>
                      </Box>
                    </Box> */}
                    {/* <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        gap: "8px",
                      }}
                    >
                      <Box
                        component={"img"}
                        src={cameraIcon}
                        alt=""
                        sx={{
                          width: {
                            xs: "12px",
                            sm: "15px",
                            md: "18px",
                            lg: "20px",
                            xl: "20px",
                          },
                          mt: "2px",
                        }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "5px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "baseline",
                            gap: "4px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#faa61a",
                              textDecoration: "underline",
                              textDecorationColor: "#faa61a",
                              fontSize: {
                                xs: "10px",
                                sm: "12px",
                                md: "13px",
                                lg: "14px",
                                xl: "14px",
                              },
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setActiveEquipment(element);
                              // if (element?.owner_id === AuthUserId) {
                              //   handleRenterPickupImagesOpen();
                              // } else {
                              //   handlePickupDropoffOpen();
                              // }
                              handlePhotoVideoPopupOpen();
                            }}
                          >
                            Photos/Videos
                          </Typography>
                        </Box>
                      </Box>
                    </Box> */}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          flexDirection: "column",
                          gap: "8px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "flex-start",
                            // flexDirection: "column",
                            gap: "8px",
                          }}
                        >
                          <Box
                            component={"img"}
                            src={locationIcon}
                            alt=""
                            sx={{
                              width: {
                                xs: "12px",
                                sm: "15px",
                                md: "18px",
                                lg: "20px",
                                xl: "20px",
                              },
                              // mt: "2px",
                            }}
                          />
                          <Typography
                            sx={{
                              color: "#fff",
                              fontSize: {
                                xs: "10px",
                                sm: "12px",
                                md: "14px",
                                lg: "16px",
                                xl: "16px",
                              },
                              fontFamily: "Arial !important",
                            }}
                          >
                            {element?.delivery_required === "1"
                              ? "Owner Delivery"
                              : "Renter Pickup"}
                            {":"}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "5px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "baseline",
                              flexDirection: "column",
                              gap: "4px",
                            }}
                          >
                            {/* <Typography
                              sx={{
                                color: "#fff",
                                fontSize: {
                                  xs: "10px",
                                  sm: "12px",
                                  md: "13px",
                                  lg: "14px",
                                  xl: "14px",
                                },
                                fontFamily: "Arial !important",
                              }}
                            >
                              {element?.delivery_required === "1"
                                ? "Deliver to"
                                : "Renter Pickup"}
                              {":"}
                            </Typography> */}
                            <Typography
                              sx={{
                                color: "#faa61a",
                                textDecoration: "underline",
                                textDecorationColor: "#faa61a",
                                fontSize: {
                                  xs: "10px",
                                  sm: "12px",
                                  md: "13px",
                                  lg: "14px",
                                  xl: "14px",
                                },
                                fontFamily: "Arial !important",
                                cursor: "pointer",
                                display: "-webkit-box", // Required for multiline truncation
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 2, // Limit to 2 lines
                                overflow: "hidden", // Ensures the text is clipped
                                textOverflow: "ellipsis",
                                wordBreak: "break-word",
                              }}
                              onClick={() => {
                                handleMapRedirection(element);
                              }}
                            >
                              {element?.delivery_required === "1"
                                ? element?.delivery_address
                                : element?.equip_address}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>

                {/* Accept/reject */}
                {AuthUserId === element?.owner_id ? (
                  <Box
                    sx={{
                      padding: "0.5rem",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "0.5rem",
                      // minHeight:{
                      //   xs:'auto',
                      //   sm:'auto',
                      //   md:'120px',
                      //   lg:'',
                      //   xl:'120px'
                      // },
                      // boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.5)",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#fff",
                        fontFamily: "Montserrat !important",
                        fontWeight: "500",
                        fontSize: {
                          xs: "11px",
                          sm: "14px",
                          md: "16px",
                          lg: "16px",
                          xl: "18px",
                        },
                        textAlign: "center",
                      }}
                    >
                      {element?.delivery_required === "1" &&
                      element?.order_status === "payment_complete"
                        ? "Has this rental been delivered?"
                        : element?.order_status === "payment_complete"
                        ? "Has this order been picked up?"
                        : element?.order_status === "pickup"
                        ? "Has this order been returned?"
                        : element?.order_status === "returned"
                        ? "Ready to complete this order and handle security deposit?"
                        : ""}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "1rem",
                      }}
                    >
                      <Button
                        sx={{
                          // border: `3px solid ${
                          //   (new Date(element?.start_date) <= new Date() &&
                          //     element?.order_status === "payment_complete") ||
                          //   (new Date(element?.end_date) <= new Date() &&
                          //     [
                          //       "payment_complete",
                          //       "pickup",
                          //       "returned",
                          //     ].includes(element?.order_status))
                          //     ? "red"
                          //     : "#faa61a"
                          // }`,
                          background: "#008001",
                          textTransform: "capitalize",
                          color: "#fff",
                          fontFamily: "Montserrat !important",
                          borderRadius: "8px",
                          fontWeight: "800",
                          padding: "7px 15px",
                          fontSize: {
                            xs: "12px",
                            sm: "13px",
                            md: "14px",
                            lg: "16px",
                            xl: "18px",
                          },
                          "&:hover": {
                            background: "#006001",
                          },
                        }}
                        onClick={() => {
                          if (element?.order_status === "returned") {
                            handleSecurityModalOpen();
                            setRefundableSecurityAmount(
                              element?.security_deposit
                            );
                            setOrderId(element?.order_id);
                            return;
                          }
                          let message = "";
                          if (
                            element?.order_status === "payment_complete" &&
                            element?.delivery_required === "1"
                          ) {
                            message = "Item Delivered?";
                            setActionType("Pickup");
                            handleDetailModalData(element);
                          } else if (
                            element?.order_status === "payment_complete" &&
                            element?.delivery_required === "0"
                          ) {
                            message = "Item Picked Up?";
                            setActionType("Pickup");
                            handleDetailModalData(element);
                          } else if (element?.order_status === "pickup") {
                            message = "Item Returned?";
                            setActionType("Return");
                            handleDetailModalData(element);
                          }
                          setActiveEquipment(element);
                          setConfirmationMessage(message);
                          handleConfirmationOpen();
                        }}
                      >
                        {element?.delivery_required === "1" &&
                        element?.order_status === "payment_complete"
                          ? "Confirm Delivery"
                          : element?.order_status === "payment_complete"
                          ? "Confirm Picked Up"
                          : element?.order_status === "pickup"
                          ? "Confirm Returned"
                          : element?.order_status === "returned"
                          ? "Complete Order"
                          : ""}
                      </Button>
                    </Box>
                  </Box>
                ) : ["payment_complete", "pickup"].includes(
                    element?.order_status
                  ) ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      mt: 3,
                    }}
                  >
                    <Button
                      sx={{
                        background: "#008001",
                        textTransform: "capitalize",
                        color: "#fff",
                        fontFamily: "Montserrat !important",
                        borderRadius: "8px",
                        fontWeight: "800",
                        padding: "7px 15px",
                        // border: "2px solid #faa61a",
                        fontSize: {
                          xs: "12px",
                          sm: "13px",
                          md: "14px",
                          lg: "16px",
                          xl: "18px",
                        },
                        "&:hover": {
                          background: "#006001",
                        },
                      }}
                      onClick={() => {
                        // console.log("elemenentr birhfirifh====",element);
                        setExtendOrderEquipMent(element);
                        setOrderId(element?.order_id);
                        setTimeout(() => {
                          handleExtendPopupOpen();
                        }, 0);
                      }}
                    >
                      Extend Rental
                    </Button>
                  </Box>
                ) : (
                  <></>
                )}
                <Box
                  sx={{
                    mt: 2,
                  }}
                >
                  <Box
                    component={"img"}
                    src={
                      element?.order_status === "payment_complete"
                        ? orderPlaced
                        : element?.order_status === "pickup"
                        ? pickedUp
                        : AuthUserId === element?.owner_id &&
                          element?.order_status === "returned"
                        ? securityDeposit
                        : element?.order_status === "returned"
                        ? returned
                        : ""
                    }
                    sx={{
                      width: "100%",
                      height: "auto",
                      display: "block",
                      // objectFit:'cover'
                    }}
                  />
                </Box>
              </Box>
            ))}
        </Box>
        {activeRentalsData.data.length === 0 && (
          <Box
            sx={{
              width: "100%",
              padding: "2rem 1rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                color: "#fff",
                fontSize: {
                  xs: "14px",
                  sm: "14px",
                  md: "15px",
                  lg: "16px",
                  xl: "18px",
                },
                fontFamily: "Montserrat !important",
                fontWeight: "800",
              }}
            >
              No Records Found!
            </Typography>
          </Box>
        )}
        {/* {activeRentalsData?.total > activeRentalsData?.data?.length && (
          <Box className="pagination-section">
            {loadMore ? (
              <CircularProgress />
            ) : (
              <Box
                className="btn-load-more"
                onClick={() => {
                  setPageNo((prev) => prev + 1);
                  handlePagination("ACTIVE RENTALS", pageNo);
                }}
              >
                {"Load More"}
                <img
                  src={gemArrow}
                  alt="GoEquipMe"
                  className="gem-arrow arrow-load-more"
                />
              </Box>
            )}
          </Box>
        )} */}
        <ConfirmationModal
          isConfirmationOpen={isConfirmationOpen}
          handleConfirmationClose={handleConfirmationClose}
          onConfirm={handleModalConfirmation}
          message={confirmationMessage}
          loading={loading}
          equipData={activeEquipment}
        />
        <SecurityRefundModal
          isSecurityModalOpen={isSecurityModalOpen}
          handleSecurityModalClose={handleSecurityModalClose}
          refundableSecurityAmount={refundableSecurityAmount}
          orderId={orderId}
          handleSecurityCompletion={handleSecurityCompletion}
        />
        <ExtendRentalPopup
          extendRentalPopup={extendRentalPopup}
          handleExtendPopupClose={handleExtendPopupClose}
          onExtendConfirmation={onExtendConfirmation}
          orderId={orderId}
          extendRentalEquipment={extendOrderEquipMent}
          handleNotificationRedirection={handleNotificationRedirection}
        />
        <EquipmentCalender
          activeEquip={activeEquip}
          isCalendarPopupOpen={isCalendarPopupOpen}
          handleCalenderPopupClose={handleCalenderPopupClose}
        />
        {/* <PickupDropoffImages
          pickupDropoffpopup={pickupDropoffpopup}
          handlePickupDropoffClose={handlePickupDropoffClose}
          activeEquipment={activeEquipment}
        />
        <PickupDropoffImagesView
          pickupDropoffReturnPopup={pickupDropoffReturnPopup}
          handleRenterPickupImagesClose={handleRenterPickupImagesClose}
          activeEquipment={activeEquipment}
        /> */}
        {photoVideoPopup && (
          <FilesViewerPopup
            isOpen={photoVideoPopup}
            onClose={handlePhotoVideoPopupClose}
            orderDetails={activeEquipment}
          />
        )}
      </Box>
    )
  );
};

export default ActiveRentals;
