import { createSlice } from "@reduxjs/toolkit";
import {
  IEquipment,
  IStore,
  IEquipFilters,
  weekendAvailability,
  ProductsType,
  ProductsDataTypes,
  mapBoundingBox,
} from "utils/types";

interface ISetEquipmentActionType {
  payload: Array<IEquipment>;
}

interface ISetCategoriesActionType {
  payload: Array<string>;
}

interface ISCountActionType {
  payload: number;
}

interface ISCurrentPageActionType {
  payload: number;
}

interface ISetQrActionType {
  payload: string;
}

export const reduxSlice = createSlice({
  name: "reduxSlice",
  initialState: {
    equipments: [],
    mapEquipments: [],
    boundingBox: {},
    categories: [],
    isLoadingEquipments: false,
    isModalOpen: false,
    count: 0,
    currentPage: 0,
    qrCodeValue: "https://goequipme.app.link/afu3FHybdBb",
    isLogin: false,
    accessToken: null,
    isLoginModalOpen: false,
    LogInLoading: false,
    alertMessagetype: "",
    alertMessage: "",
    SessionExpire: false,
    RedirectPage: "",
    Authuser: 0,
    ProfileStatus: false,
    AuthProfileData: [],
    NotificationCount: 0,
    NotificationList: [],
    UnreadNotificationList: [],
    NotificationListCount: 0,
    WalletBalance: 0,
    RedirectToChat: false,
    EmailVerify: 0,
    AccountCompleted: 0,
    // by GD 24 Jan 2024 to for select rent or sale in find page
    equipListType: "rent",
    equipSelectType: "rent", //for how it works pages
    equipAddType: "rent", //for how it works pages redirect to add equip page acc to sale or rent
    fromHowItWorks: false,
    equipFilters: {
      category: "",
      zipCode: "",
      locationRange: "",
      startDate: "",
      endDate: "",
      equipType: "rent",
    },
    socialEmailVerifiedModalOpen: false,
    rentOnWeekend: {
      saturday: "1",
      sunday: "1",
    },
    partialOrderRenterList: {},
    partialDatesCount: {},
    reservedDates: {},
    equipmentBookedDates: [],
    equipmentBlockedDates: [],
    equipmentRequestedDates: [],
    partiallyBookedDates: [],
    partiallyPendingDates: [],
    allPartialDates: [],
    calendarDateAngle: {},
    allPartialDatesTimes: [],
    cartProducts: [],
    storeProducts: [],
    toggleFooterSwipper: true,
  },
  reducers: {
    setEquipments: (state: IStore, action: ISetEquipmentActionType) => {
      var data = [];
      if (action.payload.length > 0) {
        data = [...state.equipments, ...action.payload];
        state.equipments = data;
      } else {
        state.equipments = [];
      }
    },
    setCategories: (state: IStore, action: ISetCategoriesActionType) => {
      state.categories = action.payload.map((category, idx) => ({
        label: category,
        id: idx,
      }));
    },
    setLoadingEquipments: (
      state: IStore,
      action: {
        payload: boolean;
      }
    ) => {
      state.isLoadingEquipments = action.payload;
    },
    setModalOpen: (
      state: IStore,
      action: {
        payload: boolean;
      }
    ) => {
      state.isModalOpen = action.payload;
    },
    setCount: (state: IStore, action: ISCountActionType) => {
      state.count = action.payload;
    },
    setCurrentPage: (state: IStore, action: ISCurrentPageActionType) => {
      state.currentPage = action.payload;
    },
    setQrCodeValue: (state: IStore, action: ISetQrActionType) => {
      state.qrCodeValue = action.payload;
    },
    setLoginStatus: (
      state: IStore,
      action: {
        payload: boolean;
      }
    ) => {
      state.isLogin = action.payload;
    },
    setLoginModalOpen: (
      state: IStore,
      action: {
        payload: boolean;
      }
    ) => {
      state.isLoginModalOpen = action.payload;
    },
    setLogInLoading: (
      state: IStore,
      action: {
        payload: boolean;
      }
    ) => {
      state.LogInLoading = action.payload;
    },
    setaccessToken: (
      state: IStore,
      action: {
        payload: string;
      }
    ) => {
      state.accessToken = action.payload;
    },
    setalertMessagetype: (
      state: IStore,
      action: {
        payload: string;
      }
    ) => {
      state.alertMessagetype = action.payload;
    },
    setalertMessage: (
      state: IStore,
      action: {
        payload: string;
      }
    ) => {
      state.alertMessage = action.payload;
    },
    setSessionExpire: (
      state: IStore,
      action: {
        payload: boolean;
      }
    ) => {
      state.SessionExpire = action.payload;
    },
    SetRedirectPage: (
      state: IStore,
      action: {
        payload: string;
      }
    ) => {
      state.RedirectPage = action.payload;
    },
    setAuthuser: (
      state: IStore,
      action: {
        payload: number;
      }
    ) => {
      state.Authuser = action.payload;
    },
    setProfileStatus: (
      state: IStore,
      action: {
        payload: boolean;
      }
    ) => {
      state.ProfileStatus = action.payload;
    },
    setAuthProfileData: (
      state: IStore,
      action: {
        payload: null;
      }
    ) => {
      state.AuthProfileData = action.payload;
    },
    setNotificationCount: (
      state: IStore,
      action: {
        payload: number;
      }
    ) => {
      state.NotificationCount = action.payload;
    },
    setNotificationList: (
      state: IStore,
      action: {
        payload: null;
      }
    ) => {
      state.NotificationList = action.payload;
    },
    setUnreadNotificationList: (
      state: IStore,
      action: {
        payload: null;
      }
    ) => {
      state.UnreadNotificationList = action.payload;
    },
    setNotificationListCount: (
      state: IStore,
      action: {
        payload: number;
      }
    ) => {
      state.NotificationListCount = action.payload;
    },
    setWalletBalance: (
      state: IStore,
      action: {
        payload: number;
      }
    ) => {
      if (!action.payload || isNaN(action.payload)) {
        action.payload = 0;
      }
      state.WalletBalance = action.payload;
    },
    setRedirectToChat: (
      state: IStore,
      action: {
        payload: boolean;
      }
    ) => {
      state.RedirectToChat = action.payload;
    },
    setEmailVerify: (
      state: IStore,
      action: {
        payload: number;
      }
    ) => {
      state.EmailVerify = action.payload;
    },
    setAccountCompleted: (
      state: IStore,
      action: {
        payload: number;
      }
    ) => {
      state.AccountCompleted = action.payload;
    },
    // by GD 24 Jan 2024 to for select rent or sale in find page
    setEquipListType: (state: IStore, action: { payload: string }) => {
      state.equipListType = action.payload;
    },
    // by GD 14 Feb 2024 to for select rent or sale in How it works find page
    setEquipSelectType: (state: IStore, action: { payload: string }) => {
      state.equipSelectType = action.payload;
    },
    setEquipAddType: (state: IStore, action: { payload: string }) => {
      state.equipAddType = action.payload;
    },
    // by GD 4 mar 2024 for filters set for howItWorks page
    setFromHowItWorks: (state: IStore, action: { payload: boolean }) => {
      state.fromHowItWorks = action.payload;
    },
    setEquipFilters: (state: IStore, action: { payload: IEquipFilters }) => {
      state.equipFilters = action.payload;
    },
    setSocialEmailVerifiedModalOpen: (
      state: IStore,
      action: { payload: boolean }
    ) => {
      state.socialEmailVerifiedModalOpen = action.payload;
    },
    setEquipmentBookedDates: (state: IStore, action: { payload: any[] }) => {
      state.equipmentBookedDates = action.payload;
    },
    setEquipmentBlockedDates: (state: IStore, action: { payload: any[] }) => {
      state.equipmentBlockedDates = action.payload;
    },
    setEquipmentRequestedDates: (state: IStore, action: { payload: any[] }) => {
      state.equipmentRequestedDates = action.payload;
    },
    setRentOnWeekend: (
      state: IStore,
      action: { payload: weekendAvailability }
    ) => {
      state.rentOnWeekend = action.payload;
    },
    setPartiallyBookedDates: (state: IStore, action: { payload: any[] }) => {
      state.partiallyBookedDates = action.payload;
    },
    setPartiallyPendingDates: (state: IStore, action: { payload: any[] }) => {
      state.partiallyPendingDates = action.payload;
    },
    setAllPartialDates: (state: IStore, action: { payload: any[] }) => {
      state.allPartialDates = action.payload;
    },
    setReservedDates: (state: IStore, action: { payload: any[] }) => {
      state.reservedDates = action.payload;
    },
    setPartialDatesCount: (state: IStore, action: { payload: {} }) => {
      state.partialDatesCount = action.payload;
    },
    setPartialOrderRenterList: (state: IStore, action: { payload: {} }) => {
      state.partialOrderRenterList = action.payload;
    },
    setCalendarDateAngle: (state: IStore, action: { payload: {} }) => {
      state.calendarDateAngle = action.payload;
    },
    setAllPartialDatesTimes: (state: IStore, action: { payload: any[] }) => {
      state.allPartialDatesTimes = action.payload;
    },
    setCartProducts: (
      state: IStore,
      action: { payload: ProductsDataTypes[] }
    ) => {
      state.cartProducts = action.payload;
    },
    setStoreProducts: (state: IStore, action: { payload: ProductsType[] }) => {
      state.storeProducts = action.payload;
    },
    setMapEquipments: (state: IStore, action: ISetEquipmentActionType) => {
      if (action.payload.length > 0) {
        state.mapEquipments = action.payload;
      } else {
        state.mapEquipments = [];
      }
    },
    setBoundingBox: (state: IStore, action: { payload: mapBoundingBox }) => {
      state.boundingBox = action.payload;
    },
    setToggleFooterSwipper: (state: IStore, action: { payload: boolean }) => {
      state.toggleFooterSwipper = action.payload;
    },
  },
});

export const {
  setMapEquipments,
  setEquipments,
  setCategories,
  setLoadingEquipments,
  setModalOpen,
  setCount, //use for total records
  setCurrentPage, // use for current page
  setQrCodeValue, // for dynamic qr code value
  setLoginStatus, // Use for login status
  setaccessToken, // Access Token
  setLoginModalOpen, // use for open/close login popup modal
  setLogInLoading,
  setalertMessagetype, //use for alert message typs : suucess error
  setalertMessage, // use for dynamic alert message
  setSessionExpire, // set login session expire
  SetRedirectPage, // after login redirect page story
  setAuthuser, // save login user id
  setProfileStatus, // check user profile complete or not
  setAuthProfileData, // Set Auth Profile Object
  setNotificationCount, // Set all Notification count
  setNotificationList, // Set Notification listing
  setUnreadNotificationList,
  setNotificationListCount, // Set Notification listing count
  setWalletBalance, // Set wallet balance
  setRedirectToChat, //Check click on notification
  setEmailVerify, // User email verification status
  setAccountCompleted, // Check user account status completed or not
  setEquipListType, // by GD 24 Jan 2024 to for select rent or sale in find page
  setEquipSelectType, // by GD 14 Feb 2024 to for select rent or sale in how it works pages
  setEquipAddType,
  setFromHowItWorks,
  setEquipFilters,
  setSocialEmailVerifiedModalOpen,
  setEquipmentBookedDates,
  setEquipmentBlockedDates,
  setEquipmentRequestedDates,
  setPartiallyBookedDates,
  setPartiallyPendingDates,
  setRentOnWeekend,
  setAllPartialDates,
  setReservedDates,
  setPartialDatesCount,
  setPartialOrderRenterList,
  setCalendarDateAngle,
  setAllPartialDatesTimes,
  setCartProducts,
  setStoreProducts,
  setBoundingBox,
  setToggleFooterSwipper,
} = reduxSlice.actions;

export default reduxSlice.reducer;
