import { Box, Link, Typography } from "@mui/material";
import emptyheartIcon from "assets/images/favorite-blank.png";
import filledheartIcon from "assets/images/favorite-filled.png";
import CurrenyFormat from "components/common/CurrenyFormat";
import check from "assets/images/check.png";
import ForSale from "assets/images/for-sale.png";
import { useSelector } from "react-redux";
import { IStoreValue } from "utils/types";
import Carousel from "react-material-ui-carousel";
import { ArrowBack, ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { useState } from "react";
import placeholderImage from "assets/images/placeholder.png";
import LazyImage from "./LazyLoadImage";

const InfoCard = ({
  pricePerDay,
  pricePerMonth,
  pricePerWeek,
  equipTitle,
  equipModal,
  equipLocation,
  thumbnail,
  isBorder,
  likeStatus,
  equipListType,
  salePrice,
  rpoAvailable,
  obo,
  images,
  listView = false,
}) => {
  const isLoggedin = useSelector(
    (state: IStoreValue) => state.equipmentReducer.isLogin
  );
  return (
    <Box
      sx={{
        // outline: isBorder ? "3px solid #faa61a" : "3px solid rgba(0,0,0,0)",
        // "&:hover": {
        //   outline: "3px solid #faa61a",
        // },
        // outlineOffset: "5px",
        // boxShadow: isBorder
        //   ? "0px 0px 10px 0px #faa61a"
        //   : "0 0 0 0 rgba(0,0,0,0)",
        padding: "0px",
        borderRadius: "15px",
        mb: 0,
        mt: 0,
        width: "100%",
        boxSizing: "border-box",
        "&:hover": {
          outline: listView ? "2px solid #faa61a" : "none",
          boxShadow: listView
            ? "0px 0px 10px 0px rgba(233, 147, 35, 0.9)"
            : "none",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "nowrap",
          borderRadius: "15px",
          backgroundColor: "#3A3A3B",
          padding: "0px",
          overflow: "hidden",
          boxSizing: "border-box", // Ensures padding and borders are included in the card size
          maxWidth: "100%", // Prevents overflow in smaller widths
          cursor: "pointer",
          width: "100%",
        }}
      >
        {/* Image Section */}
        <Box
          sx={{
            position: "relative",
            // width: { xs: "35%", sm: "200px", md: "250px", lg: "180px" },
            width: "100%",
            // maxHeight: { xs: "120px", sm: "170px", md: "200px", lg: "170px" },
            height: listView ? "240px" : "170px",
            flexShrink: 0,
            boxSizing: "border-box",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "9px",
              right: listView ? "10px" : "45px",
              zIndex: 5,
            }}
          >
            {isLoggedin && (
              <Box
                component={"img"}
                src={likeStatus === "1" ? filledheartIcon : emptyheartIcon}
                sx={{
                  height: "auto",
                  width: "33px",
                  cursor: "pointer",
                }}
                className={`likeIcon ${likeStatus === "1" ? "liked" : ""}`}
              />
            )}
          </Box>
          {/* <Box
            component={"img"}
            src={thumbnail}
            // src="https://i.pinimg.com/474x/85/0e/d9/850ed9aa272e199ecbec87774a18dee1.jpg"
            alt=""
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
            }}
          /> */}
          <Carousel
            animation="slide"
            swipe={false}
            navButtonsAlwaysVisible={true}
            navButtonsAlwaysInvisible={false}
            cycleNavigation={images?.length > 1}
            autoPlay={false}
            indicators={images?.length > 1}
            sx={{
              "div:has(> .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium)":
                {
                  // backgroundColor: "transparent !important",
                  height: "40px",
                  top: "40%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                },
              "& .MuiButtonBase-root": {
                backgroundColor: "rgb(0 0 0 / 50%)",
                width: "28px",
                height: "28px",
                opacity: "1 !important",
                "&:hover": {
                  // backgroundColor: "#000 !important",
                  backgroundColor: "rgb(0 0 0 / 100%) !important",
                  opacity: "1 !important",
                },
              },
              buttonWrapper: {
                position: "absolute",
                height: "30px",
                backgroundColor: "transparent",
                top: "calc(50% - 70px)",
              },
              button: {
                position: "relative",
                backgroundColor: "#494949",
                color: "white",
                fontSize: "10px",
                cursor: "pointer",
                width: "28px",
                height: "28px",
                "&:hover": {
                  backgroundColor: "#000",
                  opacity: "1 !important",
                },
              },
              next: {
                right: 0,
              },
              prev: {
                left: 0,
              },
            }}
            indicatorIconButtonProps={{
              style: {
                backgroundColor: "#ffffff", // Default (non-active) color
                color: "transparent",
                height: "7px",
                width: "7px",
                marginRight: "5px",
                boxShadow: "0px 0px 5px 0px rgb(0 0 0 / 50%)",
                border: "1px solid #faa61a",
              },
            }}
            activeIndicatorIconButtonProps={{
              style: {
                backgroundColor: "#faa61a", // Active indicator color
                color: "transparent",
              },
            }}
            indicatorContainerProps={{
              style: {
                // marginTop: "82px",
                textAlign: "center",
                zIndex: 899,
                position: "absolute",
                background: "rgb(0 0 0 / 70%)",
                borderRadius: "30px",
                paddingLeft: "7px",
                paddingRight: "3px",
                // padding: "0",
                height: "20px",
                width: "auto",
                left: "50%",
                top: "85%",
                transform: "translate(-50%, -50%)",
              },
            }}
          >
            {images.map((img, idx) => (
              // <Box
              //   key={idx}
              //   component={"img"}
              //   src={img}
              //   alt="Equipment Images"
              //   onLoad={() => setIsLoaded(true)}
              //   sx={{
              //     width: "100%",
              //     height: listView ? "240px" : "170px",
              //     objectFit: "cover",
              //     borderTopLeftRadius: "10px",
              //     borderTopRightRadius: "10px",
              //   }}
              // />
              <LazyImage
                key={idx}
                src={img}
                placeholder={placeholderImage}
                listView={listView}
              />
            ))}
          </Carousel>
          {equipListType === "sale" && (
            <Box
              sx={{
                position: "absolute",
                top: "-5px",
                left: "-10px",
                background: "transparent",
                zIndex: "10",
                padding: "5px 15px",
                color: "#fff",
                fontFamily: "Gotham !important",
              }}
            >
              <Box
                component={"img"}
                src={ForSale}
                alt=""
                sx={{ width: "40px", height: "auto", display: "block" }}
              />
            </Box>
          )}
          {/* {equipListType === "rent" && (
            <Box
              sx={{
                backgroundColor: "#231F20",
                position: "absolute",
                right: "10px",
                bottom: "10px",
                padding: "2px 5px",
                borderRadius: "5px",
                boxSizing: "border-box",
              }}
            >
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: { xs: "10px", sm: "14px", md: "16px", lg: "18px" },
                  fontFamily: "GothamBold !important",
                }}
              >
                $<CurrenyFormat Currency={pricePerDay} trunc={true} />
                <Typography
                  component={"span"}
                  sx={{
                    color: "#faa61a",
                    fontFamily: "Gotham !important",
                    fontSize: { xs: "9px", sm: "11px", md: "12px", lg: "13px" },
                  }}
                >
                  /d
                </Typography>
              </Typography>
            </Box>
          )} */}
        </Box>

        {/* Text Section */}
        <Box
          className="text-view-item"
          sx={{
            flex: 1,
            padding: "10px 20px 10px 15px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            boxSizing: "border-box",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "1rem",
            }}
          >
            <Typography
              sx={{
                fontFamily: "GothamBold",
                color: "#fff",
                fontSize: { xs: "12px", sm: "14px", md: "16px", lg: "16px" },
                fontWeight: "700",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {equipTitle?.slice(0, listView ? 30 : 20)}
              {equipTitle?.length > (listView ? 30 : 20) ? "..." : ""}
            </Typography>
          </Box>
          <Typography
            sx={{
              fontFamily: "GothamBold",
              color: "#faa61a",
              fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "14px" },
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {equipModal?.slice(0, 30)}
            {equipModal?.length > 30 ? "..." : ""}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Gotham",
              color: "#faa61a",
              fontSize: { xs: "10px", sm: "14px", md: "14px" },
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              mt: "3px",
            }}
          >
            {equipLocation?.slice(0, 45)}
            {equipLocation?.length > 45 ? "..." : ""}
          </Typography>
          {equipListType === "rent" ? (
            <Box
              sx={{
                // display: "grid",
                // gridTemplateColumns: "repeat(3, 1fr)",
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
                padding: "0.5rem 0",
                // gap: "0",
                width: "100%",
                overflowX: "auto",
                "&::-webkit-scrollbar": {
                  width: "8px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#2d2d2d",
                  borderRadius: "4px",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: "#2d2d2d",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#2d2d2d",
                  borderRadius: "4px",
                },
                scrollbarWidth: "thin",
                scrollbarColor: "#149247 #2d2d2d",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: { xs: "10px", sm: "14px", md: "14px" },
                    fontFamily: "GothamBold !important",
                  }}
                >
                  $<CurrenyFormat Currency={pricePerMonth} trunc={true} />
                  <Typography
                    component={"span"}
                    sx={{
                      color: "#faa61a",
                      fontFamily: "Gotham !important",
                      fontSize: {
                        xs: "10px",
                        sm: "11px",
                        md: "12px",
                        lg: "13px",
                        xl: "14px",
                      },
                    }}
                  >
                    /m
                  </Typography>
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  borderLeft: "2px solid #faa61a",
                  borderRight: "2px solid #faa61a",
                  px: 1,
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: { xs: "10px", sm: "14px", md: "14px" },
                    fontFamily: "GothamBold !important",
                  }}
                >
                  $<CurrenyFormat Currency={pricePerWeek} trunc={true} />
                  <Typography
                    component={"span"}
                    sx={{
                      color: "#faa61a",
                      fontFamily: "Gotham !important",
                      fontSize: {
                        xs: "10px",
                        sm: "11px",
                        md: "12px",
                        lg: "13px",
                        xl: "14px",
                      },
                    }}
                  >
                    /w
                  </Typography>
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: { xs: "10px", sm: "14px", md: "14px" },
                    fontFamily: "GothamBold !important",
                  }}
                >
                  $<CurrenyFormat Currency={pricePerDay} trunc={true} />
                  <Typography
                    component={"span"}
                    sx={{
                      color: "#faa61a",
                      fontFamily: "Gotham !important",
                      fontSize: {
                        xs: "10px",
                        sm: "11px",
                        md: "12px",
                        lg: "13px",
                        xl: "14px",
                      },
                    }}
                  >
                    /d
                  </Typography>
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box
              className=""
              sx={{
                padding: "5px 0",
                width: "100%",
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "0",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "baseline",
                }}
              >
                {rpoAvailable === 1 && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: "1rem",
                      margin: "0rem 0",
                    }}
                  >
                    <Box
                      component={"img"}
                      src={check}
                      alt=""
                      style={{
                        width: "22px",
                        height: "22px",
                        marginTop: "0px",
                      }}
                    />
                    <Typography
                      sx={{
                        gap: "8px",
                        fontFamily: "Arial !important",
                        color: "#faa61a !important",
                        fontWeight: "700",
                      }}
                    >
                      RPO Avbl
                    </Typography>
                  </Box>
                )}
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "baseline",
                  justifyContent: "flex-end",
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: { xs: "10px", sm: "14px", md: "14px" },
                    fontFamily: "Gotham !important",
                    fontWeight: "600",
                  }}
                >
                  $<CurrenyFormat Currency={salePrice} />
                </Typography>
                &nbsp;
                {obo == 1 && (
                  <Typography
                    sx={{
                      gap: "8px",
                      fontFamily: "Arial !important",
                      color: "#faa61a !important",
                      fontWeight: "700",
                    }}
                  >
                    obo
                  </Typography>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default InfoCard;
