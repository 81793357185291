import { Box, Link, Typography } from '@mui/material';
import { Link as RLink } from 'react-router-dom';
import buildCrewImg from '../../../assets/images/howitworks/buildacrew.png';

import './styles.scss';

const BuildCrewEarn = () => {
    return (
        <Box className='buildcrew'>
            <Box className='hw-card-contentL'>
                <Box>
                    <Typography className='hw-card-heading'>
                        BUILD <span>A CREW & EARN</span><br />
                    </Typography>
                </Box>
                <Box>
                    {/* <Typography className='hw-card-text'>
                        It’s simple... sign up and refer to start
                        earning passive income from your crew’s
                        rentals, sales, loans, GPS, and more.
                        Let’s grow GoEquipMe together!
                    </Typography> */}
                    <Typography className='hw-card-text'>
                        It’s simple... sign up and refer to start
                        earning passive income from your crew’s
                        rentals, loans, GPS, and more.
                        Let’s grow GoEquipMe together!
                    </Typography>

                </Box>
                <Box>
                    {/* <Link className='hw-card-link'>
                        Tell Me More...
                    </Link> */}
                    <RLink className='hw-card-link' to="/howItWorksBuildCrew">
                        Tell Me More...
                    </RLink>
                </Box>
            </Box>
            <Box className='hw-card-img'>
                <img src={buildCrewImg} alt="" />
            </Box>
        </Box>
    )
};

export default BuildCrewEarn;
