import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
  Typography,
  SelectChangeEvent,
  Backdrop,
  CircularProgress,
  IconButton,
} from "@mui/material";
import MainLayout from "layouts/MainLayout";
import { useDispatch, useSelector } from "react-redux";
import { setalertMessagetype, setalertMessage } from "redux/redux-slice";
import { EquipmentAPIs } from "../../service/api-service";
import React, { useEffect, useRef, useState } from "react";
import AlertModal from "components/common/AlertModal";
import { useNavigate } from "react-router-dom";
import { IStoreValue } from "utils/types";
import CloseIcon from "@mui/icons-material/Close";
const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: "10px",
    "& fieldset": {
      border: "2px solid #faa61a",
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      borderColor: "#faa61a",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#faa61a",
    },
  },
  "& .MuiInputBase-input": {
    color: "black",
    backgroundColor: "#fff",
    fontFamily: "Montserrat !important",
    fontSize: "14px",
    fontWeight: "500",
  },
});

const dropDowntypes = [
  { value: "contact-us", label: "Contact Us" },
  { value: "report-bug", label: "Report Bug" },
];

const formInputDefaultValue = {
  feedback: "",
  contact: "",
  userName: "",
  userEmail: "",
};

const Contact = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputScreenShots = useRef(null);
  const AuthUserId = useSelector(
    (state: IStoreValue) => state.equipmentReducer.Authuser
  );
  const AuthToken = useSelector(
    (state: IStoreValue) => state.equipmentReducer.accessToken
  );
  const UserProfileData = useSelector(
    (state: IStoreValue) => state.equipmentReducer.AuthProfileData
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [formElement, setFormElement] = useState({ ...formInputDefaultValue });
  const [imagesUrl, setImagesUrl] = useState<Array<string>>([]);
  const [filesObject, setFilesObject] = useState<Array<File>>([]);
  const [message, setMessage] = useState<string>("");
  const [alertModalIsOpen, setAlertModalIsOpen] = useState<boolean>(false);
  const [activeDropDown, setActiveDropDown] = useState<string>("contact-us");

  const handleFormInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormElement((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const handleAlertModalOpen = () => setAlertModalIsOpen(true);
  const handleAlertModalClose = () => {
    setAlertModalIsOpen(false);
    navigate("/");
  };

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    try {
      event.preventDefault();
      const isReportedBug = activeDropDown === "report-bug";
      const email = formElement?.userEmail?.trim();
      if (isReportedBug) {
        if (!formElement?.userName?.trim()) {
          dispatch(setalertMessage("Please Enter your name."));
          dispatch(setalertMessagetype("error"));
          return false;
        }
        if (!formElement?.feedback?.trim()) {
          dispatch(
            setalertMessage("Please describe how the bug was encountered.")
          );
          dispatch(setalertMessagetype("error"));
          return false;
        }
      } else {
        const mailformat =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!email) {
          dispatch(setalertMessage("Please Enter your email."));
          dispatch(setalertMessagetype("error"));
          return false;
        } else if (!mailformat.test(email)) {
          dispatch(setalertMessage("Please Enter valid email."));
          dispatch(setalertMessagetype("error"));
          return false;
        }
      }

      if (
        !isReportedBug &&
        !formElement?.feedback?.trim() &&
        !formElement?.contact?.trim()
      ) {
        dispatch(
          setalertMessage(
            "Please Enter text in Contact support or web feedback"
          )
        );
        dispatch(setalertMessagetype("error"));
        return false;
      }

      const fileString = await uploadImagesToServer(filesObject);

      const params = {
        user_id: AuthUserId,
        feedback: isReportedBug
          ? formElement?.feedback?.trim()
          : formElement?.contact?.trim(),
        contact: isReportedBug
          ? formElement?.contact?.trim()
          : formElement?.feedback?.trim(),
        feedback_type: activeDropDown?.replace("-", "_"),
        user_name: formElement?.userName?.trim(),
        email,
        images: activeDropDown === "report-bug" ? fileString : "",
        request_from: "Web",
      };
      setLoading(true);
      const response = await EquipmentAPIs.sendContactMail(params);
      if (response["type"] === "RXSUCCESS") {
        setMessage(response["message"]);
        handleAlertModalOpen();
        setFormElement({ ...formInputDefaultValue });
        setImagesUrl([]);
        setFilesObject([]);
      } else {
        throw new Error(response["message"]);
      }
    } catch (err) {
      dispatch(setalertMessage(err?.message));
      dispatch(setalertMessagetype("error"));
    } finally {
      setLoading(false);
    }
  };

  const removeImage = (image: string, index: number) => {
    setImagesUrl((prev) => prev?.filter((_, i) => i !== index));
    setFilesObject((prev) => prev?.filter((_, i) => i !== index));
  };

  const handleDropDownChange = (
    event: SelectChangeEvent<typeof activeDropDown>
  ) => {
    const value = event.target.value;
    setFormElement({ ...formInputDefaultValue });
    setImagesUrl([]);
    setFilesObject([]);
    setActiveDropDown(value);
  };

  const uploadImagesToServer = async (files: Array<File>) => {
    const headers = {
      Authorization: AuthToken,
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      request_from: "Web",
    };
    try {
      setLoading(true);
      const uploadPromises = files.map(async (file) => {
        const formData = new FormData();
        formData.append("image", file);
        formData.append("name", file.name);
        return await EquipmentAPIs.uploadEquipImage(formData, headers);
      });

      const responses = await Promise.all(uploadPromises);
      if (responses[0]?.["status"] === "200") {
        const fileArrayString = responses?.map(
          (response) => response?.data?.image
        );
        return fileArrayString?.join(",");
      } else {
        throw new Error("Error while uploading file please try again!");
      }
    } catch (error) {
      console.error("Upload failed:", error);
      return "";
    } finally {
      setLoading(false);
    }
  };

  const handleScreenShotsChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = Array.from(event.target.files);
    if (imagesUrl?.length + files.length > 5 || files.length > 5) {
      dispatch(setalertMessage("You can select a maximum of 5 files."));
      dispatch(setalertMessagetype("error"));
      inputScreenShots.current.value = null;
      return;
    }
    const objectURLs = Array.from(files).map((file) =>
      URL.createObjectURL(file)
    );
    setImagesUrl((prev) => [...prev, ...objectURLs]);
    setFilesObject((prev) => [...prev, ...files]);
    return () => objectURLs.forEach((url) => URL.revokeObjectURL(url));
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  useEffect(() => {
    if (UserProfileData && Object.entries(UserProfileData).length > 0) {
      const fullName = `${UserProfileData["first_name"]} ${(
        UserProfileData["last_name"] || ""
      )?.trim()}`;
      const email = (UserProfileData["email"] || "")?.trim();
      if (activeDropDown === "report-bug") {
        setFormElement((prev) => ({
          ...prev,
          userName: fullName,
        }));
      } else {
        setFormElement((prev) => ({
          ...prev,
          userEmail: email,
        }));
      }
    }
  }, [UserProfileData, activeDropDown]);

  const isReportBug = activeDropDown === "report-bug";

  return (
    <MainLayout>
      <Box>
        <Container maxWidth="md">
          {loading && (
            <Box>
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            {/* <Box
              component={"img"}
              src=""
              sx={{
                width: {
                  xs: "2rem",
                  sm: "2rem",
                  md: "2.4rem",
                  lg: "2.6rem",
                  xl: "2.8rem",
                },
                height: {
                  xs: "2rem",
                  sm: "2rem",
                  md: "2.4rem",
                  lg: "2.6rem",
                  xl: "2.8rem",
                },
                objectFit: "contain",
              }}
            /> */}
            <Typography
              sx={{
                fontWeight: "700",
                fontFamily: "GothamBold !important",
                fontSize: {
                  xs: "2rem",
                  sm: "2rem",
                  md: "2.4rem",
                  lg: "2.6rem",
                  xl: "2.8rem",
                },
                color: "#fff",
              }}
            >
              Contact Us / Report Bug
            </Typography>
          </Box>

          <Box sx={{}}>
            <FormControl
              fullWidth
              sx={{
                m: 0,
                width: 170,
                mt: 3,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#faa61a", // Default border color
                  },
                  "&:hover fieldset": {
                    borderColor: "#faa61a", // Border color on hover
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#faa61a", // Border color when focused
                  },
                },
              }}
            >
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={activeDropDown}
                onChange={handleDropDownChange}
                sx={{
                  fontFamily: "Montserrat !important",
                  fontWeight: "600",
                  "& .MuiSelect-select": {
                    color: "#fff", // Text color inside select
                  },
                  "& .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined":
                    {
                      color: "#faa61a !important",
                      fontSize: "40px !important",
                    },
                }}
              >
                {dropDowntypes.map((items) => (
                  <MenuItem
                    value={items?.value}
                    key={items?.value}
                    sx={{
                      fontFamily: "Montserrat !important",
                      fontWeight: "600",
                      "&:hover": {
                        backgroundColor: "#faa61a", // Hover background color for non-selected item
                        color: "#000", // Optional: Hover text color for non-selected item
                      },
                      "&.Mui-selected": {
                        backgroundColor: "#efefef !important", // Selected item background color
                        color: "black", // Selected item text color
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor: "#faa61a !important", // Hover background color for selected item
                        color: "#000 !important", // Hover text color for selected item
                      },
                    }}
                  >
                    {items?.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box
            component={"form"}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              mt: 4,
            }}
          >
            <Box
              sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  sx={{
                    color: "#faa61a",
                    fontFamily: "Montserrat !important",
                    fontWeight: "600",
                  }}
                >
                  {isReportBug ? "Name" : "Email"} *
                </Typography>
              </Box>
              <CustomTextField
                multiline
                rows={1} // Number of rows for the multiline textarea
                variant="outlined"
                fullWidth
                InputProps={{
                  style: {
                    backgroundColor: "#fff", // Ensures background is white
                  },
                }}
                sx={{
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "50%",
                  },
                }}
                name={isReportBug ? "userName" : "userEmail"}
                value={
                  isReportBug ? formElement?.userName : formElement?.userEmail
                }
                onChange={handleFormInputChange}
              />
            </Box>
            {/* {isReportBug && (
            )} */}
            <Box
              sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                {!isReportBug && (
                  <Typography
                    sx={{
                      color: "#faa61a",
                      fontFamily: "GothamBold !important",
                      fontSize: "18px",
                    }}
                  >
                    Contact Support
                  </Typography>
                )}

                <Typography
                  sx={{
                    color: isReportBug ? "#faa61a" : "#fff",
                    fontFamily: "Montserrat !important",
                    fontWeight: isReportBug ? "600" : "400",
                  }}
                >
                  {isReportBug
                    ? "Describe how the bug was encountered *"
                    : "Please tell us how we can help. We will answer as soon as possible."}
                </Typography>
              </Box>
              <CustomTextField
                multiline
                rows={4}
                variant="outlined"
                fullWidth
                InputProps={{
                  style: {
                    backgroundColor: "#fff",
                  },
                }}
                name="feedback"
                value={formElement?.feedback}
                onChange={handleFormInputChange}
              />
            </Box>
            <Box
              sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                {!isReportBug && (
                  <Typography
                    sx={{
                      color: "#faa61a",
                      fontFamily: "GothamBold !important",
                      fontSize: "18px",
                    }}
                  >
                    Web Feedback
                  </Typography>
                )}
                <Typography
                  sx={{
                    color: isReportBug ? "#faa61a" : "#fff",
                    fontFamily: "Montserrat !important",
                    fontWeight: isReportBug ? "600" : "400",
                  }}
                >
                  {isReportBug
                    ? "What type of phone or device are you using?"
                    : "We would love to hear back from you on how we are doing and where we might be falling short. If there are things that don't work or bugs you have encounted please tell us."}
                </Typography>
              </Box>
              <CustomTextField
                multiline
                rows={isReportBug ? 1 : 4} // Number of rows for the multiline textarea
                variant="outlined"
                fullWidth
                InputProps={{
                  style: {
                    backgroundColor: "#fff", // Ensures background is white
                  },
                }}
                name="contact"
                value={formElement?.contact}
                onChange={handleFormInputChange}
              />
            </Box>
            {isReportBug && (
              <>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr",
                    gap: "1rem",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      sx={{
                        color: "#faa61a",
                        fontFamily: "Montserrat !important",
                        fontWeight: "600",
                      }}
                    >
                      Please attach any screenshots of the bug you encountered
                    </Typography>
                  </Box>
                  <Button
                    variant="contained"
                    component="label"
                    sx={{
                      fontFamily: "Montserrat !important",
                      fontWeight: "700",
                      backgroundColor: "transparent",
                      color: "#faa61a",
                      border: "2px dotted #fff",
                      padding: ".5rem",
                      borderRadius: "10px",
                      "&:hover": {
                        backgroundColor: "#faa61a",
                        color: "#fff",
                      },
                      width: {
                        xs: "100%",
                        sm: "100%",
                        md: "50%",
                      },
                      height: {
                        xs: "40px",
                        md: "50px",
                      },
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                    onClick={() => {
                      inputScreenShots.current?.click();
                    }}
                  >
                    Upload Screenshots
                  </Button>
                  <input
                    type="file"
                    hidden
                    multiple
                    accept=".jpg,.jpeg,.png"
                    onChange={handleScreenShotsChange}
                    id="bugs-screenshots"
                    ref={inputScreenShots}
                  />
                  <Box
                    component="span"
                    sx={{
                      fontWeight: "400",
                      fontSize: "12px",
                      textTransform: "capitalize",
                      mt: 0,
                      wdith: "100%",
                      textAlign: "left",
                      color: "#faa61a",
                      fontFamily: "Montserrat !important",
                    }}
                  >
                    (Formats Accepted : JPG, JPEG, PNG *)
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: {
                      xs: "center",
                      md: "flex-start",
                    },
                    alignItems: "center",
                    gap: "1rem",
                    flexWrap: "wrap",
                  }}
                >
                  {imagesUrl.map((image, index) => (
                    <Box
                      key={index}
                      sx={{
                        position: "relative",
                        display: "inline-block",
                        // margin: "10px",
                        borderRadius: "8px",
                        overflow: "hidden",
                      }}
                    >
                      {/* Close Button in Upper Right Corner */}
                      <IconButton
                        onClick={() => removeImage(image, index)}
                        sx={{
                          position: "absolute",
                          top: "5px",
                          right: "5px",
                          width: "18px",
                          height: "18px",
                          background: "#faa61a",
                          color: "#000",
                          "&:hover": {
                            background: "#d18300",
                          },
                        }}
                        size="small"
                      >
                        <CloseIcon sx={{ fontSize: "14px" }} />
                      </IconButton>

                      {/* Image Preview */}
                      <Box
                        component="img"
                        src={image}
                        alt={`preview-${index}`}
                        sx={{
                          height: "140px",
                          width: {
                            // xs:'100%',
                            xs: "140px",
                            md: "140px",
                          },
                          objectFit: "cover",
                          borderRadius: "8px",
                          border: "1px solid #ccc",
                        }}
                      />
                    </Box>
                  ))}
                </Box>
              </>
            )}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                mt: 3,
              }}
            >
              <Button
                type="button"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "8px",
                  backgroundColor: "#149247",
                  color: "#fff",
                  fontSize: "20px",
                  fontFamily: "Montserrat !important",
                  fontWeight: "700",
                  minHeight: "unset",
                  minWidth: "unset",
                  padding: "0.4rem 1rem",
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "40%",
                  },
                  textTransform: "uppercase",
                  "&:hover": {
                    backgroundColor: "#106f37",
                  },
                }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Box>
          </Box>

          {alertModalIsOpen && (
            <AlertModal
              message={message}
              alertModalIsOpen={alertModalIsOpen}
              handleAlertModalClose={handleAlertModalClose}
            />
          )}
        </Container>
      </Box>
    </MainLayout>
  );
};

export default Contact;
