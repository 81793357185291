import React from "react";
import Carousel from "react-material-ui-carousel";
import Typography from "@mui/material/Typography";
import { IEquipment } from "utils/types";
import { useDispatch } from "react-redux";
import { setModalOpen } from "redux/redux-slice";
import { Box, Link } from "@mui/material";
import "./styles.scss";

import ThumbsImg from "assets/images/favorite-filled.png";

import ForSale from "assets/images/for-sale.png";
import CurrentFormat from "../common/CurrenyFormat";
import check from "assets/images/check.png";

interface IEquipmentItemProps {
  item: IEquipment;
  type: string;
}
const EquipmentItem: React.FC<IEquipmentItemProps> = ({
  item,
  type = null,
}) => {
  const dispatch = useDispatch();
  const onCardClick = () => {
    // navigate('equipment/' + item.id)
    dispatch(setModalOpen(true));
  };
  let images = item.images.split(",");

  // added by GD 29 Jan 2024 due to empty image showing
  images = images.filter((value) => value);

  const deliveryAvailable = true;

  // const name = item.make.replace(/ /g,"-");
  // const link = '/equipments/'+item.id+'/'+name;
  var make = item.make ? item.make : "NA";
  // make = make.replace("/", "'");
  // make = make.replace(/ /g, "-");
  // by GD 12 Feb 2024 commented upper two line due to error in namespace and added encodeURIComponent(make)make in link
  // make = make.replace("/", "");

  var city = item.city ? item.city : "NA";
  city = city.replace("/", "'");
  city = city.replace(/ /g, "-");

  var state = item.state ? item.state : "NA";
  state = state.replace("/", "'");
  state = state.replace(/ /g, "-");

  var owner_id = item.user_id;
  var postal_code = item.postal_code != "undefined" ? item.postal_code : 0;
  postal_code = postal_code != "" ? postal_code : 0;

  let link = "";
  if (type != "equipDetail") {
    // link = '/detail/' + make + '/' + city + '/' + state + '/' + item.id;
    // by GD due to error in namespace
    link =
      "/detail/" +
      encodeURIComponent(make) +
      "/" +
      city +
      "/" +
      state +
      "/" +
      item.id;
    // link = '/detail/' + encodeURI(make) + '/' + city + '/' + state + '/' + item.id;
  } else {
    // link = '/detail/' + make + '/' + city + '/' + state + '/' + postal_code + '/' + owner_id + '/' + item.id;
    // by GD due to error in namespace
    link =
      "/detail/" +
      encodeURIComponent(make) +
      "/" +
      city +
      "/" +
      state +
      "/" +
      postal_code +
      "/" +
      owner_id +
      "/" +
      item.id;
    // link = '/detail/' + encodeURI(make) + '/' + city + '/' + state + '/' + postal_code + '/' + owner_id + '/' + item.id;
  }
  link = link.replace("#", "");
  // added by GD due to encoding of / error in server only works fine on local // by GD due to error in namespace
  link = link.replace(/%2F/g, "%252F");
  // gd end
  let list_type = "rent";
  if (item.equip_list_type == "sale") {
    list_type = "sale";
  }
  link = link + "/" + list_type;

  return (
    <>
      {/* onClick={onCardClick} */}
      <Box className="item-container" sx={{ boxSizing:'border-box', width:'100%' }}>
        <Box className="carousel-wrapper">
          {item.equip_list_type == "sale" && (
            <Box
              sx={{
                position: "absolute",
                top: "-5px",
                left: "-10px",
                background: "transparent",
                zIndex: "10",
                padding: "5px 15px",
                color: "#fff",
                fontFamily: "Gotham !important",
              }}
            >
              <img
                src={ForSale}
                alt=""
                style={{ width: "60px", height: "auto", display: "block" }}
              />
              {/* <Typography>For Sale</Typography> */}
            </Box>
          )}
          {item?.like_status == "1" && <Box
            className=""
            sx={{
              position: "absolute",
              top: "-16px",
              right: "-16px",
              width: "30px",
              height: "auto",
              background: "transparent",
              zIndex: "10",
              display: "block",
              objectFit: "contain",
              padding: "5px",
            }}
          >
            <Box
              component={"img"}
              className=""
              src={ThumbsImg}
              alt=""
              sx={{
                width: "100%",
                height: "100%",
                display: "block",
                objectFit: "contain",
              }}
            />
          </Box>}

          <Carousel
            animation="slide"
            navButtonsAlwaysVisible={true}
            navButtonsAlwaysInvisible={false}
            cycleNavigation={false}
            autoPlay={false}
            indicators={false}
            className="carousel-item"
          >
            {images.map((img, idx) => (
              <Link
                href={link}
                key={idx}
                target="_blank"
                className="equipment-item-details"
                sx={{ textDecoration: "none !important" }}
              >
                <img
                  src={img}
                  key={idx}
                  alt="Equipment Thumbnail"
                  className="carousel-img"
                />
              </Link>
            ))}
          </Carousel>
        </Box>
        <Link
          href={link}
          target="_blank"
          className="equipment-item-details"
          sx={{ textDecoration: "none !important" }}
        >
          <Box
            sx={{
              textAlign: "left",
            }}
          >
            {/* <Typography className='item-make'>{item.make}</Typography> */}
            <Typography
              className="item-make"
              title={item.make}
              sx={{
                display: "-webkit-box",
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 1,
              }}
            >
              {item.make ? item.make : ""}
              {item.make == null || (item.make == "" && <br />)}
            </Typography>
            <Typography
              className="item-model"
              title={item.model}
              sx={{
                display: "-webkit-box",
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 1,
              }}
            >
              {item.model ? item.model : ""}
              {item.model == null || (item.model == "" && <br />)}
            </Typography>
            <Box className="item-city-wrapper">
              <Typography
                className="item-city"
                title={item.city ? item.city + ", " + item.state : item.state}
                sx={{
                  display: "-webkit-box",
                  overflow: "hidden",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 1,
                }}
              >
                {item.city ? item.city : "NA"}
                {item.state ? ", " + item.state : ""}
                {item.city == null ||
                  (item.city == "" && item.state == null) ||
                  (item.state == "" && <br />)}
              </Typography>
            </Box>
            <Box
              className="d-flex"
              sx={{
                alignItems: "center !important",
                // overflowX: "auto",
                // "&::-webkit-scrollbar": {
                //   height: "4px",
                //   width: "2px",
                //   border: "1px solid #d5d5d5",
                // },
              }}
            >
              {item.equip_list_type !== "sale" ? (
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",
                    padding: "0.5rem 0",
                    gap: "10px",
                    width: "100%",
                    overflowX: "auto",
                    "&::-webkit-scrollbar": {
                      width: "8px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#2d2d2d",
                      borderRadius: "4px",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                      backgroundColor: "#2d2d2d",
                    },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "#2d2d2d",
                      borderRadius: "4px",
                    },
                    scrollbarWidth: "thin",
                    scrollbarColor: "#149247 #2d2d2d",
                  }}
                >
                  <Box
                    // className="item-value-content item-value-content-numbers"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      // overflowX:'auto'
                    }}
                  >
                    <Typography
                      // className="item-price"
                      sx={{
                        color: "#fff",
                        fontSize: {
                          xs: "14px",
                          sm: "16px",
                          md: "18px",
                          lg: "20px",
                          xl: "22px",
                        },
                        fontFamily: "GothamBold !important",
                      }}
                    >
                      $
                      <CurrentFormat Currency={item.price_month} trunc={true} />
                      {/* {parseInt(item.price_month).toLocaleString("en-US")} */}
                      <Typography
                        component={"span"}
                        // className="rentyrd-rates-span"
                        sx={{
                          color: "#faa61a",
                          fontFamily: "Gotham !important",
                          fontSize: {
                            xs: "10px",
                            sm: "11px",
                            md: "12px",
                            lg: "13px",
                            xl: "14px",
                          },
                        }}
                      >
                        /m
                      </Typography>
                    </Typography>
                  </Box>

                  <Box
                    // className="item-value-content item-value-content-numbers"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      borderLeft: "2px solid gray",
                      borderRight: "2px solid gray",
                    }}
                  >
                    <Typography
                      // className="item-price"
                      sx={{
                        color: "#fff",
                        fontSize: {
                          xs: "14px",
                          sm: "16px",
                          md: "18px",
                          lg: "20px",
                          xl: "22px",
                        },
                        fontFamily: "GothamBold !important",
                      }}
                    >
                      $
                      <CurrentFormat
                        Currency={item.price_per_week}
                        trunc={true}
                      />
                      {/* {parseInt(item.price_per_week).toLocaleString("en-US")} */}
                      <Typography
                        component={"span"}
                        // className="rentyrd-rates-span"
                        sx={{
                          color: "#faa61a",
                          fontFamily: "Gotham !important",
                          fontSize: {
                            xs: "10px",
                            sm: "11px",
                            md: "12px",
                            lg: "13px",
                            xl: "14px",
                          },
                        }}
                      >
                        /w
                      </Typography>
                    </Typography>
                  </Box>

                  <Box
                    // className="item-value-content item-value-content-numbers"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      // className="item-price"
                      sx={{
                        color: "#fff",
                        fontSize: {
                          xs: "14px",
                          sm: "16px",
                          md: "18px",
                          lg: "20px",
                          xl: "22px",
                        },
                        fontFamily: "GothamBold !important",
                      }}
                    >
                      $
                      <CurrentFormat Currency={item.price_day} trunc={true} />
                      {/* {parseInt(item.price_day).toLocaleString("en-US")} */}
                      <Typography
                        component={"span"}
                        // className="rentyrd-rates-span"
                        sx={{
                          color: "#faa61a",
                          fontFamily: "Gotham !important",
                          fontSize: {
                            xs: "10px",
                            sm: "11px",
                            md: "12px",
                            lg: "13px",
                            xl: "14px",
                          },
                        }}
                      >
                        /d
                      </Typography>
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <Box
                  className=""
                  sx={{
                    padding: "5px 0",
                    width: "100%",
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: "0",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "baseline",
                    }}
                  >
                    {item.rpo_available == 1 && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          gap: "1rem",
                          margin: "0rem 0",
                        }}
                      >
                        <img
                          src={check}
                          alt=""
                          style={{
                            width: "22px",
                            height: "22px",
                            marginTop: "0px",
                          }}
                        />
                        <Typography sx={{ gap: "8px" }}>
                          <span
                            className="radio-span-yellow"
                            style={{
                              fontFamily: "",
                              color: "#faa61a !important",
                            }}
                          >
                            RPO Avbl
                          </span>
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "baseline",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Typography className="item-price">
                      $
                      <span>
                        <CurrentFormat Currency={item.equip_sale_price} />
                      </span>
                    </Typography>
                    &nbsp;
                    <Typography className="item-price-unit">
                      {item.obo == 1 && <>obo</>}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Link>
      </Box>
    </>
  );
};

export default EquipmentItem;
