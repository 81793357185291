import React from "react";
import { Popper, Fade, Paper, ClickAwayListener, Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Slider from "react-slick";
import InfoCard from "./InfoCard"; // Ensure correct import path

const MapPopper = ({
  anchorEl,
  showMapData,
  popPosition,
  selectedEquip,
  settings,
  handleMarkerClick,
  handlePopperClose,
}) => {
  return (
    <Popper
      open={Boolean(anchorEl && showMapData)}
      anchorEl={anchorEl}
      placement={popPosition}
      sx={{
        zIndex: 1500,
        borderRadius: "10px",
        "& .MuiPopper-root": {
          backgroundColor: "transparent !important",
          boxShadow: "unset !important",
        },
      }}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} in={Boolean(anchorEl && showMapData)} timeout={300}>
          <Paper
            sx={{
              background: "transparent",
              boxShadow: "none",
              borderRadius: "10px",
            }}
          >
            <ClickAwayListener onClickAway={(event) => handlePopperClose(event, "away")}>
              <Box
                sx={{
                  width: "300px",
                  backgroundColor: "transparent",
                  borderRadius: "10px",
                }}
              >
                <Box sx={{ width: "100%", background: "transparent" }} className="map-popover">
                  {selectedEquip.length > 0 && (
                    <Slider {...settings}>
                      {selectedEquip.map((equip, index) => (
                        <Box key={index} sx={{ width: "100%" }}>
                          <Box
                            key={equip.id}
                            onClick={(event) =>
                              handleMarkerClick(event, {
                                id: equip.id,
                                price_day: equip.price_day,
                                lat: equip?.lat,
                                lng: equip?.lng,
                                title: equip?.make,
                                userId: equip?.user_id,
                                equipListType: equip?.equip_list_type,
                              })
                            }
                            sx={{
                              cursor: "pointer",
                              position: "relative",
                            }}
                          >
                            <IconButton
                              onClick={(event) => handlePopperClose(event, "")}
                              sx={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                                bgcolor: "#2d2d2d",
                                color: "#000",
                                zIndex: "2000",
                                p: 0,
                                width: "32px",
                                height: "32px",
                                border: "2px solid #a0938c",
                                "&:hover": { bgcolor: "#000" },
                              }}
                            >
                              <CloseIcon sx={{ fontSize: "22px", color: "#babcbf" }} />
                            </IconButton>
                            <InfoCard
                              pricePerDay={equip.price_day || 0}
                              pricePerMonth={equip.price_month || 0}
                              pricePerWeek={equip.price_per_week || 0}
                              equipTitle={equip.make || "N/A"}
                              equipModal={equip.model || "N/A"}
                              equipLocation={
                                !equip.city && !equip.state
                                  ? "N/A"
                                  : !equip.city
                                  ? `N/A, ${equip.state}`
                                  : `${equip.city}, ${equip.state}`
                              }
                              thumbnail={equip.thumbnail}
                              isBorder={false}
                              likeStatus={equip?.like_status}
                              equipListType="rent"
                              salePrice={equip?.equip_sale_price || 0}
                              rpoAvailable={equip?.rpo_available}
                              obo={equip?.obo}
                              images={equip?.images?.split(",")}
                            />
                          </Box>
                        </Box>
                      ))}
                    </Slider>
                  )}
                </Box>
              </Box>
            </ClickAwayListener>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
};

export default MapPopper;
