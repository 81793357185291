import {
  Modal,
  Box,
  IconButton,
  Backdrop,
  CircularProgress,
  Typography,
  Rating,
  Avatar,
  Card,
  CardContent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { customRound } from "utils/commonFxns";
import { EquipmentAPIs } from "service/api-service";
import momentTimezone from "moment-timezone";
import { useSelector } from "react-redux";
import { IStoreValue } from "utils/types";
import dummyProfile from "assets/images/dummy-profile-pic.jpeg";

interface RentalRatingType {
  avg_rating: number;
  created_at: string;
  full_name: string;
  id: number;
  review: string;
  timezone: string;
  profile_pic: string;
}
const limit = 5;

const ShowReviewModal = ({
  isReviewModalOpen,
  handleReviewModalClose,
  ownerId,
  ownerFirstName,
  ownerProfilePic,
}) => {
  const AuthProfileData = useSelector(
    (state: IStoreValue) => state.equipmentReducer.AuthProfileData
  );
  const [reviewLoader, setReviewLoader] = useState<boolean>(true);
  const [reviewData, setReviewData] = useState<Array<RentalRatingType>>([]);
  const [totalReview, setTotalReview] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const callPagination = useRef<boolean>(true);

  const maxPage = useMemo(
    () => (totalReview > limit ? Math.floor(totalReview / limit) : 0),
    [totalReview]
  );

  const getRentalReview = async (page = 0) => {
    if (page > maxPage) return; // Stop if all reviews are loaded

    try {
      setReviewLoader(true);
      callPagination.current = false;
      const response = await EquipmentAPIs.getRentalReview({
        owner_id: ownerId,
        limit,
        page,
      });

      if (response["type"] === "RXSUCCESS") {
        setReviewData((prev) => [...prev, ...response.data]); // Append new reviews
        if (page === 0) {
          setTotalReview(response.total);
        }
        setTimeout(() => {
          callPagination.current = true;
        }, 100);
      } else {
        throw new Error(response["message"] ?? "Something went wrong!");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setReviewLoader(false);
    }
  };

  // Handle scroll event
  const handleScroll = () => {
    if (!scrollContainerRef.current || reviewLoader) return;

    const container = scrollContainerRef.current;
    const scrollBottom =
      container.scrollHeight - container.scrollTop - container.clientHeight;
    if (scrollBottom < 150 && callPagination.current) {
      // Load more when the user is close to the bottom
      setPage((prev) => prev + 1);
    }
  };

  useEffect(() => {
    if (isReviewModalOpen) {
      getRentalReview(0);
    }
  }, [isReviewModalOpen]);

  useEffect(() => {
    if (page > 0) {
      getRentalReview(page);
    }
  }, [page]);

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [reviewData]);

  return (
    <Modal open={isReviewModalOpen} onClose={handleReviewModalClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "90%", sm: "500px" },
          maxHeight: "80vh",
          //   minHeight: "50vh",
          bgcolor: "#2e2e2e",
          border: "5px solid #faa61a",
          borderRadius: "10px",
          outline: "none !important",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <IconButton
          onClick={handleReviewModalClose}
          sx={{
            position: "absolute",
            top: "-20px",
            right: "-20px",
            bgcolor: "#faa61a",
            color: "#000",
            "&:hover": {
              bgcolor: "#faa61a",
            },
          }}
        >
          <CloseIcon />
        </IconButton>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: 2,
            p: 2,
            ml: 2,
            // width: "100%",
            bgcolor: "#2d2d2d",
            color: "#fff",
          }}
        >
          <Avatar
            src={ownerProfilePic} // Replace with actual image URL
            alt="Owner Avatar"
            sx={{ width: 40, height: 40 }}
          />

          <Typography
            variant="h6"
            sx={{
              color: "#fff",
              fontWeight: "bold",
              textTransform: "capitalize",
            }}
          >
            {ownerFirstName}'s Reviews
          </Typography>
        </Box>

        {/* Scrollable Container */}
        <Box
          ref={scrollContainerRef}
          sx={{
            flex: 1,
            overflowY: "auto",
            p: 2,
          }}
        >
          {reviewData.map((reviewsData) => (
            <Card
              key={reviewsData?.id}
              sx={{
                width: "100%",
                maxWidth: 500,
                bgcolor: "#2d2d2d",
                color: "#fff",
                borderRadius: "12px",
                mb: 2,
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                    mb: 1,
                  }}
                >
                  <Avatar
                    alt="Renter Avatar"
                    src={reviewsData?.profile_pic ?? dummyProfile}
                    sx={{
                      // bgcolor: "#faa61a",
                      // textTransform: "uppercase",
                      width: 40,
                      height: 40,
                    }}
                  />
                  {/* {reviewsData?.full_name?.trim()?.[0]}
                  </Avatar> */}
                  <Box>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: "bold",
                        color: "#faa61a",
                        textTransform: "capitalize",
                      }}
                    >
                      {reviewsData?.full_name?.trim()}
                    </Typography>
                    <Typography variant="caption" sx={{ color: "gray" }}>
                      {momentTimezone
                        .tz(
                          reviewsData["created_at"],
                          AuthProfileData["timezone"] ?? "America/Denver"
                        )
                        .format("hh:mm A MM/DD/YY")}
                    </Typography>
                  </Box>
                </Box>

                <Rating
                  name="customized-5"
                  precision={0.5}
                  value={customRound(reviewsData?.avg_rating)}
                  max={5}
                  sx={{ color: "#faa61a" }}
                  emptyIcon={
                    <StarBorderIcon sx={{ color: "gray" }} fontSize="inherit" />
                  }
                  readOnly
                />

                <Typography
                  variant="body2"
                  sx={{ mt: 1, color: "#fff", whiteSpace: "pre-line" }}
                >
                  {reviewsData?.review?.trim()}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Box>
        {reviewLoader && (
          <Box
            sx={{
              display: "flex",
              textAlign: "center",
              justifyContent: "center",
              color: "#faa61a",
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default ShowReviewModal;
