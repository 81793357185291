import React, {
  ChangeEvent,
  createContext,
  useEffect,
  useState,
  useRef,
} from "react";
import GemStoreChild from "./GemStoreChild";
import { IStoreValue, ProductsType } from "utils/types";
import { EquipmentAPIs } from "service/api-service";
import { useDispatch, useSelector } from "react-redux";
import {
  setStoreProducts,
  setalertMessage,
  setalertMessagetype,
} from "redux/redux-slice";

const products = [
  {
    id: 1,
    title: "Wireless Headphones",
    description: "High-quality wireless headphones with noise cancellation.",
    thumbnail:
      "https://rukminim2.flixcart.com/image/612/612/xif0q/t-shirt/a/m/c/s-aka6-msdhoni7-csk-yellow2024-teky-original-imagymt6zgzh4wzf.jpeg",
    price: 99.99,
  },
  {
    id: 2,
    title: "Smart Watch",
    description: "Stylish smart watch with fitness tracking features.",
    thumbnail:
      "https://rukminim2.flixcart.com/image/612/612/xif0q/t-shirt/a/m/c/s-aka6-msdhoni7-csk-yellow2024-teky-original-imagymt6zgzh4wzf.jpeg",
    price: 149.99,
  },
  {
    id: 3,
    title: "Bluetooth Speaker",
    description:
      "Portable Bluetooth speaker with deep bass and long battery life.",
    thumbnail:
      "https://rukminim2.flixcart.com/image/612/612/xif0q/t-shirt/a/m/c/s-aka6-msdhoni7-csk-yellow2024-teky-original-imagymt6zgzh4wzf.jpeg",
    price: 49.99,
  },
  {
    id: 4,
    title: "Gaming Mouse",
    description:
      "Ergonomic gaming mouse with customizable buttons and RGB lighting.",
    thumbnail:
      "https://rukminim2.flixcart.com/image/612/612/xif0q/t-shirt/a/m/c/s-aka6-msdhoni7-csk-yellow2024-teky-original-imagymt6zgzh4wzf.jpeg",
    price: 39.99,
  },
  {
    id: 5,
    title: "4K Monitor",
    description: "27-inch 4K UHD monitor with stunning picture quality.",
    thumbnail:
      "https://rukminim2.flixcart.com/image/612/612/xif0q/t-shirt/a/m/c/s-aka6-msdhoni7-csk-yellow2024-teky-original-imagymt6zgzh4wzf.jpeg",
    price: 299.99,
  },
];

export const StoreContext = createContext(null);

interface ProductQtySizeType {
  [key: number]: {
    size: number;
    qty: number | "";
  };
}

const GemStoreContext = ({ children }) => {
  const dispatch = useDispatch();
  const AuthToken = useSelector(
    (state: IStoreValue) => state.equipmentReducer.accessToken
  );
  const AuthUserId = useSelector(
    (state: IStoreValue) => state.equipmentReducer.Authuser
  );
  const storeProducts = useSelector(
    (state: IStoreValue) => state.equipmentReducer.storeProducts
  );
  // const [storeProducts, setStoreProducts] = useState<ProductsType[]>([]);

  const [cartTotal, setCartTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  // const [ownerProducts, setOwnerProducts] = useState<ProductsType>({
  //   title: "",
  //   total: 0,
  //   data: [],
  // });
  const [addCartPopup, setAddCartPopup] = useState<boolean>(false);
  const [productQtySize, setProductQtySize] = useState<ProductQtySizeType>(
    {} as ProductQtySizeType
  );

  const actionedProductData = (productId) => {
    const productData = storeProducts
      .map(({ data }) =>
        data.find(({ product_id }) => product_id === productId)
      )
      .find(Boolean);
    return productData;
  };

  const handleAddCart = async (productId: number) => {
    try {
      // Stops at the first non-null match
      const productData = actionedProductData(productId);
      if (productData?.has_sizes && !productQtySize[productId]?.size) {
        dispatch(setalertMessage("Please select size first!"));
        dispatch(setalertMessagetype("error"));
        return false;
      }
      if (!productQtySize[productId]?.qty) {
        dispatch(setalertMessage("Please fill the quantity first!"));
        dispatch(setalertMessagetype("error"));
        return false;
      }
      setLoading(true);
      const params = {
        user_id: AuthUserId,
        product_id: productId,
        size: productQtySize[productId]?.size,
        quantity: productQtySize[productId]?.qty,
      };
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const response = await EquipmentAPIs.addToCart(params, headers);
      if (response["type"] === "RXSUCCESS") {
        console.log("response", response);
        setCartTotal(response?.data?.cart_total);
        setAddCartPopup(true);
      } else {
        throw new Error(response["message"]);
      }
    } catch (error) {
      console.log("add to cart error", error);
      dispatch(setalertMessage(error.message));
      dispatch(setalertMessagetype("error"));
    } finally {
      setLoading(false);
    }
  };
  const handleCartPopupOpenClose = () => setAddCartPopup(false);
  const handleQtySizeChanges = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    id: number,
    type: string
  ) => {
    let value = 0;
    const productData = actionedProductData(id);
    const hasSize = productData?.has_sizes;
    if (hasSize && !productQtySize[id]?.size && type === "qty") {
      dispatch(setalertMessage("Please select size first!"));
      dispatch(setalertMessagetype("error"));
      return false;
    }
    if (
      (type === "size" && event.target instanceof HTMLParagraphElement) ||
      event.target instanceof HTMLDivElement
    ) {
      value = parseFloat(event.target.getAttribute("data-value"));
    } else {
      value = parseFloat(event.target.value);
    }
    if (type === "size" && Number.isNaN(value)) return;

    let availableQty: {
      stock_quantity: number;
    };
    if (hasSize) {
      availableQty = productData?.stock_data?.find(
        (product) =>
          product?.product_size_id ===
          Number(`${type === "qty" ? productQtySize[id]?.size : value}`)
      );
    } else {
      availableQty = productData?.stock_data?.[0];
    }
    const availability = availableQty?.stock_quantity;
    if (type === "size" && availability === 0) {
      // dispatch(
      //   setalertMessage("Insufficient stock available. Adjust your quantity.")
      // );
      // dispatch(setalertMessagetype("error"));
      return false;
    } else if (type === "qty" && value > availability) {
      dispatch(
        setalertMessage("Insufficient stock available. Adjust your quantity.")
      );
      dispatch(setalertMessagetype("error"));
      return false;
    }
    if (type === "size") {
      setProductQtySize((prev) => ({
        ...prev,
        [id]: {
          ...prev[id],
          size: value,
          qty: 1,
        },
      }));
    } else if (type.includes("qty")) {
      setProductQtySize((prev) => ({
        ...prev,
        [id]: {
          ...prev[id],
          qty: value || "",
        },
      }));
    }
  };

  const fetchProduct = async () => {
    try {
      setLoading(true);
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const response = await EquipmentAPIs.getStoreProduct(
        { user_id: AuthUserId },
        headers
      );
      if (response["type"] === "RXSUCCESS") {
        const strProducts = response?.data;
        const combined = [];
        strProducts?.forEach((productsInfo) => {
          combined.push(...productsInfo?.data);
        });
        const pdtSizeQty = combined.reduce((acc, product) => {
          acc[product.product_id] = {
            size: 0,
            qty: 1,
          };
          return acc;
        }, {} as ProductQtySizeType);
        setProductQtySize(pdtSizeQty);
        dispatch(setStoreProducts(strProducts));
        setCartTotal(response?.cart_total);
      } else {
        throw new Error(response["message"] || "An error occured!");
      }
    } catch (error) {
      dispatch(setalertMessage(error.message));
      dispatch(setalertMessagetype("error"));
      console.log("first", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchProductTimer = useRef(null);
  useEffect(() => {
    clearTimeout(fetchProductTimer.current);
    window.scrollTo({ top: 0, behavior: "smooth" });
    fetchProductTimer.current = setTimeout(() => {
      fetchProduct();
    }, 500);
  }, [AuthUserId]);
  return (
    <StoreContext.Provider
      value={{
        loading,
        addCartPopup,
        cartTotal,
        handleAddCart,
        handleCartPopupOpenClose,
        productQtySize,
        handleQtySizeChanges,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

const GemStore = () => {
  return (
    <GemStoreContext>
      <GemStoreChild />
    </GemStoreContext>
  );
};

export default GemStore;
