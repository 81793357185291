import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import dummyItem from "../../assets/images/dummy-item-img.png";
import { Direction } from "react-toastify/dist/utils";
import CurrenyFormat from "components/common/CurrenyFormat";
import React, { useContext, useState } from "react";
import { ProductsDataTypes } from "utils/types";
import { Add, Remove } from "@mui/icons-material";
import { Link } from "react-router-dom";
import Carousel from "react-material-ui-carousel";
const inputStyles = {
  border: "0px solid gray",
  color: "#2d2d2d",
  borderRadius: "4px",
  outline: "none",
  boxShadow: "unset",
  width: "50px",
  backgroundColor: "#e3e3e3",
  padding: {
    xs: "0.4rem",
    sm: "0.5rem",
  },
  fontSize: {
    xs: "12px",
    sm: "14px",
  },
  fontFamily: "Gotham !important",
  direction: "rtl",
  "&::-webkit-inner-spin-button": {
    opacity: 1,
    display: "block",
  },
  "&::-webkit-outer-spin-button": {
    opacity: 1,
    display: "block",
  },
};

const selectStyles = {
  border: "0px solid gray",
  color: "#2d2d2d",
  borderRadius: "4px",
  outline: "none",
  boxShadow: "unset",
  width: "66px",
  backgroundColor: "#e3e3e3",
  padding: {
    xs: "0.4rem",
    sm: "0.5rem",
  },
  fontSize: {
    xs: "12px",
    sm: "14px",
  },
  fontFamily: "Gotham !important",
  textTransform: "uppercase",
};

type ProductQtySizeType = {
  [productId: string]: {
    size: string;
    qty: number;
  };
};

type StoreCardProps = {
  storeType: string;
  product: ProductsDataTypes;
  handleCart: (e: number) => void;
  productQtySize: ProductQtySizeType; // Adjust the type according to your actual data type
  handleQtySizeChanges: (
    e:
      | React.FormEvent<HTMLDivElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.FormEvent<SVGSVGElement>,
    size: number,
    type: string
  ) => void; // Adjust as per function requirement
  handleImageClick?: (b,a: string[]) => void;
};
const descLen = 145;
const StoreCard: React.FC<StoreCardProps> = ({
  storeType,
  product,
  handleCart,
  productQtySize,
  handleQtySizeChanges,
  handleImageClick,
}) => {
  let price = product.price;
  let productId = product.product_id;
  if (storeType === "cart") {
    productId = product?.cart_id;
    price = product?.price * productQtySize[productId]?.qty;
  }
  const isCart = storeType === "cart";
  const [expandDescription, setExpandDescription] = useState<boolean>(false);
  const description = product?.description || "";
  const isLongDescription = description.length > descLen;
  const displayedText = expandDescription
    ? description
    : description.slice(0, descLen) + (isLongDescription ? " ..." : "");
  const lowerProductName = product?.product_name?.toLowerCase()?.trim();
  const showDetails =
    lowerProductName?.includes("wired gps") ||
    lowerProductName?.includes("battery gps");
  const images = product?.images?.split(",");

  // let availableQuantity = 0;
  // if (!isCart && !product?.has_sizes) {
  //   availableQuantity = product?.stock_data?.[0]?.stock_quantity;
  // } else if (isCart) {
  //   const sizeId = product?.product_size;
  //   if (!sizeId) {
  //     availableQuantity = product?.stock_data?.[0]?.stock_quantity;
  //   } else {
  //     const productWithSize = product?.stock_data?.find(
  //       (prod) => prod?.product_size_id === sizeId
  //     );
  //     if (productWithSize) {
  //       availableQuantity = productWithSize?.stock_quantity;
  //     }
  //   }
  // }

  let isAddToCartActive = false;
  let isQuatityActive = true;
  if (!isCart) {
    isQuatityActive = product?.has_sizes
      ? product?.stock_data?.some(({ stock_quantity }) => stock_quantity > 0)
      : product?.stock_data?.[0]?.stock_quantity > 0;
    isAddToCartActive = !isQuatityActive;
  } else {
    // const sizeId = product?.has_sizes ? product?.product_size : 0;
    // isQuatityActive = sizeId
    //   ? product?.stock_data?.find((prod) => prod?.product_size_id === sizeId)
    //       ?.stock_quantity >= productQtySize[productId]?.qty
    //   : product?.stock_data?.[0]?.stock_quantity >=
    //     productQtySize[productId]?.qty;
    isQuatityActive =
      product?.stock_quantity >= productQtySize[productId]?.qty &&
      product?.available_status;
  }

  return (
    <Box
      sx={{
        backgroundColor: "#48473F",
        padding: "10px 15px",
        borderRadius: "10px",
        boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.5)",
        width: "100%",
        boxSizing: "border-box",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          gap: "1rem",
          height: "100%",
          flexDirection: isCart ? "row-reverse" : "row",
        }}
      >
        {!isCart ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
              width: {
                xs: "70%",
                md: "100%",
              },
              height: "100%",
              flexGrow: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                gap: "0.5rem",
              }}
            >
              <Tooltip
                arrow
                title={product?.product_name}
                placement="top"
                componentsProps={{
                  tooltip: {
                    sx: {
                      fontSize: "16px",
                      backgroundColor: "#000",
                      color: "#fff",
                      padding: "8px 12px",
                      borderRadius: "10px",
                    },
                  },
                  arrow: {
                    sx: {
                      color: "#000",
                    },
                  },
                }}
              >
                <Typography
                  sx={{
                    color: "#faa61a",
                    fontFamily: "GothamBold !important",
                    fontSize: {
                      xs: "16px",
                      sm: "18px",
                      md: "24px",
                    },
                    display: "-webkit-box",
                    overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 1,
                  }}
                >
                  {product?.product_name ?? "N/A"}
                </Typography>
              </Tooltip>
              <Typography
                sx={{
                  color: "#fff",
                  fontFamily: "Gotham !important",
                  fontWeight: 700,
                  fontSize: {
                    xs: "16px",
                    sm: "18px",
                    md: "24px",
                  },
                }}
              >
                $<CurrenyFormat Currency={price || 0} />
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
                height: "100%",
              }}
            >
              <Typography
                sx={{
                  color: "#fff",
                  fontFamily: "Gotham !important",
                  fontSize: { xs: "11px", sm: "12px", md: "14px" },
                  // display: "-webkit-box",
                  // overflow: "hidden",
                  // WebkitBoxOrient: "vertical",
                  // WebkitLineClamp: 10,
                  minHeight: "65px",
                }}
                onClick={() =>
                  isLongDescription && setExpandDescription((prev) => !prev)
                }
              >
                {displayedText}
                {isLongDescription && (
                  <Typography
                    sx={{
                      display: "inline-block",
                      marginLeft: "3px",
                      color: "#faa61a",
                      cursor: "pointer",
                      fontSize: "12px",
                    }}
                  >
                    {expandDescription ? "" : "more"}
                  </Typography>
                )}
                {showDetails && (
                  <Link
                    style={{
                      textDecoration: "underline",
                      textDecorationColor: "#faa61a",
                      marginLeft: "5px",
                      color: "#faa61a",
                      cursor: "pointer",
                      fontSize: "14px",
                      justifySelf: "flex-end",
                      fontFamily: "Montserrat !important",
                      fontWeight: "500",
                    }}
                    to="/equipment-tracking"
                  >
                    Details
                  </Link>
                )}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  // justifyContent: "space-between",
                  gap: "0.5rem",
                  width: "100%",
                  boxSizing: "border-box",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    // justifyContent: "space-between",
                    gap: "0.5rem",
                    height: "45px",
                  }}
                  onClick={(event) => {
                    if (!isCart) {
                      handleQtySizeChanges(event, productId, "size");
                    }
                  }}
                >
                  {/* {product?.has_sizes && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.5rem",
                    }}
                  >
                    <Box
                      component={"select"}
                      sx={{
                        ...selectStyles,
                        background: isCart ? "#808080" : "transparent",
                        color: "#faa61a",
                        border: "2px solid #faa61a",
                        borderRadius: "10px",
                        fontFamily: "Montserrat !important",
                        fontWeight: "600",
                        pr: 2,
                        px: 1,
                        py: 0.5,
                        width: "120px",
                        height: "38px",
                      }}
                      value={productQtySize[productId]?.size}
                      onChange={(event) =>
                        handleQtySizeChanges(event, productId, "size")
                      }
                      disabled={isCart}
                    >
                      <option value="" disabled>
                        Size
                      </option>
                      {!isCart &&
                        product?.stock_data?.map((stock) => (
                          <option
                            value={stock?.product_size_id}
                            key={stock?.product_size_id}
                          >
                            {stock?.size_name}
                          </option>
                        ))}
                      {isCart && (
                        <option value={product?.size_short_name}>
                          {product?.size_short_name}
                        </option>
                      )}
                     
                    </Box>

                   
                  </Box>
                )} */}
                  {product?.has_sizes && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0.5rem",
                      }}
                    >
                      {product?.stock_data?.map((stock) => {
                        const isBackGround =
                          Number(productQtySize[productId]?.size) ==
                          Number(stock?.product_size_id);
                        const sizeLength = stock?.size_name?.length;
                        const hasStockAvailable = stock?.stock_quantity > 0;
                        return (
                          <Box
                            sx={{
                              borderRadius: "10px",
                              width: {
                                xs: `${
                                  sizeLength > 3
                                    ? "30px !important"
                                    : "26px !important"
                                }`,
                                md: `${
                                  sizeLength > 3
                                    ? "35px !important"
                                    : "30px !important"
                                }`,
                              },
                              height: {
                                xs: "26px !important",
                                md: "30px !important",
                              },
                              border: "2px solid #faa61a",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              cursor: hasStockAvailable ? "pointer" : "default",
                              background: isBackGround
                                ? "#faa61a"
                                : "transparent",
                              opacity: hasStockAvailable ? 1 : 0.3,
                            }}
                            key={stock?.product_size_id}
                            data-value={stock?.product_size_id}
                          >
                            <Typography
                              sx={{
                                color: isBackGround ? "#000" : "#faa61a",
                                fontSize: "12px",
                                fontFamily: "Montserrat !important",
                                fontWeight: "700",
                                textTransform: "uppercase",
                              }}
                              data-value={stock?.product_size_id}
                            >
                              {stock?.size_name?.slice(0, 4)}
                            </Typography>
                          </Box>
                        );
                      })}
                    </Box>
                  )}
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  {isQuatityActive ? (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0.5rem",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          gap: "0.5rem",
                          border: "2px solid #faa61a",
                          borderRadius: "10px",
                          py: 0,
                          px: 1,
                          height: {
                            xs: "30px",
                            md: "38px",
                          },
                          width: {
                            xs: "80px !important",
                            md: "100px !important",
                          },
                        }}
                      >
                        <Remove
                          sx={{
                            color: "#fff",
                            fontSize: "16px",
                            cursor: "pointer",
                          }}
                          onClick={(event) => {
                            event.preventDefault();
                            const newQty = Math.max(
                              1,
                              (productQtySize[productId]?.qty || 1) - 1
                            );

                            // Create a properly typed synthetic event
                            const changeEvent = {
                              target: { value: newQty.toString() },
                            } as React.ChangeEvent<HTMLInputElement>;

                            handleQtySizeChanges(changeEvent, productId, "qty");
                          }}
                        />

                        <Box
                          component="input"
                          type="number"
                          // placeholder="1"
                          min={0}
                          defaultValue={1}
                          value={productQtySize[productId]?.qty}
                          onChange={(event) =>
                            handleQtySizeChanges(event, productId, "qty")
                          }
                          sx={{
                            background: "transparent !important",
                            border: "none !important",
                            outline: "none !important",
                            boxShadow: "none !important",
                            textAlign: "center",
                            fontFamily: "Montserrat !important",
                            fontWeight: "600",
                            color: "#faa61a",
                            fontSize: "16px",
                            width: `${Math.max(
                              String(productQtySize[productId]?.qty || 1)
                                .length,
                              1
                            )}ch`, // Auto adjust width
                            minWidth: "2ch", // Prevent shrinking too much
                            "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                              {
                                WebkitAppearance: "none",
                                margin: 0,
                              },
                            "&": {
                              MozAppearance: "textfield",
                            },
                          }}
                        />

                        <Add
                          sx={{
                            color: "#fff",
                            fontSize: "16px",
                            cursor: "pointer",
                          }}
                          onClick={(event) => {
                            event.preventDefault();
                            const newQty = Math.max(
                              1,
                              (productQtySize[productId]?.qty || 1) + 1
                            );

                            // Create a properly typed synthetic event
                            const changeEvent = {
                              target: { value: newQty.toString() },
                            } as React.ChangeEvent<HTMLInputElement>;

                            handleQtySizeChanges(changeEvent, productId, "qty");
                          }}
                        />
                      </Box>
                    </Box>
                  ) : (
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "0.5rem",
                        border: "none",
                        background: "#9393939c",
                        borderRadius: "10px",
                        fontFamily: "Montserrat !important",
                        fontWeight: "600",
                        color: "#fff",
                        fontSize: { xs: "12px", md: "14px" },
                        py: 0,
                        px: 1,
                        height: {
                          xs: "30px",
                          md: "38px",
                        },
                        width: {
                          xs: "80px !important",
                          md: "120px !important",
                        },
                      }}
                    >
                      Out of Stock
                    </Typography>
                  )}
                  <Button
                    sx={{
                      color: "#fff",
                      fontSize: {
                        xs: "10px",
                        sm: "12px",
                        md: "14px",
                        lg: "16px",
                        xl: "16px",
                      },
                      px: "12px",
                      fontFamily: "Montserrat !important",
                      fontWeight: "700",
                      textTransform: "initial",
                      width: {
                        xs: "fit-content",
                        md: "fit-content",
                      },
                      borderRadius: "10px",
                      backgroundColor: "#149247",
                      "&:hover": {
                        backgroundColor: "#1E8130",
                      },
                    }}
                    disabled={isAddToCartActive}
                    onClick={() => handleCart(productId)}
                  >
                    {"Add to Cart"}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  gap: "0.5rem",
                }}
              >
                <Tooltip
                  arrow
                  title={product?.product_name}
                  placement="top"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        fontSize: "16px",
                        backgroundColor: "#000",
                        color: "#fff",
                        padding: "8px 12px",
                        borderRadius: "10px",
                      },
                    },
                    arrow: {
                      sx: {
                        color: "#000",
                      },
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: "#faa61a",
                      fontFamily: "GothamBold !important",
                      fontSize: {
                        xs: "16px",
                        sm: "18px",
                        md: "24px",
                      },
                      display: "-webkit-box",
                      overflow: "hidden",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 1,
                    }}
                  >
                    {product?.product_name ?? "N/A"}
                  </Typography>
                </Tooltip>
                <Typography
                  sx={{
                    color: "#fff",
                    fontFamily: "Gotham !important",
                    fontWeight: 700,
                    fontSize: {
                      xs: "14px",
                      sm: "18px",
                      md: "24px",
                    },
                  }}
                >
                  $<CurrenyFormat Currency={price || 0} />
                </Typography>
              </Box>

              {product?.has_sizes && (
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Montserrat !important",
                      color: "#fff",
                      fontWeight: "500",
                      fontSize: {
                        xs: "12px",
                        md: "16px",
                      },
                    }}
                  >
                    Size :
                  </Typography>
                  <Box
                    sx={{
                      borderRadius: {
                        xs: "7px",
                        md: "10px",
                      },
                      width: {
                        xs: `${
                          product?.size_short_name?.length > 3
                            ? "30px !important"
                            : "24px !important"
                        }`,
                        md: `${
                          product?.size_short_name?.length > 3
                            ? "33px !important"
                            : "26px !important"
                        }`,
                      },
                      height: {
                        xs: "22px !important",
                        md: "22px !important",
                      },
                      border: "2px solid #faa61a",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      background: "#faa61a",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#000",
                        fontSize: "12px",
                        fontFamily: "Montserrat !important",
                        fontWeight: "700",
                      }}
                    >
                      {product?.size_short_name?.slice(0, 4)}
                    </Typography>
                  </Box>
                  {/* <Button
                  sx={{
                    color: isCart ? "#faa61a" : "#fff",
                    fontSize: {
                      xs: "10px",
                      sm: "12px",
                      md: "14px",
                      lg: "16px",
                      xl: "16px",
                    },
                    px: "12px",
                    fontFamily: "Montserrat !important",
                    fontWeight: "700",
                    textTransform: "initial",
                    textDecoration: isCart ? "underline" : "none",
                    textDecorationColor: isCart ? "#faa61a" : "#fff",
                    width: {
                      xs: "fit-content",
                      md: "fit-content",
                    },
                    borderRadius: "10px",
                    backgroundColor: isCart ? "transparent" : "#149247",
                    "&:hover": {
                      backgroundColor: isCart ? "transparent" : "#1E8130",
                    },
                  }}
                  onClick={() => handleCart(productId)}
                >
                  {isCart ? "Remove" : "Add to Cart"}
                </Button> */}
                </Box>
              )}
              <Box
                sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
              >
                <Typography
                  sx={{
                    fontFamily: "Montserrat !important",
                    color: "#fff",
                    fontWeight: "500",
                    fontSize: {
                      xs: "12px",
                      md: "16px",
                    },
                  }}
                >
                  Quantity :
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: {
                        xs: "12px",
                        md: "16px",
                      },
                      fontFamily: "Montserrat !important",
                      fontWeight: "700",
                    }}
                  >
                    {productQtySize[productId]?.qty}
                  </Typography>
                </Box>
                <Button
                  sx={{
                    color: "#faa61a",
                    fontSize: {
                      xs: "10px",
                      sm: "12px",
                      md: "14px",
                      lg: "16px",
                      xl: "16px",
                    },
                    px: "12px",
                    fontFamily: "Montserrat !important",
                    fontWeight: "700",
                    textTransform: "initial",
                    textDecoration: "underline",
                    textDecorationColor: "#faa61a",
                    width: {
                      xs: "fit-content",
                      md: "fit-content",
                    },
                    borderRadius: "10px",
                    "&:hover": {
                      backgroundColor: "unset",
                      textDecoration: "underline",
                      color: "#db9521",
                    },
                  }}
                  onClick={() => handleCart(productId)}
                >
                  {"Remove"}
                </Button>
              </Box>
            </Box>
            {isQuatityActive ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "0.5rem",
                  border: "2px solid #faa61a",
                  borderRadius: "10px",
                  py: 0,
                  px: 1,
                  height: {
                    xs: "30px",
                    md: "38px",
                  },
                  width: {
                    xs: "70px !important",
                    md: "100px !important",
                  },
                }}
              >
                <Remove
                  sx={{
                    color: "#fff",
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                  onClick={(event) => {
                    event.preventDefault();
                    const newQty = Math.max(
                      1,
                      (productQtySize[productId]?.qty || 1) - 1
                    );

                    // Create a properly typed synthetic event
                    const changeEvent = {
                      target: { value: newQty.toString() },
                    } as React.ChangeEvent<HTMLInputElement>;

                    handleQtySizeChanges(changeEvent, productId, "qty");
                  }}
                />

                <Box
                  component="input"
                  type="number"
                  placeholder="1"
                  min={1}
                  value={productQtySize[productId]?.qty}
                  onChange={(event) =>
                    handleQtySizeChanges(event, productId, "qty")
                  }
                  sx={{
                    background: "transparent !important",
                    border: "none !important",
                    outline: "none !important",
                    boxShadow: "none !important",
                    textAlign: "center",
                    fontFamily: "Montserrat !important",
                    fontWeight: "600",
                    color: "#faa61a",
                    fontSize: "16px",
                    width: `${Math.max(
                      String(productQtySize[productId]?.qty || 1).length,
                      1
                    )}ch`, // Auto adjust width
                    minWidth: "2ch", // Prevent shrinking too much
                    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                      {
                        WebkitAppearance: "none",
                        margin: 0,
                      },
                    "&": {
                      MozAppearance: "textfield",
                    },
                  }}
                />

                <Add
                  sx={{
                    color: "#fff",
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                  onClick={(event) => {
                    event.preventDefault();
                    const newQty = Math.max(
                      1,
                      (productQtySize[productId]?.qty || 1) + 1
                    );

                    // Create a properly typed synthetic event
                    const changeEvent = {
                      target: { value: newQty.toString() },
                    } as React.ChangeEvent<HTMLInputElement>;

                    handleQtySizeChanges(changeEvent, productId, "qty");
                  }}
                />
              </Box>
            ) : (
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "0.5rem",
                  border: "none",
                  background: "#9393939c",
                  borderRadius: "10px",
                  fontFamily: "Montserrat !important",
                  fontWeight: "600",
                  color: "#fff",
                  fontSize: { xs: "8px", md: "12px" },
                  py: 0,
                  px: 1,
                  height: {
                    xs: "30px",
                    md: "38px",
                  },
                  width: {
                    xs: "80px !important",
                    md: "120px !important",
                  },
                }}
              >
                Out of Stock
              </Typography>
            )}
          </Box>
        )}
        {/* <Box
          component={"img"}
          src={product?.thumbnail}
          alt=""
          sx={{
            width: {
              xs: "100px",
              sm: "140px",
              md: "150px",
            },
            height: "200px",
            objectFit: "cover",
          }}
        /> */}
        <Box
          sx={{
            width: {
              xs: "30%",
              sm: "140px",
              md: "190px",
            },
            height: {
              xs: isCart ? "110px" : "200px",
              md: isCart ? "150px" : "200px",
            },
            // objectFit: "cover",
            objectFit: "contain",
            cursor: isCart ? "default" : "pointer",
          }}
          onClick={(event) => {
            if (!isCart) {
              handleImageClick(event,images);
            }
          }}
        >
          <Carousel
            animation="slide"
            swipe={false}
            navButtonsAlwaysVisible={true}
            navButtonsAlwaysInvisible={false}
            cycleNavigation={images?.length > 1}
            autoPlay={false}
            indicators={images?.length > 1}
            sx={{
              "div:has(> .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium)":
                {
                  // backgroundColor: "transparent !important",
                  height: "40px",
                  top: "40%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                },
              "& .MuiButtonBase-root": {
                backgroundColor: "rgb(0 0 0 / 50%)",
                width: "28px",
                height: "28px",
                opacity: "1 !important",
                "&:hover": {
                  // backgroundColor: "#000 !important",
                  backgroundColor: "rgb(0 0 0 / 100%) !important",
                  opacity: "1 !important",
                },
              },
              buttonWrapper: {
                position: "absolute",
                height: "30px",
                backgroundColor: "transparent",
                top: "calc(50% - 70px)",
              },
              button: {
                position: "relative",
                backgroundColor: "#494949",
                color: "white",
                fontSize: "10px",
                cursor: "pointer",
                width: "28px",
                height: "28px",
                "&:hover": {
                  backgroundColor: "#000",
                  opacity: "1 !important",
                },
              },
              next: {
                right: 0,
              },
              prev: {
                left: 0,
              },
            }}
            indicatorIconButtonProps={{
              style: {
                backgroundColor: "#ffffff", // Default (non-active) color
                color: "transparent",
                height: "7px",
                width: "7px",
                marginRight: "5px",
                boxShadow: "0px 0px 5px 0px rgb(0 0 0 / 50%)",
                border: "1px solid #faa61a",
              },
            }}
            activeIndicatorIconButtonProps={{
              style: {
                backgroundColor: "#faa61a", // Active indicator color
                color: "transparent",
              },
            }}
            indicatorContainerProps={{
              style: {
                // marginTop: "82px",
                textAlign: "center",
                zIndex: 899,
                position: "absolute",
                background: "rgb(0 0 0 / 70%)",
                borderRadius: "30px",
                paddingLeft: "7px",
                paddingRight: "3px",
                // padding: "0",
                height: "20px",
                width: "auto",
                left: "50%",
                top: "80%",
                transform: "translate(-50%, -50%)",
              },
            }}
          >
            {images.map((img, idx) => (
              <Box
                key={idx}
                component={"img"}
                src={img}
                alt="Equipment Images"
                sx={{
                  // width: {
                  //   xs: "100px",
                  //   sm: "140px",
                  //   md: "150px",
                  // },
                  width: "100%",
                  height: {
                    xs: isCart ? "110px" : "200px",
                    md: isCart ? "150px" : "200px",
                  },
                  // objectFit: "cover",
                  objectFit: "contain",
                }}
                data-index={idx}
              />
            ))}
          </Carousel>
        </Box>
      </Box>
    </Box>
  );
};

export default StoreCard;
