import {
  Box,
  CircularProgress,
  ClickAwayListener,
  Container,
  Fade,
  IconButton,
  Paper,
  Popover,
  Popper,
  PopperPlacementType,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useRef, useState, useCallback } from "react";
import InfoCard from "./InfoCard";
import { IEquipment, IStoreValue } from "utils/types";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  Circle,
} from "@react-google-maps/api";
import greenDotIcon from "assets/images/GPS/rectangle-marker-grn.png";
import orangeDotIcon from "assets/images/GPS/rectangle-marker-org.png";
import clusterIconGreen from "assets/images/GPS/cluster-icon-green.png";
import clusterIconOrange from "assets/images/GPS/cluster-icon-orange.png";
import CurrenyFormat from "components/common/CurrenyFormat";
import { useSelector, useDispatch } from "react-redux";
import { EquipmentAPIs } from "service/api-service";
import {
  setLoginModalOpen,
  setalertMessage,
  setalertMessagetype,
  setSessionExpire,
  setEquipments,
  setCount,
  setBoundingBox,
} from "redux/redux-slice";
import CloseIcon from "@mui/icons-material/Close";
import emptyheartIcon from "assets/images/favorite-blank.png";
import filledheartIcon from "assets/images/favorite-filled.png";
import EquipmentItem from "components/equipment-item/EquipmentItem";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import MapPopper from "./MapPopper";
import mapLoader from "assets/images/mapLoaderTheme.gif";

const APIKEY = process.env.REACT_APP_GEOCODDER_KEY;

interface MarkerKeys {
  lat: number;
  lng: number;
  id: number;
  price_day: string;
  title: string;
  userId?: string;
  equipListType?: string;
  likeStatus?: string;
}
interface PopOverPos {
  vertical: "top" | "center" | "bottom";
  horizontal: "left" | "right" | "center";
}

interface FetchParams {
  radius?: number;
  maxLat?: number | null;
  minLat?: number | null;
  maxLng?: number | null;
  minLng?: number | null;
  lat?: string | null;
  lng?: string | null;
  zoomLevel: number;
}

// const proximityThreshold = 0.000051;
const proximityThreshold = 0.1;
// const centerPL = {
//   lat: 39.4781974,
//   lng: -113.7905753,
// };
const centerPL = {
  lat: 39.78373,
  lng: -100.445882,
};
const ftBoundR = 861111;

const USA_BOUNDS = {
  north: 59.07905839835499, // Max latitude
  south: 20.45600818294818, // Min latitude
  west: -154.87647181193, // Min longitude
  east: -53.626471811929996, // Max longitude
};

const MapResult = ({
  // equipments,
  keyword,
  zipCode,
  startDate,
  endDate,
  locationRange,
  listEquipType,
  category,
  mapBoxRef,
  showMapData,
  fitbound,
  setFitBound,
  isEquipLiked,
  handleLikeEquipments,
  fetchData,
  loading,
  activeMarker,
  setActiveMarker,
}: {
  keyword: string;
  zipCode: string;
  startDate: string;
  endDate: string;
  locationRange: string;
  listEquipType: string;
  category: string;
  mapBoxRef: React.RefObject<HTMLDivElement>;
  showMapData: boolean;
  fitbound: string;
  setFitBound: (a: string) => void;
  isEquipLiked: React.MutableRefObject<{
    id: number;
    likeStatus: string;
    status: boolean;
  } | null>;
  handleLikeEquipments: (a: {
    element: HTMLImageElement;
    equipId: number;
    likeType: string;
    listOfClasses: unknown[];
  }) => void;
  fetchData: (params: FetchParams) => void;
  loading: Boolean;
  activeMarker: Array<number>;
  setActiveMarker: (a: number[]) => void;
}) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const dispatch = useDispatch();
  const AuthUserId = useSelector(
    (state: IStoreValue) => state.equipmentReducer.Authuser
  );
  const AuthToken = useSelector(
    (state: IStoreValue) => state.equipmentReducer.accessToken
  );
  const isLoggedin = useSelector(
    (state: IStoreValue) => state.equipmentReducer.isLogin
  );
  const equipments = useSelector(
    (state: IStoreValue) => state.equipmentReducer.mapEquipments
  );

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: APIKEY,
  });
  const mapview = useRef(null);
  const [map, setMap] = useState<google.maps.Map | null>(null); // Explicitly typed
  const [markerLocation, setMarkerLocation] = useState<Array<MarkerKeys[]>>([]);
  // const [filterEquipment, setFilterEquipment] = useState<IEquipment[]>([]);
  // const [activeMarker, setActiveMarker] = useState<Array<number>>([]);
  const [firstInitialized, setFirstInitialized] = useState<boolean>(true);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedEquip, setSelectedEquip] = useState<IEquipment[]>([]);
  const [popoverPosition, setPopoverPosition] = useState<PopOverPos>({
    vertical: "center",
    horizontal: "center",
  });
  const [popPosition, setPopPosition] = useState<PopperPlacementType>("bottom");
  const zoomLevel = useRef<null | number>(null);
  // const [loading, setLoading] = useState<boolean>(false);
  const prevantMarker = useRef<boolean | null>(null);
  const [fitBoundApply, setFitBoundApply] = useState<boolean>(false);
  const [markerOverId, setMarkerOverId] = useState<number>(null);
  // const isEquipLiked = useRef<boolean | null>(null);
  const isPopperOpend = useRef<boolean | null>(null);
  const isClickedOnAnotherMarker = useRef<boolean | null>(null);
  const currentActiveImagePop = useRef<number>(0);

  const handleMouseOut = useCallback(() => setMarkerOverId(null), []);
  const handleMouseOver = useCallback((id) => setMarkerOverId(id), []);

  const fitMapBound = (markerLocation: { lat: number; lng: number }[]) => {
    const type = Boolean(
      zipCode || startDate || endDate || category || keyword
    );
    if (!type) {
      return;
    }
    setTimeout(() => {
      if (map) {
        const bounds = new window.google.maps.LatLngBounds();
        let validGps = 0;
        let otherThan = 0;
        markerLocation.forEach((group) => {
          const { lat, lng } = group[0];

          if (
            lat &&
            lng &&
            lat >= USA_BOUNDS.south &&
            lat <= USA_BOUNDS.north &&
            lng >= USA_BOUNDS.west &&
            lng <= USA_BOUNDS.east
          ) {
            bounds.extend({ lat, lng });
            validGps++;
          }
        });
        if (validGps > 0) {
          setFitBoundApply(true);
          map.fitBounds(bounds); // Fit bounds to include all markers
        } else {
          let otherThan = 0;
          markerLocation.forEach((group) => {
            const { lat, lng } = group[0];
            if (lat && lng) {
              bounds.extend({ lat, lng });
              otherThan++;
            }
          });
          if (otherThan > 0) {
            setFitBoundApply(true);
            map.fitBounds(bounds);
          }
        }
      }
    }, 500);
  };

  const handleFilterData = (equipmentsData) => {
    const equipLatLng = equipmentsData.map(
      ({
        lat,
        lng,
        id,
        price_day,
        make,
        equip_sale_price,
        price_month,
        price_per_week,
        like_status,
      }: IEquipment) => ({
        lat,
        lng,
        id,
        price_day:
          listEquipType === "sale"
            ? equip_sale_price
            : listEquipType === "daily" || listEquipType === "rent"
            ? price_day
            : listEquipType === "weekly"
            ? price_per_week
            : listEquipType === "monthly"
            ? price_month
            : null,
        title: make,
        likeStatus: like_status,
      })
    );
    // setCenterLocation(equipLatLng[0]);
    // setActiveMarker([equipLatLng[0]?.id]);
    const threshold = getDynamicThreshold(zoomLevel.current || 5);
    console.log("threshold", threshold);
    // const threshold = proximityThreshold;
    const clusters = groupLocationsByProximity(equipLatLng, threshold);
    setMarkerLocation(clusters);
    if (isEquipLiked?.current?.status) {
      const { id, likeStatus } = isEquipLiked?.current;
      setSelectedEquip((prevItems) =>
        prevItems.map((item) =>
          item?.id === id ? { ...item, like_status: likeStatus } : item
        )
      );
      isEquipLiked.current = { id: 0, likeStatus: "", status: false };
    } else if (!Boolean(anchorEl)) {
      setActiveMarker([]);
    }

    if (fitbound === "apply") {
      fitMapBound(clusters);
    }
    // setFilterEquipment(equipmentsData);
  };

  // const handleElementLike = (
  //   element: HTMLImageElement,
  //   likeStatus: string,
  //   listOfClasses: unknown[]
  // ) => {
  //   if (likeStatus === "0") {
  //     element.src = emptyheartIcon;
  //     element.classList.remove("liked");
  //   } else if (!listOfClasses?.includes("liked")) {
  //     element.classList.add("liked");
  //     element.src = filledheartIcon;
  //   }
  // };

  // const handleLikeEquipments = useCallback(
  //   async ({
  //     element,
  //     equipId,
  //     likeType,
  //     listOfClasses,
  //   }: {
  //     element: HTMLImageElement;
  //     equipId: number;
  //     likeType: string;
  //     listOfClasses: unknown[];
  //   }) => {
  //     try {
  //       if (!isLoggedin) {
  //         dispatch(setLoginModalOpen(true));
  //         return false;
  //       }
  //       const likeStatus = listOfClasses?.includes("liked") ? "0" : "1";
  //       handleElementLike(element, likeStatus, listOfClasses);
  //       // Make the API call to AddToLikeDislike
  //       const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
  //       const response = await EquipmentAPIs.AddToLikeDislike(
  //         {
  //           user_id: AuthUserId.toString(),
  //           equip_id: equipId.toString(),
  //           status: likeStatus,
  //           liked_equip_list_type: likeType,
  //         },
  //         headers
  //       );

  //       // Check the type of response received
  //       if (response["type"] == "RXSUCCESS") {
  //         const modifiedEquip = equipments.map((equip) => {
  //           if (equip.id === equipId) {
  //             return { ...equip, like_status: likeStatus };
  //           }
  //           return { ...equip };
  //         });
  //         // setFilterEquipment(modifiedEquip);
  //         isEquipLiked.current = true;
  //         dispatch(setEquipments([] as Array<IEquipment>));
  //         dispatch(setEquipments(modifiedEquip as Array<IEquipment>));
  //         // dispatch(setCount(modifiedEquip?.length));
  //       } else if (
  //         response["status"] == false &&
  //         response["slug"] == "Session Expired"
  //       ) {
  //         const likeStatus = listOfClasses?.includes("liked") ? "1" : "0";
  //         handleElementLike(element, likeStatus, listOfClasses);
  //         dispatch(setSessionExpire(true));
  //       } else {
  //         throw new Error(response["message"]);
  //       }
  //     } catch (err) {
  //       const likeStatus = listOfClasses?.includes("liked") ? "1" : "0";
  //       handleElementLike(element, likeStatus, listOfClasses);
  //       dispatch(setalertMessage(err.message));
  //       dispatch(setalertMessagetype("error"));
  //     }
  //   },
  //   [equipments]
  // );

  const handleMarkerClick = useCallback(
    (event, location: MarkerKeys) => {
      if (
        event.target instanceof HTMLImageElement &&
        event.target.tagName === "IMG"
      ) {
        const listOfClasses = Array.from(event.target.classList);
        if (listOfClasses?.includes("likeIcon")) {
          handleLikeEquipments({
            element: event.target,
            equipId: location?.id,
            likeType: location?.equipListType,
            listOfClasses,
          });
          const likeStatus = listOfClasses?.includes("liked") ? "0" : "1";
          let isCluster = true;
          const modifiedMarker = markerLocation?.map((group) => {
            if (group.length === 1) {
              const { id } = group[0];
              if (id === location?.id) {
                group[0].likeStatus = likeStatus;
              }
              isCluster = false;
              return group;
            }
            return group;
          });
          if (!isCluster) {
            setMarkerLocation(modifiedMarker);
          }
          setSelectedEquip((prevItems) =>
            prevItems.map((item) =>
              item?.id === location?.id
                ? { ...item, like_status: likeStatus }
                : item
            )
          );
        } else {
          handleAddVisiblityHidden();
          window.open(
            `/detail/${location?.userId}/${location?.id}/${location?.equipListType}`,
            "_blank"
          );
        }
        return;
      }
      if ("latLng" in event) {
        handleClusterClick(event, [location]);
      } else {
        handleAddVisiblityHidden();
        window.open(
          `/detail/${location?.userId}/${location?.id}/${location?.equipListType}`,
          "_blank"
        );
      }
    },
    [
      markerLocation,
      handleLikeEquipments,
      setMarkerLocation,
      setSelectedEquip,
      loading,
    ]
  );

  const handleClusterClick = useCallback(
    function (event, groups: MarkerKeys[]) {
      if (loading || prevantMarker.current) {
        return false;
      }
      setAnchorEl(null);
      currentActiveImagePop.current = 0;
      const ids = groups.map(({ id }) => id);
      const topOfEquip = [];
      const restOfEquip = [];
      equipments?.forEach((equip) => {
        if (ids?.includes(equip?.id)) {
          topOfEquip.push(equip);
        } else {
          restOfEquip.push(equip);
        }
      });
      isClickedOnAnotherMarker.current = true;
      setTimeout(() => {
        setAnchorEl(event.domEvent.target);
        isClickedOnAnotherMarker.current = false;
      }, 100);
      isPopperOpend.current = true;
      setSelectedEquip(topOfEquip);
      // const modifiedEquip = [...topOfEquip, ...restOfEquip];
      // setFilterEquipment(modifiedEquip);
      setActiveMarker(ids);

      /************* adjust popover within marker */

      if (!map) return;

      const mapBounds = map?.getBounds();
      if (!mapBounds) return;
      const activeEquip = topOfEquip[0];
      const markerPosition = new window.google.maps.LatLng(
        activeEquip.lat,
        activeEquip.lng
      );
      // const isNearTop = markerPosition.lat() > mapBounds.getCenter().lat();
      // const isNearLeft = markerPosition.lng() < mapBounds.getCenter().lng();

      // // Adjust popover positioning based on marker location
      // const vertical = isNearTop ? "bottom" : "top";
      // const horizontal = isNearLeft ? "right" : "left";
      const mapCenter = mapBounds.getCenter();
      const mapNE = mapBounds.getNorthEast(); // Top-right corner
      const mapSW = mapBounds.getSouthWest(); // Bottom-left corner

      const isNearTop = markerPosition.lat() > mapCenter.lat();
      const isNearBottom = markerPosition.lat() < mapCenter.lat();
      const isNearLeft = markerPosition.lng() < mapCenter.lng();
      const isNearRight = markerPosition.lng() > mapCenter.lng();

      // Determine popover position based on proximity
      let popPosition = "top"; // Default
      if (isMobile) {
        // **Mobile View: Only allow "top" and "bottom"**
        if (isNearTop) {
          popPosition = "bottom";
        } else {
          popPosition = "top";
        }
      } else {
        if (
          isNearTop &&
          markerPosition.lat() > mapNE.lat() - (mapNE.lat() - mapSW.lat()) * 0.2
        ) {
          popPosition = "bottom"; // Marker is near the top edge, show popover at bottom
        } else if (
          isNearBottom &&
          markerPosition.lat() < mapSW.lat() + (mapNE.lat() - mapSW.lat()) * 0.2
        ) {
          popPosition = "top"; // Marker is near the bottom edge, show popover at top
        } else if (isNearLeft) {
          popPosition = "right"; // Marker is on the left side, show popover to the right
        } else if (isNearRight) {
          popPosition = "left"; // Marker is on the right side, show popover to the left
        }
      }
      setPopPosition(popPosition as PopperPlacementType);
      // setPopoverPosition({ vertical, horizontal });
    },
    [
      loading,
      equipments,
      map,
      setAnchorEl,
      setSelectedEquip,
      setActiveMarker,
      isMobile,
    ]
  );

  const handlePopperClose = (
    event:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | MouseEvent
      | TouchEvent,
    target: string
  ) => {
    if (target === "away" && isClickedOnAnotherMarker.current) {
      return;
    }
    event.stopPropagation();
    setAnchorEl(null);
    setActiveMarker([]);
  };

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const arePointsNear = (point1, point2, threshold) => {
    return (
      Math.abs(point1.lat - point2.lat) <= threshold &&
      Math.abs(point1.lng - point2.lng) <= threshold
    );
  };

  const getDynamicThreshold = (zoom) => {
    if (zoom >= 15 || equipments?.length <= 200) return 0.000051; // Very small clusters (close markers)
    if (zoom >= 12) return 0.00095; // Small clusters
    if (zoom >= 10) return 0.0001; // Medium clusters
    if (zoom >= 8) return 0.001; // Large clusters
    if (zoom >= 6) return 0.01; // Very large clusters
    return 0.1; // Huge clusters (group everything)
  };

  // Group locations by proximity
  const groupLocationsByProximity = (locations, threshold) => {
    const groups = [];
    const visited = new Set();

    locations.forEach((location, index: number) => {
      if (visited.has(index)) return;

      const group = [location];
      visited.add(index);

      for (let j = index + 1; j < locations.length; j++) {
        if (visited.has(j)) continue;

        const otherLocation = locations[j];
        if (arePointsNear(location, otherLocation, threshold)) {
          group.push(otherLocation);
          visited.add(j);
        }
      }

      groups.push(group);
    });

    return groups;
  };

  const onMapLoad = (map) => {
    setMap(map);
    map.addListener("zoom_changed", () => {
      if (isPopperOpend.current) {
        setAnchorEl(null);
      }
      const zoom = map.getZoom();
      zoomLevel.current = zoom;
      // const threshold = getDynamicThreshold(zoomLevel.current);
      // console.log("threshold", threshold, zoomLevel.current);
    });
  };

  const calculateRadius = useCallback((map) => {
    const bounds = map.getBounds();
    const mapCenter = map.getCenter();

    // Get the northeast corner of the map bounds
    const ne = bounds.getNorthEast();

    // Haversine formula to calculate distance (radius) in meters
    const R = 6371000; // Earth's radius in meters
    const lat1 = mapCenter.lat();
    const lon1 = mapCenter.lng();
    const lat2 = ne.lat();
    const lon2 = ne.lng();

    const dLat = ((lat2 - lat1) * Math.PI) / 180;
    const dLon = ((lon2 - lon1) * Math.PI) / 180;

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distanceInMeters = R * c;

    // Convert meters to miles
    const distanceInMiles = distanceInMeters / 1609.344;

    return distanceInMiles; // Radius in miles
  }, []);

  const useDebounce = (func, wait) => {
    const timeoutRef = useRef(null);

    return useCallback(
      (...args) => {
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => func(...args), wait);
      },
      [func, wait]
    );
  };

  const debouncedFetch = useDebounce(() => {
    prevantMarker.current = false;
    const type = Boolean(
      zipCode || startDate || endDate || category || keyword
    );
    if (fitBoundApply && type) {
      setFitBoundApply(false);
      return;
    }
    if (map && !Boolean(anchorEl)) {
      const mapCenter = map.getCenter();
      const radius = calculateRadius(map);
      const centerPoint = {
        lat: mapCenter.lat()?.toString(),
        lng: mapCenter.lng()?.toString(),
      };
      const bounds = map.getBounds();
      if (bounds) {
        const northEast = bounds.getNorthEast();
        const southWest = bounds.getSouthWest();
        // console.log("minLat", southWest.lat());
        // console.log("maxLat", northEast.lat());
        // console.log("minLng", southWest.lng());
        // console.log("maxLng", northEast.lng());
        if (southWest.lat() !== northEast.lat()) {
          fetchData({
            radius: radius,
            minLat: southWest.lat(), // Bottom
            maxLat: northEast.lat(), // Top
            minLng: southWest.lng(), // Left
            maxLng: northEast.lng(), // Right
            ...centerPoint,
            zoomLevel: zoomLevel.current,
          });
        }
      }
    }
  }, 1000);

  const handleBoundsChanged = () => {
    if (map) {
      const bounds = map.getBounds();
      if (bounds) {
        const northEast = bounds.getNorthEast();
        const southWest = bounds.getSouthWest();
        const boundingBox = {
          minLat: southWest.lat(), // Bottom
          maxLat: northEast.lat(), // Top
          minLng: southWest.lng(), // Left
          maxLng: northEast.lng(), // Right
        };
        dispatch(setBoundingBox(boundingBox));
      }
      if (!firstInitialized) {
        prevantMarker.current = true;
        // if (mapBoxRef.current) {
        //   mapBoxRef.current.style.pointerEvents = "none";
        // }
        debouncedFetch();
      } else {
        // getLocation();
        setFirstInitialized(false);
      }
    }
  };

  const handleAddVisiblityHidden = () => {
    const prevArrow = document.querySelector(".custom-arrow.prev-arrow");
    const nextArrow = document.querySelector(".custom-arrow.next-arrow");
    if (prevArrow && prevArrow.getAttribute("class")?.includes("disabled")) {
      prevArrow.classList.add("visibility-hidden");
    } else if (
      nextArrow &&
      nextArrow.getAttribute("class")?.includes("disabled")
    ) {
      nextArrow.classList.add("visibility-hidden");
    }
  };

  const handleFilterDataRef = useRef(null);
  useEffect(() => {
    if (handleFilterDataRef.current) {
      clearTimeout(handleFilterDataRef.current);
    }
    handleFilterDataRef.current = setTimeout(() => {
      handleFilterData(equipments);
    }, 100);
  }, [equipments]);

  useEffect(() => {
    if (handleFilterDataRef.current) {
      clearTimeout(handleFilterDataRef.current);
    }
    if (listEquipType !== "sale") {
      handleFilterDataRef.current = setTimeout(() => {
        handleFilterData(equipments);
      }, 100);
    }
  }, [listEquipType]);

  const timeoutRef = useRef(null);

  // useEffect(() => {
  //   if (!map) return;
  //   if (timeoutRef.current) {
  //     clearTimeout(timeoutRef.current);
  //   }

  //   // Debounce to detect the final center change
  //   timeoutRef.current = setTimeout(() => {
  //     const bounds = new window.google.maps.Circle({
  //       center: centerPL,
  //       radius: ftBoundR,
  //     }).getBounds();
  //     map.fitBounds(bounds);
  //     // if (!centerLocation?.lat && !centerLocation?.lng) {
  //     // }
  //   }, 500); // Adjust delay as needed (500ms is good for debouncing)

  //   return () => clearTimeout(timeoutRef.current); // Cleanup
  // }, [map]); // ✅ Run when `map` or `centerLocation` changes

  useEffect(() => {
    if (!map) return;

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      // Define bounding coordinates covering the entire USA
      // const usBounds = new window.google.maps.LatLngBounds(
      //   new window.google.maps.LatLng(27.0, -118.0), // Move Left (←)
      //   new window.google.maps.LatLng(49.0, -78.0) // Move Left (←)
      // );
      const usBounds = new window.google.maps.LatLngBounds(
        new window.google.maps.LatLng(28.0, -118.0), // Move Up (↑)
        new window.google.maps.LatLng(50.0, -78.0) // Move Up (↑)
      );

      // Fit map to USA bounds
      map.fitBounds(usBounds);

      // Ensure map refits on window resize
      const handleResize = () => map.fitBounds(usBounds);
      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, 500); // Debounce delay

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [map]);
  const arrowTimeout = useRef(null);

  const PrevArrow = ({ onClick }) => {
    return (
      <Box
        className={`custom-arrow prev-arrow ${
          currentActiveImagePop.current === 0
            ? "disabled visibility-hidden"
            : ""
        }`}
        onClick={(event) => {
          onClick && onClick(event);
          clearTimeout(arrowTimeout.current);
          arrowTimeout.current = setTimeout(() => {
            handleAddVisiblityHidden();
          }, 2000);
        }}
      >
        <ArrowBack sx={{ fontSize: "16px" }} />
      </Box>
    );
  };

  const NextArrow = ({ onClick }) => {
    return (
      <Box
        className={`custom-arrow next-arrow ${
          currentActiveImagePop.current === selectedEquip.length - 1
            ? "disabled visibility-hidden"
            : ""
        }`}
        onClick={(event) => {
          onClick && onClick(event);
          clearTimeout(arrowTimeout.current);
          arrowTimeout.current = setTimeout(() => {
            handleAddVisiblityHidden();
          }, 2000);
        }}
      >
        <ArrowForward sx={{ fontSize: "16px" }} />
      </Box>
    );
  };

  const settings = {
    prevArrow: <PrevArrow onClick />,
    nextArrow: <NextArrow onClick />,
    dots: false,
    infinite: false, // Disable infinite loop when only one item
    speed: 500,
    slidesToShow: 1, // Show 1 slide if only one item
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768, // For smaller screens
        settings: {
          slidesToShow: 1, // Show 1 slide on mobile
          slidesToScroll: 1,
        },
      },
    ],
    afterChange: (current: number) => {
      const prevArrow = document.querySelector(".custom-arrow.prev-arrow");
      const nextArrow = document.querySelector(".custom-arrow.next-arrow");
      prevArrow?.classList?.remove("disabled");
      nextArrow?.classList?.remove("disabled");
      prevArrow?.classList?.remove("visibility-hidden");
      nextArrow?.classList?.remove("visibility-hidden");
      currentActiveImagePop.current = current;
      if (current === 0) {
        prevArrow?.classList?.add("disabled");
      } else if (current === selectedEquip.length - 1) {
        nextArrow?.classList?.add("disabled");
      }
    },
  };

  return (
    // <Container>
    <Box
      sx={{
        position: "relative",
        display: "grid",
        gridTemplateColumns: {
          xs: "1fr",
          sm: "1fr",
          md: "repeat(1, 1fr)",
          gap: "2rem",
        },
        alignItems: "flex-start",
        // mb: "3rem",
        // mt: "2rem",
        width: "100%",
      }}
    >
      {loading && (
        <Box
          sx={{
            position: "absolute",
            top: "10%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: "999",
            background: "rgba(0,0,0,0.8)",
            p: 2,
            width: "50px",
            height: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            {/* <CircularProgress sx={{ color: "#faa61a" }} /> */}
            <Box
              component={"img"}
              src={mapLoader}
              sx={{ width: "70px", height: "auto" }}
            />
          </Box>
        </Box>
      )}
      <Box sx={{ position: { xs: "unset", md: "sticky" }, top: "8rem" }}>
        <Box
          sx={{
            width: "100%",
            // height:'100%',
            // height: "600px",
            height: "calc(100vh - 14.5rem)",
            backgroundColor: "white",
          }}
        >
          {isLoaded ? (
            <GoogleMap
              onLoad={onMapLoad}
              ref={mapview}
              center={centerPL}
              // center={{ lat: 39.2650590452179, lng: -111.68376570638267 }}
              zoom={5}
              mapTypeId="terrain"
              options={{
                // ...mapOptions,
                minZoom: 4, // Set the minimum zoom level
                maxZoom: 20, // Optionally set a maximum zoom level
                gestureHandling: "greedy",
                mapTypeControl: true,
                mapTypeControlOptions: {
                  style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                  position: google.maps.ControlPosition.TOP_RIGHT,
                },
                streetViewControl: false, // Removes Street View option
                fullscreenControl: false, // Removes Fullscreen button
                isFractionalZoomEnabled: true,
              }}
              mapContainerStyle={{
                width: "100%",
                height: "100%",
              }}
              onUnmount={onUnmount}
              onIdle={handleBoundsChanged}
            >
              {markerLocation.map((group, index) => {
                const firstLocation = group[0];
                const isCluster = group.length > 1;
                const length =
                  group.length === 1
                    ? parseFloat(firstLocation?.price_day)?.toString().length +
                      1
                    : 3;

                let width = isCluster
                  ? 96
                  : Math.floor(
                      length > 8 ? length * 18 : length > 3 ? length * 15 : 60
                    );
                let centerWidth = isCluster ? 48 : Math.floor(width / 2);
                const activeCluster =
                  isCluster &&
                  group.some(({ id }) => activeMarker.includes(id));
                const isActiveMarker =
                  activeCluster || activeMarker[0] === firstLocation.id;
                const scalling =
                  markerOverId === firstLocation?.id || isActiveMarker;

                const label = isCluster
                  ? {
                      text: `${group.length} items`,
                      color: "#fff",
                      fontWeight: "bold",
                      fontSize: scalling ? "15px" : "14px",
                    }
                  : {
                      text: `${new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0,
                      }).format(parseFloat(firstLocation?.price_day) || 0)} ${
                        firstLocation?.likeStatus === "1" ? "🧡" : ""
                      }`,
                      color: "#fff",
                      fontWeight: "bold",
                      fontSize: scalling ? "15px" : "14px",
                    };

                let markerLength = 35;
                let markerCenterLength = 16;
                const iconUrl = isActiveMarker ? orangeDotIcon : greenDotIcon;

                if (!isCluster && firstLocation?.likeStatus === "1") {
                  markerLength += 1;
                  width += 25;
                  centerWidth += 12;
                }

                if (scalling) {
                  markerLength += 1;
                  markerCenterLength += 1;
                  width += 4;
                  centerWidth += 1;
                }

                return (
                  <MemoizedMarker
                    key={index}
                    position={{
                      lat: firstLocation.lat,
                      lng: firstLocation.lng,
                    }}
                    title={isCluster ? "" : firstLocation?.title}
                    label={label}
                    zIndex={
                      activeCluster || activeMarker[0] === firstLocation.id
                        ? 999
                        : index + 1
                    }
                    icon={{
                      url: iconUrl,
                      scaledSize: new window.google.maps.Size(
                        width,
                        markerLength
                      ),
                      labelOrigin: new window.google.maps.Point(
                        centerWidth,
                        markerCenterLength
                      ),
                    }}
                    onClick={(event) =>
                      isCluster
                        ? handleClusterClick(event, group)
                        : handleMarkerClick(event, firstLocation)
                    }
                    onMouseOut={handleMouseOut}
                    onMouseOver={() => handleMouseOver(firstLocation?.id)}
                  />
                );
              })}
              <MapPopper
                anchorEl={anchorEl}
                showMapData={showMapData}
                popPosition={popPosition}
                selectedEquip={selectedEquip}
                settings={settings}
                handleMarkerClick={handleMarkerClick}
                handlePopperClose={handlePopperClose}
              />
              {/* <Circle
                  center={centerPL}
                  radius={1609000}
                  options={{
                    strokeColor: "#FF0000", // Circle border color
                    strokeOpacity: 0.8, // Border opacity
                    strokeWeight: 2, // Border thickness
                    fillColor: "#FF0000", // Fill color
                    fillOpacity: 0.35, // Fill opacity
                  }}
                /> */}
            </GoogleMap>
          ) : null}
        </Box>
      </Box>
    </Box>
    // </Container>
  );
};

type MarkerProps = {
  position: { lat: number; lng: number };
  title?: string;
  label?: {
    text: string;
    color: string;
    fontWeight: string;
    fontSize: string;
  };
  zIndex?: number;
  icon?: {
    url: string;
    scaledSize: google.maps.Size;
    labelOrigin: google.maps.Point;
  };
  onClick?: (event: google.maps.MapMouseEvent) => void;
  onMouseOut?: () => void;
  onMouseOver?: () => void;
};

const MemoizedMarker: React.FC<MarkerProps> = React.memo((props) => {
  return <Marker {...props} />;
});

MemoizedMarker.displayName = "MemoizedMarker";

export default MapResult;
