import { Box, Typography } from "@mui/material";
import React from "react";

const CalendarLegend = ({ owner = false }) => {
  return (
    <Box
      sx={{
        backgroundColor: "#faa61a0d",
        borderRadius: 2,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "0rem",
        pb: 2,
      }}
    >
      <Box
        sx={{
          // display: "flex",
          alignItems: "center",
          // gap: "1.5rem",
          // flexWrap: "wrap",
          display: "grid",
          gridTemplateColumns: {
            xs: "repeat(2, 1fr)",
            sm: "repeat(4, 1fr)",
            md: "repeat(4, 1fr)",
          },
          gap: "1rem",
          mt: 0,
          mb: 0,
          p: 2,
          width: "100%",
          boxSizing: "border-box",
          // backgroundColor: "#faa61a0d",
          // borderRadius: 2,
        }}
      >
        {/* Available */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
          }}
        >
          <Box
            sx={{
              position: "relative",
              height: "20px",
              width: "20px",
              overflow: "hidden",
              backgroundColor: "#2d2d2d",
              border: "1px solid gray",
              // borderRadius: "5px",
            }}
          />
          <Typography
            sx={{
              color: "#fff",
              fontSize: "10px",
            }}
          >
            Available
          </Typography>
        </Box>

        {/* Pending */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
          }}
        >
          <Box
            sx={{
              position: "relative",
              height: "20px",
              width: "20px",
              overflow: "hidden",
              backgroundColor: "skyblue",
              // borderRadius: "5px",
            }}
          />
          <Typography
            sx={{
              color: "#fff",
              // fontSize: {
              //   xs: "12px",
              //   sm: "12px",
              //   md: "13px",
              //   lg: "14px",
              //   xl: "14px",
              // },
              fontSize: "10px",
            }}
          >
            Pending
          </Typography>
        </Box>

        {/* Booked */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
          }}
        >
          <Box
            sx={{
              position: "relative",
              height: "20px",
              width: "20px",
              overflow: "hidden",
              backgroundColor: "#faa61a",
              // borderRadius: "5px",
            }}
          />
          <Typography
            sx={{
              color: "#fff",
              // fontSize: {
              //   xs: "12px",
              //   sm: "12px",
              //   md: "13px",
              //   lg: "14px",
              //   xl: "14px",

              // },
              fontSize: "10px",
            }}
          >
            Booked
          </Typography>
        </Box>

        {/* Partial */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
          }}
        >
          <Box
            sx={{
              position: "relative",
              height: "20px",
              width: "20px",
              overflow: "hidden",
              backgroundColor: "#2d2d2d",
              // borderRadius: "5px",
              "&:before": {
                content: `''`,
                position: "absolute",
                right: "0",
                top: "0",
                width: "0",
                height: "0",
                borderBottom: "50px solid #faa61a",
                borderLeft: "50px solid transparent",
                zIndex: "1000",
                background: "none",
              },
            }}
          />
          <Typography
            sx={{
              color: "#fff",
              // fontSize: {
              //   xs: "12px",
              //   sm: "12px",
              //   md: "13px",
              //   lg: "14px",
              //   xl: "14px",
              // },
              fontSize: "10px",
            }}
          >
            Partial
          </Typography>
        </Box>
      </Box>

      {owner && (
        <Box
          sx={{
            // display: "flex",
            alignItems: "center",
            gap: "1rem",
            // flexWrap: "wrap",
            display: {
              xs: "grid",
              sm: "flex",
            },
            justifyContent: "center",
            gridTemplateColumns: {
              xs: "repeat(2, 1fr)",
              sm: "repeat(2, 1fr)",
            },
            p: 2,
            pt: 0,
            pb: 0,
            // gap: "1rem",
            width: "100%",
            boxSizing: "border-box",
          }}
        >
          {/* Blocked */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <Box
              sx={{
                position: "relative",
                height: "20px",
                width: "20px",
                overflow: "hidden",
                backgroundColor: "red",
              }}
            />
            <Typography
              sx={{
                color: "#fff",
                fontSize: "10px",
              }}
            >
              Blocked
            </Typography>
          </Box>

          {/* No pickup/dropoff */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <Box
              sx={{
                position: "relative",
                height: "20px",
                width: "20px",
                overflow: "hidden",
                backgroundColor: "rgb(96, 96, 96)",
              }}
            />
            <Typography
              sx={{
                color: "#fff",
                fontSize: "10px",
              }}
            >
              No Drop Off/Delivery
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CalendarLegend;
