import React, { useCallback, useEffect, useState, useRef } from "react";
import { EquipmentAPIs } from "../../../service/api-service";
import {
  Box,
  CircularProgress,
  Link,
  Typography,
  Backdrop,
  Button,
  Modal,
  IconButton,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Select,
  styled,
  InputLabel,
  Switch,
  TextField,
  FormLabel,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import {
  setalertMessagetype,
  setalertMessage,
  setSessionExpire,
} from "redux/redux-slice";
import { IApiResponseRawData, IStoreValue, GpsEquipParams } from "utils/types";
import gemArrow from "assets/images/gem-arrow.png";
import satellite from "assets/images/GPS/gps-icon.png";
import requestGPS from "assets/images/GPS/requestGPS.png";
import noGpsAssigned from "assets/images/GPS/nogpsassigned.png";
import mapNotAvailable from "assets/images/GPS/noGpsAssigned.jpg";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import CurrentFormat from "../../common/CurrenyFormat";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  DirectionsRenderer,
  DirectionsService,
} from "@react-google-maps/api";
import fireAnalyticsEvent from "service/firebase";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
// -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
// filter: grayscale(100%);
import { io } from "socket.io-client";
import { Calendar as DatePicker } from "react-multi-date-picker";
import { Form, useNavigate, useParams } from "react-router-dom";
import NewGps from "./NewGps";
import SeeMoreBtn from "components/common/SeeMoreBtn";
import { Label } from "@mui/icons-material";
import SocialShareModal from "components/common/SocialShareModal";
import redDotIcon from "assets/images/GPS/red-location-marker.png";
import orangeDotIcon from "assets/images/GPS/yellow-location-marker.png";

const IOSSwitch = styled(Switch)(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 22,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(14px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(16px)",
      "& .MuiSwitch-thumb": {
        backgroundColor: "#fff", // Thumb color when active (white)
      },
      "& + .MuiSwitch-track": {
        backgroundColor: "#faa61a", // Track color when active (orange)
        opacity: 1,
      },
    },
    "&.Mui-disabled": {
      color: "#BDBDBD",
      "& + .MuiSwitch-track": {
        backgroundColor: "#BDBDBD",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    width: 22,
    height: 22,
    borderRadius: 11,
    backgroundColor: "#535353", // Default thumb color (black)
    transition: theme.transitions.create(["background-color", "transform"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 13,
    opacity: 1,
    backgroundColor: "#BDBDBD", // Default track color (gray)
    boxSizing: "border-box",
    transition: theme.transitions.create(["background-color"], {
      duration: 200,
    }),
  },
}));

const NewCssTextField = styled(TextField)({
  "& .MuiInput-underline:after": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiInput-underline:before": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiFilledInput-underline:after": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiFilledInput-underline:before": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },

  "& .MuiInputLabel-shrink.MuiInputLabel-filled": {
    color: "#2d2d2d",
  },
  "& .MuiInputBase-root": {
    color: "#2d2d2d",
    background: "#fff",
    fontFamily: "Gotham !important",
    fontSize: "clamp(12px, 3vw, 15px) !important",
    borderRadius: "10px !important",
    // border: "2px solid #faa61a",
  },
  "&. MuiInputLabel-shrink": {
    color: "red !important",
  },

  "&. MuiInputLabel-shrink.Mui-focused": {
    color: "red !important",
  },

  "& label.Mui-focused": {
    color: "#2d2d2d !important",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "2px solid #faa61a",
      backgroundColor: "#fff !important",
    },
    "&:hover fieldset": {
      borderColor: "#2d2d2d",
      border: "2px solid #faa61a",
      backgroundColor: "#fff !important",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#faa61a",
    },
  },
  "& .MuiInputBase-root-MuiFilledInput-root:before": {
    borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
  },
});

const loginstyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  p: 4,
};

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  border: "2px solid #faa61a",
  backgroundColor: "#fff",
  ".Mui-focusVisible &": {},
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#fff",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg'  viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd'  clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%232d2d2d'/%3E%3C/svg%3E\")",
    content: '""',
  },
});

let SOCKET_URL = process.env.REACT_APP_STAGING_SOCKET_URL;
if (process.env.REACT_APP_PLATFORM === "Live") {
  SOCKET_URL = process.env.REACT_APP_LIVE_SOCKET_URL;
} else if (process.env.REACT_APP_PLATFORM === "PreLive") {
  SOCKET_URL = process.env.REACT_APP_PRELIVE_SOCKET_URL;
}
let socket;

const gpsDeviceObj = {
  title: "",
  data: [
    // {
    //   id: 0,
    //   make: "",
    //   model: "",
    //   thumbnail: "",
    //   address: "",
    //   city: "",
    //   state: "",
    //   price_day: "",
    //   device_assign_status: "",
    //   device_imei: "",
    //   lat: "",
    //   lng: "",
    // },
  ],
  total: 0,
};
const limit = 10;
const Gps = ({ handleListEquipment }) => {
  const routeParams = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const AuthToken = useSelector(
    (state: IStoreValue) => state.equipmentReducer.accessToken
  );
  const AuthUserID = useSelector(
    (state: IStoreValue) => state.equipmentReducer.Authuser
  );
  const AuthProfileData = useSelector(
    (state: IStoreValue) => state.equipmentReducer.AuthProfileData
  );

  const [loading, setloading] = React.useState(false);
  // const [Actionequip, setActionequip] = React.useState([]);
  // const [AllEquips, setAllEquips] = React.useState([]);
  // const paginationPage = 5;
  // const [NewPageLen, setNewPageLen] = React.useState(paginationPage);
  const [CurrentActiveEquip, setCurrentActiveEquip] = React.useState(null);
  const [CurrentActiveEquipObj, setCurrentActiveEquipObj] =
    React.useState(null);
  // const [UpdateCallback, setUpdateCallback] = React.useState(null);
  // const today = moment().format("YYYY-MM-DD");

  const [GpsRequestPopup, setGpsRequestPopup] = React.useState(false);
  const [GpsRequestPopupResult, setGpsRequestPopupResult] =
    React.useState(false);
  const [RequestResponse, setRequestResponse] = React.useState("");
  const [TotalDevice, setTotalDevice] = React.useState(0);
  const [TotalUnassigned, setTotalUnassigned] = React.useState(0);

  const [GpsAssignPopup, setGpsAssignPopup] = React.useState(false);
  const [GpsDevices, setGpsDevices] = React.useState(null);
  const [DeviceSerialNumber, setDeviceSerialNumber] = React.useState(null);
  // const [DeviceAssignEquip, setDeviceAssignEquip] = React.useState([]);
  const [GpsDeviceUnassignPopup, setGpsDeviceUnassignPopup] =
    React.useState(false);
  const [UnassignEquipId, setUnassignEquipId] = React.useState(null);

  const [GpsDeviceLocationArr, setGpsDeviceLocationArr] = React.useState([]);
  // const [GpsDeviceLocationDefault, setGpsDeviceLocationDefault] =
  //   React.useState(null);
  const [ViewallLocation, setViewallLocation] = React.useState(true);
  const [defaulCenterLocation, setdefaulCenterLocation] = React.useState(null);

  const [AssignSerialNumbers, setAssignSerialNumbers] = React.useState([]);
  const [ReAssignPopup, setReAssignPopup] = React.useState(false);

  const mapview = useRef(null);
  const [map, setMap] = React.useState(null);
  // Google map section start
  // const APIKEY = process.env.REACT_APP_GEOCODDER_KEY;
  const APIKEY = process.env.REACT_APP_GEOCODDER_KEY;
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: APIKEY,
  });
  const containerStyle = {
    width: "100%",
    height: "550px",
  };
  // Google map section end
  const [gpsDateRangeModel, setGpsDateRangeModel] = useState(false);
  const [gpsDateRange, setGpsDateRange] = useState([]);
  const [gpsDeviceFilter, setGpsDeviceFilter] = useState(false);
  // const [minDateValue, setMinDateValue] = useState(null);
  const [gpsDeviceDirection, setGpsDeviceDirection] = useState([]);
  const [directions, setDirections] = useState([]);
  const [realTimeEnabled, setRealTimeEnabled] = useState(false);
  const [activeEquipGpsData, setActiveEquipGpsData] = useState<object>({});

  // gps state with new ui

  const [assignedDevices, setAssignedDevices] = useState<GpsEquipParams>({
    ...gpsDeviceObj,
  });
  const [unAssignedDevices, setUnAssignedDevices] = useState<GpsEquipParams>({
    ...gpsDeviceObj,
  });
  const [equipObjToAssign, setEquipObjToAssign] = useState({});
  const [assignedPageNo, setAssignedPageNo] = useState<number>(1);
  const [unAssignedPageNo, setUnAssignedPageNo] = useState<number>(1);
  const [shareUrl, setShareUrl] = useState<string>("");
  const [shareModalOpen, setShareModalOpen] = useState<boolean>(false);
  const handleShareModalOpen = () => setShareModalOpen(true);
  const handleShareModalClose = () => setShareModalOpen(false);
  const preventFitBound = useRef<boolean>(false);
  /**
   * Function: ListHome
   * Description:
   * Fetches equipment data from the server, processes and filters it based on various criteria,
   * updates the state, and handles success or error scenarios.
   * If successful, sets the current page, updates the total results, and sets the default active equipment.
   * If an error occurs, dispatches relevant alert messages and handles session expiration.
   *
   * @param {string} user_id - The user ID for fetching equipment data.
   * @param {number} lat - The latitude for location filtering.
   * @param {number} lng - The longitude for location filtering.
   * @param {string} equipId - The optional equipment ID.
   */

  const fitMapBound = function () {
    if(preventFitBound.current) {
      preventFitBound.current = false;
      return;
    }
    setTimeout(() => {
      const bounds = new window.google.maps.LatLngBounds();
      let validGps = 0;
      assignedDevices?.data?.forEach(({ latitude, longitude }) => {
        const lat = parseFloat(latitude);
        const lng = parseFloat(longitude);
        if (lat && lng) {
          bounds.extend({
            lat,
            lng,
          });
          validGps++;
        }
      });
      if (validGps > 1) {
        map.fitBounds(bounds);
      }
    }, 500);
  };

  const getActiveEquipLoaction = (
    equipments,
    equipId = null,
    zoomMap = true
  ) => {
    if (!equipId) {
      equipId = CurrentActiveEquip;
    }
    const equipment = equipments?.filter((equip) => equipId === equip?.id)[0];
    if (parseFloat(equipment?.["lat"]) && parseFloat(equipment?.["lng"])) {
      const location = {
        lat: parseFloat(equipment?.["lat"]),
        lng: parseFloat(equipment?.["lng"]),
      };
      if (map && zoomMap) {
        map.setZoom(12);
        map.panTo(location);
      }
      zoomMap && setdefaulCenterLocation(location);
    }
    // setActiveEquipGpsData(equipment);
    // setGpsDeviceLocationArr(equipments);
    setGpsDeviceLocationArr(
      equipments.filter(
        (equip) => parseFloat(equip?.latitude) && parseFloat(equip?.latitude)
      )
    );
  };

  const GetGpsDeviceLocation = async (
    equipId: number,
    type = "all",
    zoomMap = false
  ) => {
    getActiveEquipLoaction(assignedDevices?.data, equipId, zoomMap);
  };

  const modifiedEquipData = (equipments) => {
    const data = equipments?.map((equip) => ({
      ...equip,
      latitude: equip.lat,
      longitude: equip.lng,
    }));
    return data;
  };

  const getEquipViaGpsDevice = async (pageNo = 0, type = "both") => {
    try {
      setloading(true);
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const params = {
        user_id: AuthUserID,
        page: pageNo,
        type,
        per_page: limit,
      };
      const response = await EquipmentAPIs.getEquipWithGpsDevice(
        params,
        headers
      );
      if (response["type"] == "RXSUCCESS") {
        console.log("response.data", response.data);

        if (pageNo && type !== "both") {
          const updatedData =
            type === "Assigned"
              ? [...assignedDevices.data, ...modifiedEquipData(response?.data)]
              : [...unAssignedDevices.data, ...response?.data];

          type === "Assigned"
            ? setAssignedDevices((prev) => ({ ...prev, data: updatedData }))
            : setUnAssignedDevices((prev) => ({ ...prev, data: updatedData }));
          type === "Assigned" && getActiveEquipLoaction(updatedData);
        } else {
          const equipId = routeParams.equipId;
          const totalDevices = response?.devices?.total_assign;
          if (totalDevices === 0) {
            navigate("/equipment-tracking");
            return false;
          }
          const [assignedData, unassignedData] = response?.data || [];
          if (equipId && assignedData?.data?.length > 0) {
            const targetIndex = assignedData?.data?.findIndex(
              (item) => +item.id === +equipId
            );
            if (targetIndex !== -1) {
              const [targetObject] = assignedData?.data?.splice(targetIndex, 1);
              assignedData?.data?.unshift(targetObject);
            }
          }
          assignedData.data = [...modifiedEquipData(assignedData.data)];
          let activeId;
          if (assignedData?.data?.length) {
            const [activeEquip] = assignedData?.data;
            setCurrentActiveEquipObj(activeEquip);
            activeId = activeEquip?.id;
            getActiveEquipLoaction(assignedData.data, activeId);
          } else if (unassignedData?.data?.length) {
            const [activeEquip] = unassignedData?.data;
            setCurrentActiveEquipObj(activeEquip);
            activeId = activeEquip?.id;
          }
          setTotalDevice(response?.devices?.total_assign);
          setTotalUnassigned(response?.devices?.total_unassign);
          setCurrentActiveEquip(activeId);
          setAssignedDevices(assignedData);
          setUnAssignedDevices(unassignedData);
        }
      } else {
        throw new Error(response?.message);
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setloading(false);
    }
  };

  /**
   * Function: HandleRentalYardPagination
   * Description:
   * Handles the pagination logic for the Gps tab.
   * Calculates the new page length based on the total equipment and paginationPage value.
   * Updates the state with the new page length and sets the equipment array accordingly.
   * Sets loading state to false after completion.
   */ // Function to handle rental yard pagination
  const handleGpsPagination = async (type) => {
    let page = 0;
    if (type === "Assigned") {
      page = assignedPageNo;
      setAssignedPageNo((prev) => prev + 1);
    } else if (type === "Unassigned") {
      page = unAssignedPageNo;
      setUnAssignedPageNo((prev) => prev + 1);
    }
    await getEquipViaGpsDevice(page, type);
  };

  /**
   * Function: GpsRequestCreate
   * Description:
   * Creates a GPS request by sending a request to the server,
   * updates the state, and handles success or error scenarios.
   * If successful, hides the GPS request popup, sets the result popup,
   * and updates the response message.
   * If an error occurs, dispatches relevant alert messages.
   */
  //Make Gps Request
  const GpsRequestCreate = async () => {
    navigate("/gemstore");
    return false;
    try {
      setloading(true);

      // Call EquipmentAPIs.GpsRequest to create a GPS request
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const RequestData = await EquipmentAPIs.GpsRequest(
        {
          user_id: AuthUserID.toString(),
        },
        headers
      );

      // Check if the API response is successful
      if (RequestData["type"] == "RXSUCCESS") {
        // Hide GPS request popup, show result popup, and update response
        setGpsRequestPopup(false);
        setGpsRequestPopupResult(true);
        setRequestResponse(RequestData["message"]);
        // dispatch(setalertMessage(RequestData['message']));
        // dispatch(setalertMessagetype('success'));
        setloading(false);
      } else {
        // Dispatch error alert messages
        dispatch(setalertMessage(RequestData["message"]));
        dispatch(setalertMessagetype("error"));
        setloading(false);
        return false;
      }
    } catch (err) {
      // Log and handle errors
      console.log(err);
      setloading(false);
    }
  };

  /**
   * Function: GpsDevice
   * Description:
   * Fetches GPS device data from the server, updates the state,
   * and handles success or error scenarios.
   * If successful, processes the device data and updates relevant state variables.
   * If an error occurs, dispatches relevant alert messages.
   * @param {string} user_id - The user ID for fetching GPS device data.
   * @param {string} clickfrom - The source of the click event (e.g., "Assign").
   */ // Get GPS Device
  const GpsDevice = async (user_id, clickfrom = null) => {
    try {
      setloading(true);

      // Call EquipmentAPIs.GetGpsDevice to get GPS device data
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const GpsDeviceData = await EquipmentAPIs.GetGpsDevice(
        {
          user_id: user_id.toString(),
        },
        headers
      );

      // Check if the API response is successful
      if (GpsDeviceData["type"] == "RXSUCCESS") {
        const deviceArr = GpsDeviceData["data"];
        const deviceArrNew = [];

        // Process device data to filter unassigned devices
        const connectEmei = [];
        deviceArr.forEach((item) => {
          deviceArrNew.push(item);
          if (item?.connected_status === "1") {
            connectEmei.push(item.imei.trim());
          }
        });
        setAssignSerialNumbers(connectEmei);
        // Set the state with unassigned devices and update total device counts
        setGpsDevices(deviceArrNew);

        // Show GPS request popup if there are no assigned devices
        if (GpsDeviceData["total_assign"] == 0) {
          navigate("/equipment-tracking");
          return false;
        }

        // Update total device counts and set loading state to false
        // setTotalDevice(GpsDeviceData["total_assign"]);
        // setTotalUnassigned(GpsDeviceData["total_unassign"]);
        setloading(false);

        // Handle special actions if the click event is from the "Assign" button
        if (clickfrom == "Assign") {
          if (deviceArrNew.length > 0) {
            // Show GPS assign popup and reset device serial number
            setGpsAssignPopup(true);
            setDeviceSerialNumber(null);
          }
          // else {
          //   setGpsRequestPopup(true);
          // }
        }
      } else {
        // Dispatch error alert messages
        dispatch(setalertMessage(GpsDeviceData["message"]));
        dispatch(setalertMessagetype("error"));
        setloading(false);
        return false;
      }
    } catch (err) {
      console.log(err);
      setloading(false);
    }
  };

  /**
   * Function: GpsDeviceAssign
   * Description:
   * Assigns a GPS device to equipment by sending a request to the server,
   * updates the state, and handles success or error scenarios.
   * If successful, dispatches a success alert message, hides the GPS assign popup,
   * and triggers a refresh of the equipment list.
   * If an error occurs or device serial number is not selected,
   * dispatches relevant error alert messages.
   */
  // Assign GPS device to Equip
  const GpsDeviceAssign = async () => {
    try {
      setloading(true);

      // Check if the device serial number is not selected
      if (DeviceSerialNumber == "" || DeviceSerialNumber == null) {
        dispatch(setalertMessage("Please select device first"));
        dispatch(setalertMessagetype("error"));
        setloading(false);
        return false;
      }

      // Call EquipmentAPIs.EquipConnectGpsDevice to assign a GPS device
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const GpsDeviceConnectData = await EquipmentAPIs.EquipConnectGpsDevice(
        {
          device_id: DeviceSerialNumber.toString(),
          equip_id: equipObjToAssign?.["id"].toString(),
          user_id: AuthUserID.toString(),
        },
        headers
      );

      // Check if the API response is successful
      if (GpsDeviceConnectData["type"] == "RXSUCCESS") {
        // Dispatch success alert messages
        dispatch(setalertMessage(GpsDeviceConnectData["message"]));
        dispatch(setalertMessagetype("success"));

        // Set loading state to false, hide popups, and refresh equipment list
        setloading(false);
        setGpsAssignPopup(false);
        setReAssignPopup(false);
        clearDirectionFilter();
        getEquipViaGpsDevice();
      } else {
        // Dispatch error alert messages
        dispatch(setalertMessage(GpsDeviceConnectData["message"]));
        dispatch(setalertMessagetype("error"));
        setloading(false);
        return false;
      }
    } catch (err) {
      // Log and handle errors
      console.log(err);
      setloading(false);
    }
  };

  /**
   * Function: GpsDeviceUnassign
   * Description:
   * Unassigns a GPS device from equipment by sending a request to the server,
   * updates the state, and handles success or error scenarios.
   * If successful, dispatches a success alert message, hides the GPS unassign popup,
   * and triggers a refresh of the equipment list.
   * If an error occurs, dispatches relevant error alert messages.
   */ // Unassign GPS device from Equip
  const GpsDeviceUnassign = async () => {
    try {
      setloading(true);

      // Call EquipmentAPIs.EquipDisconnectGpsDevice to unassign a GPS device
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const GpsDeviceConnectData = await EquipmentAPIs.EquipDisconnectGpsDevice(
        {
          equip_id: UnassignEquipId.toString(),
          user_id: AuthUserID.toString(),
        },
        headers
      );

      // Check if the API response is successful
      if (GpsDeviceConnectData["type"] == "RXSUCCESS") {
        // Dispatch success alert messages
        dispatch(setalertMessage(GpsDeviceConnectData["message"]));
        dispatch(setalertMessagetype("success"));

        // Set loading state to false, hide popups, and refresh equipment list
        setloading(false);
        // setGpsAssignPopup(false)
        setGpsDeviceUnassignPopup(false);
        clearDirectionFilter();
        getEquipViaGpsDevice();
      } else {
        // Dispatch error alert messages
        dispatch(setalertMessage(GpsDeviceConnectData["message"]));
        dispatch(setalertMessagetype("error"));
        setloading(false);
        return false;
      }
    } catch (err) {
      // Log and handle errors
      console.log(err);
      setloading(false);
    }
  };

  /**
   * Function: GetGpsDeviceLocation
   * Description:
   * Gets the location of a GPS device or multiple GPS devices from the server,
   * updates the state, and handles success or error scenarios.
   * If successful, processes the location data, sets the default center location,
   * and adjusts the map bounds if there are multiple locations.
   * If an error occurs, dispatches relevant error alert messages.
   *
   * @param {string} equipId - The equipment ID for fetching GPS device location.
   * @param {string} type - The type of response ('single' or 'multiple').
   */ // Get connected device location

  /** // Function to handle the "View All" checkbox
   * Function: HandleViewAll
   * Description:
   * Handles the state change of the "View All" checkbox, triggering
   * the appropriate action based on whether the checkbox is checked or unchecked.
   * If checked, calls GetGpsDeviceLocation with 'all' type to show all GPS device locations.
   * If unchecked, calls GetGpsDeviceLocation with 'single' type to show the location of a single GPS device.
   */
  const HandleViewAll = (event) => {
    const checked = event.target.checked;
    setViewallLocation(checked);
    if (checked) {
      fitMapBound();
    } else {
      updateStates(event, CurrentActiveEquipObj, true);
    }
    // // Call GetGpsDeviceLocation with the appropriate type based on checkbox state
    // if (val) {
    //   GetGpsDeviceLocation(CurrentActiveEquip, "all");
    //   setGpsDeviceFilter(false);
    //   setDirections([]);
    //   setRealTimeEnabled(false);
    //   disconnectSocket();
    // } else {
    //   GetGpsDeviceLocation(CurrentActiveEquip, "single");
    // }
  };

  /** // Function to handle assigning a GPS device
   * Function: HandleAssignGP
   * Description:
   * Handles the assignment of a GPS device to equipment.
   * Checks if the selected GPS device is already assigned to another equipment.
   * If assigned, displays a reassign popup; otherwise, proceeds with the assignment.
   */
  const HandleAssignGP = () => {
    // Get the selected serial number
    let selectdSerialNumber = DeviceSerialNumber;

    // Flag to check if the selected GPS device is already assigned to another equipment
    let ob = false;

    // Check if GpsDevices and selectdSerialNumber are available
    if (GpsDevices && selectdSerialNumber) {
      // Iterate through GpsDevices to find a match for the selected serial number
      GpsDevices?.map((item, index) => {
        if (selectdSerialNumber == item.device_tableid) {
          // Check if the selected serial number is included in AssignSerialNumbers
          if (
            AssignSerialNumbers &&
            AssignSerialNumbers.includes(item.imei.trim())
          ) {
            ob = true;
            return;
          }
        }
      });
    }

    if (ob) {
      // Close the assign popup and show the reassign popup
      setGpsAssignPopup(false);
      setReAssignPopup(true);
    } else {
      // Proceed with GPS device assignment
      GpsDeviceAssign();
    }
  };

  const clearDirectionFilter = () => {
    setGpsDeviceFilter(false);
    setDirections([]);
    setGpsDateRange([]);
  };

  interface Equipment {
    id: number;
    device_assign_status: string;
    longitude: string;
    latitude: string;
  }

  const updateStates = (event, equipment: Equipment, zoomMap: boolean) => {
    setViewallLocation(false);
    setCurrentActiveEquip(equipment.id);
    setCurrentActiveEquipObj(equipment);
    setGpsDeviceFilter(false);
    setDirections([]);
    setGpsDateRange([]);

    getRealtimeLocation(event, "click");
    if (equipment.device_assign_status === "1") {
      fireAnalyticsEvent("GPS_Office_Trailer_to_GPS_to_Active_GPS");
      GetGpsDeviceLocation(equipment.id, "single", zoomMap);
    }
  };

  const handleReactClick = (
    event: React.MouseEvent<HTMLAnchorElement>,
    equipment: Equipment
  ) => {
    if (
      parseFloat(equipment.latitude) === 0 &&
      parseFloat(equipment.longitude) === 0
    ) {
      dispatch(setalertMessage("No Map data available!"));
      dispatch(setalertMessagetype("error"));
      return false;
    }
    if (event.target instanceof HTMLElement && event.target.tagName === "IMG") {
      const path = `/myRentalYard/${AuthUserID}/${equipment.id}`;
      navigate(path);
      return false;
    }
    updateStates(event, equipment, true);
  };

  const handleGoogleMapClick = (
    event: google.maps.MapMouseEvent,
    equipment: Equipment
  ) => {
    updateStates(event, equipment, false);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement> | google.maps.MapMouseEvent,
    equipment: Equipment
  ) => {
    if ("latLng" in event) {
      handleGoogleMapClick(event as google.maps.MapMouseEvent, equipment);
    } else {
      preventFitBound.current = true;
      handleReactClick(event as React.MouseEvent<HTMLAnchorElement>, equipment);
    }
  };

  const handleAssignment = (equipment) => {
    // for assign
    GpsDevice(AuthUserID, "Assign");
    setEquipObjToAssign(equipment);
  };

  const handleUnAssignment = (equipment) => {
    // for unassign
    setUnassignEquipId(equipment.id);
    setGpsDeviceUnassignPopup(true);
  };

  const createLocationUrl = ({ lat, lng }: { lat: string; lng: string }) => {
    const sourceLat = Number(AuthProfileData?.["lat"]);
    const sourceLng = Number(AuthProfileData?.["lng"]);
    const destinationLat = Number(lat);
    const destinationLng = Number(lng);
    if (
      destinationLat &&
      destinationLng &&
      !isNaN(sourceLat) &&
      !isNaN(sourceLng) &&
      !isNaN(destinationLat) &&
      !isNaN(destinationLng)
    ) {
      return `https://www.google.com/maps/dir/?api=1&origin=${sourceLat},${sourceLng}&destination=${destinationLat},${destinationLng}`;
    } else {
      dispatch(setalertMessage("No Map data available!"));
      dispatch(setalertMessagetype("error"));
      return "";
    }
  };

  const handleShareLocation = (equip: {
    longitude: string;
    latitude: string;
  }) => {
    const { latitude, longitude } = equip;
    const mapUrl = createLocationUrl({ lat: latitude, lng: longitude });
    if (mapUrl) {
      // copy url to clipboard
      setShareUrl(mapUrl);
      handleShareModalOpen();
      // navigator.clipboard.writeText(mapUrl);
    }
  };

  const handleGetDirection = (equip: {
    longitude: string;
    latitude: string;
  }) => {
    const { latitude, longitude } = equip;
    const mapUrl = createLocationUrl({ lat: latitude, lng: longitude });
    if (mapUrl) {
      window.open(mapUrl, "_blank");
    }
  };

  async function evaluateGpsDateRange(range) {
    const start = range[0]?.format?.();
    const end = range[1]?.format?.();
    // if (start) {
    //   const selectedMonth = new Date(start);
    //   selectedMonth.setDate(1);
    //   setMinDateValue(selectedMonth);
    // } else {
    //   setMinDateValue(null);
    // }
    if (start && end) {
      setGpsDateRange([start, end]);
    } else {
      setGpsDateRange([]);
      setGpsDeviceFilter(false);
    }
  }

  function structureWaypoints(reversedDirection) {
    const originPoints = reversedDirection[0][0];
    const lastArray = reversedDirection[reversedDirection.length - 1];
    const destinationPoints = lastArray[lastArray.length - 1];
    const directionsService = new google.maps.DirectionsService();
    const origin = {
      lat: parseFloat(originPoints["latitude"]),
      lng: parseFloat(originPoints["longitude"]),
    };
    const destination = {
      lat: parseFloat(destinationPoints["latitude"]),
      lng: parseFloat(destinationPoints["longitude"]),
    };
    setGpsDeviceDirection([{ origin, destination }]);
    const routes = [];
    let requestsCompleted = 0;

    reversedDirection.forEach((points) => {
      const waypoints = points?.map((point) => ({
        location: {
          lat: parseFloat(point["latitude"]),
          lng: parseFloat(point["longitude"]),
        },
        stopover: true,
      }));

      directionsService.route(
        {
          origin,
          destination,
          waypoints,
          optimizeWaypoints: true,
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (response, status) => {
          if (status === "OK") {
            routes.push(response);
          } else {
            console.error("Directions request failed due to " + status);
          }
          requestsCompleted++;

          if (requestsCompleted === reversedDirection.length) {
            setDirections(routes);
          }
        }
      );
    });
  }

  async function getGpsDeviceLocationFilter() {
    try {
      const deviceId = CurrentActiveEquipObj
        ? CurrentActiveEquipObj["device_id"]
        : null;
      if (!deviceId) {
        throw new Error("No device detected!");
      }
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const params = {
        start_date: gpsDateRange[0]?.replaceAll("/", "-"),
        end_date: gpsDateRange[1]?.replaceAll("/", "-"),
        device_id: deviceId?.toString(),
      };
      setloading(true);
      const response = await EquipmentAPIs.getGpsDeviceFilterLocation(
        params,
        headers
      );
      console.log("locattion=========>", response);
      if (response.status == 200) {
        const reversedDirection = response.data.reverse();
        setGpsDeviceFilter(true);
        if (reversedDirection.length === 0) {
          setDirections([]);
          throw new Error("No activity detected for this device!");
        }
        structureWaypoints(reversedDirection);
      }
      setloading(false);
    } catch (err) {
      setloading(false);
      dispatch(setalertMessage(err.message));
      dispatch(setalertMessagetype("error"));
      console.log("Error while rendering directions", err);
    }
  }

  async function getRealtimeLocation(event, fitbound = "checked") {
    const isRealTime = event?.target?.checked;
    if (isRealTime && AuthProfileData) {
      setRealTimeEnabled(isRealTime);
      socket = io(SOCKET_URL, {
        query: {
          from: "web",
          user_id: AuthProfileData["user_id"],
        },
      });
      socket.on("connect", () => {
        console.log("connected");
      });
      setGpsDeviceFilter(false);
      setDirections([]);
      setGpsDateRange([]);
      map.setZoom(18);
      map.panTo({
        lat: parseFloat(CurrentActiveEquipObj["latitude"]),
        lng: parseFloat(CurrentActiveEquipObj["longitude"]),
      });
      socket.on("server_message", (data) => {
        console.log("datat from socket", data);
        const id = CurrentActiveEquipObj["device_id"];
        if (+id === +data["device_id"]) {
          const newGpsLocation = { ...CurrentActiveEquipObj };
          newGpsLocation["latitude"] = data["latitude"];
          newGpsLocation["longitude"] = data["longitude"];
          map.panTo({
            lat: parseFloat(data["latitude"]),
            lng: parseFloat(data["longitude"]),
          });
          const modifiedLocation = GpsDeviceLocationArr.map((item) => {
            if (item.id === id) {
              return newGpsLocation;
            }
            return item;
          });
          setGpsDeviceLocationArr(modifiedLocation);
        }
      });
    } else {
      if (realTimeEnabled && fitbound === "checked") {
        fitMapBound();
      }
      disconnectSocket();
      setRealTimeEnabled(false);
    }
  }

  function disconnectSocket() {
    if (socket) {
      socket.disconnect();
      socket = undefined;
    }
  }

  useEffect(() => {
    //added by pp on 8 apr 2024 due to trigger a change when user leave chat thread or close the browser tab
    window.addEventListener("beforeunload", (e) => {
      disconnectSocket();
    });
    return () => {
      disconnectSocket();
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (AuthProfileData && AuthProfileData != null) {
      // by GD 29 Jan 2024 for firebase/google analylics
      // fireAnalyticsEvent("Office_Trailer_GPS");
      fireAnalyticsEvent("GPS_Office_Trailer_to_GPS");
      getEquipViaGpsDevice();
      // GpsDevice(AuthUserID);
    }
  }, [AuthProfileData]);

  useEffect(() => {
    if (assignedDevices?.data?.length && CurrentActiveEquip && map) {
      fitMapBound();
    }
  }, [assignedDevices, map]);

  return (
    <>
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}

      <Box className="rentyrd-grid">
        <Box className="rentyrd-grid-item rentyrd-left">
          <Box className="gps-heading">
            <Box className="gps-heading-left">
              <Typography className="gps-h">GPS</Typography>

              <Box className="gps-grid-left-heading">
                <Typography> Total Devices: {TotalDevice}</Typography>
                <Typography> Total Unassigned: {TotalUnassigned}</Typography>
              </Box>
            </Box>

            <Box>
              <Button
                className="request-more-gps-btn"
                type="button"
                variant="contained"
                sx={{ textTransform: "unset !important" }}
                // onClickCapture={() => {
                //   setGpsRequestPopup(true)
                // }}
                onClickCapture={GpsRequestCreate}
              >
                Order GPS
              </Button>
            </Box>
          </Box>

          {assignedDevices?.data?.length > 0 ||
          unAssignedDevices?.data?.length > 0 ? (
            <>
              {assignedDevices?.data?.length > 0 && (
                <Box>
                  <NewGps
                    equipments={assignedDevices}
                    handleAssignment={handleUnAssignment}
                    handleShareLocation={handleShareLocation}
                    handleGetDirection={handleGetDirection}
                    handleClick={handleClick}
                    currentActiveEquip={CurrentActiveEquip}
                    deviceStatusLabel={"Unassign"}
                    isLoactionDisable={false}
                    HandlePagination={() => {
                      handleGpsPagination("Assigned");
                    }}
                  />
                </Box>
              )}
              {unAssignedDevices?.data.length > 0 && (
                <Box>
                  <NewGps
                    equipments={unAssignedDevices}
                    handleAssignment={handleAssignment}
                    handleShareLocation={() => {}}
                    handleGetDirection={() => {}}
                    handleClick={handleClick}
                    currentActiveEquip={CurrentActiveEquip}
                    deviceStatusLabel={"Assign"}
                    isLoactionDisable={true}
                    HandlePagination={() => {
                      handleGpsPagination("Unassigned");
                    }}
                  />
                </Box>
              )}
            </>
          ) : (
            <>
              <Typography className="empty-rent">
                <span>You do not have any equipment listed yet.</span>
                <span>
                  To list your first item&nbsp;
                  <Link
                    className="rentyrd-menu-link"
                    onClick={(event) => handleListEquipment(event)}
                  >
                    click here
                  </Link>
                </span>
              </Typography>
            </>
          )}
        </Box>

        {CurrentActiveEquip &&
        CurrentActiveEquipObj["device_assign_status"] == 1 &&
        parseFloat(CurrentActiveEquipObj["latitude"]) &&
        parseFloat(CurrentActiveEquipObj["longitude"]) ? (
          <Box className="rentyrd-grid-item rentyrd-right">
            <Box className="rentyrd-left-float">
              <Box className="equipment-details-heading">
                <Typography>Last Reported Location </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: {
                    xs: "flex-start",
                    sm: "center",
                  },
                  justifyContent: {
                    xs: "flex-start",
                    sm: "center",
                  },
                  flexDirection: {
                    xs: "column",
                    sm: "row",
                  },
                  margin: "1rem 0rem",
                  gap: "0.5rem",
                }}
              >
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "0rem" }}
                >
                  <Checkbox
                    sx={{
                      "&:hover": { bgcolor: "transparent" },
                    }}
                    onChange={HandleViewAll}
                    disableRipple
                    color="default"
                    checkedIcon={<BpCheckedIcon />}
                    icon={<BpIcon />}
                    checked={ViewallLocation}
                    inputProps={{ "aria-label": "Checkbox demo" }}
                  />
                  <FormLabel
                    sx={{
                      color: "#fff",
                      width: "80px",
                      fontSize: { xs: "14px", sm: "14px", md: "16px" },
                      fontFamily: "Arial !important",
                    }}
                  >
                    View All
                  </FormLabel>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "0.5rem",
                    width: "100%",
                    boxSizing: "border-box",
                  }}
                >
                  <Box
                    className="range-pick"
                    sx={{
                      cursor: "pointer",
                      padding: "0px 5px",
                      border: "3px solid #faa61a",
                      width: {
                        xs: "60%",
                        md: "40%",
                      },
                      background: "#fff",
                      height: "40px",
                      borderRadius: "8px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "5px",
                      ...(realTimeEnabled || ViewallLocation
                        ? { opacity: 0.5, pointerEvents: "none" }
                        : {}),
                    }}
                    onClick={() => {
                      if (!realTimeEnabled && !ViewallLocation) {
                        setGpsDateRangeModel(true);
                      }
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      {/* <Typography sx={{ color: '#000 !important', fontSize: '14px', margin: '0', padding: '0' }}>
                          {gpsDeviceFilter && gpsDateRange.length === 2 ? moment(gpsDateRange[0]).format("MM/DD/YYYY") : "Start Date"}
                        </Typography>
                        <HorizontalRuleIcon />
                        <Typography sx={{ color: '#000 !important', fontSize: '14px', margin: '0', padding: '0' }}>
                          {gpsDeviceFilter && gpsDateRange.length === 2 ? moment(gpsDateRange[1]).format("MM/DD/YYYY") : "End Date"}
                        </Typography> */}
                      {gpsDeviceFilter && gpsDateRange.length === 2 ? (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "3px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#000 !important",
                              margin: "0",
                              padding: "0",
                              fontSize: {
                                xs: "12px",
                                md: "14px",
                              },
                            }}
                          >
                            {moment(gpsDateRange[0]).format("MM/DD/YYYY")}
                          </Typography>
                          <HorizontalRuleIcon />
                          {/* <Typography
                              sx={{
                                color: "#faa61a",
                                fontSize: "14px",
                                fontWeight: "800",
                              }}
                            >
                              -
                            </Typography> */}
                          <Typography
                            sx={{
                              color: "#000 !important",
                              fontSize: {
                                xs: "12px",
                                md: "14px",
                              },
                              margin: "0",
                              padding: "0",
                            }}
                          >
                            {moment(gpsDateRange[1]).format("MM/DD/YYYY")}
                          </Typography>
                        </Box>
                      ) : (
                        <Typography
                          sx={{
                            color: "#000 !important",
                            fontSize: "14px",
                            margin: "0",
                            padding: "0",
                          }}
                        >
                          History
                        </Typography>
                      )}
                    </Box>

                    <CalendarMonthIcon />
                  </Box>
                  <FormControl component="fieldset">
                    {CurrentActiveEquipObj &&
                      CurrentActiveEquipObj["device_typeid"] == "1216" && (
                        <FormGroup aria-label="position" row>
                          <FormControlLabel
                            value="start"
                            control={
                              <IOSSwitch
                                style={{
                                  color: "#faa61a",
                                  fontFamily: "Gotham !important",
                                  boxSizing: "border-box",
                                }}
                                disabled={ViewallLocation ? true : false}
                                checked={realTimeEnabled}
                                onChange={(event) => getRealtimeLocation(event)}
                              />
                            }
                            sx={{
                              color: "#fff",
                              fontFamily: "Gotham !important",
                              marginRight: 0,
                            }}
                            label={
                              <Typography
                                sx={{
                                  color: "#fff",
                                  fontFamily: "Arial !important",
                                  pr: 1,
                                  fontSize: {
                                    xs: "14px",
                                    sm: "14px",
                                    md: "16px",
                                  },
                                }}
                              >
                                Realtime:
                              </Typography>
                            }
                            labelPlacement="start"
                          />
                        </FormGroup>
                      )}
                  </FormControl>
                </Box>
              </Box>
              <Box className="rentyrd-card">
                {GpsDeviceLocationArr.length > 0 ? (
                  <>
                    <GoogleMap
                      onLoad={(map) => setMap(map)}
                      ref={mapview}
                      center={defaulCenterLocation}
                      zoom={12}
                      mapContainerStyle={containerStyle}
                    >
                      {!gpsDeviceFilter && (
                        <>
                          {GpsDeviceLocationArr?.map((locationItem, indx) => {
                            // let number = Number(indx) + 1;
                            return (
                              <Marker
                                title={
                                  CurrentActiveEquip == locationItem.id &&
                                  GpsDeviceLocationArr.length === 1
                                    ? "Current Location"
                                    : ""
                                }
                                position={{
                                  lat: parseFloat(locationItem["latitude"]),
                                  lng: parseFloat(locationItem["longitude"]),
                                }}
                                key={locationItem.id}
                                //label={locationItem.id.toString()}
                                icon={{
                                  // url:
                                  //   GpsDeviceLocationArr.length === 1
                                  //     ? "http://maps.google.com/mapfiles/ms/icons/red-dot.png"
                                  //     : CurrentActiveEquip == locationItem.id
                                  //     ? `http://maps.google.com/mapfiles/ms/icons/blue-dot.png`
                                  //     : "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
                                  url:
                                    CurrentActiveEquip == locationItem.id
                                      ? orangeDotIcon
                                      : redDotIcon,
                                  scaledSize: new window.google.maps.Size(
                                    35,
                                    35
                                  ), // Change the size of the icon
                                }}
                                onClick={(event) => {
                                  handleClick(event, locationItem);
                                }}
                              />
                            );
                          })}
                        </>
                      )}
                      {gpsDeviceFilter && gpsDeviceDirection.length && (
                        <>
                          {gpsDeviceDirection[0]["origin"]["lat"] ===
                            gpsDeviceDirection[0]["destination"]["lat"] &&
                          gpsDeviceDirection[0]["origin"]["lng"] ===
                            gpsDeviceDirection[0]["destination"]["lng"] ? (
                            <>
                              <Marker
                                title={"Start and End Location"}
                                position={{
                                  lat: gpsDeviceDirection[0]["origin"]["lat"],
                                  lng: gpsDeviceDirection[0]["origin"]["lng"],
                                }}
                                icon={{
                                  // url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
                                  url: redDotIcon,
                                  scaledSize: new window.google.maps.Size(
                                    40,
                                    40
                                  ),
                                }}
                              />
                            </>
                          ) : (
                            <>
                              <Marker
                                title={"Start Location"}
                                position={{
                                  lat: gpsDeviceDirection[0]["origin"]["lat"],
                                  lng: gpsDeviceDirection[0]["origin"]["lng"],
                                }}
                                icon={{
                                  // url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
                                  url: redDotIcon,
                                  scaledSize: new window.google.maps.Size(
                                    40,
                                    40
                                  ),
                                }}
                              />
                              <Marker
                                title={"End Location"}
                                position={{
                                  lat: gpsDeviceDirection[0]["destination"][
                                    "lat"
                                  ],
                                  lng: gpsDeviceDirection[0]["destination"][
                                    "lng"
                                  ],
                                }}
                                icon={{
                                  // url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                                  url: orangeDotIcon,
                                  scaledSize: new window.google.maps.Size(
                                    40,
                                    40
                                  ),
                                }}
                              />
                            </>
                          )}
                        </>
                      )}
                      {directions?.map((direction, index) => (
                        <DirectionsRenderer
                          key={index}
                          directions={direction}
                          options={{ suppressMarkers: true }}
                        />
                      ))}
                    </GoogleMap>

                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: "20px",
                        fontFamily: "Gotham !important",
                        marginTop: "0.5rem",
                      }}
                    >
                      {CurrentActiveEquipObj &&
                      CurrentActiveEquipObj["device_typeid"] != "1216"
                        ? "Devices report location once every 24 hours"
                        : ""}
                    </Typography>
                  </>
                ) : (
                  ""
                )}
              </Box>
            </Box>
            {/* <Box mt="1rem">
                <Typography variant="h5" sx={{ color: "#fff" }}>
                  Available GPS Info
                </Typography>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    // gap: "1rem",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "14px",
                        md: "14px",
                        lg: "16px",
                        xl: "18px",
                      },
                      fontFamily: "Montserrat !important",
                      fontWeight: "500",
                      color: "#fff",
                    }}
                  >
                    GPS Serial
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "14px",
                        md: "14px",
                        lg: "16px",
                        xl: "18px",
                      },
                      fontFamily: "Montserrat !important",
                      fontWeight: "400",
                      color: "#fff",
                    }}
                  >
                    #{activeEquipGpsData?.["device_imei"]}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    // gap: "1rem",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "14px",
                        md: "14px",
                        lg: "16px",
                        xl: "18px",
                      },
                      fontFamily: "Montserrat !important",
                      fontWeight: "500",
                      color: "#fff",
                    }}
                  >
                    Battery Percentage
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "14px",
                        md: "14px",
                        lg: "16px",
                        xl: "18px",
                      },
                      fontFamily: "Montserrat !important",
                      fontWeight: "400",
                      color: "#fff",
                    }}
                  >
                    {activeEquipGpsData?.["device_typeid"] === 1216
                      ? "Wired"
                      : `${activeEquipGpsData?.["battery_level"] || 0}%  `}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    // gap: "1rem",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "14px",
                        md: "14px",
                        lg: "16px",
                        xl: "18px",
                      },
                      fontFamily: "Montserrat !important",
                      fontWeight: "500",
                      color: "#fff",
                    }}
                  >
                    Temperature Alarm
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "14px",
                        md: "14px",
                        lg: "16px",
                        xl: "18px",
                      },
                      fontFamily: "Montserrat !important",
                      fontWeight: "400",
                      color: "#fff",
                    }}
                  >
                    none
                  </Typography>
                </Box>
              </Box> */}
          </Box>
        ) : CurrentActiveEquip &&
          CurrentActiveEquipObj["device_assign_status"] == 1 &&
          !parseFloat(CurrentActiveEquipObj["latitude"]) &&
          !parseFloat(CurrentActiveEquipObj["longitude"]) ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "auto",
              textAlign: "center",
              position: "relative",
            }}
          >
            <Typography
              sx={{
                color: "#fff",
                padding: "0px 30px",
                fontSize: "22px",
                position: "absolute",
                fontFamily: "GothamBold !important",
                fontWeight: "500",
                top: "30%",
                zIndex: 2,
              }}
            >
              Map data not yet available. Make sure the GPS device is powered
              on. If location does not show after 24 hours, contact support
              using the chat widget found in the bottom right.
              <Typography
                sx={{
                  color: "#faa61a",
                  fontSize: "24px",
                  fontFamily: "GothamBold !important",
                  fontWeight: "500",
                }}
                onClick={() => {
                  localStorage.setItem("OpenChat", "Open");
                }}
                style={{ cursor: "pointer" }}
              >
                Or click here.
              </Typography>
            </Typography>
            <Box
              component="img"
              src={mapNotAvailable}
              alt="gps"
              sx={{ maxWidth: "100%", height: "auto", zIndex: 1 }}
            />
          </Box>
        ) : CurrentActiveEquip &&
          CurrentActiveEquipObj["device_assign_status"] == 0 ? (
          <Box className="rentyrd-grid-item rentyrd-right">
            <Box className="rentyrd-left-float">
              <Box className="equipment-details-heading no-gps">
                {/* <Typography>No Gps assigned </Typography> */}
                <img src={noGpsAssigned} className="nogps-img"></img>
              </Box>
            </Box>
          </Box>
        ) : null}
      </Box>

      {/* Make Gps Request Popup */}
      <Modal
        keepMounted
        open={GpsRequestPopup}
        onClose={() => setGpsRequestPopup(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popups"
          sx={{
            ...loginstyle,
            padding: "0px",
          }}
        >
          <IconButton
            className="cross-btn"
            onClick={() => setGpsRequestPopup(false)}
          >
            <CloseIcon />
          </IconButton>

          <Box className="login__main">
            <Box
              className="login__main-div edit-eqp"
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {loading && (
                <div>
                  <Backdrop
                    sx={{
                      color: "#fff",
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={loading}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </div>
              )}
              <Box className="login__main-div__form edit-eqp-inner popup-cmmn popup-white">
                <Box className="gps-modal-heading">
                  <img src={satellite} className="satelite-icon" alt="" />
                  <Typography variant="h5" sx={{ color: "#fff !important" }}>
                    GoEquipMe<span style={{ color: "#faa61a" }}>GPS</span>
                  </Typography>
                </Box>

                <Box className="rentyrd-dates">
                  <Box className="rentyrd-dates-item rental-dates">
                    <img
                      src={requestGPS}
                      className=""
                      alt=""
                      style={{ width: "100%" }}
                    />
                  </Box>
                </Box>

                <Typography variant="h4" sx={{ color: "#faa61a" }}>
                  Equipment Tracking is Here!
                </Typography>

                <Typography sx={{ color: "#fff" }}>
                  Get GoEquipMe GPS and use the app to track your equipment
                  24/7. See how good our special user rates are! Just hit the
                  REQUEST button and GoEquipMe will contact you.
                </Typography>

                <Button
                  className="gps-request-modal-btn"
                  type="button"
                  variant="contained"
                  sx={{ mt: 3, mb: 2, backgroundColor: "#139246" }}
                  onClickCapture={GpsRequestCreate}
                >
                  REQUEST
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* Make Gps Request Result Popup */}
      <Modal
        keepMounted
        open={GpsRequestPopupResult}
        onClose={() => {
          setGpsRequestPopupResult(false);
          setRequestResponse("");
        }}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popups"
          sx={{
            ...loginstyle,
            padding: "0px",
          }}
        >
          <IconButton
            className="cross-btn"
            onClick={() => {
              setGpsRequestPopupResult(false);
              setRequestResponse("");
            }}
          >
            <CloseIcon />
          </IconButton>

          <Box className="login__main">
            <Box
              className="login__main-div edit-eqp"
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {loading && (
                <div>
                  <Backdrop
                    sx={{
                      color: "#fff",
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={loading}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </div>
              )}
              <Box className="login__main-div__form edit-eqp-inner popup-cmmn popup-white">
                <Typography sx={{ color: "#fff" }}>
                  {RequestResponse}
                  {/* Thank you, a GoEquipMe representative will reach out to you.*/}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* Assign GPS Device to Equip */}
      <Modal
        keepMounted
        open={GpsAssignPopup}
        onClose={() => setGpsAssignPopup(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popups"
          sx={{
            ...loginstyle,
            padding: "0px",
          }}
        >
          <IconButton
            className="cross-btn"
            onClick={() => setGpsAssignPopup(false)}
          >
            <CloseIcon />
          </IconButton>

          <Box className="login__main">
            <Box
              className="login__main-div edit-eqp"
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box className="login__main-div__form edit-eqp-inner popup-cmmn popup-white">
                <Box className="gps-modal-heading">
                  <img src={satellite} className="satelite-icon" alt="" />
                  <Typography variant="h5" sx={{ color: "#fff !important" }}>
                    Assign<span style={{ color: "#faa61a" }}>GPS</span>
                  </Typography>
                </Box>

                {/* <Typography variant="h5" sx={{ color: "#000" }}>
                  <img src={satellite} className="step-icon" alt="" />
                  ASSIGN<span style={{ color: "#faa61a" }}>GPS</span>
                </Typography> */}

                <Box>
                  {loading && (
                    <Box>
                      <Backdrop
                        sx={{
                          color: "#000",
                          zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                        open={loading}
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    </Box>
                  )}
                  {Object.entries(equipObjToAssign).length > 0 && (
                    <>
                      <Box className="owner-div">
                        <Box
                          // className="booked-img"
                          sx={{ border: "3px solid #faa61a", mr: 2 }}
                        >
                          <Box
                            component={"img"}
                            src={equipObjToAssign["thumbnail"] || ""}
                            alt=""
                            sx={{
                              maxWidth: "150px",
                              height: "120px !important",
                              borderRadius: "0px",
                            }}
                          />
                        </Box>
                        <Box sx={{ fontWeight: "bold" }}>
                          <Typography>
                            {equipObjToAssign["make"] || "Not Available"}
                          </Typography>
                          <Typography style={{ color: "#faa61a" }}>
                            {equipObjToAssign["model"] || "Not Available"}
                          </Typography>
                        </Box>
                      </Box>

                      <Box className="rental-sec-item-top">
                        <Typography>
                          To assign your GPS device to this equipment, enter or
                          choose the IMEI # below
                        </Typography>
                        <Box
                          // className="location-sec-grid"
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "2fr 1fr",
                            gridGap: "3rem",
                          }}
                        >
                          <FormControl
                            variant="filled"
                            sx={{ marginTop: "1rem" }}
                          >
                            <InputLabel
                              id="demo-simple-select-filled-label"
                              sx={{
                                color: "#2d2d2d !important",
                                fontSize: "15px !important",
                              }}
                            >
                              Select GPS IMEI # number
                            </InputLabel>

                            <Select
                              className="select-dropdwn gps-modal-select"
                              labelId="demo-simple-select-filled-label"
                              id="demo-simple-select"
                              value={DeviceSerialNumber}
                              label="Select GPS IMEI # number"
                              fullWidth
                              onChange={(e) => {
                                setDeviceSerialNumber(e.target.value);
                              }}
                              sx={{
                                "& .MuiSelect-select": {
                                  color: "#2D2D2D !important",
                                  fontFamily: "Gotham !important",
                                  fontSize: "14px !important",
                                },
                                "& .MuiFilledInput-underline:after": {
                                  borderBottom: "0 !important",
                                  borderBottomColor: "transparent !important",
                                },
                                "& .MuiFilledInput-underline:before": {
                                  borderBottom: "0 !important",
                                  borderBottomColor: "transparent !important",
                                },
                              }}
                            >
                              <MenuItem value="">
                                Select GPS IMEI # number
                              </MenuItem>
                              {GpsDevices &&
                                GpsDevices?.map((item, index) => {
                                  const EMEI = item.imei.trim();
                                  const isAssigned =
                                    AssignSerialNumbers?.includes(EMEI);
                                  return (
                                    <MenuItem
                                      value={item.device_tableid}
                                      key={item.device_tableid}
                                    >
                                      {index + 1}. {"..."}
                                      {EMEI?.slice(-5)}
                                      {isAssigned ? (
                                        <>
                                          &nbsp;
                                          <span style={{ color: "#000" }}>
                                            (
                                            {item["equip_title"]?.slice(
                                              0,
                                              20
                                            ) ?? ""}
                                            {item["equip_title"]?.length > 20
                                              ? ".."
                                              : ""}
                                            )
                                          </span>
                                        </>
                                      ) : (
                                        <>
                                          &nbsp;
                                          <span style={{ color: "#ff0000" }}>
                                            Unassigned
                                          </span>
                                        </>
                                      )}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          </FormControl>
                        </Box>
                      </Box>
                    </>
                  )}
                </Box>

                <Button
                  className="gps-request-modal-btn"
                  type="button"
                  variant="contained"
                  sx={{ mt: 2, mb: 2, backgroundColor: "#139246" }}
                  //onClickCapture={GpsDeviceAssign}
                  onClickCapture={HandleAssignGP}
                >
                  ASSIGN
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* Unassign GPS Device from Equip */}
      <Modal
        keepMounted
        open={GpsDeviceUnassignPopup}
        onClose={() => setGpsDeviceUnassignPopup(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popups"
          sx={{
            ...loginstyle,
            padding: "0px",
          }}
        >
          <IconButton
            className="cross-btn"
            onClick={() => setGpsDeviceUnassignPopup(false)}
          >
            <CloseIcon />
          </IconButton>

          <Box className="login__main">
            <Box
              className="login__main-div edit-eqp"
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box className="login__main-div__form edit-eqp-inner popup-cmmn popup-white">
                <Box className="gps-modal-heading">
                  <img src={satellite} className="satelite-icon" alt="" />
                  <Typography variant="h5" sx={{ color: "#fff !important" }}>
                    Unassign<span style={{ color: "#faa61a" }}>GPS</span>
                  </Typography>
                </Box>

                {/* <Typography variant="h5" sx={{ color: "#000" }}>
                  <img src={satellite} className="step-icon" alt="" />
                  UNASSIGN<span style={{ color: "#faa61a" }}>GPS</span>
                </Typography> */}

                <Box>
                  {/* {loading && (
                    <Box>
                      <Backdrop
                        sx={{
                          color: "#000",
                          zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                        open={loading}
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    </Box>
                  )} */}

                  <Box className="rental-sec-item-top">
                    <Typography>
                      Are you sure you want to unassign GPS device for this
                      equipment.
                    </Typography>
                    <Box className="location-sec-grid">
                      <Button
                        className="signin-btn common-yellow-btn"
                        type="button"
                        variant="contained"
                        sx={{
                          mt: 3,
                          mb: 2,
                          backgroundColor: "#faa61a !important",
                        }}
                        onClickCapture={() => {
                          setGpsDeviceUnassignPopup(false);
                        }}
                      >
                        No
                      </Button>
                      <Button
                        className="signin-btn"
                        type="button"
                        variant="contained"
                        sx={{ mt: 3, mb: 2, backgroundColor: "#139246" }}
                        onClickCapture={GpsDeviceUnassign}
                      >
                        Yes
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* ReAssign GPS Device Equip confirm popup */}
      <Modal
        keepMounted
        open={ReAssignPopup}
        onClose={() => setReAssignPopup(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popups"
          sx={{
            ...loginstyle,
            padding: "0px",
          }}
        >
          <IconButton
            className="cross-btn"
            onClick={() => setReAssignPopup(false)}
          >
            <CloseIcon />
          </IconButton>

          <Box className="login__main">
            <Box
              className="login__main-div edit-eqp"
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box className="login__main-div__form edit-eqp-inner popup-cmmn popup-white">
                <Box className="gps-modal-heading">
                  <img src={satellite} className="satelite-icon" alt="" />
                  <Typography variant="h5" sx={{ color: "#fff !important" }}>
                    Reassign<span style={{ color: "#faa61a" }}>GPS</span>
                  </Typography>
                </Box>

                <Box>
                  <Box className="rental-sec-item-top">
                    <Typography>
                      Are you sure you want to reassign this GPS device?
                    </Typography>
                    <Box className="location-sec-grid">
                      <Button
                        className="signin-btn common-yellow-btn"
                        type="button"
                        variant="contained"
                        sx={{
                          mt: 3,
                          mb: 2,
                          backgroundColor: "#faa61a !important",
                        }}
                        onClickCapture={() => {
                          setReAssignPopup(false);
                        }}
                      >
                        No
                      </Button>
                      <Button
                        className="signin-btn"
                        type="button"
                        variant="contained"
                        sx={{ mt: 3, mb: 2, backgroundColor: "#139246" }}
                        onClickCapture={GpsDeviceAssign}
                      >
                        Yes
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* calender view model  */}

      <Modal
        keepMounted
        open={gpsDateRangeModel}
        onClose={() => setGpsDateRangeModel(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popups"
          sx={{
            ...loginstyle,
            padding: "0px",
          }}
        >
          <IconButton
            className="cross-btn"
            onClick={() => setGpsDateRangeModel(false)}
          >
            <CloseIcon />
          </IconButton>

          <Box className="login__main gps-calender-popup">
            <Box
              className="login__main-div edit-eqp"
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                className="login__main-div__form edit-eqp-inner popup-cmmn popup-white"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <Box className="gps-modal-heading gps-range-model-heading">
                  <CalendarMonthIcon sx={{ color: "#faa61a !important" }} />
                  <Typography
                    variant="h5"
                    sx={{ color: "#fff !important", ml: "1rem" }}
                  >
                    Select Date Range
                  </Typography>
                </Box>
                <Box className="filter-calendar">
                  <DatePicker
                    numberOfMonths={1}
                    disableMonthPicker
                    disableYearPicker
                    // minDate={minDateValue}
                    maxDate={new Date()}
                    value={gpsDateRange.length === 0 ? null : gpsDateRange}
                    onChange={(range) => {
                      evaluateGpsDateRange(range);
                    }}
                    range
                  />
                </Box>
                {gpsDateRange.length === 2 && (
                  <>
                    <Box className="gps-model-footer">
                      <Typography className="typography">
                        Start Date -{" "}
                        {moment(gpsDateRange[0]).format("MM/DD/YYYY")}
                      </Typography>
                      <Typography className="typography">
                        End Date -{" "}
                        {moment(gpsDateRange[1]).format("MM/DD/YYYY")}
                      </Typography>
                      <Box className="gps-model-confirmation">
                        <Button
                          className="signin-btn common-yellow-btn"
                          type="button"
                          variant="contained"
                          sx={{
                            mt: 3,
                            mb: 2,
                            backgroundColor: "#faa61a !important",
                          }}
                          onClick={() => {
                            setGpsDateRange([]);
                            setGpsDeviceFilter(false);
                            setDirections([]);
                            // setMinDateValue(null);
                          }}
                        >
                          Reset
                        </Button>
                        <Button
                          className="signin-btn"
                          type="button"
                          variant="contained"
                          onClick={() => {
                            setGpsDateRangeModel(false);
                            getGpsDeviceLocationFilter();
                          }}
                          sx={{ backgroundColor: "#139246" }}
                        >
                          Search
                        </Button>
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>

      <SocialShareModal
        open={shareModalOpen}
        handleClose={handleShareModalClose}
        title={"Share Location"}
        shareUrl={shareUrl}
      />
    </>
  );
};
export default Gps;
