import {
  Box,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import useDebounce from "components/user-signup/useDebounce";
import AddressPopup from "pages/equipment-tracking/AddressPopup";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IStoreValue } from "utils/types";
import { getFormattedGoogleAdress } from "utils/formattingAddress";
import { CartContext } from "pages/GemStore/CartCheckout";
import CurrenyFormat from "components/common/CurrenyFormat";
import PaymentHold from "components/AddBalance/PaymentHold";
import CloseIcon from "@mui/icons-material/Close";
import { setalertMessage, setalertMessagetype } from "redux/redux-slice";
import { useNavigate } from "react-router-dom";

const inputStyles = {
  border: "2px solid gray",
  color: "#2d2d2d",
  borderRadius: "6px",
  outline: "none",
  boxShadow: "unset",
  width: "auto",
  backgroundColor: "#e3e3e3",
  padding: "0.7rem",
  fontSize: "15px",
  fontFamily: "Gotham !important",
};

interface UserTypes {
  name: string;
  email: string;
  phone: string;
  shipping_address: string;
}

interface GoogleAddress {
  city: string;
  country: string;
  formatted_address: string;
  lat: number;
  lng: number;
  state: string;
  zipcode: string;
}

interface AddField {
  city: string;
  state: string;
  address: string;
  addressLine: string;
}
const APIKEY = process.env.REACT_APP_GEOCODDER_KEY;
const mailformat =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const userObject = {
  name: "",
  email: "",
  phone: "",
  shipping_address: "",
};
const cityStateObject = {
  city: "",
  state: "",
  address: "",
  addressLine: "",
};
const fullAddressObject = {
  city: "",
  country: "",
  formatted_address: "",
  lat: 0,
  lng: 0,
  state: "",
  zipcode: "",
};
const CheckoutForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    totalPrice,
    handleCartCheckout,
    successPopup,
    successPopupMsg,
    productQtySize,
  } = useContext(CartContext);
  const AuthProfileData = useSelector(
    (state: IStoreValue) => state.equipmentReducer.AuthProfileData
  );
  const AuthUserId = useSelector(
    (state: IStoreValue) => state.equipmentReducer.Authuser
  );
  const [userDetail, setUserDetail] = useState<UserTypes>({ ...userObject });
  const [zipLoading, setZipLoading] = useState(false);
  const [zipCode, setZipCode] = useState("");
  const debouncedZipCode = useDebounce(zipCode, 500);
  const [isValidZipcode, setIsValidZipcode] = useState(false);
  const [cityState, setCityState] = useState<AddField>({ ...cityStateObject });
  const [fullAddress, setFullAddress] = useState<GoogleAddress>({
    ...fullAddressObject,
  });
  const [addressModal, setAddressModal] = useState<boolean>(false);
  const handleAddressModalOpen = () => setAddressModal(true);
  const handleAddressModalClose = () => setAddressModal(false);
  const [payWithCard, setPayWithCard] = useState<boolean>(false);
  const handlePayWithCardClose = () => setPayWithCard(false);
  const handlePayWithCardOpen = () => {
    const hasEmptyValue = Object.values(productQtySize).some(
      (pdt) => isNaN(pdt["qty"]) || pdt["qty"] === ""
    );
    if(hasEmptyValue) {
      dispatch(setalertMessage("Please fill the quantity to the product."));
      dispatch(setalertMessagetype("error"));
      return false;
    }
    if (totalPrice <= 0) {
      dispatch(setalertMessage("Item in your cart are currently unavailable!"));
      dispatch(setalertMessagetype("error"));
      return false;
    }
    if (!userDetail?.name?.trim()) {
      dispatch(setalertMessage("Please fill your name to continue!"));
      dispatch(setalertMessagetype("error"));
      return false;
    }
    const email = userDetail?.email?.trim();
    if (!email) {
      dispatch(setalertMessage("Please fill email address to continue!!"));
      dispatch(setalertMessagetype("error"));
      return false;
    }
    if (!email.match(mailformat)) {
      dispatch(setalertMessage("Invalid email address!"));
      dispatch(setalertMessagetype("error"));
      return false;
    }
    if (!userDetail?.shipping_address?.trim()) {
      dispatch(setalertMessage("Please fill shipping address to continue!"));
      dispatch(setalertMessagetype("error"));
      return false;
    }
    setPayWithCard(true);
  };

  const phoneFormat = (phNumber: string) => {
    if (!phNumber) {
      return "";
    }
    phNumber = phNumber?.replace(/\D/g, "");
    phNumber = phNumber.slice(0, 10);
    if (phNumber.length === 10) {
      return `${phNumber.slice(0, 3)}-${phNumber.slice(3, 6)}-${phNumber.slice(
        6
      )}`;
    }
    return phNumber;
  };

  const handleChange = (event, type: string) => {
    const value = event.target.value;
    if (type === "name") {
      setUserDetail((prev) => ({ ...prev, name: value }));
    } else if (type === "phone") {
      const validValue = phoneFormat(value);
      setUserDetail((prev) => ({ ...prev, phone: validValue }));
    } else if (type === "email") {
      setUserDetail((prev) => ({ ...prev, email: value }));
    }
  };
  const onConfirmSaveAddress = (address: string) => {
    setUserDetail((prev) => ({ ...prev, shipping_address: address }));
  };

  const handleZipChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const zipcode = event.target.value?.trim()?.replace(/[^0-9]/g, "");
    setZipCode(zipcode);
  };

  const verifyZipCode = async (zip: string) => {
    setZipLoading(true);
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      zip
    )}&key=${APIKEY}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.status === "OK" && data.results.length > 0) {
        setIsValidZipcode(true);
        // console.log("ssssss================>", data.results[0]);
        const gFormattedAddress = getFormattedGoogleAdress(data.results[0]);
        // console.log("================>", gFormattedAddress);
        setFullAddress(gFormattedAddress);
      } else {
        setIsValidZipcode(false);
      }
    } catch (error) {
      console.error("Error verifying zipcode:", error);
    } finally {
      setZipLoading(false);
    }
  };

  const PlacingGPSOrder = async (stripeData) => {
    const body = {
      name: userDetail?.name?.trim(),
      email: userDetail?.email?.trim(),
      phone: userDetail?.phone?.replaceAll("-", "")?.trim(),
      shipping_address: userDetail?.shipping_address?.trim(),
      city: cityState.city?.trim(),
      state: cityState.state?.trim(),
      additional_address: cityState.addressLine?.trim(),
      postal_code: zipCode,
      lat: fullAddress.lat,
      lng: fullAddress.lng,
      amount: totalPrice,
      CardObj: stripeData,
      request_from: "web",
      user_id: AuthUserId || 0,
    };
    handleCartCheckout(body);
    handlePayWithCardClose();
  };

  const resetUserFiiledData = () => {
    setZipCode("");
    setFullAddress({
      ...fullAddressObject,
    });
    setCityState({ ...cityStateObject });
    setUserDetail({ ...userObject });
  };

  useEffect(() => {
    // If the debounced value is empty, reset result
    if (!debouncedZipCode) {
      return;
    }
    // Call the verification function only after the debounced value updates
    verifyZipCode(debouncedZipCode);
  }, [debouncedZipCode]);

  useEffect(() => {
    if (AuthProfileData) {
      setUserDetail((prev) => ({
        ...prev,
        email: AuthProfileData["email"],
        name: AuthProfileData["first_name"],
        phone: phoneFormat(AuthProfileData["phone_no"]),
      }));
    }
  }, [AuthProfileData]);

  useEffect(() => {
    if (successPopup) {
      resetUserFiiledData();
    }
  }, [successPopup]);

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        p: "1rem",
        border: "5px solid #faa61a",
        borderRadius: "10px",
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      <Typography
        sx={{
          color: "#faa61a !important",
          fontFamily: "Montserrat !important",
          fontWeight: "800",
          fontSize: { xs: "33px", md: "40px" },
        }}
      >
        Checkout Form
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
        <FormControl sx={{ width: "100%", boxSizing: "border-box" }}>
          <FormLabel
            sx={{
              fontFamily: "Montserrat !important",
              color: "#2d2d2d",
              fontWeight: "500",
              mb: "2px",
            }}
          >
            Name<sup style={{ color: "#faa61a" }}>*</sup>
          </FormLabel>
          <Box
            component={"input"}
            type="text"
            sx={inputStyles}
            placeholder="John Dutchmaster"
            onChange={(event) => handleChange(event, "name")}
            value={userDetail?.name}
            maxLength={100}
          />
        </FormControl>
        <FormControl sx={{ width: "100%", boxSizing: "border-box" }}>
          <FormLabel
            sx={{
              fontFamily: "Montserrat !important",
              color: "#2d2d2d",
              fontWeight: "500",
              mb: "2px",
            }}
          >
            Email<sup style={{ color: "#faa61a" }}>*</sup>
          </FormLabel>
          <Box
            component={"input"}
            type="text"
            sx={inputStyles}
            placeholder="JRocket77@gmail.com"
            onChange={(event) => handleChange(event, "email")}
            value={userDetail?.email}
            maxLength={100}
          />
        </FormControl>
        <FormControl sx={{ width: "100%", boxSizing: "border-box" }}>
          <FormLabel
            sx={{
              fontFamily: "Montserrat !important",
              color: "#2d2d2d",
              fontWeight: "500",
              mb: "2px",
            }}
          >
            Phone<sup style={{ color: "#faa61a" }}>*</sup>
          </FormLabel>
          <Box
            component={"input"}
            type="text"
            sx={inputStyles}
            placeholder="555-121-1234"
            onChange={(event) => handleChange(event, "phone")}
            value={userDetail?.phone}
            maxLength={12}
          />
        </FormControl>

        <FormControl
          sx={{ width: "100%", boxSizing: "border-box" }}
          onClick={handleAddressModalOpen}
        >
          <FormLabel
            sx={{
              fontFamily: "Montserrat !important",
              color: "#2d2d2d",
              fontWeight: "500",
              mb: "2px",
            }}
          >
            Shipping Address<sup style={{ color: "#faa61a" }}>*</sup>
          </FormLabel>
          <Box
            component={"input"}
            type="text"
            sx={inputStyles}
            placeholder="e.g., 1234 Elm St, Apt 5B, Springfield, IL, 62704"
            value={userDetail?.shipping_address}
            onChange={() => {}}
          />
        </FormControl>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "1rem",
          mt: "1rem",
          mb: "1rem",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Montserrat !important",
            fontWeight: "800",
            color: "#000",
            fontSize: { xs: "18px", sm: "20px", md: "20px" },
          }}
        >
          TOTAL AMOUNT
        </Typography>
        <Typography
          sx={{
            fontWeight: "800",
            fontFamily: "Montserrat !important",
            color: "#0bb622",
            fontSize: { xs: "22px", sm: "24px", md: "24px" },
          }}
        >
          {new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(totalPrice)}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        {/* <Typography
          sx={{
            color: "#2d2d2d",
            fontFamily: "Montserrat !important",
            fontWeight: "500",
            fontSize: { xs: "14px", sm: "16px" },
            textAlign: "center",
          }}
        >
          You’re on your way to getting the most inexpensive solution to
          reliably tell you where your stuff is!
        </Typography> */}
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            sx={{
              background: "#149247",
              "&:hover": {
                background: "#085828",
              },
              color: "#fff",
              fontFamily: "Montserrat !important",
              fontWeight: "700",
              textTransform: "initial",
              fontSize: {
                xs: "18px",
                md: "22px",
              },
              borderRadius: "15px",
              px: "1rem",
              width: "100%",
            }}
            onClick={handlePayWithCardOpen}
          >
            Proceed to checkout
          </Button>
        </Box>
        <Typography
          sx={{
            color: "#2d2d2d",
            fontFamily: "Montserrat !important",
            fontWeight: "500",
            fontSize: { xs: "14px", sm: "16px" },
            textAlign: "center",
          }}
        >
          Payment information on next screen
        </Typography>
      </Box>
      <AddressPopup
        dialogOpen={addressModal}
        handleCloseDialog={handleAddressModalClose}
        zipLoading={zipLoading}
        isValidZipcode={isValidZipcode}
        zipCode={zipCode}
        handleZipChange={handleZipChange}
        fullAddress={fullAddress}
        onConfirmSaveAddress={onConfirmSaveAddress}
        cityState={cityState}
        setCityState={setCityState}
      />
      <Modal open={payWithCard} className="addmoney-modal">
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "#2e2e2e",
            border: "5px solid #faa61a",
            borderRadius: "10px",
            outline: "none !important",
            width: {
              xs: "98%",
              sm: "450px",
              md: "550px",
              lg: "550px",
              xl: "550px",
            },
            boxSizing: "border-box",
          }}
        >
          <IconButton
            onClick={handlePayWithCardClose}
            sx={{
              position: "absolute",
              top: "-20px",
              right: {
                xs: "5px",
                sm: "-20px",
                md: "-20px",
                lg: "-20px",
                xl: "-20px",
              },
              bgcolor: "#faa61a",
              color: "#000",
              "&:hover": {
                bgcolor: "#faa61a",
              },
            }}
          >
            <CloseIcon />
          </IconButton>

          <Box>
            <Box
              sx={{
                width: "100%",
                maxHeight: "700px",
                overflowY: "auto",
                p: 4,
                boxSizing: "border-box",
              }}
            >
              <Typography
                variant="h5"
                style={{ color: "#faa61a", marginBottom: "0.5rem" }}
              >
                Pay With Card
              </Typography>

              <PaymentHold
                amount={totalPrice}
                onSuccess={async (data) => {
                  if (data.status == "success") {
                    PlacingGPSOrder(data);
                  }
                }}
                gpsPayment={true}
              />
            </Box>
            {/* </Box> */}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default CheckoutForm;
