import { ArrowBack, LocalMall } from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Modal,
  Typography,
} from "@mui/material";
import CheckoutForm from "components/store/CheckoutForm";
import StoreCard from "components/store/StoreCard";
import MainLayout from "layouts/MainLayout";
import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CartContext } from "./CartCheckout";
import { IStoreValue, ProductsDataTypes } from "utils/types";
import { useSelector } from "react-redux";

const CartCheckoutChild = () => {
  const navigate = useNavigate();
  const cartProducts = useSelector(
    (state: IStoreValue) => state.equipmentReducer.cartProducts
  );
  const {
    productQtySize,
    handleQtySizeChanges,
    handleRemoveCart,
    loading,
    successPopupMsg,
    successPopup,
  } = useContext(CartContext);
  return (
    <MainLayout>
      {loading && (
        <Box>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Box>
      )}
      <Box>
        <Container sx={{ pl: "unset", pr: "unset" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              cursor: "pointer",
            }}
            onClick={() => navigate("/gemstore")}
          >
            <ArrowBack sx={{ fontSize: "35px", color: "#fff" }} />
            {/* <Typography
              sx={{
                color: "#fff",
                fontFamily: "GothamBold !important",
                fontSize: { xs: "16px", sm: "18px" },
              }}
            >
              Back
            </Typography> */}

            <Typography
              sx={{
                color: "#faa61a",
                fontFamily: "GothamBold !important",
                fontSize: { xs: "20px", sm: "24px", md: "28px" },
                textTransform: "capitalize",
              }}
            >
              Your Cart
            </Typography>
          </Box>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
              gap: "1rem",
              alignItems: "flex-start",
              marginTop: "1rem",
              marginBottom: "2rem",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
              {cartProducts?.length > 0 &&
                cartProducts?.map((product: ProductsDataTypes) => (
                  <StoreCard
                    key={product?.cart_id}
                    storeType={"cart"}
                    product={product}
                    handleCart={handleRemoveCart}
                    productQtySize={productQtySize}
                    handleQtySizeChanges={handleQtySizeChanges}
                  />
                ))}
            </Box>
            <Box
              sx={{
                position: { xs: "unset", md: "sticky" },
                top: { xs: "unset", md: "8rem" },
              }}
            >
              {cartProducts?.length > 0 && <CheckoutForm />}
            </Box>
          </Box>

          {!loading && cartProducts?.length <= 0 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: "1rem",
                py: "4rem",
                px: "1rem",
              }}
            >
              <LocalMall sx={{ fontSize: "80px", color: "#faa61a" }} />
              <Typography
                sx={{
                  color: "#fff",
                  fontFamily: "Montserrat !important",
                  fontSize: { xs: "14px", md: "16px" },
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                Your cart is empty. <br /> Please add items in your cart to
                continue.
              </Typography>
              <Button
                variant="contained"
                sx={{
                  fontFamily: "Montserrat !important",
                  fontSize: "20px",
                  fontWeight: "700",
                  borderRadius: "15px",
                  px: 4,
                  width: {
                    xs: "100%",
                    md: "auto",
                  },
                  color: "#fff",
                  background: "#149247",
                  textTransform: "capitalize",
                  "&:hover": {
                    background: "#085828",
                  },
                }}
                onClick={() => navigate("/gemstore")}
              >
                Go To Store
              </Button>
            </Box>
          )}
        </Container>
        <Modal
          keepMounted
          open={successPopup}
          // onClose={() => setSuccessPopup(false)}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box
            className="download-popups"
            sx={{
              position: "absolute" as "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              boxShadow: 24,
              p: 4,
              padding: "0px",
            }}
          >
            {/* <IconButton
            className="cross-btn"
            onClick={() => setSuccessPopup(false)}
          >
            <CloseIcon />
          </IconButton> */}

            <Box className="login__main">
              <Box
                className="login__main-div edit-eqp login__main-div__form edit-eqp-inner popup-cmmn popup-white"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#faa61a",
                      fontWeight: "100",
                      textAlign: "center",
                    }}
                  >
                    {successPopupMsg || "Your order placed successfully."}
                  </Typography>
                </Box>
                <Button
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: "#2e7d32 !important",
                    color: "#fff !important",
                    padding: "6px 50px 6px 50px",
                    borderRadius: "7px",
                    fontFamily: "GothamBold !important",
                    fontSize: "20px !important",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate("/gemstore");
                  }}
                >
                  OK
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      </Box>
    </MainLayout>
  );
};

export default CartCheckoutChild;
