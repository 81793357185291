import React, { useState, useRef } from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import ForSale from "assets/images/for-sale.png";
import ThumbsImg from "assets/images/likeMe.png";
import ImagesGallery from "react-image-gallery";
import { Box, CircularProgress, Typography } from "@mui/material";
import { EquipmentAPIs } from "service/api-service";
import "./newStyles.scss";

const ImageGallery = ({ imagesGallerys, type = "rent" }) => {
  const isLoggedin = false;
  const [LikeStatus, setLikeStatus] = useState<string | number>(null);
  const [LikeLoading, setLikeLoading] = useState<boolean>(false);
  const LikeDislikeEquip = async (status) => {
    try {
      setLikeLoading(true);

      // Make the API call to AddToLikeDislike
      //   const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      //   const EquipData = await EquipmentAPIs.AddToLikeDislike(
      //     {
      //       user_id: AuthUserID.toString(),
      //       equip_id: params.id.toString(),
      //       status: status.toString(),
      //       liked_equip_list_type: EquipListType,
      //     },
      //     headers
      //   );

      // Check the type of response received
      //   if (EquipData["type"] == "RXSUCCESS") {
      //     // dispatch(setalertMessage('Action completed.'));
      //     // dispatch(setalertMessagetype('success'));
      //     setLikeLoading(false);
      //     // Update the like status state
      //     setLikeStatus(status);
      //   } else if (
      //     EquipData["status"] == false &&
      //     EquipData["slug"] == "Session Expired"
      //   ) {
      //     // Set loading state to false in case of session expiration
      //     // dispatch(setSessionExpire(true));
      //     setLikeLoading(false);
      //     return false;
      //   } else {
      //     // dispatch(setalertMessage(EquipData['message']));
      //     // dispatch(setalertMessagetype('error'));
      //     setLikeLoading(false);
      //     return false;
      //   }
    } catch (err) {
      // Set loading state to false in case of an error
      setLikeLoading(false);
      console.log(err);
    }
  };

  return (
    <Box
    // className="newdetail-img-slider"
    >
      <Box
        // className="equipment-carousel-main-div"
        className="new-img-gallery"
        sx={{
          backgroundColor: "#faa81a00",
          // height: 400px;
          width: "100%",
          position: "relative",
        }}
      >
        {imagesGallerys.length > 0 && (
          // <ImagesGallery
          //   items={imagesGallerys}
          //   infinite={true}
          //   lazyLoad={true}
          //   autoPlay={false}
          //   // className="new-image-gallery"
          //   // className="carousel-item equipment-details-item-img "
          //   indicatorContainerProps={{
          //     style: {
          //       marginTop: "30px", // 5
          //     },
          //   }}
          // />
          <ImagesGallery
            items={imagesGallerys}
            showBullets={imagesGallerys.length > 1}
            infinite={true}
            lazyLoad={true}
            autoPlay={false}
            showThumbnails={type === "sale" ? false : true}
            className="custom-gallery-class"
            thumbnailPosition="bottom"
            indicatorContainerProps={{
              style: {
                background: "#000000bd !important",
                borderRadius: "30px",
                paddingLeft: "7px",
                paddingRight: "3px",
              },
            }}
          />
        )}
        {type === "sale" && (
          <Box
            sx={{
              position: "absolute",
              top: "-10px",
              left: "-10px",
              background: "transparent",
              zIndex: "10",
              padding: "5px 15px",
              color: "#fff",
              fontFamily: "Gotham !important",
            }}
          >
            <Box
              component={"img"}
              src={ForSale}
              alt="For Sale"
              sx={{
                width: "70px",
                height: "auto",
                display: "block",
              }}
            />
          </Box>
        )}

        <Box
          // className="like-dislike-grp"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            position: "absolute",
            top: "10px",
            right: "10px",
          }}
        >
          {isLoggedin && (
            <>
              {LikeLoading ? (
                <CircularProgress />
              ) : (
                <>
                  {LikeStatus == "1" || LikeStatus == 1 ? (
                    <Box
                      //   className="image-like-icon"
                      sx={{
                        width: "30px",
                        height: "30px",
                        // right: 10px;
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        LikeDislikeEquip(0);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <Box
                        component={"img"}
                        src={ThumbsImg}
                        alt=""
                        sx={{ width: "100%", height: "100%", display: "block" }}
                      />
                    </Box>
                  ) : (
                    <Box
                      //   className="image-like-icon"
                      sx={{
                        width: "30px",
                        height: "30px",
                        // right: 10px;
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        LikeDislikeEquip(1);
                      }}
                      style={{
                        filter: "grayscale(100%)",
                        cursor: "pointer",
                      }}
                    >
                      <Box
                        component={"img"}
                        src={ThumbsImg}
                        alt=""
                        sx={{ width: "100%", height: "100%", display: "block" }}
                      />
                    </Box>
                  )}
                </>
              )}
            </>
          )}
        </Box>

        {/* <Box className="eqp-price">
          <Typography>
            <span style={{ color: "#fff !important" }}>
              $
              <CurrentFormat Currency={result["equip_sale_price"]} />
            </span>{" "}
            <span>{result["obo"] == 1 && <>obo</>}</span>
          </Typography>
        </Box> */}
      </Box>
    </Box>
  );
};

export default ImageGallery;
