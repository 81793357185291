import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";

const CartSuccessPopup = ({ open, handlePopupClose }) => {
  const navigate = useNavigate();
  return (
    <Modal
      keepMounted
      open={open}
      onClose={handlePopupClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",

          //  overflowX:'hidden',
          bgcolor: "#2e2e2e",
          border: "5px solid #faa61a",
          borderRadius: "10px",
          outline: "none !important",
          // p: 1,
          width: {
            xs: "90%",
            sm: "400px",
            md: "500px",
            lg: "500px",
            xl: "500px",
          },
        }}
      >
        <IconButton
          onClick={handlePopupClose}
          sx={{
            position: "absolute",
            top: "-20px",
            right: "-20px",
            bgcolor: "#faa61a",
            color: "#000",
            "&:hover": {
              bgcolor: "#faa61a",
            },
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box>
          <Box
            sx={{
              width: "100%",
              maxHeight: "690px",
              overflowY: "auto",
              p: 3,
              boxSizing: "border-box",
            }}
          >
            <Typography
              sx={{
                color: "#faa61a",
                fontFamily: "GothamBold !important",
                fontSize: { xs: "20px", md: "24px" },
              }}
            >
              Added to Cart
            </Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "1rem",
                mt: 2,
              }}
            >
              <Button
                sx={{
                  background: "#149247",
                  "&:hover": {
                    background: "#085828",
                  },
                  color: "#fff",
                  fontFamily: "Montserrat !important",
                  fontWeight: "700",
                  textTransform: "initial",
                  fontSize: {
                    xs: "16px",
                    md: "18px",
                  },
                  borderRadius: "10px",
                  px: "1rem",
                  py: "0.2rem",
                  width: "fit-content",
                }}
                onClick={() => navigate("cart")}
              >
                View Cart
              </Button>
              <Button
                sx={{
                  background: "#149247",
                  "&:hover": {
                    background: "#085828",
                  },
                  color: "#fff",
                  fontFamily: "Montserrat !important",
                  fontWeight: "700",
                  textTransform: "initial",
                  fontSize: {
                    xs: "16px",
                    md: "18px",
                  },
                  borderRadius: "10px",
                  px: "1rem",
                  py: "0.2rem",
                  width: "fit-content",
                }}
                onClick={handlePopupClose}
              >
                Continue Shopping
              </Button>
            </Box>
          </Box>
          {/* </Box> */}
        </Box>
      </Box>
    </Modal>
  );
};

export default CartSuccessPopup;
