import {
  Container,
  Box,
  Typography,
  Backdrop,
  CircularProgress,
  Skeleton,
  Button,
} from "@mui/material";
import StoreCard from "components/store/StoreCard";
import MainLayout from "layouts/MainLayout";
import cartIcon from "assets/images/cart-icon.png";
import React, { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import CartSuccessPopup from "components/store/CartSuccessPopup";
import { StoreContext } from "./GemStore";
import { useSelector } from "react-redux";
import { IStoreValue } from "utils/types";
import SkeletonUi from "./SkeletonUi";
import { LocalMall } from "@mui/icons-material";
import FileViewer from "components/store/FileViewer";
const GemStoreChild = () => {
  const navigate = useNavigate();
  const storeProducts = useSelector(
    (state: IStoreValue) => state.equipmentReducer.storeProducts
  );
  const imagesRef = useRef<string[]>([]);
  const imagesIndexRef = useRef<number>(0);
  const [lightboxOpen, setLightboxOpen] = useState<boolean>(false);
  const handleCloseLightbox = () => setLightboxOpen(false);
  const handleOpenLightbox = () => setLightboxOpen(true);

  const {
    addCartPopup,
    loading,
    cartTotal,
    handleAddCart,
    handleCartPopupOpenClose,
    productQtySize,
    handleQtySizeChanges,
  } = useContext(StoreContext);
  let emptyProduct = true;
  let showCart = true;

  const handleImageClick = (event, images: string[]) => {
    // console.log("images=>>>>>.", images);
    const index = +event.target.getAttribute("data-index");
    if (index) {
      imagesIndexRef.current = index;
    }
    imagesRef.current = images;
    handleOpenLightbox();
  };

  return (
    <MainLayout>
      {loading && (
        <Box>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Box>
        // <Box>
        //   <Box
        //     sx={{
        //       display: "flex",
        //       justifyContent: "space-between",
        //       alignItems: "center",
        //       gap: "1rem",
        //       width: "100%",
        //     }}
        //   >
        //     <Skeleton
        //       animation="wave"
        //       variant="rectangular"
        //       sx={{
        //         bgcolor: "rgba(255,255,255,0.1)",
        //         height: {
        //           xs: "24px",
        //           sm: "28px",
        //           md: "34px",
        //           lg: "40px",
        //           xl: "50px",
        //         },
        //         width: "40%",
        //       }}
        //     />
        //     <Skeleton
        //       animation="wave"
        //       variant="rectangular"
        //       sx={{
        //         bgcolor: "rgba(255,255,255,0.1)",
        //         width: {
        //           xs: "40px",
        //           md: "60px",
        //         },
        //         height: {
        //           xs: "40px",
        //           md: "60px",
        //         },
        //       }}
        //     />
        //   </Box>
        //   <Box
        //     sx={{
        //       display: "grid",
        //       gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
        //       gap: "1rem",
        //       width: "100%",
        //     }}
        //   >
        //     <SkeletonUi />
        //     <SkeletonUi />
        //     <SkeletonUi />
        //     <SkeletonUi />
        //   </Box>
        // </Box>
      )}
      <Container sx={{ pl: "unset", pr: "unset" }}>
        {storeProducts?.map((products, index) => {
          if (products?.data?.length <= 0) {
            showCart = false;
            return null;
          }
          if (!showCart && index > 0) {
            showCart = true;
          } else if (showCart && index > 0) {
            showCart = false;
          }
          emptyProduct = false;
          return (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                marginTop: "2rem",
                marginBottom: "2rem",
              }}
              key={index}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "1rem",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    color: "#faa61a",
                    fontSize: {
                      xs: "24px",
                      sm: "28px",
                      md: "34px",
                      lg: "40px",
                      xl: "50px",
                    },
                    fontFamily: "GothamBold !important",
                  }}
                >
                  {products?.title}
                </Typography>
                {showCart && (
                  <Box
                    sx={{ position: "relative", cursor: "pointer" }}
                    onClick={() => navigate("cart")}
                  >
                    <Box
                      component={"img"}
                      src={cartIcon}
                      alt="cart-icon"
                      sx={{ width: "50px" }}
                    />
                    {cartTotal > 0 && (
                      <Box
                        sx={{
                          position: "absolute",
                          top: "-10px",
                          right: "-10px",
                          background: "#ff0000",
                          borderRadius: "50%",
                          width: "14px",
                          height: "14px",
                          padding: "5px",
                          border: "2px solid #faa61a",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#fff",
                            fontFamily: "Montserrat !important",
                            fontWeight: "600",
                            fontSize: "12px !important",
                          }}
                        >
                          {cartTotal > 99 ? "99+" : cartTotal}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                )}
              </Box>

              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
                  gap: "1rem",
                  width: "100%",
                }}
              >
                {products?.data?.length > 0 &&
                  products?.data?.map((product) => (
                    <Box key={product?.product_id}>
                      <StoreCard
                        storeType="store"
                        product={product}
                        handleCart={handleAddCart}
                        productQtySize={productQtySize}
                        handleQtySizeChanges={handleQtySizeChanges}
                        handleImageClick={handleImageClick}
                      />
                    </Box>
                  ))}
              </Box>
            </Box>
          );
        })}

        {!loading && emptyProduct && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: "1rem",
              py: "4rem",
              px: "1rem",
            }}
          >
            <LocalMall sx={{ fontSize: "80px", color: "#faa61a" }} />
            <Typography
              sx={{
                color: "#fff",
                fontFamily: "Montserrat !important",
                fontSize: { xs: "14px", md: "16px" },
                fontWeight: "500",
                textAlign: "center",
              }}
            >
              No items available at the moment. Check back soon!
            </Typography>
          </Box>
        )}
        {/* <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            marginTop: "2rem",
            marginBottom: "2rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "1rem",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                color: "#faa61a",
                fontSize: {
                  xs: "24px",
                  sm: "28px",
                  md: "34px",
                  lg: "40px",
                  xl: "50px",
                },
                fontFamily: "GothamBold !important",
              }}
            >
              Equipment Owners
            </Typography>
          </Box>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
              gap: "1rem",
              width: "100%",
            }}
          >
            {ownerProducts &&
              ownerProducts?.map((product) => (
                <Box key={product?.product_id}>
                  <StoreCard
                    storeType="store"
                    product={product}
                    handleCart={handleAddCart}
                    productQtySize={productQtySize}
                    handleQtySizeChanges={handleQtySizeChanges}
                  />
                </Box>
              ))}
          </Box>
        </Box> */}
        <CartSuccessPopup
          open={addCartPopup}
          handlePopupClose={handleCartPopupOpenClose}
        />
        <FileViewer
          images={imagesRef.current}
          imagesIndexRef = {imagesIndexRef}
          lightboxOpen={lightboxOpen}
          closeLightbox={handleCloseLightbox}
        />
      </Container>
    </MainLayout>
  );
};

export default GemStoreChild;
