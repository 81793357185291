import {
  Modal,
  Box,
  IconButton,
  Typography,
  Button,
  TextField,
  Slider,
  Checkbox,
  FormControlLabel,
  InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import React, { useState } from "react";
// import FilterCalender from "components/MyofficeTrailer/EquipmentOrders/FilterCalender";
import { setalertMessage, setalertMessagetype } from "redux/redux-slice";
import { useDispatch } from "react-redux";
import WalletFilterCalendar from "./WalletFilterCalender";
import moment from "moment";

const textFieldStyles = {
  backgroundColor: "#fff",
  border: "2px solid #faa61a",
  borderRadius: "10px !important",
  boxSizing: "border-box",
  "& .MuiInputBase-root": {
    fontFamily: "Gotham !important",
    color: "#000 !important",
    boxSizing: "border-box",
    borderRadius: "10px !important",
  },
  "& .MuiInputLabel-root": {
    fontFamily: "Gotham !important",
    color: "#2d2d2d !important",
    boxSizing: "border-box",
    borderRadius: "10px !important",
  },
  "& .MuiInputBase-input": {
    fontFamily: "Gotham !important",
    color: "#000 !important",
    borderRadius: "10px !important",
  },
  "& .MuiInputBase-input::placeholder": {
    fontFamily: "Gotham !important",
    color: "#2d2d2d !important",
    fontSize: "14px",
  },
  "& .MuiInput-underline:after": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiInput-underline:before": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiFilledInput-underline:after": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiFilledInput-underline:before": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": { borderColor: "transparent !important" },
    "&:hover fieldset": { borderColor: "transparent !important" },
    "&.Mui-focused fieldset": { borderColor: "transparent !important" },
  },
};

type Filters = {
  withdrawal: boolean;
  deposits: boolean;
  pending: boolean;
  completed: boolean;
};

type WalletFilterModalProps = {
  isOpen: boolean;
  onClose: () => void;
  handleApplyFilter: (filter: {
    dateRange: [string, string];
    amountRange: [number | string, number | string];
    withdrawal: boolean;
    deposits: boolean;
    pending: boolean;
    completed: boolean;
  }) => void;
  dateRange: [string, string];
  setDateRange: React.Dispatch<React.SetStateAction<[string, string]>>;
  amountRange: [number | string, number | string];
  setAmountRange: React.Dispatch<
    React.SetStateAction<[number | string, number | string]>
  >;
  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
};

const WalletFilterModal: React.FC<WalletFilterModalProps> = ({
  isOpen,
  onClose,
  handleApplyFilter,
  dateRange,
  setDateRange,
  amountRange,
  setAmountRange,
  filters,
  setFilters,
}) => {
  const dispatch = useDispatch();
  // const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);
  // const handleCalendarOpen = () => setIsCalendarOpen(true);
  // const handleCalendarClose = () => setIsCalendarOpen(false);

  const [isStartCalendarOpen, setIsStartCalendarOpen] =
    useState<boolean>(false);
  const handleStartCalendarOpen = () => setIsStartCalendarOpen(true);
  const handleStartCalendarClose = () => setIsStartCalendarOpen(false);

  const [isEndCalendarOpen, setIsEndCalendarOpen] = useState<boolean>(false);
  const handleEndCalendarOpen = () => setIsEndCalendarOpen(true);
  const handleEndCalendarClose = () => setIsEndCalendarOpen(false);
  const [tempDateRange, setTempDateRange] = useState<[string, string]>([
    null,
    null,
  ]);

  const handleAmountChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: string
  ) => {
    let value = event.target.value.trim(); // Trim whitespace
    if (/^0{2,}/.test(value)) {
      value = "0";
    }
    // If the value is an empty string, allow it, else validate the number format
    if (value !== "" && !/^\d*(\.\d{0,2})?$/.test(value)) {
      return; // Prevent invalid input
    }
    if (value?.length > 7) {
      return;
    }
    if (type === "min") {
      setAmountRange([value, amountRange[1]]);
    } else {
      setAmountRange([amountRange[0], value]);
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "80%", sm: "400px", md: "450px" },
          bgcolor: "#2e2e2e",
          border: "5px solid #faa61a",
          borderRadius: "10px",
          p: 3,
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: "-20px",
            right: "-20px",
            bgcolor: "#faa61a",
            color: "#000",
            "&:hover": { bgcolor: "#faa61a" },
          }}
        >
          <CloseIcon />
        </IconButton>

        <Typography
          variant="h5"
          sx={{
            color: "#fff",
            fontFamily: "GothamBold",
            textAlign: "center",
            mb: 2,
          }}
        >
          Filter
        </Typography>

        {/* Date Range */}
        <Box sx={{ mb: 3 }}>
          <Typography
            sx={{
              color: "#faa61a !important",
              fontFamily: "Montserrat !important",
              fontWeight: "700",
              mb: 1,
            }}
          >
            Date Range
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              mb: 3,
              cursor: "pointer",
            }}
          >
            <TextField
              type="text"
              placeholder="Start Date"
              // label="Start Date"
              InputLabelProps={{ shrink: true, style: { color: "#faa61a" } }}
              value={
                dateRange[0] ? moment(dateRange[0])?.format("MM/DD/YYYY") : ""
              }
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarTodayIcon sx={{ color: "#faa61a" }} />
                  </InputAdornment>
                ),
                readOnly: true,
              }}
              sx={{
                ...textFieldStyles,
                cursor: "pointer",
              }}
              onClick={handleStartCalendarOpen}
            />
            <Typography
              sx={{
                color: "#fff",
                fontFamily: "GothamBold !important",
                fontSize: "16px",
              }}
            >
              to
            </Typography>
            <TextField
              type="text"
              placeholder="End Date"
              // label="End Date"
              InputLabelProps={{ shrink: true, style: { color: "#faa61a" } }}
              value={
                dateRange[1] ? moment(dateRange[1])?.format("MM/DD/YYYY") : ""
              }
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarTodayIcon sx={{ color: "#faa61a" }} />
                  </InputAdornment>
                ),
                readOnly: true,
              }}
              sx={{
                ...textFieldStyles,
                cursor: "pointer",
              }}
              onClick={handleEndCalendarOpen}
            />
          </Box>
        </Box>

        {/* Amount Range */}
        <Box sx={{ mb: 3 }}>
          <Typography
            sx={{
              color: "#faa61a !important",
              fontFamily: "Montserrat !important",
              fontWeight: "700",
              mb: 1,
            }}
          >
            Amount Range
          </Typography>

          {/* Min and Max Amount Inputs */}
          <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 2 }}>
            <TextField
              type="text"
              placeholder="Min Amount"
              value={amountRange[0] ?? ""}
              onChange={(event) => handleAmountChange(event, "min")}
              InputProps={{
                // Adds the $ sign before the value
                startAdornment: (
                  <InputAdornment position="start">
                    {amountRange[0] && (
                      <span style={{ color: "#000", marginRight: "-7px" }}>
                        $
                      </span>
                    )}
                  </InputAdornment>
                ),
                style: { color: "#000" }, // Black text
              }}
              InputLabelProps={{ shrink: true, style: { color: "#faa61a" } }}
              sx={{
                ...textFieldStyles,
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                  {
                    display: "none",
                  },
              }}
              fullWidth
            />

            <Typography
              sx={{
                color: "#fff",
                fontFamily: "GothamBold !important",
                fontSize: "16px",
              }}
            >
              &nbsp;&nbsp;&nbsp;
            </Typography>
            <TextField
              type="text"
              placeholder="Max Amount"
              value={amountRange[1] ?? ""}
              onChange={(event) => handleAmountChange(event, "max")}
              InputProps={{
                // Adds the $ sign before the value
                startAdornment: (
                  <InputAdornment position="start">
                    {amountRange[1] && (
                      <span style={{ color: "#000", marginRight: "-7px" }}>
                        $
                      </span>
                    )}
                  </InputAdornment>
                ),
                style: { color: "#000" }, // Black text
              }}
              InputLabelProps={{ shrink: true, style: { color: "#faa61a" } }}
              sx={{
                ...textFieldStyles,
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                  {
                    display: "none",
                  },
              }}
              fullWidth
            />
          </Box>
        </Box>

        {/* Filters */}
        <Box sx={{ mb: 3 }}>
          <Typography
            sx={{
              color: "#faa61a !important",
              fontFamily: "Montserrat !important",
              fontWeight: "700",
              mb: 1,
            }}
          >
            Additional Filters
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              width: "70%",
              mb: 3,
            }}
          >
            {["withdrawal", "pending", "deposits", "completed"].map((item) => (
              <FormControlLabel
                key={item}
                control={
                  <Checkbox
                    checked={filters[item]}
                    onChange={(event) =>
                      setFilters({
                        ...filters,
                        [item]: event.target.checked,
                      })
                    }
                  />
                }
                label={
                  <Typography
                    sx={{ color: "#fff", textTransform: "capitalize" }}
                  >
                    {item}
                  </Typography>
                }
              />
            ))}
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={filters.deposits}
                  onChange={() =>
                    setFilters({ ...filters, deposits: !filters.deposits })
                  }
                />
              }
              label={<Typography sx={{ color: "#fff" }}>Deposits</Typography>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.deposits}
                  onChange={() =>
                    setFilters({ ...filters, deposits: !filters.deposits })
                  }
                />
              }
              label={<Typography sx={{ color: "#fff" }}>Deposits</Typography>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.deposits}
                  onChange={() =>
                    setFilters({ ...filters, deposits: !filters.deposits })
                  }
                />
              }
              label={<Typography sx={{ color: "#fff" }}>Deposits</Typography>}
            /> */}
          </Box>
        </Box>

        {/* Actions */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              color: "#faa61a !important",
              fontFamily: "Montserrat !important",
              fontWeight: "500",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              setDateRange([null, null]);
              setAmountRange([null, null]);
              setFilters({
                withdrawal: false,
                deposits: false,
                pending: false,
                completed: false,
              });
              handleApplyFilter({
                dateRange: ["", ""],
                amountRange: [null, null],
                withdrawal: false,
                deposits: false,
                pending: false,
                completed: false,
              });
            }}
          >
            Reset
          </Typography>
          <Button
            sx={{
              bgcolor: "#149247",
              color: "#fff",
              fontFamily: "Montserrat !important",
              fontWeight: "700",
              fontSize: "clamp(15px, 3vw, 16px)",
              borderRadius: "10px",
              px: 3,
              textTransform: "capitalize",
              "&:hover": {
                bgcolor: "#149247",
              },
            }}
            onClick={() => {
              /***************** */
              // console.log("a", amountRange[1], "---", amountRange[0]);
              if (
                (typeof amountRange[0] === "string" &&
                  parseFloat(amountRange[0]) === 0) ||
                (typeof amountRange[1] === "string" &&
                  parseFloat(amountRange[1]) === 0)
              ) {
                // const fromV = /^0(\.0{1,2})?$/.test(amountRange[1].toString());
                // const toV = /^0(\.0{1,2})?$/.test(amountRange[0].toString());

                dispatch(setalertMessage("Amount shouldn't be empty or zero."));
                dispatch(setalertMessagetype("error"));
                return;
                // if (fromV || toV) {
                // }
              }
              /***************** */
              if (
                amountRange[1] &&
                amountRange[0] &&
                amountRange[0] > amountRange[1]
              ) {
                dispatch(
                  setalertMessage(
                    "Min amount should be less than equal to max amount."
                  )
                );
                dispatch(setalertMessagetype("error"));
                return;
              }

              if (
                dateRange[1] &&
                dateRange[0] &&
                moment(dateRange[0]).isAfter(moment(dateRange[1]))
              ) {
                dispatch(
                  setalertMessage(
                    "Start date should be less than equal to end date."
                  )
                );
                dispatch(setalertMessagetype("error"));
                return;
              }

              const filterObj = {
                dateRange: [dateRange?.[0] ?? "", dateRange?.[1] ?? ""] as [
                  string,
                  string
                ],
                amountRange,
                ...filters,
              };
              const hasSelectedFilter = Object.values(filterObj).some(
                (value) => {
                  if (Array.isArray(value)) {
                    return value[0] && value[1];
                  } else {
                    return value;
                  }
                }
              );
              if (!hasSelectedFilter) {
                dispatch(
                  setalertMessage(
                    "Please Select one of the filter to see result!"
                  )
                );
                dispatch(setalertMessagetype("error"));
                return false;
              }
              handleApplyFilter(filterObj);
            }}
          >
            Submit
          </Button>
        </Box>
        {isStartCalendarOpen && (
          <WalletFilterCalendar
            calendarPopup={isStartCalendarOpen}
            setCalendarPopupClose={handleStartCalendarClose}
            setDateRange={setDateRange}
            dateRange={dateRange}
            fillDate={"start"}
            tempDateRange={tempDateRange}
            setTempDateRange={setTempDateRange}
          />
        )}
        {isEndCalendarOpen && (
          <WalletFilterCalendar
            calendarPopup={isEndCalendarOpen}
            setCalendarPopupClose={handleEndCalendarClose}
            setDateRange={setDateRange}
            dateRange={dateRange}
            fillDate={"end"}
            tempDateRange={tempDateRange}
            setTempDateRange={setTempDateRange}
          />
        )}
      </Box>
    </Modal>
  );
};

export default WalletFilterModal;
