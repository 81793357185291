import { Box, Button, Typography } from "@mui/material";
import dollarIcon from "../../../assets/images/go-dollar.png";
import icon1 from "../../../assets/images/howitworks/icon-1.svg";
import icon2 from "../../../assets/images/howitworks/icon-2.svg";
import icon3 from "../../../assets/images/howitworks/icon-3.svg";
import "./styles.scss";

const BuildCrewTab = () => {
  return (
    <Box>
      <Box>
        <Box>
          <p className="tab-heading">Income Is As Easy As...</p>
          <Box>
            <Box className="tablike-content">
              <Box className="tab-grid">
                <Box>
                  <Box className="tab-grid-heading">
                    <Box>
                      <img src={icon1} alt="" className="tabicon" />
                    </Box>
                    <Typography>Sign Up</Typography>
                  </Box>
                  <Typography className="tab-grid-text">
                    Create a free user account and upgrade to Crew Boss status.
                  </Typography>
                </Box>
                <Box>
                  <Box className="tab-grid-heading">
                    <Box>
                      <img src={icon2} alt="" className="tabicon" />
                    </Box>
                    <Typography>Share</Typography>
                  </Box>
                  <Typography className="tab-grid-text">
                    Let others know about GoEquipMe and gain crew members with
                    your unique referral code.
                  </Typography>
                </Box>
                <Box>
                  <Box className="tab-grid-heading">
                    <Box>
                      <img src={icon3} alt="" className="tabicon" />
                    </Box>
                    <Typography>Build</Typography>
                  </Box>
                  {/* <Typography className="tab-grid-text">
                    Get paid from your crew’s rentals, sales, loans, GPS and
                    more.
                  </Typography> */}
                  <Typography className="tab-grid-text">
                    Get paid from your crew’s rentals, loans, GPS and more.
                  </Typography>
                </Box>
                <Box>
                  <Box className="tab-grid-heading">
                    <Box>
                      <img src={dollarIcon} alt="" className="tabicon" />
                    </Box>
                    <Typography>Get Paid</Typography>
                  </Box>
                  <Typography className="tab-grid-text">
                    Earn alongside GoEquipMe from your crew’s generated income…
                    ongoing!
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BuildCrewTab;
