import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  CircularProgress,
  Pagination,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentPage } from "redux/redux-slice";
import { IEquipment, IStoreValue } from "utils/types";
import InfoCard from "./InfoCard";
import "../styles.scss";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import CardSkeleton from "../CardSkeleton";

interface FetchParams {
  radius?: number;
  maxLat?: number | null;
  minLat?: number | null;
  maxLng?: number | null;
  minLng?: number | null;
  lat?: string | null;
  lng?: string | null;
  page: number;
}

interface IProps {
  handleLikeEquipments: (a: {
    element: HTMLImageElement;
    equipId: number;
    likeType: string;
    listOfClasses: unknown[];
  }) => void;
  memoizedEquipments: Array<IEquipment>;
  loading: boolean;
  fetchData: (params: FetchParams) => void;
  // activeMarker: number[];
  setActiveMarker: (id: number[]) => void;
  likedClicked: React.MutableRefObject<boolean>;
}

interface CardItemData {
  userId: string;
  id: number;
  equipListType: string;
}

const MapListResult = ({
  handleLikeEquipments,
  memoizedEquipments,
  fetchData,
  loading,
  // activeMarker,
  setActiveMarker,
  likedClicked,
}: IProps) => {
  const dispatch = useDispatch();
  const equipments = useSelector(
    (state: IStoreValue) => state.equipmentReducer.equipments
  );
  const isLoadingEquipments = useSelector(
    (state: IStoreValue) => state.equipmentReducer.isLoadingEquipments
  );

  const totalResult = useSelector(
    (state: IStoreValue) => state.equipmentReducer.count
  );
  const boundingBox = useSelector(
    (state: IStoreValue) => state.equipmentReducer.boundingBox
  );
  const mapEquipments = useSelector(
    (state: IStoreValue) => state.equipmentReducer.mapEquipments
  );
  const [activePage, setActivePage] = useState<number>(1);
  const listContainerRef = useRef<HTMLDivElement>(null);
  // const likedClicked = useRef<boolean>(false);

  const handleCardClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>, data: CardItemData) => {
      const targetElement = event.target as HTMLElement;
      if (
        event.target instanceof HTMLImageElement &&
        event.target.tagName === "IMG"
      ) {
        const listOfClasses = Array.from(event.target.classList);
        if (listOfClasses?.includes("likeIcon")) {
          likedClicked.current = true;
          handleLikeEquipments({
            element: event.target,
            equipId: data?.id,
            likeType: data?.equipListType,
            listOfClasses,
          });
        } else {
          window.open(
            `/detail/${data?.userId}/${data?.id}/${data?.equipListType}`,
            "_blank"
          );
        }
      } else if (targetElement.closest(".text-view-item")) {
        window.open(
          `/detail/${data?.userId}/${data?.id}/${data?.equipListType}`,
          "_blank"
        );
      }
    },
    [handleLikeEquipments, memoizedEquipments]
  );

  const handleMouseOut = useCallback(() => setActiveMarker([]), []);
  const handleMouseOver = useCallback((id) => setActiveMarker([id]), []);

  // console.log("memoizedEquipments", memoizedEquipments);

  const maxPage = useMemo(() => {
    const len = memoizedEquipments.length;
    if (len <= 20) return 0;
    return Math.ceil(len / 20);
  }, [totalResult]);

  const handlePageChange = (event, value) => {
    setActivePage(value);
    // fetchData({
    //   ...boundingBox,
    //   page: value - 1,
    // });
  };

  useEffect(() => {
    if (likedClicked.current) {
      setTimeout(() => {
        likedClicked.current = false;
      }, 0);
      return;
    }
    setActivePage(1);
  }, [mapEquipments]);

  useEffect(() => {
    if (likedClicked.current) {
      setTimeout(() => {
        likedClicked.current = false;
      }, 0);
      return;
    }
    if (listContainerRef.current) {
      listContainerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [memoizedEquipments]);

  return (
    <Box
      //   className="equipments-wrapper"
      sx={{
        overflowX: "hidden",
        // maxWidth: "100%",
        // margin: "0 auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // mt: "18px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          overflow: "hidden",
          width: "100%",
        }}
      >
        <Box
          sx={{
            width: "100%",
            boxSizing: "border-box",
            maxHeight: "calc(100vh - 15rem)",
            minHeight: "calc(100vh - 15rem)",
            position: "relative",
            overflowY: "auto",
          }}
        >
          <Box
            ref={listContainerRef}
            sx={{
              // display: "grid",
              //   gridTemplateColumns: `repeat(${columnCount}, 1fr)`,
              // gridTemplateColumns: {
              //   md: "repeat(1, 1fr)",

              //   lg: "repeat(2, 1fr)",
              // },

              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              flexWrap: "wrap",
              gap: 1.5, // Combines columnGap and rowGap
              width: "100%",
              px: 1,
              boxSizing: "border-box",
              paddingTop: "8px",
            }}
          >
            {loading &&
              [1, 2, 3, 4]?.map((item) => (
                <Box
                  sx={{
                    // width: "100%",
                    width: {
                      xs: "100%",
                      sm:'18rem',
                      md: "18.1rem",
                      lg:'18.2rem',
                      xl:'48.7%'
                      // memoizedEquipments?.length === 1 ? "100%" : "18.5rem",
                    },
                    boxSizing: "border-box",
                    display: "block",
                  }}
                  key={item}
                >
                  <CardSkeleton />
                </Box>
              ))}
            {!loading &&
              memoizedEquipments
                ?.slice(20 * (activePage - 1), 20 * activePage)
                ?.map((equip: IEquipment) => (
                  <Box
                    sx={{
                      // width: "100%",
                      width: {
                        xs: "100%",
                        sm:'18rem',
                        md: "18.1rem",
                        lg:'18.2rem',
                        xl:'48.7%'
                        // memoizedEquipments?.length === 1 ? "100%" : "18.5rem",
                      },
                      boxSizing: "border-box",
                      display: "block",
                    }}
                    key={equip.id}
                    // className="equipment-grid-item"
                    onClick={(event) =>
                      handleCardClick(event, {
                        id: equip.id,
                        userId: equip?.user_id,
                        equipListType: equip?.equip_list_type,
                      })
                    }
                    onMouseOver={() => handleMouseOver(equip.id)}
                    onMouseOut={handleMouseOut}
                  >
                    <InfoCard
                      pricePerDay={equip.price_day || 0}
                      pricePerMonth={equip.price_month || 0}
                      pricePerWeek={equip.price_per_week || 0}
                      equipTitle={equip.make || "N/A"}
                      equipModal={equip.model || "N/A"}
                      equipLocation={`${
                        !equip.city && !equip.state
                          ? "N/A"
                          : !equip.city
                          ? "N/A, " + equip.state
                          : equip.city + ", " + equip.state
                      }`}
                      thumbnail={equip.thumbnail}
                      isBorder={false}
                      likeStatus={equip?.like_status}
                      equipListType={"rent"}
                      salePrice={equip?.equip_sale_price || 0}
                      rpoAvailable={equip?.rpo_available}
                      obo={equip?.obo}
                      images={equip?.images?.split(",")}
                      listView={true}
                    />
                  </Box>
                ))}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              p: 1,
            }}
          >
            {memoizedEquipments?.length > 20 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: 0,
                  width: "100%",
                  boxSizing: "border-box",
                }}
              >
                {isLoadingEquipments ? <CircularProgress /> : null}
                <Pagination
                  count={maxPage}
                  page={activePage}
                  color="primary"
                  sx={{
                    // position:'fixed',
                    // bottom:'0',
                    position: "relative",
                    margin: "0 auto",
                    display: "block",
                    boxSizing: "border-box",
                    "& .MuiPaginationItem-root": {
                      width: "40px !important",
                      height: "40px !important",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#2d2d2d", // Default inactive background
                      color: "#fff", // Default inactive text color
                      border: "1px solid gray",
                    },
                    "& .MuiPaginationItem-root.Mui-selected": {
                      backgroundColor: "#faa61a !important", // Active background color
                      color: "#fff !important", // Active text color
                      border: "none",
                    },
                    "& .MuiPaginationItem-root.Mui-selected:hover": {
                      backgroundColor: "#faa61a", // Keep the same on hover
                    },
                  }}
                  onChange={handlePageChange}
                />
              </Box>
            )}
          </Box>
          {!loading && memoizedEquipments?.length <= 0 && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "1rem",
                flexDirection: "column",
                minHeight: "500px",
                py: "4rem",
              }}
            >
              {/* <PrecisionManufacturingIcon
                sx={{
                  color: "#faa61a",
                  fontSize: "90px",
                }}
              /> */}
              <Typography
                variant="h6"
                sx={{
                  color: "#fff",
                  fontSize: "30px",
                  fontFamily: "GothamBold !important",
                  textAlign: "center",
                }}
              >
                No equipment found.
              </Typography>
            </Box>
          )}
        </Box>
        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            p: 1,
          }}
        >
          {totalResult > 20 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: 0,
                width: "100%",
                boxSizing: "border-box",
              }}
            >
              {isLoadingEquipments ? <CircularProgress /> : null}
              <Pagination
                count={maxPage}
                page={activePage}
                color="primary"
                sx={{
                  // position:'fixed',
                  // bottom:'0',
                  position: "relative",
                  margin: "0 auto",
                  display: "block",
                  boxSizing: "border-box",
                  "& .MuiPaginationItem-root": {
                    width: "40px !important",
                    height: "40px !important",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#2d2d2d", // Default inactive background
                    color: "#fff", // Default inactive text color
                    border: "1px solid gray",
                  },
                  "& .MuiPaginationItem-root.Mui-selected": {
                    backgroundColor: "#faa61a !important", // Active background color
                    color: "#fff !important", // Active text color
                    border: "none",
                  },
                  "& .MuiPaginationItem-root.Mui-selected:hover": {
                    backgroundColor: "#faa61a", // Keep the same on hover
                  },
                }}
                onChange={handlePageChange}
              />
            </Box>
          )}
        </Box> */}
      </Box>
    </Box>
  );
};

export default MapListResult;
