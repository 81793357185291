import React, { useEffect, useState, useCallback } from "react";
import "./styles.scss";
import {
  Box,
  Button,
  Container,
  Typography,
  Backdrop,
  CircularProgress,
  useMediaQuery,
  Modal,
  IconButton,
  Link,
} from "@mui/material";
import MainLayout from "../../layouts/MainLayout";
import TrackDevice from "../../assets/images/equip-tracking/tracking-mobile.png";
import Sale from "../../assets/images/equip-tracking/sale.png";
import CheckIcon from "../../assets/images/icons_checkmark.png";
import wiredDeviceImg from "../../assets/images/equip-tracking/wired-device.png";
import wirelessDevice from "../../assets/images/equip-tracking/wireless-device.png";
import LowestTag from "../../assets/images/equip-tracking/lowest-price-tag.png";
import QuoteIcon1 from "../../assets/images/equip-tracking/quote-1.png";
import QuoteIcon2 from "../../assets/images/equip-tracking/quote-2.png";
import GPSHomeImg from "../../assets/images/equip-tracking/tracking_user.jpg";
import JcbGrp from "../../assets/images/equip-tracking/jcb-grp-img.png";
import SquareIcon from "../../assets/images/equip-tracking/SquareIcon.png";
import UsCurrencyFormat from "components/MyofficeTrailer/EquipmentOrders/UsCurrencyFormat";
import { useDispatch, useSelector } from "react-redux";
import {
  setalertMessagetype,
  setalertMessage,
  SetRedirectPage,
  setLoginModalOpen,
  setLogInLoading,
} from "redux/redux-slice";
import { EquipmentAPIs } from "../../service/api-service";
import { IApiResponseRawData, IStoreValue } from "utils/types";
import CloseIcon from "@mui/icons-material/Close";
import fireAnalyticsEvent from "service/firebase";
import useDebounce from "components/user-signup/useDebounce";
import { getFormattedGoogleAdress } from "utils/formattingAddress";
import AddressPopup from "./AddressPopup";
import PaymentHold from "components/AddBalance/PaymentHold";
import { useNavigate } from "react-router-dom";

interface GoogleAddress {
  city: string;
  country: string;
  formatted_address: string;
  lat: number;
  lng: number;
  state: string;
  zipcode: string;
}

interface AddField {
  city: string;
  state: string;
  address: string;
  addressLine: string;
}

const fontStyle = {
  fontFamily: "Gotham !important",
};

const loginstyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  //width: 800,
  //bgcolor: 'rgb(46 46 46)',
  boxShadow: 24,
  p: 4,
};
const APIKEY = process.env.REACT_APP_GEOCODDER_KEY;
const wiredDevicePrice = 299;
const batteryDevicePrice = 249;
const Tracking = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const AuthUserId = useSelector(
    (state: IStoreValue) => state.equipmentReducer.Authuser
  );
  const AuthProfile = useSelector(
    (state: IStoreValue) => state.equipmentReducer.AuthProfileData
  );
  const [loading, setLoading] = useState(false);

  const [Name, setName] = React.useState(null);
  const [Email, setEmail] = React.useState(null);
  const [Phone, setPhone] = React.useState("");
  const [wiredDevice, setWiredDevice] = React.useState(0);
  const [batteryDevice, setBatteryDevice] = React.useState(0);
  // const [AlreadyList, setAlreadyList] = React.useState(false);
  const [SuccessPopup, setSuccessPopup] = React.useState(false);
  const [successPopupMsg, setSuccessPopupMsg] = useState<string>("");
  const [totalAmount, setTotalAmount] = useState<number>(0);

  // const matchesMobileX = useMediaQuery('(max-width:425px)')

  const [zipCode, setZipCode] = useState("");
  const debouncedZipCode = useDebounce(zipCode, 500);
  const [isValidZipcode, setIsValidZipcode] = useState(false);
  const [zipLoading, setZipLoading] = useState(false);
  const [fullAddress, setFullAddress] = useState<GoogleAddress>({
    city: "",
    country: "",
    formatted_address: "",
    lat: 0,
    lng: 0,
    state: "",
    zipcode: "",
  });
  const [cityState, setCityState] = useState<AddField>({
    city: "",
    state: "",
    address: "",
    addressLine: "",
  });
  const [payWithCard, setPayWithCard] = useState<boolean>(false);
  const handlePayWithCardClose = () => setPayWithCard(false);
  const handlePayWithCardOpen = () => setPayWithCard(true);
  const [completeAddress, setCompleteAddress] = useState<string>("");
  const [addressModal, setAddressModal] = useState<boolean>(false);
  const handleAddressModalOpen = () => setAddressModal(true);
  const handleAddressModalClose = () => setAddressModal(false);

  // by GD 29 Jan 2024 for firebase/google analylics
  useEffect(() => {
    window.scroll(0, 0);
    // fireAnalyticsEvent("Toolbox_Equipment_Tracking");
    // fireAnalyticsEvent("GPS_My_Office_trailer_Equipment_Tracking");
    fireAnalyticsEvent("GPS_Toolbox_to_Equipment_Tracking");
  }, []);
  const LoginPopup = () => {
    dispatch(SetRedirectPage("/gemstore"));
    dispatch(setLoginModalOpen(true));
    dispatch(setLogInLoading(false));
  };
  // Handle name request
  const HandleName = (event) => {
    let name = event.target.value;
    var letters = name.replace(/[^A-Za-z ]+/g, "");
    // let isChar = name.split(' ').join().length > 0;
    // console.log('object', name.length);

    if (letters != null) {
      if (letters.length > 0) {
        letters = letters.trimStart();
      }
    }

    setName(letters);
  };

  // Handle email request
  const HandleEmail = (event) => {
    let email = event.target.value;
    setEmail(email);
  };

  // Define phone number format
  const phoneFormat = (phNumber) => {
    const match = phNumber.match(/(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const number = [match[1], "-", match[2], "-", match[3]].join("");
      setPhone(number);
      return;
    }
    setPhone(phNumber);
  };

  // Handle phone number request
  const HandlePhone = (event) => {
    let val = event.target.value;
    val = val.replace(/[^0-9.]/g, "").replace(/(\..*?)\..*/g, "$1");
    if (val.length == 0) {
      setPhone(val);
    } else {
      phoneFormat(val);
    }
  };

  // Handle device request
  const HandleDevice = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    const numbers = parseFloat(event.target.value?.replace(/^0+/, "")) || 0;
    if (type === "wired") {
      setWiredDevice(numbers);
    } else if (type === "battery") {
      setBatteryDevice(numbers);
    }
  };

  // Create GPS Request
  const GpsRequest = async (e) => {
    e.preventDefault();
    try {
      // setLoading(true);
      if (!Name || !Name?.trim()) {
        setName("");
        dispatch(setalertMessage("Please fill your name to continue!"));
        dispatch(setalertMessagetype("error"));
        // setLoading(false);
        return false;
      }

      const email = Email;
      const mailformat =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!email) {
        dispatch(setalertMessage("Please fill email address to continue!!"));
        dispatch(setalertMessagetype("error"));
        // setLoading(false);
        return false;
      }
      if (!email.match(mailformat)) {
        dispatch(setalertMessage("Invalid email address!"));
        dispatch(setalertMessagetype("error"));
        // setLoading(false);
        return false;
      }
      if (!Phone || !Phone.trim()) {
        dispatch(setalertMessage("Please fill your phone number to continue!"));
        dispatch(setalertMessagetype("error"));
        // setLoading(false);
        return false;
      }

      if (Phone.length !== 12) {
        dispatch(
          setalertMessage("Please fill valid phone number to continue!")
        );
        dispatch(setalertMessagetype("error"));
        setLoading(false);
        return false;
      }

      if (!wiredDevice && !batteryDevice) {
        dispatch(setalertMessage("Please add GPS device to proceed!"));
        dispatch(setalertMessagetype("error"));
        // setLoading(false);
        return false;
      }
      if (!completeAddress) {
        dispatch(setalertMessage("Please fill shipping address to continue!"));
        dispatch(setalertMessagetype("error"));
        // setLoading(false);
        return false;
      }
      handlePayWithCardOpen();
      // var device = wiredDevice;
      // if (device == null) {
      //   dispatch(setalertMessage('Please fill how many devices do you want?'));
      //   dispatch(setalertMessagetype('error'));
      //   setLoading(false);
      //   return false;
      // }
      // if (device == 0 ) {
      //   dispatch(setalertMessage('Please fill valid input in device field!'));
      //   dispatch(setalertMessagetype('error'));
      //   setLoading(false);
      //   return false;
      // }

      // var listdevice = AlreadyList ? "yes" : "no";
      // setLoading(false);

      // const headers = { Authorization: `${AuthToken}` ,request_from : "Web" };
      // const RequestData = await EquipmentAPIs.GpsDeviceRequestCreate(
      //   body
      // headers
      // );
      // if (RequestData["type"] == "RXSUCCESS") {
      //   setSuccessPopup(true);
      //   // dispatch(setalertMessage(RequestData['message']));
      //   // dispatch(setalertMessagetype('success'));
      //   setLoading(false);

      //   // Reset values
      //   setName("");
      //   setEmail("");
      //   setPhone("");
      //   setWiredDevice(0);
      //   setBatteryDevice(0);
      //   setAlreadyList(false);

      //   return false;
      // } else {
      //   dispatch(setalertMessage(RequestData["message"]));
      //   dispatch(setalertMessagetype("error"));
      //   setLoading(false);
      //   return false;
      // }
    } catch (err) {
      // setLoading(false);
      console.log(err);
    }
  };

  const PlacingGPSOrder = async (stripeData) => {
    try {
      const body = {
        name: Name,
        email: Email,
        phone: Phone,
        wired_devices: wiredDevice,
        battery_devices: batteryDevice,
        shipping_address: completeAddress?.trim(),
        city: cityState.city?.trim(),
        state: cityState.state?.trim(),
        // country: fullAddress.country,
        additional_address: cityState.addressLine?.trim(),
        postal_code: zipCode,
        lat: fullAddress.lat,
        lng: fullAddress.lng,
        amount: totalAmount,
        CardObj: stripeData,
        request_from: "web",
        user_id: AuthUserId || 0,
      };
      setLoading(true);
      handlePayWithCardClose();
      const RequestData = await EquipmentAPIs.OrderGpsDevices(body);
      // console.log("RequestData==============>", RequestData);
      if (RequestData["type"] == "RXSUCCESS") {
        setSuccessPopup(true);
        // dispatch(setalertMessage(RequestData["message"]));
        // dispatch(setalertMessagetype("success"));
        setSuccessPopupMsg(RequestData["message"]);
        setName("");
        setEmail("");
        setPhone("");
        setWiredDevice(0);
        setBatteryDevice(0);
        setCompleteAddress("");
        setTotalAmount(0);
        setZipCode("");
        setFullAddress({
          city: "",
          country: "",
          formatted_address: "",
          lat: 0,
          lng: 0,
          state: "",
          zipcode: "",
        });
        setCityState({
          city: "",
          state: "",
          address: "",
          addressLine: "",
        });
      } else {
        throw new Error(RequestData["message"]);
      }
    } catch (error) {
      dispatch(setalertMessage(error.message));
      dispatch(setalertMessagetype("error"));
    } finally {
      setLoading(false);
    }
  };

  const verifyZipCode = async (zip) => {
    setZipLoading(true);
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      zip
    )}&key=${APIKEY}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.status === "OK" && data.results.length > 0) {
        setIsValidZipcode(true);
        // console.log("ssssss================>", data.results[0]);
        const gFormattedAddress = getFormattedGoogleAdress(data.results[0]);
        // console.log("================>", gFormattedAddress);
        setFullAddress(gFormattedAddress);
      } else {
        setIsValidZipcode(false);
      }
    } catch (error) {
      console.error("Error verifying zipcode:", error);
    } finally {
      setZipLoading(false);
    }
  };
  const handleZipChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const zipcode = event.target.value?.trim()?.replace(/[^0-9]/g, "");
    setZipCode(zipcode);
  };

  const onConfirmSaveAddress = (address: string) => {
    setCompleteAddress(address);
  };

  useEffect(() => {
    // If the debounced value is empty, reset result
    if (!debouncedZipCode) {
      return;
    }
    // Call the verification function only after the debounced value updates
    verifyZipCode(debouncedZipCode);
  }, [debouncedZipCode]);

  useEffect(() => {
    const total =
      wiredDevice * wiredDevicePrice + batteryDevice * batteryDevicePrice;
    setTotalAmount(total);
  }, [wiredDevice, batteryDevice]);

  useEffect(() => {
    if (AuthProfile && Object.entries(AuthProfile).length) {
      const fullName = `${AuthProfile["first_name"] || ""} ${
        AuthProfile["last_name"] || ""
      }`?.trim();
      const userEmail = `${AuthProfile["email"] || ""}`;
      const phoneNumber = `${AuthProfile["phone_no"] || ""}`;
      setName(fullName);
      setEmail(userEmail);
      phoneFormat(phoneNumber);
    }
  }, [AuthProfile]);

  const [activeTab, setActiveTab] = useState(1);

  return (
    <MainLayout insideMainContainer={false}>
      {loading && (
        <Box>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Box>
      )}

      <Box className="gps-home">
        <Container>
          <Box className="gps-home-main">
            <Box className="gps-home-grid">
              <Box className="gps-home-grid-left">
                <Box className="gps-home-grid-left-inner">
                  <Box className="gps-home-grid-left-head">
                    <Typography
                      className="gps-home-grid-left-heading"
                      sx={{ fontFamily: "Montserrat !important" }}
                    >
                      Track Your Equipment With GoEquipMe
                    </Typography>
                    <Box className="gps-home-grid-left-head-img">
                      <img src={TrackDevice} alt="" className="track-device" />
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width:{
                        xs:'100%',
                        md:'75%'
                      }
                    }}
                  >
                    <Box className="gps-home-grid-2">
                      <Box className="gps-home-grid-2-left">
                        <img src={LowestTag} alt="" className="track-sale" />
                      </Box>
                      <Box className="gps-home-grid-2-right">
                        <ul className="grid-home-grid-2-right-ul">
                          <li style={fontStyle}>
                            <img src={CheckIcon} alt="" />
                            Protect Your Investment
                          </li>
                          <li style={fontStyle}>
                            <img src={CheckIcon} alt="" />
                            Low-Cost Asset Tracking
                          </li>
                          <li style={fontStyle}>
                            <img src={CheckIcon} alt="" />
                            Simple Installation
                          </li>
                          <li style={fontStyle}>
                            <img src={CheckIcon} alt="" />
                            No Setup Fees or Contracts
                          </li>
                        </ul>
                      </Box>
                    </Box>
                  </Box>

                  <Box
                    className="tracking-tab"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Box className="tab-btn-grp">
                      <Button
                        onClick={() => {
                          setActiveTab(1);
                        }}
                        className={`tab-button-1 ${
                          activeTab === 1 && "active"
                        }`}
                      >
                        <span>Battery</span>
                      </Button>
                      <Button
                        onClick={() => {
                          setActiveTab(2);
                        }}
                        className={`tab-button-1 ${
                          activeTab === 2 && "active"
                        }`}
                      >
                        <span>Wired</span>
                      </Button>
                    </Box>
                    <Box
                      className="tracking-tab-content"
                      sx={{ width: { xs: "100%", md: "60%" } }}
                    >
                      {activeTab === 1 && (
                        <Box className="tab-cmn tab-1">
                          <Box>
                            <Box className="tab-grid-1">
                              <Box className="tab-grid-1-in">
                                <Box className="tab-grid-1-img">
                                  <img src={wirelessDevice} alt="" />
                                </Box>
                                <Box>
                                  <Typography
                                    variant="h3"
                                    sx={{
                                      color: "#fff",
                                      fontFamily: "GothamBold !important",
                                    }}
                                  >
                                    JUST $249*
                                  </Typography>
                                  <ul className="grid-home-grid-2-right-ul">
                                    <li style={fontStyle}>
                                      <div className="yellow-sqr"></div>
                                      No Contract, You Own It
                                    </li>
                                    <li style={fontStyle}>
                                      <div className="yellow-sqr"></div>
                                      3-Year Service & Warranty
                                    </li>
                                    <li style={fontStyle}>
                                      <div className="yellow-sqr"></div>
                                      Small & Easy to Hide
                                    </li>
                                    <li style={fontStyle}>
                                      <div className="yellow-sqr"></div>
                                      Up to 7-Year Battery Life
                                    </li>
                                    <li style={fontStyle}>
                                      <div className="yellow-sqr"></div>
                                      Stay-Put Magnetic Base
                                    </li>
                                    <li style={fontStyle}>
                                      <div className="yellow-sqr"></div>
                                      Waterproof (IP67)
                                    </li>
                                  </ul>
                                </Box>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  padding: "0",
                                  width: "100%",
                                  boxSizing: "border-box",
                                }}
                              >
                                <Typography
                                  className=""
                                  sx={{
                                    color: "#faa61a !important",
                                    fontFamily: "Montserrat !important",
                                    fontWeight: "400",
                                    fontSize: "14px !important",
                                  }}
                                >
                                  * That averages out to only $6.92 per month
                                  over 3 years!
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      )}

                      {activeTab === 2 && (
                        <Box className="tab-cmn tab-2">
                          <Box>
                            <Box className="tab-grid-1">
                              <Box className="tab-grid-1-in">
                                <Box className="tab-grid-1-img">
                                  <img src={wiredDeviceImg} alt="" />
                                </Box>
                                <Box>
                                  <Typography
                                    variant="h3"
                                    sx={{
                                      color: "#fff",
                                      fontFamily: "GothamBold !important",
                                    }}
                                  >
                                    JUST $299*
                                  </Typography>
                                  <ul className="grid-home-grid-2-right-ul">
                                    <li style={fontStyle}>
                                      <div className="yellow-sqr"></div>
                                      No Contract, You Own It
                                    </li>
                                    <li style={fontStyle}>
                                      <div className="yellow-sqr"></div>
                                      3-Year Service & Warranty
                                    </li>
                                    <li style={fontStyle}>
                                      <div className="yellow-sqr"></div>
                                      Real time tracking
                                    </li>
                                    <li style={fontStyle}>
                                      <div className="yellow-sqr"></div>
                                      Small & Easy to Install
                                    </li>
                                    <li style={fontStyle}>
                                      <div className="yellow-sqr"></div>
                                      Waterproof (IP67)
                                    </li>
                                  </ul>
                                </Box>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  padding: "0",
                                  width: "100%",
                                  boxSizing: "border-box",
                                }}
                              >
                                <Typography
                                  className=""
                                  sx={{
                                    color: "#faa61a !important",
                                    fontFamily: "Montserrat !important",
                                    fontWeight: "400",
                                    fontSize: "14px !important",
                                  }}
                                >
                                  * That averages out to only $8.31 per month
                                  over 3 years!
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>

                  <Box sx={{ mt: "1rem" }}>
                    <Typography className="gps-home-cmmn-pb">
                      Want to track a diferent piece of equipment? Simply
                      reassign the GPS with the touch of a button in Your Rental
                      Yard.
                    </Typography>
                  </Box>

                  <Box className="gps-home-img-div">
                    <img src={JcbGrp} alt="" />
                  </Box>

                  <Box className="gps-home-quote">
                    <Box className="gps-home-quote-inner">
                      <img
                        className="gps-quote-icon-1"
                        src={QuoteIcon1}
                        alt=""
                      />
                      <Typography className="gps-home-quote-p">
                        Using GoEquipMe GPS is a game changer for my rentals.
                        Not only can I keep track of where my equipment is, but
                        I can switch out GPS units easily when I need. And it’s
                        cheap, too!
                      </Typography>
                      <Typography className="gps-home-quote-ps">
                        – Pete Williams, Washington, UT
                      </Typography>
                      <img
                        className="gps-quote-icon-2"
                        src={QuoteIcon2}
                        alt=""
                      />
                    </Box>
                  </Box>

                  <Box className="gps-home-gen-spec">
                    <Typography className="gps-home-cmmn-heading">
                      General Specifications
                    </Typography>
                    <Box className="gps-home-gen-spec-item">
                      <Typography className="gps-home-gen-spec-item-h">
                        Dimensions
                      </Typography>
                      <Typography className="gps-home-gen-spec-item-p">
                        Wireless: 3.5 in × 2.0 in × 1.25 in
                      </Typography>
                      <Typography className="gps-home-gen-spec-item-p">
                        Wired: 5.30 in × 2.66 in × 0.58 in
                      </Typography>
                    </Box>

                    <Box className="gps-home-gen-spec-item">
                      <Typography className="gps-home-gen-spec-item-h">
                        Weight
                      </Typography>
                      <Typography className="gps-home-gen-spec-item-p">
                        Wireless: 5.75 oz (w/ magnetic case)
                      </Typography>
                      <Typography className="gps-home-gen-spec-item-p">
                        Wired: 4.6 oz
                      </Typography>
                    </Box>

                    <Box className="gps-home-gen-spec-item">
                      <Typography className="gps-home-gen-spec-item-h">
                        Backup Battery
                      </Typography>
                      <Typography className="gps-home-gen-spec-item-p">
                        Wireless: CR123A lithium batteries – 1400 mAh
                      </Typography>
                      <Typography className="gps-home-gen-spec-item-p">
                        Wired: High temperature NiMH, 200 mAh
                      </Typography>
                    </Box>

                    <Box className="gps-home-gen-spec-item">
                      <Typography className="gps-home-gen-spec-item-h">
                        Standby Time (Wireless)*
                      </Typography>
                      <Typography className="gps-home-gen-spec-item-p">
                        One report daily: 5-7 years
                      </Typography>
                      <Typography className="gps-home-spec-item-ps">
                        * Standby times are estimated under normal conditions.
                      </Typography>
                    </Box>

                    <Box className="gps-home-gen-spec-item">
                      <Typography className="gps-home-gen-spec-item-h">
                        Waterproof (IP67)
                      </Typography>
                      <Typography className="gps-home-gen-spec-item-p">
                        Wireless: Yes
                      </Typography>
                      <Typography className="gps-home-gen-spec-item-p">
                        Wired: Yes
                      </Typography>
                    </Box>

                    <Box className="gps-home-gen-spec-item">
                      <Typography className="gps-home-gen-spec-item-h">
                        Operating Temperature
                      </Typography>
                      <Typography className="gps-home-gen-spec-item-p">
                        Wireless: -4°F ~ +140°F
                      </Typography>
                      <Typography className="gps-home-gen-spec-item-p">
                        Wired: -22°F ~ +158°F
                      </Typography>
                    </Box>

                    <Box className="gps-home-gen-spec-item">
                      <Typography className="gps-home-gen-spec-item-h">
                        Internal Temperature Sensor
                      </Typography>
                      <Typography className="gps-home-gen-spec-item-p">
                        For temperature monitoring.
                      </Typography>
                      <Typography className="gps-home-gen-spec-item-p">
                        Accuracy: ±1°C
                      </Typography>
                    </Box>

                    <Box className="gps-home-gen-spec-item">
                      <Typography className="gps-home-gen-spec-item-h">
                        Internal Light Sensor
                      </Typography>
                      <Typography className="gps-home-gen-spec-item-p">
                        For tamper detection and ambient light monitoring.
                      </Typography>
                    </Box>

                    <Box className="gps-home-gen-spec-item">
                      <Typography className="gps-home-gen-spec-item-h">
                        Internal Motor
                      </Typography>
                      <Typography className="gps-home-gen-spec-item-p">
                        For vibration feedback after successful operation of
                        function button.
                      </Typography>
                    </Box>

                    <Box className="gps-home-gen-spec-item">
                      <Typography className="gps-home-gen-spec-item-h">
                        Magnetic Mounting Case (Optional)
                      </Typography>
                      <Typography className="gps-home-gen-spec-item-p">
                        Available on wireless device for easy installation on
                        metal surface.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              {/* <Box className="gps-home-grid-right">
                <Box className="gps-home-grid-right-form">
                  <Typography className="gps-home-grid-right-heading">

                    Order Your GPS
                  </Typography>
                  <Box className="gps-home-input">
                    <label htmlFor="">
                      Name<sup className="mandatory-star">*</sup>
                    </label>
                    <input
                      className="gps-home-input-field form-control contact-inputs"
                      type="text"
                      placeholder="John Dutchmaster"
                      onChange={HandleName}
                      value={Name}
                    />
                  </Box>
                  <Box className="gps-home-input">
                    <label htmlFor="">
                      Email<sup className="mandatory-star">*</sup>
                    </label>
                    <input
                      className="gps-home-input-field form-control contact-inputs"
                      type="email"
                      placeholder="JRocket77@gmail.com"
                      onChange={HandleEmail}
                      value={Email}
                    />
                  </Box>
                  <Box className="gps-home-input">
                    <label htmlFor="">
                      Phone<sup className="mandatory-star">*</sup>
                    </label>
                    <input
                      className="gps-home-input-field form-control contact-inputs"
                      type="text"
                      placeholder="555-121-1234"
                      onChange={HandlePhone}
                      value={Phone}
                      maxLength={12}
                    />
                  </Box>
                  <Box className="gps-home-input-2 devices-input-grp">
                    <input
                      className="gps-home-input-field form-control contact-inputs"
                      type="number"
                      placeholder=""
                      dir="rtl"
                      min={0}
                      onChange={(event) => HandleDevice(event, "wired")}
                      value={wiredDevice?.toString()}
                    />
                    <label htmlFor="" style={{ marginLeft: "1rem" }}>
                      How Many Wired Devices?
                    </label>
                  </Box>
                  <Box className="gps-home-input-2 devices-input-grp">
                    <input
                      className="gps-home-input-field form-control contact-inputs"
                      type="number"
                      placeholder=""
                      dir="rtl"
                      min={0}
                      onChange={(event) => HandleDevice(event, "battery")}
                      value={batteryDevice?.toString()}
                    />
                    <label htmlFor="" style={{ marginLeft: "1rem" }}>
                      How Many Battery Devices?
                    </label>
                  </Box>
                  <Box className="gps-home-input">
                    <label htmlFor="">
                      Shipping Address<sup className="mandatory-star">*</sup>
                    </label>
                    <input
                      className="gps-home-input-field form-control contact-inputs"
                      type="text"
                      placeholder="e.g., 1234 Elm St, Apt 5B, Springfield, IL, 62704"
                      onClick={handleAddressModalOpen}
                      readOnly
                      value={completeAddress}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "1rem",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Montserrat !important",
                        fontWeight: "800",
                        color: "#000",
                        fontSize: { xs: "18px", sm: "20px", md: "20px" },
                      }}
                    >
                      TOTAL AMOUNT
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "800",
                        fontFamily: "Montserrat !important",
                        color: "#0bb622",
                        fontSize: { xs: "22px", sm: "24px", md: "24px" },
                      }}
                    >
                      {totalAmount <= 0 ? (
                        "$0"
                      ) : (
                        <UsCurrencyFormat
                          amount={totalAmount}
                          decimal={false}
                        />
                      )}
                    </Typography>
                  </Box>

                  <Typography className="gps-home-grid-right-btm-p">
                    You’re on your way to getting the most inexpensive solution
                    to reliably tell you where your stuff is!
                  </Typography>
                  <Box className="get-gps-btn-div">
                    <Button className="get-gps-btn" onClickCapture={GpsRequest}>
                      Proceed to checkout
                    </Button>
                  </Box>
                  <Typography
                    sx={{ mt: 1, textAlign: "center", color: "#000" }}
                  >
                    Payment information on next screen
                  </Typography>
                </Box>
              </Box> */}
            </Box>
          </Box>
        </Container>
      </Box>

      <Box sx={{ padding: "1rem" }}>
        <Box className="get-started">
          {/* <Box className="close-get-started">
            <Typography className="close-text">X Close</Typography>
          </Box> */}
          <Box className="get-started-content">
            {/* <Typography>List YOUR Services on GoEquipMe for Free!</Typography> */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "0.5rem",
              }}
            >
              <Link
                className="get-started-btn"
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  if (AuthUserId) {
                    navigate("/gemstore");
                  } else {
                    LoginPopup();
                  }
                }}
              >
                Order GPS
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* <Modal open={payWithCard} className="addmoney-modal">
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "#2e2e2e",
            border: "5px solid #faa61a",
            borderRadius: "10px",
            outline: "none !important",
            width: {
              xs: "98%",
              sm: "450px",
              md: "550px",
              lg: "550px",
              xl: "550px",
            },
            boxSizing: "border-box",
          }}
        >
          <IconButton
            onClick={handlePayWithCardClose}
            sx={{
              position: "absolute",
              top: "-20px",
              right: {
                xs: "5px",
                sm: "-20px",
                md: "-20px",
                lg: "-20px",
                xl: "-20px",
              },
              bgcolor: "#faa61a",
              color: "#000",
              "&:hover": {
                bgcolor: "#faa61a",
              },
            }}
          >
            <CloseIcon />
          </IconButton>

          <Box>
            <Box
              sx={{
                width: "100%",
                maxHeight: "700px",
                overflowY: "auto",
                p: 4,
                boxSizing: "border-box",
              }}
            >
              <Typography
                variant="h5"
                style={{ color: "#faa61a", marginBottom: "0.5rem" }}
              >
                Pay With Card
              </Typography>

              <PaymentHold
                amount={totalAmount}
                onSuccess={async (data) => {
                  if (data.status == "success") {
                    PlacingGPSOrder(data);
                  }
                }}
                gpsPayment={true}
              />
            </Box>
          </Box>
        </Box>
      </Modal> */}

      {/* Submit request success popup */}
      {/* <AddressPopup
        dialogOpen={addressModal}
        handleCloseDialog={handleAddressModalClose}
        zipLoading={zipLoading}
        isValidZipcode={isValidZipcode}
        zipCode={zipCode}
        handleZipChange={handleZipChange}
        fullAddress={fullAddress}
        onConfirmSaveAddress={onConfirmSaveAddress}
        cityState={cityState}
        setCityState={setCityState}
      /> */}
      {/* <Modal
        keepMounted
        open={SuccessPopup}
        // onClose={() => setSuccessPopup(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popups"
          sx={{
            ...loginstyle,
            padding: "0px",
          }}
        >

          <Box className="login__main">
            <Box
              className="login__main-div edit-eqp login__main-div__form edit-eqp-inner popup-cmmn popup-white"
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <Box>
                <Typography
                  variant="h6"
                  sx={{
                    color: "#faa61a",
                    fontWeight: "100",
                    textAlign: "center",
                  }}
                >
                  {successPopupMsg ||
                    "Thank you, your GPS order was placed successfully."}
                </Typography>
              </Box>
              <Button
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "#2e7d32 !important",
                  color: "#fff !important",
                  padding: "6px 50px 6px 50px",
                  borderRadius: "7px",
                  fontFamily: "GothamBold !important",
                  fontSize: "20px !important",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate("/");
                }}
              >
                OK
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal> */}
    </MainLayout>
  );
};

export default Tracking;
