import {
  Modal,
  Box,
  IconButton,
  TextField,
  styled,
  Button,
  Typography,
  Rating,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import React, { useState } from "react";
import ReviewContent from "components/common/ReviewContent";

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: "10px",
    "& fieldset": {
      border: "2px solid #faa61a",
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      borderColor: "#faa61a",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#faa61a",
    },
  },
  "& .MuiInputBase-input": {
    color: "black",
    backgroundColor: "#fff",
    fontFamily: "Montserrat !important",
    fontSize: "14px",
    fontWeight: "500",
  },
});

// interface ReviewFormType {
//   q1_rating: number;
//   q2_rating: number;
//   q3_rating: number;
//   review: string;
// }

// const reviewElementDefault = {
//   q1_rating: null,
//   q2_rating: null,
//   q3_rating: null,
//   review: "",
// };

// const q1RatingLabel = ["", "Terrible", "Bad", "Okay", "Good", "Excellent"];
// const q2RatingLabel = ["", "Poor", "Fair", "Average", "Great", "Superb"];
// const q3RatingLabel = [
//   "",
//   "Never",
//   "Unlikely",
//   "Maybe",
//   "Likely",
//   "Definitely",
// ];

const ReviewPopup = ({
  isReviewModalOpen,
  handleReviewModalClose,
  formElement,
  handleReviewSubmission,
  handleFormInputChange,
  reviewLoader,
  characterLeft,
}) => {
  // const [ratingHoverElement, setRatingHoverElement] = useState<ReviewFormType>({
  //   ...reviewElementDefault,
  // });
  // const handleRatingHover = (
  //   event: React.SyntheticEvent<Element, Event>,
  //   newHover: number,
  //   name: string
  // ) => {
  //   if (!newHover) return; // Avoid updating state when there's no hover value

  //   setRatingHoverElement((prev) => ({
  //     ...prev,
  //     [name]: newHover, // Store the hovered value
  //   }));
  // };

  return (
    <Modal
      open={isReviewModalOpen}
      onClose={handleReviewModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: {
            xs: "90%",
            sm: "500px",
            md: "500px",
            lg: "500px",
            xl: "500px",
          },

          bgcolor: "#2e2e2e",
          border: "5px solid #faa61a",
          borderRadius: "10px",
          outline: "none !important",
          // p: 3,
        }}
      >
        <IconButton
          onClick={handleReviewModalClose}
          sx={{
            position: "absolute",
            top: "-20px",
            right: "-20px",
            bgcolor: "#faa61a",
            color: "#000",
            "&:hover": {
              bgcolor: "#faa61a",
            },
          }}
        >
          <CloseIcon />
        </IconButton>
        {reviewLoader && (
          <Box>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={reviewLoader}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Box>
        )}
        <ReviewContent
          formElement={formElement}
          handleFormInputChange={handleFormInputChange}
          handleReviewSubmission={handleReviewSubmission}
          characterLeft = {characterLeft}
        />
        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
            boxSizing: "border-box",
          }}
        >
          <Box
            sx={{
              width: "100%",
              boxSizing: "border-box",
              p: { xs: 2, md: 4 },
              maxHeight: "calc(100vh - 3rem)",
              overflowY: "auto",
            }}
            component={"form"}
            onSubmit={handleReviewSubmission}
          >
            <Box sx={{ width: "100%", boxSizing: "border-box", mb: 2 }}>
              <Typography
                sx={{
                  color: "#faa61a",
                  textAlign: "left",
                  fontFamily: "GothamBold !important",
                  fontSize: { xs: "1.2rem", md: "1.5rem" },
                }}
              >
                Write a Review
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                mb: 3,
              }}
            >
              <Box>
                <Typography component="legend" sx={{ color: "#fff" }}>
                  How would you rate your overall experience with this owner?
                </Typography>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "1rem" }}
                >
                  <Rating
                    name="q1_rating"
                    value={formElement?.q1_rating}
                    max={5}
                    emptyIcon={
                      <StarBorderIcon
                        sx={{ color: "gray" }}
                        fontSize="inherit"
                      />
                    }
                    onChange={handleFormInputChange}
                    onChangeActive={(event, newHover) =>
                      handleRatingHover(event, newHover, "q1_rating")
                    }
                  />
                  {(formElement?.q1_rating > 0 ||
                    ratingHoverElement?.q1_rating > 0) && (
                    <Typography
                      component="legend"
                      sx={{ color: "#faa61a", fontSize: "12px" }}
                    >
                      {
                        q1RatingLabel[
                          ratingHoverElement?.q1_rating > 0
                            ? ratingHoverElement?.q1_rating
                            : formElement?.q1_rating > 0
                            ? formElement?.q1_rating
                            : 0
                        ]
                      }
                    </Typography>
                  )}
                </Box>
              </Box>

              <Box>
                <Typography component="legend" sx={{ color: "#fff" }}>
                  How would you rate this owner's communication?{" "}
                </Typography>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "1rem" }}
                >
                  <Rating
                    name="q2_rating"
                    value={formElement?.q2_rating}
                    max={5}
                    emptyIcon={
                      <StarBorderIcon
                        sx={{ color: "gray" }}
                        fontSize="inherit"
                      />
                    }
                    onChange={handleFormInputChange}
                    onChangeActive={(event, newHover) =>
                      handleRatingHover(event, newHover, "q2_rating")
                    }
                  />
                  {(formElement?.q2_rating > 0 ||
                    ratingHoverElement?.q2_rating > 0) && (
                    <Typography
                      component="legend"
                      sx={{ color: "#faa61a", fontSize: "12px" }}
                    >
                      {
                        q2RatingLabel[
                          ratingHoverElement?.q2_rating > 0
                            ? ratingHoverElement?.q2_rating
                            : formElement?.q2_rating > 0
                            ? formElement?.q2_rating
                            : 0
                        ]
                      }
                    </Typography>
                  )}
                </Box>
              </Box>

              <Box>
                <Typography component="legend" sx={{ color: "#fff" }}>
                  How likely are you to rent from this owner again?{" "}
                </Typography>

                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "1rem" }}
                >
                  <Rating
                    name="q3_rating"
                    value={formElement?.q3_rating}
                    max={5}
                    emptyIcon={
                      <StarBorderIcon
                        sx={{ color: "gray" }}
                        fontSize="inherit"
                      />
                    }
                    onChange={handleFormInputChange}
                    onChangeActive={(event, newHover) =>
                      handleRatingHover(event, newHover, "q3_rating")
                    }
                  />
                  {(formElement?.q3_rating > 0 ||
                    ratingHoverElement?.q3_rating > 0) && (
                    <Typography
                      component="legend"
                      sx={{ color: "#faa61a", fontSize: "12px" }}
                    >
                      {
                        q3RatingLabel[
                          ratingHoverElement?.q3_rating > 0
                            ? ratingHoverElement?.q3_rating
                            : formElement?.q3_rating > 0
                            ? formElement?.q3_rating
                            : 0
                        ]
                      }
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>

            <Box
              sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
            >
              <Typography
                sx={{
                  color: "#fff",
                  fontFamily: "Arial !important",
                  fontSize: "16px",
                }}
              >
                Please share your thoughts on this Owner with other renters.
              </Typography>

              <CustomTextField
                multiline
                rows={4} // Number of rows for the multiline textarea
                variant="outlined"
                fullWidth
                InputProps={{
                  style: {
                    backgroundColor: "#fff", // Ensures background is white
                  },
                }}
                name="review"
                value={formElement?.review}
                onChange={handleFormInputChange}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mt: 2,
              }}
            >
              <Button
                sx={{
                  // border:AuthUserId === element?.owner_id? "3px solid #008001":"none",
                  background: "#008001",
                  color: "#fff",
                  textTransform: "capitalize",
                  fontFamily: "Montserrat !important",
                  borderRadius: "8px",
                  fontWeight: "800",
                  padding: "5px 15px",
                  fontSize: {
                    xs: "12px",
                    sm: "13px",
                    md: "14px",
                    lg: "16px",
                    xl: "18px",
                  },
                  width: {
                    xs: "100%",
                    md: "70%",
                  },
                  "&:hover": {
                    background: "#006001",
                  },
                }}
                type="submit"
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Box> */}
      </Box>
    </Modal>
  );
};

export default ReviewPopup;
