import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Link,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { Link as RLink, useNavigate } from "react-router-dom";
import MainLayout from "../../layouts/MainLayout";

// redux
import { useDispatch } from "react-redux";
import { setEquipSelectType } from "redux/redux-slice";

// components
import Testimonials from "components/howItWorks/Testimonials/Testimonials";
import BuildCrewEarn from "components/howItWorks/BuildCrewEarn/BuildCrewEarn";
import SiteMap from "components/howItWorks/SiteMap/SiteMap";

// images
import dollarIcon from "../../assets/images/go-dollar.png";
import findIcon from "../../assets/images/howitworks/search.svg";
import settingIcon from "../../assets/images/howitworks/settings.svg";
import shareIcon from "../../assets/images/howitworks/share.svg";
import editIcon from "../../assets/images/howitworks/edit.svg";
import reviewIcon from "../../assets/images/howitworks/review.svg";
import pointerIcon from "../../assets/images/howitworks/pointer.svg";
import buildIcon from "../../assets/images/howitworks/menatwork.svg";
import checkIcon from "../../assets/images/howitworks/check.svg";
import dLogo from "../../assets/images/howitworks/gem-logo.png";
import mLogo from "../../assets/images/howitworks/mgem-logo.png";
import findYourNext from "../../assets/images/howitworks/find-your-next.png";
import findYourRental from "../../assets/images/howitworks/find-your-rental.png";
import listEqpSold from "../../assets/images/howitworks/list-your-eqp-sold.png";
import listEqpRent from "../../assets/images/howitworks/list-your-eqp-rent.png";

// styles
import "./styles.scss";
import Howitworks_slider from "components/howItWorks/Testimonials/Howitworks_slider";

const HowItWorks = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(1);
  const [aboutText, setAboutText] = useState("earning together.");
  // const handleChange = (event, newValue) => {
  //     setValue(newValue);
  // };
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <MainLayout>
      <Box className="hw">
        <Box className="hw-in">
          <Box>
            <Typography
              variant="h1"
              sx={{
                color: "#fff",
                fontFamily: "GothamBold !important",
                fontSize: "clamp(18.5px ,3vw, 45px)",
                margin: {
                  xs: "0 0 1rem 0",
                  sm: "0 0 1rem 0",
                  md: "0 0 2rem 0",
                  lg: " 0 0 2rem 0 ",
                  xl: "0 0 2rem 0",
                },
              }}
            >
              How Will GoEquipMe Work for YOU?
            </Typography>
          </Box>

          <Box className="hw-tab">
            <Box className="tab-btn-grp">
              <Button
                onClick={() => {
                  setActiveTab(1);
                  setAboutText("earning together.");
                }}
                className={`tab-button ${activeTab === 1 && "active"}`}
              >
                <span>Find</span>
                <span className="tab-span-y">equipment</span>
              </Button>
              <Button
                onClick={() => {
                  setActiveTab(2);
                  setAboutText("building community.");
                }}
                className={`tab-button ${activeTab === 2 && "active"}`}
              >
                <span>List</span>
                <span className="tab-span-y tab-span-2">equipment</span>
              </Button>
              <Button
                onClick={() => {
                  setActiveTab(3);
                  setAboutText("getting stuff done.");
                }}
                className={`tab-button ${activeTab === 3 && "active"}`}
              >
                <span>Build</span>
                <span className="tab-span-y tab-span-3">a crew</span>
              </Button>
            </Box>
            <Box className="hw-tab-content">
              {activeTab === 1 && (
                <Box className="tab-cmn tab-1">
                  <Box className="tab-grid">
                    <Box>
                      <Box className="tab-grid-heading">
                        <img src={findIcon} alt="" className="tabicon1" />
                        <Typography>Find</Typography>
                      </Box>
                      <Typography className="tab-grid-text">
                        Search for privately- owned equipment in your area.
                      </Typography>
                    </Box>
                    <Box>
                      <Box className="tab-grid-heading">
                        <img src={pointerIcon} alt="" className="tabicon2" />
                        <Typography>Select</Typography>
                      </Box>
                      <Typography className="tab-grid-text">
                        Select the equipment you need.
                      </Typography>
                    </Box>
                    <Box>
                      <Box className="tab-grid-heading">
                        <img src={editIcon} alt="" className="tabicon3" />
                        <Typography>Reserve</Typography>
                      </Box>
                      {/* <Typography className="tab-grid-text">
                        Secure your rental or purchase.
                      </Typography> */}
                      <Typography className="tab-grid-text">
                        Secure your rental.
                      </Typography>
                    </Box>
                    <Box>
                      <Box className="tab-grid-heading">
                        <img src={settingIcon} alt="" className="tabicon4" />
                        <Typography>Get to Work</Typography>
                      </Box>
                      <Typography className="tab-grid-text">
                        Build your project.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}

              {activeTab === 2 && (
                <Box className="tab-cmn tab-2">
                  <Box className="tab-grid">
                    <Box>
                      <Box className="tab-grid-heading">
                        <img src={editIcon} alt="" className="tabicon1" />
                        <Typography>List</Typography>
                      </Box>
                      <Typography className="tab-grid-text">
                        Set your prices and select listing options.
                      </Typography>
                    </Box>
                    <Box>
                      <Box className="tab-grid-heading">
                        <img src={reviewIcon} alt="" className="tabicon2" />
                        <Typography>Review</Typography>
                      </Box>
                      <Typography className="tab-grid-text">
                        Approve or deny inquiries.
                      </Typography>
                    </Box>
                    <Box>
                      <Box className="tab-grid-heading">
                        <img src={checkIcon} alt="" className="tabicon3" />
                        <Typography>Finalize</Typography>
                      </Box>
                      <Typography className="tab-grid-text">
                        Manage and complete transactions from your Owners.
                      </Typography>
                    </Box>
                    <Box>
                      <Box className="tab-grid-heading">
                        <img src={dollarIcon} alt="" className="tabicon4" />
                        <Typography>Collect</Typography>
                      </Box>
                      <Typography className="tab-grid-text">
                        Watch your wallet balance grow.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}

              {activeTab === 3 && (
                <Box className="tab-cmn tab-3">
                  <Box className="tab-grid">
                    <Box>
                      <Box className="tab-grid-heading">
                        <img src={editIcon} alt="" className="tabicon1" />
                        <Typography>Sign Up</Typography>
                      </Box>
                      <Typography className="tab-grid-text">
                        Create a free user account and upgrade to Crew Boss
                        status.
                      </Typography>
                    </Box>
                    <Box>
                      <Box className="tab-grid-heading">
                        <img src={shareIcon} alt="" className="tabicon2" />
                        <Typography>Share</Typography>
                      </Box>
                      <Typography className="tab-grid-text">
                        Let others know about GoEquipMe and gain crew members
                        with your unique referral code.
                      </Typography>
                    </Box>
                    <Box>
                      <Box className="tab-grid-heading">
                        <img src={buildIcon} alt="" className="tabicon3" />
                        <Typography>Build</Typography>
                      </Box>
                      {/* <Typography className="tab-grid-text">
                        Get paid from your crew’s rentals, sales, loans, GPS and
                        more.
                      </Typography> */}
                      <Typography className="tab-grid-text">
                        Get paid from your crew’s rentals, loans, GPS and more.
                      </Typography>
                    </Box>
                    <Box>
                      <Box className="tab-grid-heading">
                        <img src={dollarIcon} alt="" className="tabicon4" />
                        <Typography>Get Paid</Typography>
                      </Box>
                      <Typography className="tab-grid-text">
                        Earn alongside GoEquipMe from your crew’s generated
                        income… ongoing!
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>

          {/* Cards */}
          <Box className="hw-2-card">
            <Box className="hw-card">
              <Box className="hw-card-contentL">
                <Box>
                  <Typography className="hw-card-heading">
                    FIND <span>YOUR</span> RENTAL <span>JUST</span> <br />
                    <span>AROUND THE CORNER</span>
                  </Typography>
                </Box>
                <Box>
                  <Typography className="hw-card-text">
                    Enjoy savings and the benefits of an equipment sharing
                    marketplace.
                  </Typography>
                </Box>
                <Box>
                  <Link
                    className="hw-card-link"
                    onClick={() => {
                      dispatch(setEquipSelectType("rent"));
                      navigate("/howItWorksFind");
                    }}
                  >
                    Tell Me More...
                  </Link>

                  {/* <RLink className='hw-card-link' to="/howItWorksFindRent">
                                        Tell Me More...
                                    </RLink> */}
                </Box>
              </Box>
              <Box className="hw-card-img">
                <img src={findYourRental} alt="" />
              </Box>
            </Box>

            {/* <hr className="hw-card-divider" /> */}

            {/* <Box className="hw-card">
              <Box className="hw-card-img">
                <img src={findYourNext} alt="" />
              </Box>

              <Box className="hw-card-contentR">
                <Box>
                  <Typography className="hw-card-heading">
                    FIND <span>YOUR NEXT</span>
                    <br />
                    <span>EQUIPMENT</span> PURCHASE
                  </Typography>
                </Box>
                <Box>
                  <Typography className="hw-card-text">
                    Know what you are buying with Rent Purchase Options (RPO).
                    Utilize GoEquipMe’s in-house financing to get your purchase
                    done.
                  </Typography>
                </Box>
                <Box>
                  <Link
                    className="hw-card-link"
                    onClick={() => {
                      // dispatch(setEquipSelectType("sale"));
                      dispatch(setEquipSelectType("rent"));
                      navigate("/howItWorksFind");
                    }}
                  >
                    Tell Me More...
                  </Link>
                </Box>
              </Box>
            </Box> */}
          </Box>

          {/* Testimonials */}
          <Testimonials />

          {/* Cards */}
          <Box className="hw-2-card">
            <Box className="hw-card">
              <Box className="hw-card-contentL">
                <Box>
                  <Typography className="hw-card-heading">
                    LIST <span>YOUR EQUIPMENT</span>
                    <br />
                    <span>FOR</span> RENT
                  </Typography>
                </Box>
                <Box>
                  <Typography className="hw-card-text">
                    Build a rental yard or simply offset your existing equipment
                    cost by listing it on GoEquipMe.
                  </Typography>
                </Box>
                <Box>
                  {/* <Link className='hw-card-link'>
                                        Tell Me More...
                                    </Link> */}

                  <RLink className="hw-card-link" to="/howItWorksListRent">
                    Tell Me More...
                  </RLink>
                </Box>
              </Box>
              <Box className="hw-card-img">
                <img src={listEqpRent} alt="" />
              </Box>
            </Box>

            {/* <hr className="hw-card-divider" /> */}

            {/* <Box className="hw-card">
              <Box className="hw-card-img">
                <img src={listEqpSold} alt="" />
              </Box>

              <Box className="hw-card-contentR">
                <Box>
                  <Typography className="hw-card-heading">
                    LIST <span>YOUR EQUIPMENT</span>
                    <br />
                    <span>AND GET IT</span> SOLD
                  </Typography>
                </Box>
                <Box>
                  <Typography className="hw-card-text">
                    Put your listing in front of thousands of GoEquipMe users.
                    Utilize GoEquipMe’s built-in rental purchase options (RPO)
                    and in-house financing to get your sale done.
                  </Typography>
                </Box>
                <Box>
                  <RLink className="hw-card-link" to="/howItWorksListSale">
                    Tell Me More...
                  </RLink>
                </Box>
              </Box>
            </Box> */}
          </Box>

          {/*  */}
          <Box className="about">
            <Box className="about-in">
              <img className="d-about-logo" src={dLogo} alt="" />
              <img className="m-about-logo" src={mLogo} alt="" />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "0",
                alignItems: "center",
                width: "100%",
                boxSizing: "border-box",
              }}
            >
              <Typography
                sx={{
                  fontSize: "clamp(20px, 3vw, 34px)",
                  color: "#faa61a",
                  fontFamily: "GothamBold !important",
                }}
              >
                GoEquipMe is about
              </Typography>
              <Box>
                <Howitworks_slider />
              </Box>

              {/* <Typography sx={{ fontSize: 'clamp(20px, 3vw, 34px)', color: '#fff', fontFamily: 'GothamBold !important' }}>
                                {aboutText}
                            </Typography> */}
            </Box>
          </Box>

          {/* Build Crew and Earn */}
          <BuildCrewEarn />

          {/* SiteMap */}
          {/* <SiteMap /> */}
        </Box>
      </Box>
    </MainLayout>
  );
};

export default HowItWorks;
