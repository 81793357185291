import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { Dialog, DialogContent, IconButton, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState } from "react";
import ImagesGallery from "react-image-gallery";
import Carousel from "react-material-ui-carousel";
import placeholderImage from "assets/images/placeholder.png";

const FileViewer = ({
  closeLightbox,
  imagesIndexRef,
  lightboxOpen,
  images = [],
}) => {
  // Ensure images are properly formatted for react-image-gallery
  // const formattedImages = images.map((url) => ({
  //   original: url,
  //   thumbnail: url, // Use the same image if no thumbnail is available
  // }));

  return (
    <Dialog
      open={lightboxOpen}
      onClose={closeLightbox}
      maxWidth="xl"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0,0,0,0.5)",
      }}
      PaperProps={{
        sx: {
          backgroundColor: "transparent !important",
          borderRadius: "8px",
          overflow: "visible",
        },
      }}
    >
      <DialogContent
        sx={{
          padding: 0,
          backgroundColor: "#2d2d2d",
          border: "4px solid #faa61a",
          borderRadius: "8px",
          position: "relative",
          textAlign: "center",
          width: {
            xs: "300px",
            sm: "350px",
            md: "500px",
            lg: "700px",
          },
          maxWidth: "1000px",
          overflow: "visible",
        }}
      >
        <IconButton
          sx={{
            position: "absolute",
            top: "-20px",
            right: "-20px",
            zIndex: 2,
            bgcolor: "#faa61a",
            color: "#000",
            "&:hover": {
              bgcolor: "#faa61a",
            },
          }}
          onClick={closeLightbox}
        >
          <CloseIcon />
        </IconButton>

        <Box
          sx={{
            width: "100%",
            height: {
              xs: "350px",
              sm: "500px",
              md: "500px",
              lg: "600px",
              xl: "700px",
            },
            margin: "auto",
            display: "block",
          }}
        >
          {/* {formattedImages.length > 0 ? (
            <ImagesGallery
              items={formattedImages}
              showBullets={formattedImages.length > 1}
              infinite={true}
              lazyLoad={true}
              autoPlay={false}
              showThumbnails={formattedImages.length > 1}
              thumbnailPosition="bottom"
              indicatorContainerProps={{
                style: {
                  background: "#000000bd !important",
                  borderRadius: "30px",
                  paddingLeft: "7px",
                  paddingRight: "3px",
                },
              }}
            />
          ) : (
            <p style={{ color: "#fff" }}>No images available</p>
          )} */}
          <Carousel
            index={imagesIndexRef.current}
            animation="slide"
            swipe={false}
            navButtonsAlwaysVisible={true}
            navButtonsAlwaysInvisible={false}
            cycleNavigation={images?.length > 1}
            autoPlay={false}
            indicators={images?.length > 1}
            sx={{
              height: {
                xs: "350px",
                sm: "500px",
                md: "500px",
                lg: "600px",
                xl: "700px",
              },
              "div:has(> .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium)":
                {
                  // backgroundColor: "transparent !important",
                  height: "40px",
                  top: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                },
              "& .MuiButtonBase-root": {
                backgroundColor: "rgb(0 0 0 / 50%)",
                width: "28px",
                height: "28px",
                opacity: "1 !important",
                "&:hover": {
                  // backgroundColor: "#000 !important",
                  backgroundColor: "rgb(0 0 0 / 100%) !important",
                  opacity: "1 !important",
                },
              },
              buttonWrapper: {
                position: "absolute",
                height: "30px",
                backgroundColor: "transparent",
                top: "calc(50% - 70px)",
              },
              button: {
                position: "relative",
                backgroundColor: "#494949",
                color: "white",
                fontSize: "10px",
                cursor: "pointer",
                width: "28px",
                height: "28px",
                "&:hover": {
                  backgroundColor: "#000",
                  opacity: "1 !important",
                },
              },
              next: {
                right: 0,
              },
              prev: {
                left: 0,
              },
            }}
            indicatorIconButtonProps={{
              style: {
                backgroundColor: "#ffffff", // Default (non-active) color
                color: "transparent",
                height: "7px",
                width: "7px",
                marginRight: "5px",
                boxShadow: "0px 0px 5px 0px rgb(0 0 0 / 50%)",
                border: "1px solid #faa61a",
              },
            }}
            activeIndicatorIconButtonProps={{
              style: {
                backgroundColor: "#faa61a", // Active indicator color
                color: "transparent",
              },
            }}
            indicatorContainerProps={{
              style: {
                // marginTop: "82px",
                textAlign: "center",
                zIndex: 899,
                position: "absolute",
                background: "rgb(0 0 0 / 70%)",
                borderRadius: "30px",
                paddingLeft: "7px",
                paddingRight: "3px",
                // padding: "0",
                height: "20px",
                width: "auto",
                left: "50%",
                top: "90%",
                transform: "translate(-50%, -50%)",
              },
            }}
          >
            {images.map((img, idx) => (
              // <Box
              //   key={idx}
              //   component={"img"}
              //   src={img}
              //   alt="Equipment Images"
              //   onLoad={() => setIsLoaded(true)}
              //   sx={{
              //     width: "100%",
              //     height: listView ? "240px" : "170px",
              //     objectFit: "cover",
              //     borderTopLeftRadius: "10px",
              //     borderTopRightRadius: "10px",
              //   }}
              // />
              <LazyImage
                key={idx}
                src={img}
                placeholder={placeholderImage}
                listView={true}
              />
            ))}
          </Carousel>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const LazyImage = ({ src, placeholder, listView }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: {
          xs: "350px",
          sm: "500px",
          md: "500px",
          lg: "600px",
          xl: "700px",
        },
      }}
    >
      {/* Placeholder Image */}
      <Box
        component="img"
        src={placeholder}
        alt="Placeholder Image"
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "contain",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          transition: "opacity 0.3s ease-in-out",
          opacity: isLoaded ? 0 : 1, // Hide when actual image loads
        }}
      />

      {/* Actual Image */}
      <Box
        component="img"
        src={src}
        alt="Equipment Image"
        onLoad={() => setIsLoaded(true)}
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "contain",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          transition: "opacity 0.3s ease-in-out",
          opacity: isLoaded ? 1 : 0, // Show when loaded
        }}
      />
    </Box>
  );
};

export default FileViewer;
