import { Box, Skeleton, Typography } from "@mui/material";
import React from "react";

const CardSkeleton = () => {
  return (
    <Box
      sx={{
        padding: "0px",
        borderRadius: "15px",
        mb: 0,
        mt: 0,
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "nowrap",
          borderRadius: "15px",
          backgroundColor: "#3A3A3B",
          padding: "0px",
          overflow: "hidden",
          boxSizing: "border-box",
          maxWidth: "100%",
          cursor: "pointer",
          width: "100%",
        }}
      >
        {/* Image Section */}
        <Skeleton
          animation="wave"
          variant="rectangular"
          sx={{
            bgcolor: "rgba(255,255,255,0.1)",
            height: "240px",
            width: "100%",
            borderRadius: 1,
            mb: 1,
          }}
        />

        {/* Text Section */}
        <Box
          sx={{
            flex: 1,
            padding: "10px 20px 10px 15px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            boxSizing: "border-box",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "1rem",
            }}
          >
            <Skeleton
              animation="wave"
              variant="rectangular"
              sx={{
                bgcolor: "rgba(255,255,255,0.1)",
                height: "20px",
                width: "100%",
                borderRadius: 1,
                mb: 1,
              }}
            />
          </Box>
          <Skeleton
            animation="wave"
            variant="rectangular"
            sx={{
              bgcolor: "rgba(255,255,255,0.1)",
              height: "13px",
              width: "90%",
              borderRadius: 1,
              mb: 1,
            }}
          />
          <Skeleton
            animation="wave"
            variant="rectangular"
            sx={{
              bgcolor: "rgba(255,255,255,0.1)",
              height: "13px",
              width: "80%",
              borderRadius: 1,
            }}
          />

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              padding: "0.5rem 0",
              width: "100%",
            }}
          >
            <Skeleton
              animation="wave"
              variant="rectangular"
              sx={{
                bgcolor: "rgba(255,255,255,0.1)",
                height: "20px",
                width: "25%",
                borderRadius: 1,
              }}
            />
            <Skeleton
              animation="wave"
              variant="rectangular"
              sx={{
                bgcolor: "rgba(255,255,255,0.1)",
                height: "20px",
                width: "25%",
                borderRadius: 1,
              }}
            />
            <Skeleton
              animation="wave"
              variant="rectangular"
              sx={{
                bgcolor: "rgba(255,255,255,0.1)",
                height: "20px",
                width: "25%",
                borderRadius: 1,
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CardSkeleton;
