import React from "react";
import { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Box, Rating, Typography, useMediaQuery } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import dummyImg from "../../../assets/images/jcb.jpg";
import "./styles.scss";

import daleImg from "../../../assets/images/howitworks/dale.png";
import caseyImg from "../../../assets/images/howitworks/casey.png";
import paytonImg from "../../../assets/images/howitworks/payton.png";
import bobbyImg from "../../../assets/images/howitworks/bobby.png";
import charlesImg from "../../../assets/images/howitworks/charles.png";
import stuartImg from "../../../assets/images/howitworks/stuart.png";
import dougImg from "../../../assets/images/howitworks/doug.png";
import fredImg from "../../../assets/images/howitworks/fred.png";
import michaelImg from "../../../assets/images/howitworks/michael.png";
import monteImg from "../../../assets/images/howitworks/monte.png";
import tylerImg from "../../../assets/images/howitworks/tyler.png";
import bradImg from "../../../assets/images/howitworks/brad.png";
import { useSelector } from "react-redux";
import { IStoreValue } from "utils/types";

// Import custom arrow icons for navigation
// import PrevArrowIcon from './prev_arrow.svg';
// import NextArrowIcon from './next_arrow.svg';

const Testimonials = () => {
  const value = 12;
  const matchesMobileX = useMediaQuery("(max-width:992px)");
  const ToggleSwipper = useSelector(
    (state: IStoreValue) => state.equipmentReducer.toggleFooterSwipper
  );
  // console.log("matchesMobileX: ", matchesMobileX);
  if (ToggleSwipper) {
    return (
      <Box className="testimonial-sec">
        {/* Desktop Swiper */}
        <Swiper
          navigation={{
            prevEl: ".swiper-button-prev",
            nextEl: ".swiper-button-next",
          }}
          slidesPerView={1}
          spaceBetween={10}
          loop={true}
          autoplay={{ delay: 5000, disableOnInteraction: false }}
          modules={[Pagination, Navigation, Autoplay]}
          className="mySwiper-desktop"
        >
          <SwiperSlide>
            <Box className="cfeedback">
              <Box className="cfeedback-img">
                <img src={caseyImg} alt="" />
              </Box>
              <Box className="cfeedback-content">
                <Typography className="cfeedback-text">
                  As we need equipment, it seems like we can just find it on the
                  app. It's been really beneficial for our business.
                </Typography>
                <Box className="cfeedback-name">
                  <Box className="cfeedback-name-comp">
                    – Casey D. / Renter, Owner
                  </Box>
                  <Box className="stars-rating">
                    <Rating
                      name="text-feedback"
                      value={value}
                      readOnly
                      precision={0.5}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55, color: "#faa61a" }}
                          fontSize="inherit"
                        />
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </SwiperSlide>

          <SwiperSlide>
            <Box className="cfeedback">
              <Box className="cfeedback-img">
                <img src={daleImg} alt="" />
              </Box>
              <Box className="cfeedback-content">
                <Typography className="cfeedback-text">
                  I think I’ve used GoEquipMe for every aspect of my business. I
                  started out as a renter, then purchased equipment financed by
                  GoEquipMe, and now list equipment to rent as well. Great
                  equipment sharing concept that has both saved me money, and
                  paid big dividends. Thank you GoEquipMe!
                </Typography>
                <Box className="cfeedback-name">
                  <Box className="cfeedback-name-comp">
                    – Dale B. / Owner, Sales, Renter, Borrower
                  </Box>
                  <Box className="stars-rating">
                    <Rating
                      name="text-feedback"
                      value={value}
                      readOnly
                      precision={0.5}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55, color: "#faa61a" }}
                          fontSize="inherit"
                        />
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </SwiperSlide>

          <SwiperSlide>
            <Box className="cfeedback">
              <Box className="cfeedback-img">
                <img src={bradImg} alt="" />
              </Box>
              <Box className="cfeedback-content">
                <Typography className="cfeedback-text">
                  I've used about everything on the app, I've rented equipment,
                  listed equipment for rent and I've gotten a loan to buy
                  equipment. Every transaction was easy and I've saved money or
                  made money. This app has really helped my company grow. I
                  couldn't do it without them!
                </Typography>
                <Box className="cfeedback-name">
                  <Box className="cfeedback-name-comp">
                    – Brad P. / Owner, Sales, Renter, Borrower
                  </Box>
                  <Box className="stars-rating">
                    <Rating
                      name="text-feedback"
                      value={value}
                      readOnly
                      precision={0.5}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55, color: "#faa61a" }}
                          fontSize="inherit"
                        />
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </SwiperSlide>

          <SwiperSlide>
            <Box className="cfeedback">
              <Box className="cfeedback-img">
                <img src={michaelImg} alt="" />
              </Box>
              <Box className="cfeedback-content">
                <Typography className="cfeedback-text">
                  I've rented several times through the app. It's helped me save
                  money on projects and I've enjoyed working with the equipment
                  owners. I've also rented my equipment out to both contractors
                  and for the guy down the road. It's worked out great!
                </Typography>
                <Box className="cfeedback-name">
                  <Box className="cfeedback-name-comp">
                    – Michael B. / Owner, Renter
                  </Box>
                  <Box className="stars-rating">
                    <Rating
                      name="text-feedback"
                      value={value}
                      readOnly
                      precision={0.5}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55, color: "#faa61a" }}
                          fontSize="inherit"
                        />
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </SwiperSlide>

          <SwiperSlide>
            <Box className="cfeedback">
              <Box className="cfeedback-img">
                <img src={fredImg} alt="" />
              </Box>
              <Box className="cfeedback-content">
                <Typography className="cfeedback-text">
                  Using GoEquipMe has been great to keep my trailer and
                  telehandler busy when I’m not using it. It’s been really
                  helpful to have the extra income!
                </Typography>
                <Box className="cfeedback-name">
                  <Box className="cfeedback-name-comp">– Fred B. / Owner</Box>
                  <Box className="stars-rating">
                    <Rating
                      name="text-feedback"
                      value={value}
                      readOnly
                      precision={0.5}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55, color: "#faa61a" }}
                          fontSize="inherit"
                        />
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </SwiperSlide>

          <SwiperSlide>
            <Box className="cfeedback">
              <Box className="cfeedback-img">
                <img src={paytonImg} alt="" />
              </Box>
              <Box className="cfeedback-content">
                <Typography className="cfeedback-text">
                  I used GoEquipMe to rent a large excavator to prep a
                  greenhouse pad. I was able to rent it from a contractor that
                  was working just down the road. Saved me a ton of money! I’ll
                  be using them again. And will be listing some equipment as
                  well!
                </Typography>
                <Box className="cfeedback-name">
                  <Box className="cfeedback-name-comp">
                    – Payton F. / Renter
                  </Box>
                  <Box className="stars-rating">
                    <Rating
                      name="text-feedback"
                      value={value}
                      readOnly
                      precision={0.5}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55, color: "#faa61a" }}
                          fontSize="inherit"
                        />
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </SwiperSlide>

          <SwiperSlide>
            <Box className="cfeedback">
              <Box className="cfeedback-img">
                <img src={monteImg} alt="" />
              </Box>
              <Box className="cfeedback-content">
                <Typography className="cfeedback-text">
                  I've rented through GoEquipMe several times now--and I've
                  listed my own equipment on GoEquipMe. The extra money I've
                  made off my equipment, when I'm not using it, has really
                  helped my bottom line. I've even gotten paid for transporting
                  equipment. Easy platform to use, love it!
                </Typography>
                <Box className="cfeedback-name">
                  <Box className="cfeedback-name-comp">– Monte C. / Owner</Box>
                  <Box className="stars-rating">
                    <Rating
                      name="text-feedback"
                      value={value}
                      readOnly
                      precision={0.5}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55, color: "#faa61a" }}
                          fontSize="inherit"
                        />
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </SwiperSlide>

          <SwiperSlide>
            <Box className="cfeedback">
              <Box className="cfeedback-img">
                <img src={stuartImg} alt="" />
              </Box>
              <Box className="cfeedback-content">
                <Typography className="cfeedback-text">
                  GoEquipMe helped us purchase equipment we needed for a job.
                  Without that equipment we couldn't have made it happen. I
                  recommend GoEquipMe to everyone I meet, they understand the
                  construction world; we will be using them lots more.
                </Typography>
                <Box className="cfeedback-name">
                  <Box className="cfeedback-name-comp">
                    – Stuart M. / Sales, Borrower
                  </Box>
                  <Box className="stars-rating">
                    <Rating
                      name="text-feedback"
                      value={value}
                      readOnly
                      precision={0.5}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55, color: "#faa61a" }}
                          fontSize="inherit"
                        />
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </SwiperSlide>

          <SwiperSlide>
            <Box className="cfeedback">
              <Box className="cfeedback-img">
                <img src={dougImg} alt="" />
              </Box>
              <Box className="cfeedback-content">
                <Typography className="cfeedback-text">
                  We've listed alot of equipment on GoEquipMe and made six
                  figures just from the rentals. It is easy to manage all the
                  transactions with their calendar, notifications, deposits
                  etc...and their customer service is off the charts, they’ve
                  gone above and beyond to help us secure the rentals!
                </Typography>
                <Box className="cfeedback-name">
                  <Box className="cfeedback-name-comp">– Doug D. / Owner</Box>
                  <Box className="stars-rating">
                    <Rating
                      name="text-feedback"
                      value={value}
                      readOnly
                      precision={0.5}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55, color: "#faa61a" }}
                          fontSize="inherit"
                        />
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </SwiperSlide>

          <SwiperSlide>
            <Box className="cfeedback">
              <Box className="cfeedback-img">
                <img src={tylerImg} alt="" />
              </Box>
              <Box className="cfeedback-content">
                <Typography className="cfeedback-text">
                  Having completed a few rentals I’ve now listed several more
                  items. I’m excited to watch GoEquipMe grow and my Wallet!
                </Typography>
                <Box className="cfeedback-name">
                  <Box className="cfeedback-name-comp">– Tyler S. / Owner</Box>
                  <Box className="stars-rating">
                    <Rating
                      name="text-feedback"
                      value={value}
                      readOnly
                      precision={0.5}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55, color: "#faa61a" }}
                          fontSize="inherit"
                        />
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </SwiperSlide>

          <SwiperSlide>
            <Box className="cfeedback">
              <Box className="cfeedback-img">
                <img src={bobbyImg} alt="" />
              </Box>
              <Box className="cfeedback-content">
                <Typography className="cfeedback-text">
                  As an equipment owner, buyer, renter, and borrower on
                  GoEquipMe I highly recommend using them for whatever you need.
                  Finally, a company with great customer support and that really
                  cares about its users.
                </Typography>
                <Box className="cfeedback-name">
                  <Box className="cfeedback-name-comp">
                    – Bobby P. / Owner, sales, renter, Borrower
                  </Box>
                  <Box className="stars-rating">
                    <Rating
                      name="text-feedback"
                      value={value}
                      readOnly
                      precision={0.5}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55, color: "#faa61a" }}
                          fontSize="inherit"
                        />
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </SwiperSlide>

          <SwiperSlide>
            <Box className="cfeedback">
              <Box className="cfeedback-img">
                <img src={charlesImg} alt="" />
              </Box>
              <Box className="cfeedback-content">
                <Typography className="cfeedback-text">
                  Everyone likes to make a little more cash… and everyone likes
                  to have equipment on hand when they need it. The ROI makes a
                  lot more sense when you can rent out equipment when you’re not
                  using it.
                </Typography>
                <Box className="cfeedback-name">
                  <Box className="cfeedback-name-comp">
                    – Charles H. / Equipment
                  </Box>
                  <Box className="stars-rating">
                    <Rating
                      name="text-feedback"
                      value={value}
                      readOnly
                      precision={0.5}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55, color: "#faa61a" }}
                          fontSize="inherit"
                        />
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </SwiperSlide>
        </Swiper>

        {/* Custom navigation buttons */}
        <div className="custom-navigation">
          <button className="swiper-button-prev">{"<"}</button>
          <button className="swiper-button-next">{">"}</button>
        </div>

        {/* Mobile Swiper */}
        {matchesMobileX && (
          <Swiper
            // pagination={true}
            pagination={{
              dynamicBullets: true,
            }}
            slidesPerView={"auto"}
            spaceBetween={10}
            centeredSlides={true}
            loop={true}
            autoplay={{ delay: 5000, disableOnInteraction: false }}
            modules={[Pagination, Navigation, Autoplay]}
            className="mySwiper-mobile"
          >
            <SwiperSlide>
              <Box className="cfeedback">
                <Box className="cfeedback-img">
                  <img src={caseyImg} alt="" />
                  <Box className="stars-rating">
                    <Rating
                      name="text-feedback"
                      value={value}
                      readOnly
                      precision={0.5}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55, color: "#faa61a" }}
                          fontSize="inherit"
                        />
                      }
                    />
                  </Box>
                </Box>
                <Box className="cfeedback-content">
                  <Typography className="cfeedback-text">
                    As we need equipment, it seems like we can just find it on
                    the app. It's been really beneficial for our business.
                  </Typography>
                  <Box className="cfeedback-name">
                    <Box className="cfeedback-name-comp">
                      – Casey D. / Renter, Owner
                    </Box>
                  </Box>
                </Box>
              </Box>
            </SwiperSlide>

            <SwiperSlide>
              <Box className="cfeedback">
                <Box className="cfeedback-img">
                  <img src={daleImg} alt="" />
                  <Box className="stars-rating">
                    <Rating
                      name="text-feedback"
                      value={value}
                      readOnly
                      precision={0.5}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55, color: "#faa61a" }}
                          fontSize="inherit"
                        />
                      }
                    />
                  </Box>
                </Box>
                <Box className="cfeedback-content">
                  <Typography className="cfeedback-text">
                    I think I’ve used GoEquipMe for every aspect of my business.
                    I started out as a renter, then purchased equipment financed
                    by GoEquipMe, and now list equipment to rent as well. Great
                    equipment sharing concept that has both saved me money, and
                    paid big dividends. Thank you GoEquipMe!
                  </Typography>
                  <Box className="cfeedback-name">
                    <Box className="cfeedback-name-comp">
                      – Dale B. / Owner, Sales, Renter, Borrower
                    </Box>
                  </Box>
                </Box>
              </Box>
            </SwiperSlide>

            <SwiperSlide>
              <Box className="cfeedback">
                <Box className="cfeedback-img">
                  <img src={bradImg} alt="" />
                  <Box className="stars-rating">
                    <Rating
                      name="text-feedback"
                      value={value}
                      readOnly
                      precision={0.5}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55, color: "#faa61a" }}
                          fontSize="inherit"
                        />
                      }
                    />
                  </Box>
                </Box>
                <Box className="cfeedback-content">
                  <Typography className="cfeedback-text">
                    I've used about everything on the app, I've rented
                    equipment, listed equipment for rent and I've gotten a loan
                    to buy equipment. Every transaction was easy and I've saved
                    money or made money. This app has really helped my company
                    grow. I couldn't do it without them!
                  </Typography>
                  <Box className="cfeedback-name">
                    <Box className="cfeedback-name-comp">
                      – Brad P./ Owner, Sales, Renter, Borrower
                    </Box>
                  </Box>
                </Box>
              </Box>
            </SwiperSlide>

            <SwiperSlide>
              <Box className="cfeedback">
                <Box className="cfeedback-img">
                  <img src={michaelImg} alt="" />
                  <Box className="stars-rating">
                    <Rating
                      name="text-feedback"
                      value={value}
                      readOnly
                      precision={0.5}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55, color: "#faa61a" }}
                          fontSize="inherit"
                        />
                      }
                    />
                  </Box>
                </Box>
                <Box className="cfeedback-content">
                  <Typography className="cfeedback-text">
                    I've rented several times through the app. It's helped me
                    save money on projects and I've enjoyed working with the
                    equipment owners. I've also rented my equipment out to both
                    contractors and for the guy down the road. It's worked out
                    great!
                  </Typography>
                  <Box className="cfeedback-name">
                    <Box className="cfeedback-name-comp">
                      – Michael B. / Owner, Renter
                    </Box>
                  </Box>
                </Box>
              </Box>
            </SwiperSlide>

            <SwiperSlide>
              <Box className="cfeedback">
                <Box className="cfeedback-img">
                  <img src={fredImg} alt="" />
                  <Box className="stars-rating">
                    <Rating
                      name="text-feedback"
                      value={value}
                      readOnly
                      precision={0.5}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55, color: "#faa61a" }}
                          fontSize="inherit"
                        />
                      }
                    />
                  </Box>
                </Box>
                <Box className="cfeedback-content">
                  <Typography className="cfeedback-text">
                    Using GoEquipMe has been great to keep my trailer and
                    telehandler busy when I’m not using it. It’s been really
                    helpful to have the extra income!
                  </Typography>
                  <Box className="cfeedback-name">
                    <Box className="cfeedback-name-comp">– Fred B. / Owner</Box>
                  </Box>
                </Box>
              </Box>
            </SwiperSlide>

            <SwiperSlide>
              <Box className="cfeedback">
                <Box className="cfeedback-img">
                  <img src={paytonImg} alt="" />
                  <Box className="stars-rating">
                    <Rating
                      name="text-feedback"
                      value={value}
                      readOnly
                      precision={0.5}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55, color: "#faa61a" }}
                          fontSize="inherit"
                        />
                      }
                    />
                  </Box>
                </Box>
                <Box className="cfeedback-content">
                  <Typography className="cfeedback-text">
                    I used GoEquipMe to rent a large excavator to prep a
                    greenhouse pad. I was able to rent it from a contractor that
                    was working just down the road. Saved me a ton of money!
                    I’ll be using them again. And will be listing some equipment
                    as well!
                  </Typography>
                  <Box className="cfeedback-name">
                    <Box className="cfeedback-name-comp">
                      – Payton F. / Renter
                    </Box>
                  </Box>
                </Box>
              </Box>
            </SwiperSlide>

            <SwiperSlide>
              <Box className="cfeedback">
                <Box className="cfeedback-img">
                  <img src={monteImg} alt="" />
                  <Box className="stars-rating">
                    <Rating
                      name="text-feedback"
                      value={value}
                      readOnly
                      precision={0.5}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55, color: "#faa61a" }}
                          fontSize="inherit"
                        />
                      }
                    />
                  </Box>
                </Box>
                <Box className="cfeedback-content">
                  <Typography className="cfeedback-text">
                    I've rented through GoEquipMe several times now--and I've
                    listed my own equipment on GoEquipMe. The extra money I've
                    made off my equipment, when I'm not using it, has really
                    helped my bottom line. I've even gotten paid for
                    transporting equipment. Easy platform to use, love it!
                  </Typography>
                  <Box className="cfeedback-name">
                    <Box className="cfeedback-name-comp">
                      – Monte C. / Owner
                    </Box>
                  </Box>
                </Box>
              </Box>
            </SwiperSlide>

            <SwiperSlide>
              <Box className="cfeedback">
                <Box className="cfeedback-img">
                  <img src={stuartImg} alt="" />
                  <Box className="stars-rating">
                    <Rating
                      name="text-feedback"
                      value={value}
                      readOnly
                      precision={0.5}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55, color: "#faa61a" }}
                          fontSize="inherit"
                        />
                      }
                    />
                  </Box>
                </Box>
                <Box className="cfeedback-content">
                  <Typography className="cfeedback-text">
                    GoEquipMe helped us purchase equipment we needed for a job.
                    Without that equipment we couldn't have made it happen. I
                    recommend GoEquipMe to everyone I meet, they understand the
                    construction world; we will be using them lots more.
                  </Typography>
                  <Box className="cfeedback-name">
                    <Box className="cfeedback-name-comp">
                      – Stuart M. / Sales, Borrower
                    </Box>
                  </Box>
                </Box>
              </Box>
            </SwiperSlide>

            <SwiperSlide>
              <Box className="cfeedback">
                <Box className="cfeedback-img">
                  <img src={dougImg} alt="" />
                  <Box className="stars-rating">
                    <Rating
                      name="text-feedback"
                      value={value}
                      readOnly
                      precision={0.5}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55, color: "#faa61a" }}
                          fontSize="inherit"
                        />
                      }
                    />
                  </Box>
                </Box>
                <Box className="cfeedback-content">
                  <Typography className="cfeedback-text">
                    We've listed alot of equipment on GoEquipMe and made six
                    figures just from the rentals. It is easy to manage all the
                    transactions with their calendar, notifications, deposits
                    etc...and their customer service is off the charts, they’ve
                    gone above and beyond to help us secure the rentals!
                  </Typography>
                  <Box className="cfeedback-name">
                    <Box className="cfeedback-name-comp">– Doug D. / Owner</Box>
                  </Box>
                </Box>
              </Box>
            </SwiperSlide>

            <SwiperSlide>
              <Box className="cfeedback">
                <Box className="cfeedback-img">
                  <img src={tylerImg} alt="" />
                  <Box className="stars-rating">
                    <Rating
                      name="text-feedback"
                      value={value}
                      readOnly
                      precision={0.5}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55, color: "#faa61a" }}
                          fontSize="inherit"
                        />
                      }
                    />
                  </Box>
                </Box>
                <Box className="cfeedback-content">
                  <Typography className="cfeedback-text">
                    Having completed a few rentals I’ve now listed several more
                    items. I’m excited to watch GoEquipMe grow and my Wallet!
                  </Typography>
                  <Box className="cfeedback-name">
                    <Box className="cfeedback-name-comp">
                      – Tyler S. / Owner
                    </Box>
                  </Box>
                </Box>
              </Box>
            </SwiperSlide>

            <SwiperSlide>
              <Box className="cfeedback">
                <Box className="cfeedback-img">
                  <img src={bobbyImg} alt="" />
                  <Box className="stars-rating">
                    <Rating
                      name="text-feedback"
                      value={value}
                      readOnly
                      precision={0.5}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55, color: "#faa61a" }}
                          fontSize="inherit"
                        />
                      }
                    />
                  </Box>
                </Box>
                <Box className="cfeedback-content">
                  <Typography className="cfeedback-text">
                    As an equipment owner, buyer, renter, and borrower on
                    GoEquipMe I highly recommend using them for whatever you
                    need. Finally, a company with great customer support and
                    that really cares about its users.
                  </Typography>
                  <Box className="cfeedback-name">
                    <Box className="cfeedback-name-comp">
                      – Bobby P. / Owner, sales, renter, Borrower
                    </Box>
                  </Box>
                </Box>
              </Box>
            </SwiperSlide>

            <SwiperSlide>
              <Box className="cfeedback">
                <Box className="cfeedback-img">
                  <img src={charlesImg} alt="" />
                  <Box className="stars-rating">
                    <Rating
                      name="text-feedback"
                      value={value}
                      readOnly
                      precision={0.5}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55, color: "#faa61a" }}
                          fontSize="inherit"
                        />
                      }
                    />
                  </Box>
                </Box>
                <Box className="cfeedback-content">
                  <Typography className="cfeedback-text">
                    Everyone likes to make a little more cash… and everyone
                    likes to have equipment on hand when they need it. The ROI
                    makes a lot more sense when you can rent out equipment when
                    you’re not using it.
                  </Typography>
                  <Box className="cfeedback-name">
                    <Box className="cfeedback-name-comp">
                      – Charles H. / Equipment
                    </Box>
                  </Box>
                </Box>
              </Box>
            </SwiperSlide>
          </Swiper>
        )}
      </Box>
    );
  }
  return null;
};

export default Testimonials;
