import React, {
  ChangeEvent,
  createContext,
  useEffect,
  useRef,
  useState,
} from "react";
import CartCheckoutChild from "./CartCheckoutChild";
import { IStoreValue, ProductsDataTypes } from "utils/types";
import { EquipmentAPIs } from "service/api-service";
import { useDispatch, useSelector } from "react-redux";
import {
  setCartProducts,
  setalertMessage,
  setalertMessagetype,
} from "redux/redux-slice";
const products = [
  {
    id: 1,
    title: "Wireless Headphones",
    description: "High-quality wireless headphones with noise cancellation.",
    thumbnail:
      "https://rukminim2.flixcart.com/image/612/612/xif0q/t-shirt/a/m/c/s-aka6-msdhoni7-csk-yellow2024-teky-original-imagymt6zgzh4wzf.jpeg",
    price: 99.99,
  },
  {
    id: 2,
    title: "Smart Watch",
    description: "Stylish smart watch with fitness tracking features.",
    thumbnail:
      "https://rukminim2.flixcart.com/image/612/612/xif0q/t-shirt/a/m/c/s-aka6-msdhoni7-csk-yellow2024-teky-original-imagymt6zgzh4wzf.jpeg",
    price: 149.99,
  },
  {
    id: 3,
    title: "Bluetooth Speaker",
    description:
      "Portable Bluetooth speaker with deep bass and long battery life.",
    thumbnail:
      "https://rukminim2.flixcart.com/image/612/612/xif0q/t-shirt/a/m/c/s-aka6-msdhoni7-csk-yellow2024-teky-original-imagymt6zgzh4wzf.jpeg",
    price: 49.99,
  },
  {
    id: 4,
    title: "Gaming Mouse",
    description:
      "Ergonomic gaming mouse with customizable buttons and RGB lighting.",
    thumbnail:
      "https://rukminim2.flixcart.com/image/612/612/xif0q/t-shirt/a/m/c/s-aka6-msdhoni7-csk-yellow2024-teky-original-imagymt6zgzh4wzf.jpeg",
    price: 39.99,
  },
  {
    id: 5,
    title: "4K Monitor",
    description: "27-inch 4K UHD monitor with stunning picture quality.",
    thumbnail:
      "https://rukminim2.flixcart.com/image/612/612/xif0q/t-shirt/a/m/c/s-aka6-msdhoni7-csk-yellow2024-teky-original-imagymt6zgzh4wzf.jpeg",
    price: 299.99,
  },
];

export const CartContext = createContext(null);

interface ProductQtySizeType {
  [key: number]: {
    size: number;
    qty: number | string;
  };
}

interface CartTypes {
  children: React.ReactNode;
}

interface orderDetail {
  name: string;
  email: string;
  phone: string;
  shipping_address: string;
  city: string;
  state: string;
  additional_address: string;
  postal_code: string;
  lat: string;
  lng: string;
  amount: number;
  CardObj: {
    balance: number;
    is_card_saved: string;
    is_saved: string;
    status: string;
    token: string;
  };
  request_from: string;
  user_id: number;
}

const CartCheckoutContext: React.FC<CartTypes> = ({ children }) => {
  const dispatch = useDispatch();

  const AuthToken = useSelector(
    (state: IStoreValue) => state.equipmentReducer.accessToken
  );
  const AuthUserId = useSelector(
    (state: IStoreValue) => state.equipmentReducer.Authuser
  );
  const cartProducts = useSelector(
    (state: IStoreValue) => state.equipmentReducer.cartProducts
  );
  console.log("cartProducts", cartProducts);
  // const [cartProducts, setCartProducts] = useState<ProductsDataTypes[]>([]);
  const [productQtySize, setProductQtySize] = useState<ProductQtySizeType>(
    {} as ProductQtySizeType
  );
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [successPopup, setSuccessPopup] = useState<boolean>(false);
  const [successPopupMsg, setSuccessPopupMsg] = useState<boolean>(false);
  const debounceQuantity = useRef(null);
  // products.reduce((acc, product) => {
  //   acc[product.id] = { size: "s", qty: 1 };
  //   return acc;
  // }, {} as ProductQtySizeType)

  const updateCart = ({
    id,
    value,
    type,
  }: {
    id: number;
    value: number | string;
    type: string;
  }) => {
    if (type === "size") {
      setProductQtySize((prev) => ({
        ...prev,
        [id]: {
          ...prev[id],
          size: Number(value),
        },
      }));
    } else if (type === "qty") {
      // const productData = cartProducts
      //   ?.map(({ stock_data }) =>
      //     stock_data.find((product) => {
      //       if (product?.product_size_id === Number(productQtySize[id]?.size)) {
      //         return product;
      //       } else if (!productQtySize[id]?.size && !product?.product_size_id) {
      //         return product;
      //       }
      //     })
      //   )
      //   .find(Boolean);
      const productData = cartProducts?.filter(
        (product) => product?.cart_id === id
      )?.[0];
      let intValue;
      if (typeof value === "string") {
        intValue = parseFloat(value) || "";
      } else {
        intValue = Number(value);
      }
      if (intValue > productData?.stock_quantity) {
        clearTimeout(debounceQuantity.current);
        dispatch(
          setalertMessage("Insufficient stock available. Adjust your quantity.")
        );
        dispatch(setalertMessagetype("error"));
        return false;
      }
      setProductQtySize((prev) => ({
        ...prev,
        [id]: {
          ...prev[id],
          qty: intValue,
        },
      }));
    }
  };

  const handleQtySizeChanges = async (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    id: number,
    type: string
  ) => {
    let value: number | string = parseFloat(event.target.value);
    if (Number.isNaN(value) && type !== "qty") return;
    value = value || "";
    try {
      clearTimeout(debounceQuantity.current);
      debounceQuantity.current = setTimeout(async () => {
        try {
          const headers = {
            Authorization: `${AuthToken}`,
            request_from: "Web",
          };
          const product = cartProducts.find((prdt) => prdt?.cart_id === id);

          if (!product) {
            console.error("Product not found in cart");
            return;
          }
          if (!value) {
            return;
          }
          let product_id = product.product_id;
          const params = {
            user_id: AuthUserId,
            id,
            product_id,
            quantity: value,
            size: type === "size" ? value : null,
            update_type: type === "qty" ? "quantity" : "size",
          };

          const response = await EquipmentAPIs.updateCart(params, headers);
          if (response["type"] === "RXERROR") {
            throw new Error(response["message"]);
          }
        } catch (apiError) {
          console.error("API Call Failed:", apiError);
          await fetchCartProducts();
        }
      }, 500);
      updateCart({ id, value, type });
    } catch (error) {
      console.error("Error updating cart:", error);
      await fetchCartProducts();
    }
  };

  const fetchCartProducts = async () => {
    try {
      setLoading(true);
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const response = await EquipmentAPIs.viewCart(
        { user_id: AuthUserId },
        headers
      );
      if (response["type"] === "RXSUCCESS") {
        console.log("response", response);
        const cartData = response?.data;
        const sizeQty = cartData.reduce((acc, product: ProductsDataTypes) => {
          acc[product.cart_id] = {
            size: product?.product_size,
            qty: product?.product_quantity,
          };
          return acc;
        }, {} as ProductQtySizeType);
        setProductQtySize(sizeQty);
        dispatch(setCartProducts(cartData));
      } else {
        throw new Error(response["message"] || "An error ocurred!");
      }
    } catch (err) {
      console.log("first", err);
      dispatch(setalertMessage(err.message));
      dispatch(setalertMessagetype("error"));
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveCart = async (productId: number) => {
    try {
      setLoading(true);
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const params = {
        user_id: AuthUserId,
        id: productId,
      };
      const response = await EquipmentAPIs.removeFromCart(params, headers);
      if (response["type"] === "RXSUCCESS") {
        await fetchCartProducts();
      } else {
        throw new Error(response["message"] || "An error occured!");
      }
    } catch (error) {
      dispatch(setalertMessage(error.message));
      dispatch(setalertMessagetype("error"));
      console.log("error while removing cart", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCartCheckout = async (params: orderDetail) => {
    try {
      setLoading(true);
      const filteredProduct = cartProducts?.filter((product) => {
        const orderQty = productQtySize[product?.cart_id]?.qty;
        if (typeof orderQty === "string") {
          return false;
        }
        if (!orderQty) return false; // Prevent errors if orderQty is undefined

        const stocksProduct = product?.product_size
          ? product?.stock_data?.find(
              (prdts) => prdts?.product_size_id === product?.product_size
            )
          : product?.stock_data?.[0];

        return stocksProduct?.stock_quantity >= orderQty;
      });

      const productArray = filteredProduct?.map(
        ({
          product_id,
          cart_id,
          price,
          product_size,
          thumbnail,
          size_short_name,
          product_name,
        }) => ({
          product_id,
          quantity: productQtySize[cart_id]?.qty || 0, // Ensure qty is always defined
          price,
          size_id: product_size,
          product_name,
          product_size_name: size_short_name,
          thumbnail,
        })
      );

      const finalObject = {
        ...params,
        order_arr: productArray,
      };
      console.log("finalObject", finalObject);
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const RequestData = await EquipmentAPIs.placeStoreOrder(
        finalObject,
        headers
      );
      console.log("RequestData==============>", RequestData);
      if (RequestData["type"] == "RXSUCCESS") {
        setSuccessPopup(true);
        setSuccessPopupMsg(RequestData["message"]);
        dispatch(setCartProducts([]));
      } else {
        throw new Error(RequestData["message"]);
      }
    } catch (error) {
      dispatch(setalertMessage(error.message));
      dispatch(setalertMessagetype("error"));
    } finally {
      setLoading(false);
    }
  };

  const cartTimer = useRef(null);
  useEffect(() => {
    clearTimeout(cartTimer.current);
    window.scrollTo({ top: 0, behavior: "smooth" });
    cartTimer.current = setTimeout(() => {
      fetchCartProducts();
    }, 500);
  }, [AuthUserId]);

  useEffect(() => {
    const totalPrice = cartProducts?.reduce((sum, product) => {
      const cartId = product?.cart_id;
      const price = Number(product?.price);
      let qty = productQtySize[cartId]?.["qty"];
      if (typeof qty === "string") {
        qty = 0;
      }
      // if (product?.has_sizes) {
      //   const productSpecificSize = product?.stock_data?.find(
      //     (stock) => stock?.product_size_id === product?.product_size
      //   );
      //   if (productSpecificSize?.stock_quantity < qty) {
      //     qty = 0;
      //   }
      // } else {
      //   if (product?.stock_data?.[0]?.stock_quantity < qty) {
      //     qty = 0;
      //   }
      // }
      if (product?.stock_quantity < qty || !product?.available_status) {
        qty = 0;
      }
      return sum + price * qty;
    }, 0);
    setTotalPrice(totalPrice);
  }, [productQtySize, cartProducts]);

  return (
    <CartContext.Provider
      value={{
        productQtySize,
        totalPrice,
        handleQtySizeChanges,
        handleRemoveCart,
        loading,
        successPopup,
        successPopupMsg,
        handleCartCheckout,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

const CartCheckout: React.FC = () => {
  return (
    <CartCheckoutContext>
      <CartCheckoutChild />
    </CartCheckoutContext>
  );
};

export default CartCheckout;
