import {
  Box,
  Button,
  IconButton,
  Modal,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { Calendar as NewCalendar } from "react-multi-date-picker";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "rgb(46 46 46)", //background.paper
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const FilterCalender = ({
  calendarPopup,
  setCalendarPopupClose,
  setDateRange,
  defaultDateRange,
  maxDate = null,
}) => {
  const matchesMobileX = useMediaQuery("(max-width:425px)");
  const handleDateRange = (rang) => {
    let start = rang[0]?.format?.();
    let end = rang[1]?.format?.();
    if (start || end) {
      if (start && end) {
        const dateRange = [
          moment(start).format("YYYY-MM-DD"),
          moment(end).format("YYYY-MM-DD"),
        ];
        setDateRange(dateRange);
      }
    }
  };
  return (
    <>
      <Modal
        open={calendarPopup}
        onClose={setCalendarPopupClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: {
              xs: "80%",
              sm: "400px",
              md: "450px",
              lg: "450px",
              xl: "450px",
            },
            bgcolor: "#2e2e2e",
            border: "5px solid #faa61a",
            borderRadius: "10px",
            outline: "none !important",
            p: 3,
          }}
        >
          <IconButton
            onClick={setCalendarPopupClose}
            sx={{
              position: "absolute",
              top: "-20px",
              right: "-20px",
              bgcolor: "#faa61a",
              color: "#000",
              "&:hover": {
                bgcolor: "#faa61a",
              },
            }}
          >
            <CloseIcon />
          </IconButton>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box className="date-cal filtercalendar filter-calendar">
              <NewCalendar
                numberOfMonths={1}
                disableMonthPicker
                disableYearPicker
                value={defaultDateRange}
                onChange={handleDateRange}
                maxDate={maxDate}
                className="twin-cal-custom-calendar"
                range
              />
            </Box>
            <Box
              sx={{
                width: "100% !important",
                display: "flex",
                justifyContent: "center",
                gap: "2rem",
                //   mt: "1rem",
                alignItems: "center",
                mt:4
              }}
            >
              <Typography
                className="reset-filter"
                sx={{
                  // width:''
                  color: "#ffa700",
                  textDecoration: "underline",
                  marginLeft: "20px !important",
                  cursor: "pointer",
                }}
                onClick={() => setDateRange([])}
                variant="h6"
              >
                Reset
              </Typography>

              <Button
                sx={{
                  borderRadius: "8px",
                  bgcolor: "#149247",
                  textTransform: "capitalize",
                  fontFamily: "Montserrat !important",
                  fontWeight: "800",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                  fontSize: {
                    xs: "13px",
                    sm: "14px",
                    md: "14px",
                    lg: "15px",
                    xl: "16px",
                  },
                  color: "#fff",
                }}
                onClick={setCalendarPopupClose}
              >
                Submit Dates
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default FilterCalender;
