import React, { useEffect, useState } from "react";
import { Modal, Box, IconButton, Button, Typography } from "@mui/material";
// import CustomCalendarWithTimes from "components/Calendar/CustomCalendarWithTimes";
import CustomCalendar from "components/Calendar/CustomCalendar";
import CloseIcon from "@mui/icons-material/Close";
// import CustomCalendarTime from "components/Calendar/CustomCalendarTime";
import { setalertMessage, setalertMessagetype } from "redux/redux-slice";
import { useDispatch } from "react-redux";
import CustomTimeModal from "./CustomTimeModal";
import moment from "moment";
// import arrowIcon from "assets/images/newback.png";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  //  overflowX:'hidden',
  bgcolor: "#2e2e2e",
  border: "5px solid #faa61a",
  borderRadius: "10px",
  outline: "none !important",
  // p: 3,
  width: {
    xs: "90%",
    sm: "400px",
    md: "450px",
    lg: "450px",
    xl: "450px",
    // sm:'auto'
  },
};

const CalendarModal = ({
  open,
  handleClose,
  timeZoneAbbr,
  equipRentedDates,
  setEquipRentedDates,
  equipRentedTimes,
  setEquipRentedTimes,
  minDateTime,
  currentMonth,
  setCurrentMonth,
  calendarRef,
  handleRentNowBtn,
}) => {
  const dispatch = useDispatch();
  // const [steps, setSteps] = useState<number>(1);
  const [pickupTimeModalOpen, setPickupTimeModalOpen] =
    useState<boolean>(false);
  const [dropoffTimeModalOpen, setDropoffTimeModalOpen] =
    useState<boolean>(false);
  const [label, setLabel] = useState<string>("Select Pickup Time");
  const [tempDates, setTempDates] = useState([null, null]);
  const [tempRentalTimes, setTempRentalTimes] = useState({
    startTime: null,
    endTime: null,
  });
  const handlePickupTimeModalOpen = () => setPickupTimeModalOpen(true);
  const handleDropoffTimeModalOpen = () => setDropoffTimeModalOpen(true);

  const [enableDateSelection, setEnableDateSelection] = useState<string>("end");
  const [highlightSelection, setHighlightSelection] = useState<string>("start");

  const handleTimeModalClose = (event, type = null) => {
    setPickupTimeModalOpen(false);
    setDropoffTimeModalOpen(false);
    if (equipRentedDates[0] && !equipRentedTimes?.startTime) {
      setEquipRentedDates([null, null]);
      setTempDates([null, null]);
    }
    if (equipRentedDates[1] && !equipRentedTimes?.endTime) {
      setEquipRentedDates([equipRentedDates[0], null]);
      setTempDates([tempDates[0], null]);
    }
    if (equipRentedDates[0] && equipRentedTimes?.startTime) {
      setTempRentalTimes((prev) => ({
        ...prev,
        startTime: equipRentedTimes?.startTime,
      }));
    }
    // if (label?.includes("Drop-off")) {
    // setTempDates([tempDates[0],null])
    // setTempRentalTimes(prev=>({...prev,endTime:null}));
    // setTempDates([null, null]);
    // setTempRentalTimes({
    //   startTime: null,
    //   endTime: null,
    // });
    // if (type !== "cross") {
    //   handleClose();
    //   handleRentNowBtn();
    // }
    // }
  };

  const handleNextSteps = () => {
    if (!equipRentedDates[0] || !equipRentedDates[1]) {
      dispatch(setalertMessage("Please select start and end date to proceed."));
      dispatch(setalertMessagetype("error"));
      return false;
    }
    handleClose();
    handleRentNowBtn();
    // if (steps <= 2) {
    //   if (steps === 2) {
    //     handleClose();
    //   } else {
    //     if (!equipRentedDates[0] || !equipRentedDates[1]) {
    //       dispatch(
    //         setalertMessage("Please select start and end date to proceed.")
    //       );
    //       dispatch(setalertMessagetype("error"));
    //       return false;
    //     }
    //     setSteps((prev) => prev + 1);
    //   }
    // }
  };

  // const handleBackSteps = () => {
  //   if (steps > 1) {
  //     setSteps((prev) => prev - 1);
  //   }
  // };

  const handleResetCalendar = () => {
    setTempDates([null, null]);
    setTempRentalTimes({
      startTime: null,
      endTime: null,
    });
    setEquipRentedTimes({
      startTime: null,
      endTime: null,
    });
    setEquipRentedDates([null, null]);
    // setEnableDateSelection("start");
    setHighlightSelection("start");
  };

  const handleTimeSelectionOnNext = () => {
    if (label?.includes("Pickup") && !equipRentedTimes?.startTime) {
      // show alert
      dispatch(setalertMessage("Please select pickup time to proceed."));
      dispatch(setalertMessagetype("error"));
      return false;
    } else if (label?.includes("Drop-off") && !equipRentedTimes?.endTime) {
      // show alert
      dispatch(setalertMessage("Please select drop-off time to proceed."));
      dispatch(setalertMessagetype("error"));
      return false;
    }
    handleTimeModalClose("alpha");
  };

  useEffect(() => {
    if (open) {
      // console.log(
      //   "tempDates",
      //   tempDates,
      //   tempRentalTimes,
      //   equipRentedDates,
      //   equipRentedTimes
      // );
      if (tempDates[0] && !tempDates[1] && !tempRentalTimes?.startTime) {
        setLabel("Select Pickup Time");
        handlePickupTimeModalOpen();
      } else if (tempDates[1] && !tempRentalTimes?.endTime) {
        handleDropoffTimeModalOpen();
        setLabel("Select Drop-off Time");
      }
    }
  }, [tempDates, open, tempRentalTimes]);

  useEffect(() => {
    // console.log("equipRentedTimes", equipRentedTimes);
    if (!equipRentedTimes?.startTime && !equipRentedTimes?.endTime) {
      setHighlightSelection("start");
    } else if (equipRentedTimes?.startTime && !equipRentedTimes?.endTime) {
      setHighlightSelection("end");
    }
  }, [equipRentedTimes]);

  // useEffect(()=>{
  //   if(equipRentedDates[0] && equipRentedDates[1] && !equipRentedTimes?.startTime && !equipRentedTimes?.endTime) {
  //     setTempDates([null,null])
  //   } else if(equipRentedDates[0] && equipRentedDates[1] && equipRentedTimes?.startTime && !equipRentedTimes?.endTime) {
  //     setTempDates([equipRentedDates[0],equipRentedDates[1]])
  //     setTempRentalTimes(prev=>({...prev,startTime:equipRentedTimes?.startTime}));
  //   } else if (equipRentedDates[0] && equipRentedDates[1] && equipRentedTimes?.startTime && equipRentedTimes?.endTime) {
  //     setTempDates([equipRentedDates[0],equipRentedDates[1]])
  //     setTempRentalTimes({startTime:equipRentedTimes?.startTime,endTime:equipRentedTimes?.endTime});
  //   }else if(equipRentedDates[0] && !equipRentedTimes?.startTime) {
  //     setTempDates([equipRentedDates[0],null])
  //   }
  // },[equipRentedDates,equipRentedTimes])

  return (
    <Modal open={open} onClose={handleClose} keepMounted>
      <Box sx={modalStyle}>
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: "-20px",
            right: {
              xs: "5px",
              sm: "-20px",
              md: "-20px",
              lg: "-20px",
              xl: "-20px",
            },
            bgcolor: "#faa61a",
            color: "#000",
            "&:hover": {
              bgcolor: "#faa61a",
            },
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box
          sx={{
            mt: 2,
            mb: 2,
            width: "100%",
            maxHeight: "750px",
            overflowY: "auto",
          }}
        >
          <CustomCalendar
            equipRentedTimes={equipRentedTimes}
            equipRentedDates={equipRentedDates}
            setEquipRentedDates={setEquipRentedDates}
            label={
              // !equipRentedDates[0] || !equipRentedTimes?.startTime ? (
              //   "Select Pick Up Date"
              // ) : !equipRentedDates[1] || !equipRentedTimes?.endTime ? (
              //   "Select Drop Off Date"
              // ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "1.5rem",
                }}
              >
                <Box
                  sx={{
                    textAlign: "center",
                    cursor: "pointer",
                    border:
                      highlightSelection === "start"
                        ? "2px solid #faa61a"
                        : "none",
                    borderRadius: "5px",
                    padding: "5px",
                  }}
                  //  onClick={()=>setEnableDateSelection("start")}
                >
                  <Typography
                    sx={{
                      // color:
                      //   highlightSelection === "start" ? "#faa61a" : "#fff",
                      color: "#fff",
                      fontWeight: "800",
                      fontFamily: "Montserrat !important",
                      fontSize: "16px",
                    }}
                  >
                    {!equipRentedDates[0]
                      ? "Start"
                      : moment(equipRentedDates[0].format("YYYY-MM-DD")).format(
                          "ddd, MMM D"
                        )}
                  </Typography>
                  <Typography
                    sx={{
                      // color:
                      //   highlightSelection === "start" ? "#faa61a" : "#fff",
                      color: "#fff",
                      fontSize: "14px",
                      fontFamily: "Montserrat !important",
                    }}
                  >
                    {equipRentedTimes?.startTime
                      ? `${moment(equipRentedTimes?.startTime, "HH:mm").format(
                          "hh:mm A"
                        )} (${timeZoneAbbr})`
                      : `Start Time (${timeZoneAbbr})`}
                  </Typography>
                </Box>
                {/* <LabelIcon /> */}
                {/* <Box
                  component={"img"}
                  src={arrowIcon}
                  alt=""
                  sx={{
                    width: "30px",
                    height: "auto",
                    transform: "rotate(180deg)",
                  }}
                /> */}
                <LabelImportantIcon
                  sx={{
                    width: "30px",
                    height: "auto",
                    color: "#faa61a",
                    // transform: "rotate(180deg)",
                  }}
                />
                <Box
                  sx={{
                    textAlign: "center",
                    cursor: "pointer",
                    border:
                      highlightSelection === "end"
                        ? "2px solid #faa61a"
                        : "none",
                    borderRadius: "5px",
                    padding: "5px",
                  }}
                  // onClick={()=>setEnableDateSelection("end")}
                >
                  <Typography
                    sx={{
                      // color: highlightSelection === "end" ? "#faa61a" : "#fff",
                      color: "#fff",
                      fontWeight: "800",
                      fontFamily: "Montserrat !important",
                      fontSize: "16px",
                    }}
                  >
                    {!equipRentedDates[1]
                      ? "End"
                      : moment(equipRentedDates[1].format("YYYY-MM-DD")).format(
                          "ddd, MMM D"
                        )}
                  </Typography>
                  <Typography
                    sx={{
                      // color: highlightSelection === "end" ? "#faa61a" : "#fff",
                      color: "#fff",
                      fontSize: "14px",
                      fontFamily: "Montserrat !important",
                    }}
                  >
                    {equipRentedTimes?.endTime
                      ? `${moment(equipRentedTimes?.endTime, "HH:mm").format(
                          "hh:mm A"
                        )} (${timeZoneAbbr})`
                      : `End Time (${timeZoneAbbr})`}
                  </Typography>
                </Box>
              </Box>

              // <Box
              //   sx={{
              //     display: "grid",
              //     gridTemplateColumns: "1fr ",
              //     gap: "0.5rem",
              //     alignItems: "center",
              //     width: "100%",
              //   }}
              // >
              //   <Box
              //     sx={{
              //       // mb: 1,
              //       display: "flex",
              //       alignItems: "baseline",
              //       gap: "0.5rem",
              //     }}
              //   >
              //     <Typography
              //       sx={{
              //         fontSize: "13px",
              //         textAlign: "center",
              //         // mb: "5px",
              //         fontWeight: "500",
              //         fontFamily: "Montserrat !important",
              //         width: "100%",
              //       }}
              //     >
              //       Pickup Date & Time :
              //     </Typography>
              //     <Typography
              //       sx={{
              //         color: "#faa61a",
              //         // background: "#faa61a",
              //         display: "flex",
              //         alignItems: "center",
              //         justifyContent: "center",
              //         p: "5px",
              //         border: "1px solid #5a5a52",
              //         fontSize: "13px",
              //         fontWeight: "700",
              //         fontFamily: "Montserrat !important",
              //         borderRadius: 2,
              //         width: "100%",
              //       }}
              //     >
              //       {moment(
              //         `${equipRentedDates[0]?.format("MM/DD/YY")} ${
              //           equipRentedTimes.startTime
              //         }`
              //       ).format("MM/DD/YY hh:mm A") + ` (${timeZoneAbbr})`}
              //     </Typography>
              //   </Box>
              //   <Box
              //     sx={{
              //       // mb: 2,
              //       display: "flex",
              //       alignItems: "baseline",
              //       gap: "0.5rem",
              //     }}
              //   >
              //     <Typography
              //       sx={{
              //         fontSize: "13px",
              //         textAlign: "center",
              //         // mb: "5px",
              //         fontWeight: "500",
              //         fontFamily: "Montserrat !important",
              //         width: "100%",
              //       }}
              //     >
              //       Drop-off Date & Time:
              //     </Typography>
              //     <Typography
              //       sx={{
              //         width: "100%",
              //         color: "#faa61a",
              //         // background: "#faa61a",
              //         display: "flex",
              //         alignItems: "center",
              //         justifyContent: "center",
              //         p: "5px",
              //         fontSize: "13px",
              //         border: "1px solid #5a5a52",
              //         fontWeight: "700",
              //         fontFamily: "Montserrat !important",
              //         borderRadius: 2,
              //       }}
              //     >
              //       {moment(
              //         `${equipRentedDates[1]?.format("MM/DD/YY")} ${
              //           equipRentedTimes.endTime
              //         }`
              //       ).format("MM/DD/YY hh:mm A") + ` (${timeZoneAbbr})`}
              //     </Typography>
              //   </Box>
              //   {/* <Typography sx={{ fontSize:'14px', color:'#faa61a', fontWeight:'700' }}>Seleted Start Date : {equipRentedDates[0]?.format("MM/DD/YY")}</Typography>
              //   <Typography sx={{ fontSize:'14px', color:'#faa61a', fontWeight:'700' }}>Seleted End Date : {equipRentedDates[1]?.format("MM/DD/YY")}</Typography> */}
              // </Box>
              // )
            }
            minDateTime={minDateTime}
            setEquipRentedTimes={setEquipRentedTimes}
            setTempDates={setTempDates}
            setTempRentalTimes={setTempRentalTimes}
            currentMonth={currentMonth}
            setCurrentMonth={setCurrentMonth}
            calendarRef={calendarRef}
            enableDateSelection={enableDateSelection}
            setEnableDateSelection={setEnableDateSelection}
            modalOpen={open}
          />
          {/* {steps === 1 ? (
          ) : (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "1rem",
              }}
            >
              <CustomCalendarTime
                label={"Pickup Time"}
                timeZoneAbbr={timeZoneAbbr || "UTC"}
                equipRentedTimes={equipRentedTimes}
                setEquipRentedTimes={setEquipRentedTimes}
                minDateTime={minDateTime}
                equipRentedDates={equipRentedDates}
                openInModal={true}
              />
              <CustomCalendarTime
                label={"Return Time"}
                timeZoneAbbr={timeZoneAbbr || "UTC"}
                equipRentedTimes={equipRentedTimes}
                setEquipRentedTimes={setEquipRentedTimes}
                minDateTime={minDateTime}
                equipRentedDates={equipRentedDates}
                openInModal={true}
              />
            </Box>
          )} */}
          <CustomTimeModal
            open={pickupTimeModalOpen}
            handleClose={handleTimeModalClose}
            label={"Select Pickup Time"}
            timeZoneAbbr={timeZoneAbbr || "UTC"}
            equipRentedTimes={equipRentedTimes}
            setEquipRentedTimes={setEquipRentedTimes}
            minDateTime={minDateTime}
            equipRentedDates={equipRentedDates}
            openInModal={true}
            handleTimeSelectionOnNext={handleTimeSelectionOnNext}
            setTempRentalTimes={setTempRentalTimes}
            modalOpen={open}
          />
          <CustomTimeModal
            open={dropoffTimeModalOpen}
            handleClose={handleTimeModalClose}
            label={"Select Drop-off Time"}
            timeZoneAbbr={timeZoneAbbr || "UTC"}
            equipRentedTimes={equipRentedTimes}
            setEquipRentedTimes={setEquipRentedTimes}
            minDateTime={minDateTime}
            equipRentedDates={equipRentedDates}
            openInModal={true}
            handleTimeSelectionOnNext={handleTimeSelectionOnNext}
            setTempRentalTimes={setTempRentalTimes}
            modalOpen={open}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "1rem",
              mt: 2,
              mb: 1,
            }}
          >
            <Button
              sx={{
                background: "none",
                fontFamily: "Montserrat !important",
                fontWeight: "800",
                fontSize: {
                  xs: "14px",
                  sm: "15px",
                  md: "16px",
                  lg: "17px",
                  xl: "18px",
                },
                color: "#faa61a",
                textTransform: "capitalize",
                padding: "2.5px 20px",
                "&:hover": {
                  background: "none",
                },
                textDecoration: "underline",
              }}
              onClick={handleResetCalendar}
            >
              Reset
            </Button>
            <Button
              sx={{
                background: "#179346",
                fontFamily: "Montserrat !important",
                fontWeight: "800",
                fontSize: {
                  xs: "14px",
                  sm: "15px",
                  md: "16px",
                  lg: "17px",
                  xl: "18px",
                },
                color: "#fff",
                textTransform: "capitalize",
                padding: "2.5px 20px",
                "&:hover": {
                  background: "green",
                },
              }}
              onClick={handleNextSteps}
            >
              {/* {steps === 2 ? "Confirm" : "Select Time"} */}
              Next
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default CalendarModal;
