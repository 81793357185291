import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Collapse,
  Fade,
  Grow,
  Slide,
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
  Pagination,
  FormControl,
  Link,
  CircularProgress,
  Modal,
  IconButton,
  useMediaQuery,
  styled,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Select,
  MenuItem,
  FormGroup,
  Checkbox,
  Slider,
  ListItemIcon,
  Backdrop,
  Container,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentPage,
  setalertMessagetype,
  setalertMessage,
  setEquipListType,
  setFromHowItWorks,
  setSessionExpire,
  setLoginModalOpen,
  setMapEquipments,
  setBoundingBox,
  setToggleFooterSwipper,
} from "redux/redux-slice";
import {
  IApiResponseRawData,
  IEquipment,
  IStore,
  IStoreValue,
} from "utils/types";
import {
  setCategories,
  setCount,
  setEquipments,
  setLoadingEquipments,
} from "redux/redux-slice";
import { EquipmentAPIs } from "service/api-service";
import debounce from "lodash.debounce";
import ClearIcon from "@mui/icons-material/Clear";
// import ListIcon from '@mui/icons-material/List';
import ListIcon from "@mui/icons-material/FormatListBulleted";

//import whiteMark from 'assets/images/white-mark1.png'
import Filtericon from "assets/images/filter.png";
import searchFormBorderLeft from "assets/images/grunge_frame_left.png";
import searchFormBorderRight from "assets/images/grunge_frame_right.png";
import CalendarImg from "assets/images/details-page/calendar_two.png";
import gemArrow from "assets/images/gem-arrow.png";
import "./styles.scss";
//import PlaceComponent from "../place.component";
import EquipmentsView from "../equipments-view/EquipmentsView"; //components/equipments-view/EquipmentsView
import { Label, MapOutlined, Star, Swipe } from "@mui/icons-material";
import SwiperCarousel from "components/swipercarousel/SwiperCarousel";

import CloseIcon from "@mui/icons-material/Close";
import { Calendar as NewCalendar } from "react-multi-date-picker";
import moment from "moment";
import "../../pages/equipment-detail/calendar.scss";
import NumericInput from "material-ui-numeric-input";
import MapResult from "components/equipments-view/MapView/MapResult";
import emptyheartIcon from "assets/images/favorite-blank.png";
import filledheartIcon from "assets/images/favorite-filled.png";
import MapListResult from "components/equipments-view/MapView/MapListResult";

const CssTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    color: "#2e2e2e",
    background: "#fff",
    fontFamily: "GothamBold !important",
    fontSize: "clamp(12px, 3vw, 15px) !important",
    borderRadius: "10px !important",
    // border: "2px solid #faa61a",
  },
  "& label.Mui-focused": {
    color: "#faa61a",
    fontWeight: "bold",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#2e2e2e",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "2px solid #faa61a",
    },
    "&:hover fieldset": {
      borderColor: "#2e2e2e",
      border: "2px solid #faa61a",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#faa61a",
    },
  },
});

const NewCssTextField = styled(TextField)({
  "& .MuiInput-underline:after": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiInput-underline:before": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiFilledInput-underline:after": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiFilledInput-underline:before": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },

  "& .MuiInputLabel-shrink.MuiInputLabel-filled": {
    color: "#2d2d2d",
  },
  "& .MuiInputBase-root": {
    color: "#2d2d2d",
    background: "#fff",
    fontFamily: "Gotham !important",
    fontSize: "clamp(12px, 3vw, 15px) !important",
    borderRadius: "10px !important",
    // border: "2px solid #faa61a",
  },
  "&. MuiInputLabel-shrink": {
    color: "red !important",
  },

  "&. MuiInputLabel-shrink.Mui-focused": {
    color: "red !important",
  },

  "& label.Mui-focused": {
    color: "#2d2d2d !important",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "2px solid #faa61a",
      backgroundColor: "#fff !important",
    },
    "&:hover fieldset": {
      borderColor: "#2d2d2d",
      border: "2px solid #faa61a",
      backgroundColor: "#fff !important",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#faa61a",
    },
  },
  "& .MuiInputBase-root-MuiFilledInput-root:before": {
    borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
  },
});

const NewCssNumericInput = styled(NumericInput)({
  "& .MuiInput-underline:after": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiInput-underline:before": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiFilledInput-underline:after": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiFilledInput-underline:before": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },

  "& .MuiInputBase-root": {
    color: "#2d2d2d",
    background: "#fff",
    fontFamily: "Gotham !important",
    fontSize: "clamp(12px, 3vw, 15px) !important",
    borderRadius: "10px !important",
    // border: "2px solid #faa61a",
  },
  "& label.Mui-focused": {
    color: "#2d2d2d",
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "2px solid #faa61a",
    },
    "&:hover fieldset": {
      borderColor: "#2d2d2d",
      border: "2px solid #faa61a",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#faa61a",
    },
  },
});

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "rgb(46 46 46)", //background.paper
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const radioStyles = {
  // Base styling for the outer ring
  "& .MuiSvgIcon-root": {
    fontSize: "1.5rem", // Adjust size as needed
    color: "#fff", // White ring color when unchecked
  },
  "&.Mui-checked .MuiSvgIcon-root": {
    color: "#fff", // Ensures outer ring stays white when checked
  },
};

var _lat = "";
var _long = "";

var _currentlat = "";
var _currentlng = "";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const searchInputProp = {
  disableUnderline: true,
  style: {
    border: "3px solid #faa61a",
    borderRadius: "3px",
    background: "white",
    height: "45px",
  },
};

const inputLabelProp = {
  style: {
    color: "#7f8084",
  },
};
interface defaultLocation {
  lat: number;
  lng: number;
}

const SearchSection = () => {
  const dispatch = useDispatch();
  const [showResult, setShowResult] = useState(false);
  const matchesMobileX = useMediaQuery("(max-width:425px)");

  const [minPrice, setMinPrice] = useState<number>(0);
  const [maxPrice, setMaxPrice] = useState<number>(0);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [currentlat, setCurrentlat] = useState(null);
  const [currentlng, setCurrentlng] = useState(null);
  const [locations, setLocations] = useState<Array<string>>([]);

  const inRef = useRef(null);
  const [page, setPage] = React.useState(0);
  const [pagelimit, setPageLimit] = React.useState(15);
  const [loadmore, setLoadmore] = React.useState(true);
  // const [centerLocation, setCenterLocation] = useState<defaultLocation | null>(
  //   null
  // );
  const [fitbound, setFitBound] = useState<string>("");
  const AuthUserId = useSelector(
    (state: IStoreValue) => state.equipmentReducer.Authuser
  );

  const currentPage = useSelector(
    (state: IStoreValue) => state.equipmentReducer.currentPage
  );
  const equipments = useSelector(
    (state: IStoreValue) => state.equipmentReducer.equipments
  );
  const mapEquipments = useSelector(
    (state: IStoreValue) => state.equipmentReducer.mapEquipments
  );
  const pagecount = useSelector(
    (state: IStoreValue) => state.equipmentReducer.count
  );
  const isLoggedin = useSelector(
    (state: IStoreValue) => state.equipmentReducer.isLogin
  );
  const AuthToken = useSelector(
    (state: IStoreValue) => state.equipmentReducer.accessToken
  );
  const boundingBox = useSelector(
    (state: IStoreValue) => state.equipmentReducer.boundingBox
  );
  // console.log("equipments",equipments)
  // by GD 24 Jan 2024 for remember equiplist type
  // const equipListType = useSelector((state: IStoreValue) => state.equipmentReducer.equipListType);
  // console.log("equipListType in header: " , equipListType);

  // by GD 4 march 2024 for filters working from howItWorks page
  const equipFilters = useSelector(
    (state: IStoreValue) => state.equipmentReducer.equipFilters
  );
  const fromHowItWorks = useSelector(
    (state: IStoreValue) => state.equipmentReducer.fromHowItWorks
  );
  const seeResultRef = useRef(null);
  const seeResult2Ref = useRef(null);
  // GD end

  const [Keyword, setKeyword] = useState("");
  const [searchApplied, setSearchApplied] = useState(false);
  // Filter Popup states
  const [FilterPopUp, setFilterPopUp] = useState(false);
  const [State, setState] = useState("");
  const [StateLabel, setStateLabel] = useState("");
  const [City, setCity] = useState("");
  const [ZipCode, setZipCode] = useState("");
  const [locationRange, setLocationRange] = useState("");
  const [category, setCategory] = useState("");

  const [StateList, setStateList] = useState([]);
  const [StateListLoading, setStateListLoading] = useState(false);
  const [CityList, setCityList] = useState([]);
  const [CityListLoading, setCityListLoading] = useState(false);

  const [CalendarPopup, setCalendarPopup] = useState(false);
  const [values, setValues] = useState([]);
  const [StartDate, setStartDate] = useState("");
  const [EndDate, setEndDate] = useState("");

  const [FilterType, setFilterType] = useState("");
  const [FilterTagReset, setFilterTagReset] = useState("");
  const [FilterTagData, setFilterTagData] = useState({
    State: "",
    City: "",
    ZipCode: "",
    StartDate: "",
    EndDate: "",
    category: "",
    locationRange: "",
  });
  const [showMapData, setShowMapData] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const pageNo = useRef<number>(0);
  const callApi = useRef<boolean>(true);
  const clearFilter = useRef<boolean>(false);
  const [memoizedEquipments, setMemoizedEquipments] = useState<
    Array<IEquipment>
  >([]);
  const [paginationLoad, setPaginationLoad] = useState<boolean>(false);
  const [activeMarker, setActiveMarker] = useState<Array<number>>([]);
  const likedClicked = useRef<boolean>(false);

  // Location range disable attribute
  const [RangeValidate, setRangeValidate] = useState(true);
  const [SearchResults, setSearchResults] = useState(false);

  // by GD to 24 Jan 2024 to remember the filter selected by user
  // used local storage due to redux not working . because on every link the site is reloading thus redux states set to default
  // let equipFilterType = localStorage.getItem("equipFilterType");
  // equipFilterType =
  //   equipFilterType === "rent" || !equipFilterType
  //     ? "monthly"
  //     : equipFilterType;

  const [ListEquipType, setListEquipType] = useState("daily");
  //end by GD 24 Jan 2024

  // const [ListEquipType, setListEquipType] = useState('rent')
  const [APIStatus, setAPIStatus] = useState(false);
  const [Loadtype, setLoadtype] = useState("default");

  // by GD 15Dec for sale and radio buttons disable enable on api loading
  const [radioDisabled, setRadioDisabled] = useState(true);
  // end by GD

  const listViewRef = useRef(null); // Reference to the last row
  const mapBoxRef = useRef(null); // Reference to the button box
  const isEquipLiked = useRef<{
    id: number;
    likeStatus: string;
    status: boolean;
  } | null>(null);
  // const radioFilterRef = useRef<string | null>(equipFilterType);

  /**
   * Function: searchEquipments
   * Description:
   * Handles the search functionality for equipment based on the provided parameters.
   * Retrieves equipment data from the server based on the specified search criteria.
   * Updates the global state with the fetched equipment data and count.
   *
   * @param {number} limit - The maximum number of results to fetch.
   * @param {string|null} cat - The category to filter the search results (null for all categories).
   * @param {number|null} lati - The latitude for location-based filtering (null for no location filter).
   * @param {number|null} lngi - The longitude for location-based filtering (null for no location filter).
   * @param {number} page_number - The page number for paginated results.
   */
  // call on simple filter

  /**
   * Function: EquipFilter
   * Description:
   * Handles the filtering of equipment based on the provided filter criteria.
   * Retrieves equipment data from the server based on the specified filter parameters.
   * Updates the global state with the fetched equipment data and count.
   *
   * @param {Object} ObjData - The object containing filter parameters.
   *                           (Assuming ObjData includes the necessary properties for filtering)
   */

  const categories = useSelector(
    (state: IStoreValue) => state.equipmentReducer.categories
  );

  /**
   * Function: searchReset
   * Description:
   * Resets the search parameters and initiates a new search for equipment based on default criteria.
   * Clears the search input, location details, category, price range, and location range.
   * Handles both successful and unsuccessful scenarios, updating the global state accordingly.
   *
   * @param {number} limit - The maximum number of equipment items to retrieve (optional).
   */
  /**
   * useEffect Hook:
   * Trigger Search on Page Change
   * Description:
   * This useEffect hook is triggered whenever the 'page' state changes.
   * It checks if the page is not zero and, if true, sets 'loadmore' to true and initiates a search
   * using the function with the specified 'FilterType'.
   */

  /**
   * useEffect Hook:
   * Trigger Search on Filter Tag Reset
   * Description:
   * This useEffect hook is triggered whenever the 'FilterTagReset' state changes.
   * If 'FilterTagReset' is not an empty string, it initiates a search using the  function.
   * It considers the current filter type ('FilterType') and resets it if certain location-related filters (State, City, ZipCode, locationRange)
   * and date-related filters (StartDate, EndDate) are empty, ensuring an appropriate search is performed.
   * Additionally, it sets 'loadmore' to true and clears the existing equipments by dispatching 'setEquipments([])'.
   */
  useEffect(() => {
    if (FilterTagReset != "") {
      // console.log("filter reset useEffect running");
      let typee = FilterType;
      if (
        State == "" &&
        City == "" &&
        ZipCode == "" &&
        locationRange == "" &&
        StartDate == "" &&
        EndDate == ""
      ) {
        typee = "";
      }
      // startDate: StartDate,
      // endDate: EndDate,
      setLoadmore(true);
      dispatch(setEquipments([]));
      searchFilter(typee, "", "", 0);
    }
  }, [FilterTagReset]);

  /**
   * useEffect Hook:
   * Fetch Categories on Component Mount
   * Description:
   * This useEffect hook is triggered on component mount.
   * It checks if the 'categories' state is empty. If true, it initiates an asynchronous function 'getCategories'
   * to fetch categories using the 'getAllCategories' API from EquipmentAPIs.
   * Once the categories are fetched, it dispatches 'setCategories' to update the state with the retrieved categories.
   * The loading state is managed using 'setLoadingEquipments' to indicate whether the categories are being loaded.
   */
  useEffect(() => {
    const getCategories = async (): Promise<void> => {
      try {
        dispatch(setLoadingEquipments(true));
        const categoriesRes: IApiResponseRawData =
          await EquipmentAPIs.getAllCategories();
        const filteredCategory = categoriesRes?.data?.rows?.filter(
          (cat: string) => cat?.toLowerCase() !== "private"
        );
        dispatch(setCategories(filteredCategory as Array<string>));
        // dispatch(setCategories(categoriesRes.data.rows as Array<string>))
        dispatch(setLoadingEquipments(false));
      } catch (err) {
        console.log("Get Categories Error : ", err);
      }
    };
    if (categories.length === 0) {
      getCategories();
    }
  }, [categories.length, dispatch]);

  /**
   * useEffect Hook:
   * Initialize Component and Fetch Initial Data
   * Description:
   * This useEffect hook is triggered on component mount.
   * It performs the following tasks:
   * 1. Fetches states using the 'getStates' function.
   * 2. Fetches city lists using the 'getAllCityList' function.
   * 3. Dispatches 'setEquipments([])' to clear the existing equipment data.
   * 4. Initiates a timeout to wait for 500 milliseconds before executing the next steps.
   * 5. Resets 'FilterType' to an empty string.
   * 6. Calls the '_getlocation' function to fetch the user's current location.
   * 7. Scrolls the window to the top.
   * 8. Returns a cleanup function to clear the timeout when the component is unmounted.
   */
  const timeoutRef = useRef(null);
  useEffect(() => {
    dispatch(setEquipments([]));

    // Create a ref to track the debounce timeout

    // Clear the previous timeout if useEffect runs again
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // Set a timeout to delay execution
    timeoutRef.current = setTimeout(() => {
      searchFilter(""); // Runs once after changes settle
      setFilterType(""); // Runs at the end
    }, 500);

    // Cleanup function to clear timeout
    return () => {
      dispatch(
        setBoundingBox({
          maxLat: null,
          maxLng: null,
          minLat: null,
          minLng: null,
        })
      );
      clearTimeout(timeoutRef.current);
    };
  }, [AuthUserId]);

  // set Category value on category select
  const onCategoryChange = useCallback((event) => {
    let val = event.target.value;
    //setCity('')
    if (val != "") {
      setPage(0);
      setCategory(val);
    } else {
      setPage(0);
      setCategory("");
    }
  }, []);

  const HandleKeyword = useCallback(
    (e) => {
      setKeyword(e.target.value);
    },
    [Keyword]
  );

  const handleKeyUp = (event) => {
    if (
      (event.key === "Backspace" || event.key === "Delete") &&
      searchApplied &&
      event.target.value === ""
    ) {
      setPage(0);
      searchFilter("keywordEmpty", "", "", 0);
      setSearchApplied(false);
    }
    if (event.key === "Enter" && event.target.value?.length >= 1) {
      setPage(0);
      dispatch(setEquipments([]));
      setLoadmore(true);
      setFilterType("");
      searchFilter("Filter", "", "", 0);
      setSearchApplied(true);
    }
  };
  const ResetKeyword = () => {
    setKeyword("");
    if (searchApplied) {
      setPage(0);
      searchFilter("keywordEmpty", "", "", 0);
    }
    setSearchApplied(false);
  };

  // for handling zipCode field
  const HandlePostalCode = (e) => {
    var val = e.target.value;
    val = val.replace(/[^0-9.]/g, "");
    setZipCode(val);
    //set location range disable if zip code not available
    setRangeValidate(true);
    if (val.length > 0) {
      setRangeValidate(false);
    }
    if (val.length == 0) {
      setLocationRange("");
    } else if (locationRange === "" || locationRange === null) {
      setLocationRange("25");
    }
  };

  const ResetZipCode = useCallback((e) => {
    setZipCode("");

    // Handle location range field
    setRangeValidate(true);
    setLocationRange("");
  }, []);

  /**
   * HandleReset Function:
   * Reset Search and Filter Settings
   *
   * Description:
   * This function is triggered when resetting the search and filter settings.
   * It performs the following tasks:
   * 1. Resets the search results count to false.
   * 2. Dispatches 'setEquipments([])' to clear existing equipment data.
   * 3. Resets min and max price, location range, and page number.
   * 4. Resets filter tag data, keyword, state, city, zip code, category, and date values.
   * 5. Dispatches 'setFilterTagReset('')' to reset the filter tag.
   * 6. Calls 'getAllCityList()' to fetch the city list.
   * 7. Closes the filter popup.
   * 8. Resets filter type to an empty string.
   * 9. Sets 'loadmore' to true.
   * 10. Calls '_getlocation()' to fetch the user's current location.
   */
  const HandleReset = useCallback(
    (e) => {
      setSearchResults(false); // reset result count
      dispatch(setEquipments([]));
      setMinPrice(0);
      setMaxPrice(0);
      setLocationRange("");
      setPage(0);
      //searchEquipments(0, null, _lat, _long)

      setFilterTagData({
        State: "",
        City: "",
        ZipCode: "",
        StartDate: "",
        EndDate: "",
        category: "",
        locationRange: "",
      });

      // setKeyword('');
      setState("");
      setStateLabel("");
      setCity("");
      setZipCode("");
      setCategory("");
      setLocationRange("");
      setValues([]);
      setStartDate("");
      setEndDate("");

      setFilterTagReset("");

      getAllCityList();
      setFilterPopUp(false); // close filter popup
      setFilterType("");
      //if (FilterType == '') {
      setLoadmore(true);
      // _getlocation()
      searchFilter("Filter", "", "", 0);
    },
    // [Keyword, ListEquipType]
    [Keyword]
  );

  const HandleFilterPopUp = useCallback(() => {
    setFilterPopUp(true);
    // setKeyword('')
    //setFilterType('Filter')
    // getStates()
    // getAllCityList()
  }, []);

  // Get all City List
  const getAllCityList = async () => {
    var requestOptions = {
      method: "GET",
      //redirect: "follow",
      headers: { "Content-Type": "application/json" },
      //body: JSON.stringify(''),
    };

    try {
      setCityListLoading(true);
      await fetch(
        "https://countriesnow.space/api/v0.1/countries",
        requestOptions
      ).then((response) => {
        response.json().then((result) => {
          let arr = [...result.data];
          let city_options = [];
          arr.map((item) => {
            if (item.country == "United States") {
              let city_options = item.cities;
              // city_arr.map((cityitem) => {
              //   city_options.push({ label: cityitem, id: cityitem });
              // });
              //console.log('city_options', city_options);
              setCityList(city_options);
              setCityListLoading(false);
            }
          });
        });
      });
    } catch (error) {
      console.error(error);
    }
  };
  // Handle calendar popup
  const HandleCalendarPopup = async () => {
    setCalendarPopup(true);
  };

  // reset the calendar
  const resetCalendar = () => {
    let a = ["", ""];
    setValues(a);
    setStartDate("");
    setEndDate("");
  };

  // handle dates in calendar popup
  const HandleCalendarDates = () => {
    if (!EndDate && !StartDate) {
      dispatch(setalertMessage("Please select both start & end date."));
      dispatch(setalertMessagetype("error"));
      return false;
    }
    setCalendarPopup(false);
    // setStartDate(start)
    // setEndDate(end)
  };

  /** commenting by GD
   * Function: HandlepopupFilter
   * Description:
   * Handles the equipment filter popup. If filters are provided (State, City, ZipCode, StartDate, EndDate, or locationRange),
   * it sets the filter tag data, triggers a search with the specified filters, and closes the filter popup.
   * If no filters are provided, it displays an error message.
   */
  // Handle popup filter
  const HandlepopupFilter = async () => {
    try {
      // console.log("HandlepopupFilter locationRange :" , locationRange);
      if (
        State != "" ||
        City != "" ||
        ZipCode != "" ||
        StartDate != "" ||
        EndDate != "" ||
        locationRange != "" ||
        category != ""
      ) {
        setFilterTagData({
          State: State,
          City: City,
          ZipCode: ZipCode,
          StartDate: StartDate,
          EndDate: EndDate,
          category: category,
          locationRange:
            locationRange == null || locationRange == "0" ? "" : locationRange,
        });
        setSearchResults(true);
        // setPage(0);
        dispatch(setEquipments([]));
        dispatch(setCount(0));
        setFilterPopUp(false);
        setFilterType("Filter");
        setLoadmore(true);

        searchFilter("Filter", "", "", 0);
        setPage(0);
      } else {
        // console.log("in else ");
        // dispatch(setalertMessage('Please enter a zipcode.'));
        dispatch(setalertMessage("Please select a filter."));
        dispatch(setalertMessagetype("error"));
        return false;
      }
    } catch (error) {
      console.error(error);
    }
  };

  //Handle reset filter
  const HandleResetFilters = async (resetfield = null) => {
    setFilterTagReset("");
    setSearchResults(false);
    clearFilter.current = true;
    if (resetfield == "reset_state") {
      setFilterTagReset(resetfield);
      setState("");
      setStateLabel("");
      setFilterTagData({
        State: "",
        City: City,
        ZipCode: ZipCode,
        StartDate: StartDate,
        EndDate: EndDate,
        category: category,
        locationRange: locationRange == null ? "" : locationRange,
      });
    }

    if (resetfield == "reset_city") {
      setFilterTagReset(resetfield);
      setCity("");
      setFilterTagData({
        State: State,
        City: "",
        ZipCode: ZipCode,
        StartDate: StartDate,
        EndDate: EndDate,
        category: category,
        locationRange: locationRange == null ? "" : locationRange,
      });
    }

    if (resetfield == "reset_zipcode") {
      setFilterTagReset(resetfield);
      setZipCode("");
      setFilterTagData({
        State: State,
        City: City,
        ZipCode: "",
        StartDate: StartDate,
        EndDate: EndDate,
        category: category,
        locationRange: locationRange == null ? "" : locationRange,
      });
    }

    if (resetfield == "reset_locationrange") {
      setFilterTagReset(resetfield);
      setLocationRange("");
      setFilterTagData({
        State: State,
        City: City,
        ZipCode: ZipCode,
        StartDate: StartDate,
        EndDate: EndDate,
        category: category,
        locationRange: "",
      });
    }

    if (resetfield == "reset_category") {
      setFilterTagReset(resetfield);
      setCategory("");
      setFilterTagData({
        State: State,
        City: City,
        ZipCode: ZipCode,
        StartDate: StartDate,
        EndDate: EndDate,
        category: "",
        locationRange: locationRange == null ? "" : locationRange,
      });
    }

    if (resetfield == "reset_dates") {
      setFilterTagReset(resetfield);
      setStartDate("");
      setEndDate("");
      setValues([]);

      setFilterTagData({
        State: State,
        City: City,
        ZipCode: ZipCode,
        StartDate: "",
        EndDate: "",
        category: category,
        locationRange: locationRange == null ? "" : locationRange,
      });
    }
  };

  // function getLocation() {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(showPosition);
  //   }
  // }

  // function showPosition(position) {
  //   const lat = position?.coords?.latitude;
  //   const lng = position?.coords?.longitude;
  //   if (lat && lng) {
  //     setLat(lat);
  //     setLng(lng);
  //     setCenterLocation({ lat, lng });
  //   }
  // }

  /** commenting by GD
   * Function: search-Filter
   * Description:
   * Performs a search with the provided filters and updates the equipment list.
   *
   * @param {string} filter - The filter for the search.
   * @param {number} defLat - Default latitude if not provided.
   * @param {number} defLng - Default longitude if not provided.
   * @param {number} pagenumber - The page number for pagination.
   */
  // const search-Filter = async (filter = '', defLat = null, defLng = null, pagenumber = page) => {
  const searchFilter = async (
    filter = "",
    defLat = null,
    defLng = null,
    pagenumber = page,
    cat = category
  ) => {
    try {
      // by GD to enable disable radio buttons
      const type =
        ZipCode || StartDate || EndDate || locationRange || category || Keyword
          ? "Filter"
          : "";
      if (clearFilter.current && type !== "Filter") {
        setFitBound("");
      } else {
        setFitBound("apply");
      }
      setRadioDisabled(true);
      setAPIStatus(true);
      let body = {
        search: filter === "keywordEmpty" ? "" : Keyword,
        type: filter,
        ZipCode: ZipCode,
        startDate: StartDate,
        endDate: EndDate,
        category: cat,
        range_miles:
          ZipCode && Number(locationRange) ? Number(locationRange) : 0,
        page: pagenumber,
        user_id: AuthUserId,
        // equip_list_type: ListEquipType === "sale" ? "sale" : "rent",
        boundingBox: type === "Filter" ? null : { ...boundingBox },
        equip_list_type: "rent",
        requestFrom: "web",
        lat: null,
        lng: null,
        viewType: "List",
      };
      if (pagenumber == 0) {
        dispatch(setEquipments([] as Array<IEquipment>));
      }
      const FilterData = await EquipmentAPIs.searchFilter(body);

      setLoadtype("Loaded");
      setLoadmore(false);
      if (FilterData["type"] == "RXSUCCESS") {
        pageNo.current = 0;
        if (pagenumber == 0) {
          dispatch(setEquipments([] as Array<IEquipment>));
        }
        // setMemoizedEquipments(FilterData.listData);
        setMemoizedEquipments(FilterData.data);
        dispatch(setMapEquipments(FilterData.data));
        setFilterTagReset("");
        dispatch(setEquipments(FilterData.listData));
        dispatch(setCount(FilterData.total));
        setAPIStatus(false);
        setRadioDisabled(false); // enable radio buttons
        setTimeout(() => {
          callApi.current = true;
        }, 100);
      } else {
        setRadioDisabled(false); // enable radio buttons
        dispatch(setLoadingEquipments(false));
        dispatch(setCount(0));
        setAPIStatus(false);
      }
    } catch (err) {
      console.log(err);
      dispatch(setLoadingEquipments(false));
      setRadioDisabled(false);
    } finally {
      clearFilter.current = false;
      // if (mapBoxRef.current) {
      //   setTimeout(() => {
      //     mapBoxRef.current.style.pointerEvents = "auto";
      //   }, 0);
      // }
    }
  };
  // end by GD

  const [visible, setVisible] = useState(true);

  const searchRef = useRef(null);
  // end by GD 24 Jan 2024 search hide and show

  const handleToggleMapView = () => {
    setShowMapData((prev) => !prev);
  };

  const handleElementLike = (
    element: HTMLImageElement,
    likeStatus: string,
    listOfClasses: unknown[]
  ) => {
    if (likeStatus === "0") {
      element.src = emptyheartIcon;
      element.classList.remove("liked");
    } else if (!listOfClasses?.includes("liked")) {
      element.classList.add("liked");
      element.src = filledheartIcon;
    }
  };

  const handleLikeEquipments = useCallback(
    async ({
      element,
      equipId,
      likeType,
      listOfClasses,
    }: {
      element: HTMLImageElement;
      equipId: number;
      likeType: string;
      listOfClasses: unknown[];
    }) => {
      try {
        const AuthUser = localStorage.getItem("token-info");
        // console.log("AuthUser", AuthUser);
        // if (!isLoggedin) {
        //   dispatch(setLoginModalOpen(true));
        //   return false;
        // }
        if (!AuthUser) {
          dispatch(setLoginModalOpen(true));
          return false;
        }
        const parsedData = JSON.parse(AuthUser);
        const authUserId = parsedData["user_id"];
        const authToken = parsedData["auth_token"];
        const likeStatus = listOfClasses?.includes("liked") ? "0" : "1";
        handleElementLike(element, likeStatus, listOfClasses);
        // Make the API call to AddToLikeDislike
        const headers = { Authorization: `${authToken}`, request_from: "Web" };
        const response = await EquipmentAPIs.AddToLikeDislike(
          {
            user_id: authUserId.toString(),
            equip_id: equipId.toString(),
            status: likeStatus,
            liked_equip_list_type: likeType,
          },
          headers
        );

        // Check the type of response received
        if (response["type"] == "RXSUCCESS") {
          likedClicked.current = true;
          const modifiedEquip = equipments.map((equip) => {
            if (equip.id === equipId) {
              return { ...equip, like_status: likeStatus };
            }
            return { ...equip };
          });
          const modifiedMapEquip = mapEquipments.map((equip) => {
            if (equip.id === equipId) {
              return { ...equip, like_status: likeStatus };
            }
            return { ...equip };
          });
          // console.log("modifiedEquip", modifiedEquip);
          // setFilterEquipment(modifiedEquip);
          isEquipLiked.current = { id: equipId, likeStatus, status: true };
          dispatch(setEquipments([] as Array<IEquipment>));
          dispatch(setEquipments(modifiedEquip as Array<IEquipment>));
          // setMemoizedEquipments(modifiedEquip);
          setMemoizedEquipments(modifiedMapEquip);
          // modifiedMapEquip
          dispatch(setMapEquipments(modifiedMapEquip));
          // dispatch(setCount(modifiedEquip?.length));
        } else if (
          response["status"] == false &&
          response["slug"] == "Session Expired"
        ) {
          const likeStatus = listOfClasses?.includes("liked") ? "1" : "0";
          handleElementLike(element, likeStatus, listOfClasses);
          dispatch(setSessionExpire(true));
        } else {
          throw new Error(response["message"]);
        }
      } catch (err) {
        const likeStatus = listOfClasses?.includes("liked") ? "1" : "0";
        handleElementLike(element, likeStatus, listOfClasses);
        dispatch(setalertMessage(err.message));
        dispatch(setalertMessagetype("error"));
      }
    },
    [equipments, dispatch, mapEquipments]
  );

  const stateRef = useRef({
    ZipCode: null,
    StartDate: null,
    EndDate: null,
    locationRange: null,
    category: null,
    Keyword: null,
  });

  const fetchData = useCallback(
    async ({
      radius = 0,
      maxLat = null,
      minLat = null,
      maxLng = null,
      minLng = null,
      lat = null,
      lng = null,
      page = 0,
      zoomLevel = 3,
    }) => {
      try {
        if (fitbound === "apply") {
          setFitBound("");
        }
        const {
          ZipCode,
          StartDate,
          EndDate,
          locationRange,
          category,
          Keyword,
        } = stateRef.current;
        const type =
          ZipCode ||
          StartDate ||
          EndDate ||
          locationRange ||
          category ||
          Keyword
            ? "Filter"
            : "";
        if (page === 0) {
          setPaginationLoad(true);
          setLoading(true);
        }
        const boundingBox = { maxLat, minLat, maxLng, minLng };
        const FilterData = await EquipmentAPIs.searchFilter({
          user_id: AuthUserId,
          lng,
          lat,
          range_miles: ZipCode ? locationRange : radius,
          type,
          search: Keyword,
          equip_list_type: "rent",
          category,
          ZipCode,
          startDate: StartDate,
          endDate: EndDate,
          boundingBox: { ...boundingBox },
          zoom_level: zoomLevel,
          requestFrom: "web",
          viewType: "List",
          page,
        });

        if (FilterData["type"] === "RXSUCCESS") {
          const totalResult = FilterData?.total || 0;
          const allData = FilterData?.data || [];
          // const listData =
          //   (totalResult < 60 ? FilterData?.data : FilterData?.listData) || [];
          if (page === 0) {
            dispatch(setMapEquipments(allData));
            dispatch(setEquipments([] as Array<IEquipment>));
            dispatch(setCount(totalResult));
            dispatch(setBoundingBox(boundingBox));
            pageNo.current = 0;
            // setMemoizedEquipments(FilterData?.listData);
            setMemoizedEquipments(allData);
          }
          dispatch(setEquipments(FilterData?.listData));
          setTimeout(() => {
            callApi.current = true;
          }, 100);
        }
      } catch (err) {
        console.log("map result error", err);
      } finally {
        setLoading(false);
        setPaginationLoad(false);
      }
    },
    [AuthUserId, fitbound, dispatch]
  );

  const fetchPaginationData = useCallback(
    async ({
      radius = 0,
      maxLat = null,
      minLat = null,
      maxLng = null,
      minLng = null,
      lat = null,
      lng = null,
      page = 0,
    }) => {
      try {
        const {
          ZipCode,
          StartDate,
          EndDate,
          locationRange,
          category,
          Keyword,
        } = stateRef.current;
        const type =
          ZipCode ||
          StartDate ||
          EndDate ||
          locationRange ||
          category ||
          Keyword
            ? "Filter"
            : "";
        setPaginationLoad(true);
        const boundingBox = { maxLat, minLat, maxLng, minLng };
        const FilterData = await EquipmentAPIs.searchFilter({
          user_id: AuthUserId,
          lng,
          lat,
          range_miles: ZipCode ? locationRange : radius,
          type,
          search: Keyword,
          equip_list_type: "rent",
          category,
          ZipCode,
          startDate: StartDate,
          endDate: EndDate,
          boundingBox: { ...boundingBox },
          zoom_level: null,
          requestFrom: "web",
          viewType: "List",
          page,
        });

        if (FilterData["type"] === "RXSUCCESS") {
          setMemoizedEquipments(FilterData?.listData);
        }
      } catch (err) {
        console.log("map result error", err);
      } finally {
        setPaginationLoad(false);
      }
    },
    [AuthUserId]
  );

  useEffect(() => {
    stateRef.current = {
      ZipCode,
      StartDate,
      EndDate,
      locationRange,
      category,
      Keyword,
    };
  }, [ZipCode, StartDate, EndDate, locationRange, category, Keyword]);

  /**
   * useEffect Dependency Update:
   * Effect to Handle List Equipment Type Changes
   * Description:
   * This effect is triggered when there is a change in the 'ListEquipType' variable.
   * If 'Loadtype' is not 'default' and 'ListEquipType' is defined and not empty, it sets
   * the page to 0, clears the existing equipment list, and initiates a search with the
   * 'Filter' type and page 0.
   */
  // useEffect(() => {
  //   if (Loadtype != "default" && ListEquipType) {
  //     if (ListEquipType === "sale" || radioFilterRef.current === "sale") {
  //       setPage(0);
  //       dispatch(setEquipments([] as Array<IEquipment>));
  //       searchFilter("Filter", lat, lng, 0);
  //     }
  //     radioFilterRef.current = ListEquipType;
  //   }
  // }, [ListEquipType]);

  // by GD 4 march 2024 for filters working from howItWorks page with local storage
  useEffect(() => {
    // getLocation();
    const fromFindPageLocal = localStorage.getItem("fromFindPageLocal");
    // console.log("fromFindPageLocal: " , fromFindPageLocal);

    if (fromFindPageLocal && fromFindPageLocal == "yes") {
      console.log("firstCheck from local storage works");
      setRadioDisabled(true);
      setAPIStatus(true);
      const equipFiltersObjSt = localStorage.getItem("equipFiltersObj");
      if (equipFiltersObjSt) {
        console.log("how it works if fromHowItWorks");
        console.log("equipFiltersObj: ", JSON.parse(equipFiltersObjSt));
        const equipFiltersObj = JSON.parse(equipFiltersObjSt);

        const locRange = equipFiltersObj["locationRange"] || "";
        const zipCd = equipFiltersObj["zipCode"];
        const sDate = equipFiltersObj["startDate"];
        const eDate = equipFiltersObj["endDate"];
        const cat = equipFiltersObj["category"];
        console.log("cat: ", cat);

        setCategory(cat);
        setLocationRange(locRange);
        setStartDate(sDate);
        setEndDate(eDate);
        setZipCode(zipCd);

        setFilterTagData({
          State: State,
          City: City,
          ZipCode: zipCd,
          StartDate: sDate,
          EndDate: eDate,
          category: cat,
          locationRange: locRange == "0" ? "" : locRange,
        });

        setTimeout(() => {
          localStorage.setItem("fromFindPageLocal", "");
        }, 4000);

        if (
          equipFiltersObj["zipCode"] != "" ||
          equipFiltersObj["startDate"] != "" ||
          equipFiltersObj["endDate"] != "" ||
          equipFiltersObj["locationRange"] != ""
        ) {
          console.log("in search filters if");
          setTimeout(() => {
            seeResult2Ref.current.click();
          }, 2000);
          return;
        } else {
          console.log("in search filters else");

          setTimeout(() => {
            setSearchResults(true);
            setFilterType("");
            setLoadmore(true);
            searchFilter("", "", "", 0, cat);
          }, 2000);
        }
      } else {
        console.log("how it works fromHowItWorks is false");
      }
    }
  }, []);
  // by GD 4 march 2024
  useEffect(() => {
    // Create an observer to monitor `.section-container`
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const isVisible = entries.some((entry) => entry.isIntersecting);
          if (mapBoxRef?.current) {
            if (entry.boundingClientRect.top < 0) {
              // Below the .section-container: hide mapBoxRef
              mapBoxRef.current.style.display = "none";
            } else {
              mapBoxRef.current.style.display = isVisible ? "none" : "flex";
            }
          }
        });
      },
      {
        root: null, // Use the viewport as the root
        threshold: 0, // Trigger when any part of the element enters/exits the viewport
      }
    );

    // Select the `.section-container` elements
    // const sectionContainers = document.querySelectorAll(".section-container");
    const sectionContainers = document.querySelectorAll(
      ".section-content-wrapper"
    );

    // Observe each `.section-container`
    sectionContainers.forEach((section) => observer.observe(section));
    window.scrollTo({ top: 0, behavior: "smooth" });

    // Cleanup observer on component unmount
    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    const footer = document.querySelector(".new-footer") as HTMLElement | null;
    const section = document.querySelector(
      ".section-container"
    ) as HTMLElement | null;
    if (showMapData) {
      if (footer) footer.style.display = "none";
      if (section) section.style.display = "none";
      dispatch(setToggleFooterSwipper(false));
      window.scrollTo({ top: 360, behavior: "smooth" });
    } else {
      if (equipments.length === 0) {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
      if (footer) footer.style.display = "block";
      if (section) section.style.display = "block";
      dispatch(setToggleFooterSwipper(true));
    }
  }, [showMapData]);

  // by GD Slider Value-----------------------------------//
  const handleSliderChange = (event: Event, newValue) => {
    setLocationRange(newValue as string);
  };
  return (
    <>
      <Box className="carousel-container">
        <SwiperCarousel />
        <Box className="search-form-container">
          <img
            src={searchFormBorderLeft}
            alt="Search Form Border"
            className="img-search-form-border-left"
          />

          <Box className="search-main-container">
            <Box className="search-main-container-div">
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  sx={{
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    gap: "9px",
                    fontSize: "clamp(1.7rem, 3vw, 2.5rem) !important",
                    fontFamily: "GothamBold !important",
                    lineHeight: "normal",
                  }}
                >
                  <span>Find</span>
                  <span className="yellow-text">Equipment</span>
                </Typography>
                <Typography
                  sx={{
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    gap: "9px",
                    fontSize: "clamp(1.7rem, 3vw, 2.5rem) !important",
                    fontFamily: "GothamBold !important",
                    lineHeight: "normal",
                  }}
                >
                  <span>List</span>
                  <span className="yellow-text">Equipment</span>
                </Typography>
                <Typography
                  sx={{
                    color: "#fff",
                    display: "flex",
                    alignItems: "baseline",
                    gap: "9px",
                    fontSize: "clamp(1.7rem, 3vw, 2.5rem) !important",
                    fontFamily: "GothamBold !important",
                    lineHeight: "normal",
                  }}
                >
                  <span>Build</span>
                  <span className="yellow-text">a Crew</span>
                  {/* below line coomented by pp on 16 apr 2024 */}
                  {/* <span style={{ color: '#fff', fontSize: '10px' }}>
                    (Coming Soon)
                  </span> */}
                </Typography>
              </Box>
              <Typography
                className=""
                sx={{
                  color: "#fff",
                  fontSize: "clamp(1rem, 3vw, 1.2rem) !important",
                  lineHeight: "normal",
                  textAlign: "left",
                  mt: "1rem",
                }}
              >
                Discover the power of peer-to-peer rentals, purchases, lending
                and much, much more!
              </Typography>
            </Box>
          </Box>
          <img
            src={searchFormBorderRight}
            alt="Search Form Border"
            className="img-search-form-border-right"
          />
        </Box>
      </Box>
      <Box
        // className={!showMapData ? "pagination-section" : ""}
        sx={{
          display: "flex",
          alignItems: "center",
          // justify-content: flex-end;
          justifyContent: "center",
          margin: `0rem auto ${showMapData ? "0rem" : "3rem"} auto`,
          // width: 65%;
          // margin-bottom: -35px;
          maxWidth: `${!showMapData ? "1200px" : "auto"}`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0rem",
            width: "100%",
          }}
        >
          {/* <Grow in={visible}> */}
          <Slide
            in={visible}
            container={searchRef.current}
            //  timeout={300}
          >
            <Box
              className="search-result"
              // by GD 23 Jan  added ref sx section
              ref={searchRef}
              sx={{
                width: "100%",
                boxSizing: "border-box",
                px: 0,
              }}
            >
              <Container maxWidth="lg">
                <Box
                  sx={{
                    width: "100%",
                    flexDirection: "column",
                    display: "flex",
                    gap: "1rem",
                  }}
                >
                  <Box
                    className="search-main"
                    sx={{
                      paddingLeft: "1rem",
                      paddingRight: "1rem",
                    }}
                  >
                    <Box className="search-div">
                      {/* <Box className='search-grid'> */}
                      <Box className="search-subgrid">
                        <Box className="search-subgrid-inner">
                          <Box
                            className="search-cselect"
                            sx={{
                              position: "relative",
                            }}
                          >
                            {/* above select commented Below line is added by pp on 18 apr 2023 due to search filter based on keyword changes */}
                            <Box className="search-box">
                              <FormControl
                                className="dsfsdf"
                                fullWidth
                                sx={{
                                  borderRadius: "7px",
                                  oultine: "2px solid #faa61a",
                                }}
                              >
                                <TextField
                                  label="Search equipment"
                                  // variant="outlined"
                                  variant="filled"
                                  fullWidth
                                  type={"text"}
                                  InputProps={{ disableUnderline: true }}
                                  // InputProps={searchInputProp}
                                  value={Keyword}
                                  onChange={HandleKeyword}
                                  onKeyUp={handleKeyUp}
                                  sx={{
                                    "& .MuiInputBase-input": {
                                      paddingRight: "50px",
                                    },
                                    backgroundColor: "white",
                                    color: "#2d2d2d",
                                  }}
                                />
                                {Keyword.length >= 1 && (
                                  <span
                                    className="search-cross-icon"
                                    onClick={ResetKeyword}
                                  >
                                    X
                                  </span>
                                )}
                              </FormControl>
                            </Box>
                          </Box>
                          <Box>
                            <img
                              onClick={HandleFilterPopUp}
                              src={Filtericon}
                              alt="filter-icon"
                              className="search-filter-icon"
                              style={{
                                width: "35px",
                                height: "auto",
                                display: "block",
                              }}
                            />
                          </Box>
                        </Box>
                        <Box className="search-btn-grid">
                          <Box className="search-result-btn-div">
                            <Button
                              //disabled={category==''? true:false}
                              ref={seeResultRef}
                              variant="contained"
                              color="success"
                              className="search-result-btn"
                              sx={{
                                height: "50px",
                                paddingLeft: "1rem",
                                paddingRight: "1rem",
                                bgcolor: "#149247",
                                fontFamily: "GothamBold",
                                color: "#fff",
                                width: "100% !important",
                                textTransform: "none",
                                fontSize: "20px",
                                lineHeight: "normal",
                                textAlign: "center",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "7px",
                              }}
                              onClick={() => {
                                if (Keyword?.length >= 1) {
                                  setFilterTagData({
                                    State: State,
                                    City: City,
                                    ZipCode: ZipCode,
                                    StartDate: StartDate,
                                    EndDate: EndDate,
                                    category: category,
                                    locationRange:
                                      locationRange == null
                                        ? ""
                                        : locationRange,
                                  });
                                  setPage(0);
                                  dispatch(
                                    setEquipments([] as Array<IEquipment>)
                                  );
                                  //searchEquipments(pagelimit, null, _lat, _long)
                                  setSearchApplied(true);
                                  if (!loadmore) {
                                    setSearchResults(true);
                                    setFilterType("");
                                    setLoadmore(true);
                                    setPage(0);
                                    searchFilter("Filter", "", "", 0);
                                  }
                                }
                              }}
                            >
                              See Results
                            </Button>
                          </Box>
                          <Box
                          // className="search-radio-btns"
                          >
                            {/* for rent and sales radio buttons */}
                            {/* <FormControl className="">
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                            // onClick={HandleEquipType}
                            className="radio-grp list-eqp-page new-radio-grp"
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "1rem",
                              }}
                            >
                              <FormControlLabel
                                checked={ListEquipType == "rent" ? true : false}
                                // checked={equipListType == 'rent' ? true : false}
                                value={"rent"}
                                control={<Radio disabled={radioDisabled} />}
                                // control={<Radio className='disabled-radio' sx={{ color: 'gray' }} disabled={true} />}
                                onClick={(e) => {
                                  if (radioDisabled) return;
                                  setListEquipType("rent");
                                  localStorage.setItem(
                                    "equipFilterType",
                                    "rent"
                                  );
                                  // dispatch(setEquipListType("rent"));
                                  // HandleEquipType(e)
                                }}
                                label={
                                  <Box>
                                    <Typography sx={{ gap: "8px" }}>
                                      <span
                                        className=""
                                        style={{
                                          fontFamily: "",
                                          color: "#fff !important",
                                        }}
                                      >
                                        For Rent
                                      </span>
                                    </Typography>
                                  </Box>
                                }
                                className="radio-grp"
                              />

                              <FormControlLabel
                                checked={ListEquipType == "sale" ? true : false}
                                // checked={equipListType == 'sale' ? true : false}
                                value={"sale"}
                                control={<Radio disabled={radioDisabled} />}
                                // control={<Radio className='disabled-radio' disabled={true} />}
                                onClick={(e) => {
                                  if (radioDisabled) return;
                                  setListEquipType("sale");
                                  localStorage.setItem(
                                    "equipFilterType",
                                    "sale"
                                  );
                                  // dispatch(setEquipListType("sale"));
                                  // HandleEquipType(e)
                                }}
                                label={
                                  <Box>
                                    <Typography sx={{ gap: "8px" }}>
                                      <span
                                        className=""
                                        style={{
                                          fontFamily: "",
                                          color: "#fff !important",
                                        }}
                                      >
                                        For Sale
                                      </span>
                                    </Typography>
                                  </Box>
                                }
                                className="radio-grp"
                              />
                            </Box>
                          </RadioGroup>
                        </FormControl> */}
                            {showMapData && (
                              <FormControl>
                                <RadioGroup
                                  aria-labelledby="equip-price-filter"
                                  defaultValue="monthly"
                                  name="equip-price-filter-radio-buttons"
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    gap: "5px",
                                  }}
                                  value={ListEquipType}
                                  onChange={(event) => {
                                    const filterType = event.target.value;
                                    if (radioDisabled) return;
                                    setListEquipType(filterType);
                                    // localStorage.setItem(
                                    //   "equipFilterType",
                                    //   filterType
                                    // );
                                  }}
                                >
                                  <FormControlLabel
                                    value="daily"
                                    control={
                                      <Radio
                                        sx={radioStyles}
                                        disabled={radioDisabled}
                                      />
                                    }
                                    label="Daily"
                                    sx={{
                                      "& .MuiFormControlLabel-label": {
                                        color: radioDisabled
                                          ? "gray !important"
                                          : "#fff",
                                      },
                                    }}
                                  />
                                  <FormControlLabel
                                    value="weekly"
                                    control={
                                      <Radio
                                        sx={radioStyles}
                                        disabled={radioDisabled}
                                      />
                                    }
                                    label="Weekly"
                                    sx={{
                                      "& .MuiFormControlLabel-label": {
                                        color: radioDisabled
                                          ? "gray !important"
                                          : "#fff",
                                      },
                                    }}
                                  />
                                  <FormControlLabel
                                    value="monthly"
                                    control={
                                      <Radio
                                        sx={radioStyles}
                                        disabled={radioDisabled}
                                      />
                                    }
                                    label="Monthly"
                                    sx={{
                                      "& .MuiFormControlLabel-label": {
                                        color: radioDisabled
                                          ? "gray !important"
                                          : "#fff",
                                      },
                                    }}
                                  />
                                </RadioGroup>
                              </FormControl>
                            )}
                          </Box>
                        </Box>
                      </Box>
                      {/* </Box> */}
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display:
                        // ZipCode || StartDate || EndDate || locationRange || category
                        Object.values(FilterTagData).some((value) => value)
                          ? "block"
                          : "none",
                    }}
                  >
                    {/* Add filter values */}
                    {/* {FilterType == "Filter" && (                 */}
                    <Box className="filter-tag-grid">
                      {FilterTagData.State && (
                        <Box className="filter-search">
                          <Box className="filter-search-tags">
                            State: {FilterTagData.State}
                            <span
                              className="tag-cross-icon"
                              onClick={() => HandleResetFilters("reset_state")}
                            >
                              X
                            </span>
                          </Box>
                        </Box>
                      )}

                      {FilterTagData.City && (
                        <Box className="filter-search">
                          <Box className="filter-search-tags">
                            City: {FilterTagData.City}
                            <span
                              className="tag-cross-icon"
                              onClick={() => HandleResetFilters("reset_city")}
                            >
                              X
                            </span>
                          </Box>
                        </Box>
                      )}

                      {FilterTagData.ZipCode && (
                        <Box className="filter-search">
                          <Box className="filter-search-tags">
                            ZipCode: {FilterTagData.ZipCode}
                            <span
                              className="tag-cross-icon"
                              onClick={() =>
                                HandleResetFilters("reset_zipcode")
                              }
                            >
                              X
                            </span>
                          </Box>
                        </Box>
                      )}
                      {parseFloat(FilterTagData.locationRange) > 0 && (
                        <Box className="filter-search">
                          <Box className="filter-search-tags">
                            Range : {FilterTagData.locationRange}
                            <span
                              className="tag-cross-icon"
                              onClick={() =>
                                HandleResetFilters("reset_locationrange")
                              }
                            >
                              X
                            </span>
                          </Box>
                        </Box>
                      )}

                      {FilterTagData.category && (
                        <Box className="filter-search">
                          <Box className="filter-search-tags">
                            Category : {FilterTagData.category}
                            <span
                              className="tag-cross-icon"
                              onClick={() =>
                                HandleResetFilters("reset_category")
                              }
                            >
                              X
                            </span>
                          </Box>
                        </Box>
                      )}

                      {FilterTagData.StartDate && FilterTagData.EndDate && (
                        <Box className="filter-search">
                          <Box className="filter-search-tags">
                            Dates : {FilterTagData.StartDate} To{" "}
                            {FilterTagData.EndDate}
                            <span
                              className="tag-cross-icon"
                              onClick={() => HandleResetFilters("reset_dates")}
                            >
                              X
                            </span>
                          </Box>
                        </Box>
                      )}
                    </Box>
                    {/* )} */}
                  </Box>

                  {!showMapData && (
                    <Box sx={{ padding: "0" }}>
                      {!APIStatus &&
                        (FilterTagData.category != "" ||
                          FilterTagData.City != "" ||
                          FilterTagData.State != "" ||
                          FilterTagData.ZipCode != "" ||
                          FilterTagData.locationRange != "" ||
                          FilterTagData.StartDate != "" ||
                          FilterTagData.EndDate != "" ||
                          ListEquipType) && (
                          <Box className="">
                            <Typography
                              sx={{
                                color: "#fff",
                                fontSize: "18px",
                                fontFamily: "GothamBold !important",
                              }}
                            >
                              {`${pagecount} Results`}
                              {FilterTagData.City != "" ||
                              FilterTagData.State != ""
                                ? " in"
                                : ""}
                              {/* {FilterTagData.category != '' ? FilterTagData.category : ''} */}
                              {FilterTagData.City != ""
                                ? " " + FilterTagData.City
                                : ""}
                              {FilterTagData.City != "" &&
                              FilterTagData.State != ""
                                ? ","
                                : ""}
                              {FilterTagData.State != ""
                                ? " " + FilterTagData.State
                                : ""}
                            </Typography>
                          </Box>
                        )}
                    </Box>
                  )}
                </Box>
              </Container>
              {showMapData && (
                <Box sx={{ px: 2 }}>
                  {!APIStatus &&
                    (FilterTagData.category != "" ||
                      FilterTagData.City != "" ||
                      FilterTagData.State != "" ||
                      FilterTagData.ZipCode != "" ||
                      FilterTagData.locationRange != "" ||
                      FilterTagData.StartDate != "" ||
                      FilterTagData.EndDate != "" ||
                      ListEquipType) && (
                      <Box className="">
                        <Typography
                          sx={{
                            color: "#fff",
                            fontSize: "18px",
                            fontFamily: "GothamBold !important",
                          }}
                        >
                          {`${pagecount} Results`}
                          {FilterTagData.City != "" || FilterTagData.State != ""
                            ? " in"
                            : ""}
                          {/* {FilterTagData.category != '' ? FilterTagData.category : ''} */}
                          {FilterTagData.City != ""
                            ? " " + FilterTagData.City
                            : ""}
                          {FilterTagData.City != "" && FilterTagData.State != ""
                            ? ","
                            : ""}
                          {FilterTagData.State != ""
                            ? " " + FilterTagData.State
                            : ""}
                        </Typography>
                      </Box>
                    )}
                </Box>
              )}
            </Box>
            {/* </Grow> */}
          </Slide>
          <Box>
            {APIStatus && showMapData ? (
              <Box>
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={APIStatus}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              </Box>
            ) : APIStatus ? (
              <Box className="pagination-section">
                <CircularProgress />
              </Box>
            ) : (
              ""
            )}
            <Box sx={{ marginTop: "-1rem" }} ref={listViewRef}>
              <Box
                sx={{
                  width: "100%",
                  display: showMapData ? "block" : "none",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    // flexDirection: "row-reverse",

                    // display: "grid",
                    // gridTemplateColumns: "0.5fr 5fr",
                    mt: "1rem",
                    gap: 0,
                    // maxHeight:'calc(100vh - 15rem)'
                  }}
                >
                  <Box
                    sx={{
                      width: { xs: "0", md: "20rem", lg: "40rem" },
                      display: { xs: "none", md: "block" },
                    }}
                  >
                    <MapListResult
                      fetchData={fetchPaginationData}
                      handleLikeEquipments={handleLikeEquipments}
                      memoizedEquipments={memoizedEquipments}
                      loading={paginationLoad}
                      // activeMarker={activeMarker}
                      setActiveMarker={setActiveMarker}
                      likedClicked={likedClicked}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: {
                        xs: "100vw",
                        md: "calc(100vw - 20rem)",
                        lg: "calc(100vw - 40rem)",
                      },
                    }}
                  >
                    <MapResult
                      keyword={Keyword}
                      zipCode={ZipCode}
                      startDate={StartDate}
                      endDate={EndDate}
                      locationRange={locationRange}
                      listEquipType={ListEquipType}
                      category={category}
                      mapBoxRef={mapBoxRef}
                      showMapData={showMapData}
                      fitbound={fitbound}
                      setFitBound={setFitBound}
                      isEquipLiked={isEquipLiked}
                      handleLikeEquipments={handleLikeEquipments}
                      fetchData={fetchData}
                      loading={loading}
                      activeMarker={activeMarker}
                      setActiveMarker={setActiveMarker}
                    />
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: !showMapData ? "block" : "none" }}>
                <EquipmentsView
                  next={15}
                  handleLikeEquipments={handleLikeEquipments}
                  callApi={callApi}
                  fetchData={fetchData}
                  pageNo={pageNo}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        ref={mapBoxRef}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
          position: "fixed",
          bottom: "2rem",
          left: {
            xs: "50%",
            sm: "50%",
            // md: showMapData ? "66%" : "50%",
            // lg: showMapData ? "70%" : "50%",
            // xl: showMapData ? "68%" : "50%",
            md: showMapData ? "calc((100vw - 20rem) / 2 + 20rem)" : "50%", // Center of map width
            lg: showMapData ? "calc((100vw - 40rem) / 2 + 40rem)" : "50%",
            xl: showMapData ? "calc((100vw - 40rem) / 2 + 40rem)" : "50%",
          },
          transform: "translate(-50%,-50%)",
          zIndex: "1600",
        }}
        onClick={handleToggleMapView}
      >
        <Button
          sx={{
            backgroundColor: "#149247",
            "&:hover": {
              backgroundColor: "darkgreen",
            },
            color: "#fff",
            fontFamily: "Montserrat !important",
            fontWeight: "600",
            textTransform: "capitalize",
            fontSize: "18px",
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            justifyContent: "center",
            borderRadius: "12px",
            padding: "10px 15px",
          }}
        >
          {!showMapData ? (
            <MapOutlined sx={{ color: "#fff", fontSize: "28px" }} />
          ) : (
            <ListIcon
              sx={{
                color: "#fff !important",
                fontSize: "28px",
                "&.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium": {
                  color: "#fff !important",
                },
              }}
            />
          )}
          Show {!showMapData ? "Map" : "List"}
        </Button>
      </Box>

      {/* {!showMapData && equipments.length > 0 && (
        <Box className="pagination-section">
          {loadmore && <CircularProgress />}
          {loadmore ||
            (pagecount > equipments.length && (
              <Link
                className="btn-load-more"
                onClick={() => {
                  setLoadmore(true);
                  let newPage = page + 1;
                  setPage(newPage);
                }}
              >
                {"See More"}
                <img
                  src={gemArrow}
                  alt="GoEquipMe"
                  className="gem-arrow arrow-load-more"
                />
              </Link>
            ))}
        </Box>
      )} */}

      <Modal
        keepMounted
        open={FilterPopUp}
        onClose={() => setFilterPopUp(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popup search-pop"
          sx={{
            ...style,
            borderRadius: " 10px",
            // width: '460px',
            width: matchesMobileX ? "calc(100vw - 100px)" : 400,
            padding: matchesMobileX ? "20px" : 4,
          }}
        >
          <IconButton
            onClick={() => setFilterPopUp(false)}
            sx={{ position: "absolute", right: 0, top: 0, color: "#fff" }}
          >
            <Typography
              sx={{
                color: "#faa61a",
                fontSize: "25px",
                paddingRight: "7px",
                fontWeight: "800",
              }}
            >
              X
            </Typography>
          </IconButton>

          <Box className="filter-popup">
            <Box>
              <Typography
                sx={{
                  paddingLeft: "0.5rem",
                  mb: " 1rem",
                  color: "#faa61a !important",
                  fontSize: "31px !important",
                  fontFamily: "GothamBold !important",
                }}
              >
                Filters
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1.6rem",
                marginTop: "1rem",
              }}
            >
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "0px" }}
              >
                <Box sx={{ textAlign: "center", marginBottom: "0.5rem" }}>
                  <Typography
                    sx={{
                      color: "#faa61a !important",
                      marginBottom: "0px",
                      fontFamily: "GothamBold !important",
                      padding: "0 0.6rem",
                    }}
                  >
                    Select Category
                  </Typography>
                </Box>
                {/* below select category added by pp on 18 apr 2024 */}
                <Select
                  value={category}
                  onChange={onCategoryChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{
                    "& .MuiSelect-select": {
                      color: "#2D2D2D !important",
                      fontFamily: "GothamBold !important",
                    },
                  }}
                  style={{
                    backgroundColor: "#fff",
                    border: "2px solid #FAA61A",
                    borderRadius: "10px",
                  }}
                  className="select-dropdwn"
                >
                  <MenuItem value="" className="category-dropdown">
                    All
                  </MenuItem>
                  {categories.map((item, index) => (
                    <MenuItem
                      className="list-eqp-select-options"
                      value={item.label}
                      key={index}
                    >
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
                {/* end by pp */}
              </Box>

              {/* <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0px', }}> */}
              <Box className="equipment-filter-grid">
                <Box sx={{ textAlign: "center", marginBottom: "0.5rem" }}>
                  {/* <Typography sx={{ color: '#faa61a !important', marginBottom: '0px', fontFamily: 'GothamBold !important', padding: '0 0.6rem' }}>Enter Your Location Manually </Typography> */}
                  {/* above line commented and next line added by pp on 22 apr 2024 */}
                  <Typography
                    sx={{
                      color: "#faa61a !important",
                      marginBottom: "0px",
                      fontFamily: "GothamBold !important",
                      padding: "0 0.6rem",
                    }}
                  >
                    Enter Zip Code
                  </Typography>
                </Box>
                <Box>
                  <FormControl variant="filled" sx={{ width: "100%" }}>
                    <CssTextField
                      id="filled-number"
                      placeholder="Zip Code"
                      InputLabelProps={{
                        shrink: false,
                      }}
                      // inputProps={{ maxLength: 8 }}
                      inputProps={{ maxLength: 6 }}
                      variant="outlined"
                      onChange={HandlePostalCode}
                      value={ZipCode}
                    />
                    {ZipCode.length > 0 && (
                      <span
                        className="filter-cross-icon"
                        onClick={ResetZipCode}
                      >
                        <ClearIcon
                          sx={{ fontSize: "18px", marginRight: "10px" }}
                        />
                      </span>
                    )}
                  </FormControl>
                </Box>
                <Box sx={{ textAlign: "center", marginBottom: "0.5rem" }}>
                  <Typography
                    sx={{
                      color: "#faa61a !important",
                      marginBottom: "0px",
                      fontFamily: "GothamBold !important",
                      padding: "0 0.6rem",
                    }}
                  >
                    Filter Dates
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    cursor: "pointer",
                    gap: "1.5rem",
                  }}
                >
                  <Box onClick={HandleCalendarPopup}>
                    <img
                      src={CalendarImg}
                      alt="GoEquipMe"
                      className=""
                      style={{
                        width: "42px",
                        outline: "3px solid #faa61a",
                        outlineOffset: "6px",
                        borderRadius: "3.5px",
                      }}
                    />
                  </Box>
                  {StartDate && EndDate && (
                    <Box>
                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Typography
                          sx={{ color: "#fff !important", fontSize: "14px" }}
                        >
                          Start:
                        </Typography>
                        <Typography
                          sx={{
                            color: "#fff !important",
                            fontSize: "14px",
                            marginLeft: "auto",
                          }}
                        >
                          {moment(StartDate).format("MM/DD/YY")}
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Typography
                          sx={{ color: "#fff !important", fontSize: "14px" }}
                        >
                          End:
                        </Typography>
                        <Typography
                          sx={{
                            color: "#fff !important",
                            fontSize: "14px",
                            marginLeft: "auto",
                          }}
                        >
                          {moment(EndDate).format("MM/DD/YY")}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>

              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "0px" }}
              >
                <Box sx={{ textAlign: "center" }}>
                  <Typography
                    sx={{
                      color: "#faa61a !important",
                      marginBottom: "0px",
                      fontFamily: "GothamBold !important",
                      padding: "0 0.6rem",
                    }}
                  >
                    {" "}
                    Radius{" "}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#fff !important",
                      fontSize: "12px",
                      padding: "0 0.6rem",
                    }}
                  >
                    &#40;search radius must have a valid location zipcode&#41;
                  </Typography>
                </Box>
                <Box
                  className="radius-slider-div"
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1.5fr 0.5fr",
                    alignItems: "center",
                    gap: "2rem",
                    padding: "1rem",
                  }}
                >
                  <Slider
                    className={
                      locationRange == ""
                        ? "radius-slider disabled"
                        : "radius-slider"
                    }
                    defaultValue={0}
                    aria-label="Default"
                    // aria-label="Disabled slider"
                    // valueLabelDisplay="auto"
                    // valueLabelDisplay="on"
                    disabled={ZipCode == "" ? true : false}
                    value={
                      !isNaN(parseInt(locationRange))
                        ? parseInt(locationRange)
                        : 0
                    }
                    // value={typeof value === 'number' ? value : 0}
                    onChange={handleSliderChange}
                  />
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.1rem",
                      color: "#fff !important",
                    }}
                  >
                    <span style={{ width: "45%" }}>
                      {!isNaN(parseInt(locationRange))
                        ? parseInt(locationRange)
                        : 0}
                    </span>
                    <span style={{ width: "55%" }}>Miles</span>
                  </Typography>
                  {/* <Input
                    value={value}
                    size="small"
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    inputProps={{
                      step: 10,
                      min: 0,
                      max: 100,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                    }}
                  /> */}
                  {/* <Typography>
                    5 K.M
                  </Typography> */}
                </Box>
                {/* <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(1, 1fr)', gap: '1rem', alignItems: 'center' }}>
                  <FormControl variant="filled" sx={{ m: 1, minWidth: 120, marginLeft: "8px !important" }}>
                    <TextField
                      id="filled-number"
                      placeholder="XX miles"
                      type="text"
                      sx={{ backgroundColor: '#fff !important', color: '#2d2d2d !important', borderRadius: '5px', border: '1px solid #faa61a' }}
                      InputLabelProps={{
                        shrink: false,
                      }}
                      inputProps={{ maxLength: 3 }}
                      disabled={RangeValidate}
                      variant="filled"
                      onChange={onRangeChange}
                      value={locationRange}
                    />
                    {locationRange.length > 0 && (
                      <span className="filter-cross-icon"
                        onClick={ResetlocationRange}><ClearIcon sx={{ fontSize: '18px', marginRight: '10px' }} /></span>
                    )}
                  </FormControl>
                </Box> */}
              </Box>

              {/* <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0px', }}>
                <Box>
                  <FormLabel sx={{ color: '#fff', marginBottom: '0px', fontFamily: 'GothamBold !important', padding: '0 0.6rem' }}> Range </FormLabel>
                </Box>
                <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(1, 1fr)', gap: '1rem', alignItems: 'center' }}>

                  <FormControl variant="filled" sx={{ m: 1, minWidth: 120, marginLeft: "8px !important" }}>
                    <TextField
                      id="filled-number"
                      placeholder="XX miles"
                      type="text"
                      sx={{ backgroundColor: '#fff !important', color: '#2d2d2d !important', borderRadius: '5px', border: '1px solid #faa61a' }}
                      InputLabelProps={{
                        shrink: false,
                      }}
                      inputProps={{ maxLength: 3 }}
                      disabled={RangeValidate}
                      variant="filled"
                      onChange={onRangeChange}
                      value={locationRange}

                    />
                    {locationRange.length > 0 && (
                      <span className="filter-cross-icon"
                        onClick={ResetlocationRange}><ClearIcon sx={{ fontSize: '18px', marginRight: '10px' }} /></span>
                    )}
                  </FormControl>
                </Box>
              </Box> */}

              {/* below codes are commented by pp on 22 apr 2024 due to design changes */}
              {/* <Box className='main-search-grid'>

                <Box className='main-search-item'>
                </Box>
              </Box> */}

              {/* <Box>
                <Box
                  onClick={HandleCalendarPopup}
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '1.5rem', paddingLeft: '1rem', mt: '0rem', mb: '1rem', cursor: 'pointer' }}
                >
                  <img
                    src={CalendarImg}
                    alt='GoEquipMe'
                    className=''
                    style={{ width: "40px", outline: '4px solid #faa61a', outlineOffset: '6px', borderRadius: '3.5px' }}
                  />
                  <Typography sx={{ color: '#fff', fontFamily: 'GothamBold !important', fontSize: '24px !important' }}>
                    Date Availability
                  </Typography>

                </Box>

                {StartDate && EndDate && (
                  <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '1rem', width: '65%', margin: 'auto' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
                      <Typography sx={{ color: '#fff !important', fontWeight: '700' }}>
                        START DATE :
                      </Typography>
                      <Typography sx={{ color: '#fff !important' }}>
                        {moment(StartDate).format("M-D-YYYY")}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
                      <Typography sx={{ color: '#fff !important', fontWeight: '700' }}>
                        END DATE :
                      </Typography>
                      <Typography sx={{ color: '#fff !important' }}>
                        {moment(EndDate).format("M-D-YYYY")}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box> */}

              {/* end by pp*/}
            </Box>

            <Box className="reset-grid">
              <Typography
                className="reset-filter"
                sx={{
                  color: "#ffa700",
                  textDecoration: "underline",
                  cursor: "pointer",
                  // paddingLeft: '3rem !important',
                  fontSize: "25px !important",
                }}
                onClick={(e) => {
                  HandleReset(e);
                }}
                variant="h6"
              >
                Reset
              </Typography>

              <Button
                className="filter-btn"
                sx={{
                  boxSizing: "border-box",
                  bgcolor: "#149247",
                  fontFamily: "GothamBold",
                  color: "#fff",
                  width: "200px !important",
                  textTransform: "none",
                  fontSize: "clamp(16px, 3vw ,22px)",
                  padding: "7px 0.5rem !important",
                  lineHeight: "normal",
                }}
                onClick={HandlepopupFilter}
                ref={seeResult2Ref}
              >
                See Results
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* Calendar PopUp */}
      <Modal
        keepMounted
        open={CalendarPopup}
        onClose={() => setCalendarPopup(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popup search-pop"
          sx={{
            ...style,
            borderRadius: " 10px",
            width: "460px",
            // width: matchesMobileX ? 'calc(100vw - 100px)' : 400,
            padding: matchesMobileX ? "20px" : 5,
          }}
        >
          <IconButton
            onClick={() => setCalendarPopup(false)}
            sx={{ position: "absolute", right: 0, top: 0, color: "#fff" }}
          >
            <Typography
              sx={{
                color: "#faa61a",
                fontSize: "25px",
                paddingRight: "7px",
                fontWeight: "800",
              }}
            >
              X
            </Typography>
          </IconButton>

          <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <Box
              className="filter-calendar"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <NewCalendar
                numberOfMonths={1}
                disableMonthPicker
                disableYearPicker
                value={values}
                onChange={(rang) => {
                  let start = rang[0]?.format?.();
                  let end = rang[1]?.format?.();
                  if (start || end) {
                    if (start && end) {
                      let a = [start, end];
                      setValues(a);
                      setStartDate(moment(start).format("M-D-YYYY"));
                      setEndDate(moment(end).format("M-D-YYYY"));
                    }
                  }
                  return;
                }}
                minDate={new Date()}
                // className="twin-cal-custom-calendar"
                range
              />
            </Box>
          </Box>

          <Box
            sx={{
              width: "100% !important",
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "2rem",
              mt: "1rem",
              alignItems: "center",
            }}
          >
            <Typography
              className="reset-filter"
              sx={{
                color: "#ffa700",
                textDecoration: "underline",
                marginLeft: "20px !important",
                cursor: "pointer",
              }}
              onClick={resetCalendar}
              variant="h6"
            >
              Clear dates
            </Typography>

            <Button
              className="filter-btn"
              sx={{
                bgcolor: "#149247",
                fontFamily: "GothamBold",
                color: "#fff",
                width: "100% !important",
              }}
              onClick={HandleCalendarDates}
            >
              Submit
            </Button>

            {/* <Button className='cancel-btn' sx={{ bgcolor: '#faa61a', fontFamily: 'GothamBold', color: '#fff', width: '100% !important' }}
              onClick={resetCalendar}>
              Clear dates
            </Button> */}
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default SearchSection;
