import {
  Box,
  Button,
  IconButton,
  Modal,
  Typography,
  useMediaQuery,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { Calendar as NewCalendar } from "react-multi-date-picker";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { setalertMessage, setalertMessagetype } from "redux/redux-slice";
import { useDispatch } from "react-redux";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "rgb(46 46 46)", //background.paper
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const textFieldStyles = {
  backgroundColor: "#fff",
  border: "2px solid #faa61a",
  borderRadius: "10px !important",
  boxSizing: "border-box",
  "& .MuiInputBase-root": {
    fontFamily: "Gotham !important",
    color: "#000 !important",
    boxSizing: "border-box",
    borderRadius: "10px !important",
  },
  "& .MuiInputLabel-root": {
    fontFamily: "Gotham !important",
    color: "#2d2d2d !important",
    boxSizing: "border-box",
    borderRadius: "10px !important",
  },
  "& .MuiInputBase-input": {
    fontFamily: "Gotham !important",
    color: "#000 !important",
    borderRadius: "10px !important",
  },
  "& .MuiInputBase-input::placeholder": {
    fontFamily: "Gotham !important",
    color: "#2d2d2d !important",
    fontSize: "14px",
  },
  "& .MuiInput-underline:after": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiInput-underline:before": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiFilledInput-underline:after": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiFilledInput-underline:before": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": { borderColor: "transparent !important" },
    "&:hover fieldset": { borderColor: "transparent !important" },
    "&.Mui-focused fieldset": { borderColor: "transparent !important" },
  },
};
const WalletFilterCalendar = ({
  calendarPopup,
  setCalendarPopupClose,
  setDateRange,
  dateRange,
  fillDate = "start",
  tempDateRange,
  setTempDateRange,
}) => {
  const dispatch = useDispatch();
  const matchesMobileX = useMediaQuery("(max-width:425px)");
  //   const handleDateRange = (rang) => {
  //     let start = rang[0]?.format?.();
  //     let end = rang[1]?.format?.();
  //     if (start || end) {
  //       if (start && end) {
  //         const tempDateRange = [
  //           moment(start).format("YYYY-MM-DD"),
  //           moment(end).format("YYYY-MM-DD"),
  //         ];
  //         setTempDateRange(tempDateRange);
  //       }
  //     }
  //   };
  // const [tempDateRange, setTempDateRange] = useState<[string, string]>([
  //   null,
  //   null,
  // ]);
  const handleInputDateRange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let inputDate = event.target.value.replace(/[^\d]/g, ""); // Remove non-numeric characters

    if (inputDate.length > 8) return; // Limit to MMDDYYYY (8 characters)

    let formattedDate = "";

    // Add "-" dynamically as the user types
    if (inputDate.length <= 2) {
      formattedDate = inputDate;
    } else if (inputDate.length <= 4) {
      formattedDate = `${inputDate.slice(0, 2)}-${inputDate.slice(2)}`;
    } else {
      formattedDate = `${inputDate.slice(0, 2)}-${inputDate.slice(
        2,
        4
      )}-${inputDate.slice(4)}`;
    }

    // Regular expression to check MM-DD-YYYY format
    const dateRegex = /^(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])-\d{4}$/;

    if (formattedDate.length === 10 && !dateRegex.test(formattedDate)) {
      //   console.log("Invalid date format! Use MM-DD-YYYY.");
      dispatch(setalertMessage("Invalid date format!"));
      dispatch(setalertMessagetype("error"));
      return;
    }

    // Extract month, day, and year
    if (formattedDate.length === 10) {
      const [month, day, year] = formattedDate.split("-").map(Number);
      const dateObject = new Date(year, month - 1, day);

      if (
        dateObject.getFullYear() !== year ||
        dateObject.getMonth() + 1 !== month ||
        dateObject.getDate() !== day
      ) {
        dispatch(setalertMessage("Invalid date!"));
        dispatch(setalertMessagetype("error"));
        return;
      }
    }
    if (formattedDate.length >= 10) {
      fillDate === "start"
        ? setTempDateRange([
            moment(formattedDate).format("YYYY-MM-DD"),
            tempDateRange[1],
          ])
        : setTempDateRange([
            tempDateRange[0],
            moment(formattedDate).format("YYYY-MM-DD"),
          ]);
    } else {
      fillDate === "start"
        ? setTempDateRange([formattedDate, tempDateRange[1]])
        : setTempDateRange([tempDateRange[0], formattedDate]);
    }
  };

  const handleDateChange = (selectedDate) => {
    if (selectedDate) {
      const formattedDate = selectedDate.format("YYYY-MM-DD");
      if (
        fillDate === "start" &&
        dateRange[1] &&
        moment(formattedDate).isAfter(moment(dateRange[1]))
      ) {
        dispatch(
          setalertMessage("Start date should be less than equal to end date.")
        );
        dispatch(setalertMessagetype("error"));
        return;
      }
      if (
        fillDate === "end" &&
        dateRange[0] &&
        moment(dateRange[0]).isAfter(moment(formattedDate))
      ) {
        dispatch(
          setalertMessage(
            "End date should be greater than equal to start date."
          )
        );
        dispatch(setalertMessagetype("error"));
        return;
      }
      if (fillDate === "start") {
        setTempDateRange([formattedDate, tempDateRange[1]]); // Store as a string, not an array
        setDateRange([formattedDate, dateRange[1]]); // Store as a string, not an array
      } else {
        setTempDateRange([tempDateRange[0], formattedDate]);
        setDateRange([dateRange[0], formattedDate]);
      }
    }
  };

  const handleLeavinginput = () => {
    if (fillDate === "start" && tempDateRange[0]?.length < 10) {
      dispatch(setalertMessage("Invalid date!"));
      dispatch(setalertMessagetype("error"));
      return;
    } else if (fillDate === "end" && tempDateRange[1]?.length < 10) {
      dispatch(setalertMessage("Invalid date!"));
      dispatch(setalertMessagetype("error"));
      return;
    }
    const today = moment().format("YYYY-MM-DD");
    const minDate = "2020-12-31"; // Minimum allowed date

    if (
      moment(tempDateRange[0]).isAfter(today) ||
      moment(tempDateRange[1]).isAfter(today)
    ) {
      dispatch(setalertMessage("Future dates are not allowed."));
      dispatch(setalertMessagetype("error"));
      return;
    }

    if (
      moment(tempDateRange[0]).isBefore(minDate) ||
      moment(tempDateRange[1]).isBefore(minDate)
    ) {
      dispatch(setalertMessage("Dates before 2021 are not allowed."));
      dispatch(setalertMessagetype("error"));
      return;
    }

    if (
      dateRange[1] &&
      dateRange[0] &&
      moment(dateRange[0]).isAfter(moment(dateRange[1]))
    ) {
      dispatch(
        setalertMessage("Start date should be less than equal to end date.")
      );
      dispatch(setalertMessagetype("error"));
      return;
    }

    if (fillDate === "start") {
      setDateRange([tempDateRange[0], dateRange[1]]);
    } else if (fillDate === "end") {
      setDateRange([dateRange[0], tempDateRange[1]]);
    }
  };

  let textDate = "";
  if (fillDate === "start") {
    textDate =
      tempDateRange?.[0] && tempDateRange?.[0]?.length >= 10
        ? moment(tempDateRange?.[0]).format("MM/DD/YYYY")
        : tempDateRange?.[0]
        ? tempDateRange?.[0]
        : "";
  } else {
    textDate =
      tempDateRange?.[1] && tempDateRange?.[1]?.length >= 10
        ? moment(tempDateRange?.[1]).format("MM/DD/YYYY")
        : tempDateRange?.[1]
        ? tempDateRange?.[1]
        : "";
  }
  textDate = textDate.replaceAll("-", "/");
  return (
    <>
      <Modal
        open={calendarPopup}
        onClose={setCalendarPopupClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: {
              xs: "80%",
              sm: "400px",
              md: "450px",
              lg: "450px",
              xl: "450px",
            },
            bgcolor: "#2e2e2e",
            border: "5px solid #faa61a",
            borderRadius: "10px",
            outline: "none !important",
            p: 3,
          }}
        >
          <IconButton
            onClick={setCalendarPopupClose}
            sx={{
              position: "absolute",
              top: "-20px",
              right: "-20px",
              bgcolor: "#faa61a",
              color: "#000",
              "&:hover": {
                bgcolor: "#faa61a",
              },
            }}
          >
            <CloseIcon />
          </IconButton>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <TextField
                type="text"
                placeholder="MM/DD/YYYY"
                InputLabelProps={{ shrink: true, style: { color: "#faa61a" } }}
                value={textDate}
                fullWidth
                InputProps={{
                  sx: {
                    textAlign: "center", // This might not work alone
                    "& input": {
                      textAlign: "center", // Correctly targets the input element
                    },
                  },
                }}
                sx={{
                  ...textFieldStyles,
                  cursor: "pointer",
                  "& .MuiInputBase-input": {
                    textAlign: "center",
                  },
                }}
                onChange={handleInputDateRange}
                onBlur={handleLeavinginput}
              />
            </Box>
            <Box className="date-cal filtercalendar filter-calendar wallet-calendar">
              <NewCalendar
                numberOfMonths={1}
                disableMonthPicker
                disableYearPicker
                value={
                  (dateRange?.[0] && dateRange?.[1] && fillDate === "start"
                    ? dateRange
                    : dateRange?.[0] && dateRange?.[1] && fillDate === "end"
                    ? [...dateRange]?.reverse()
                    : fillDate === "start"
                    ? dateRange?.[0]
                    : fillDate === "end"
                    ? dateRange?.[1]
                    : null) || null
                }
                onChange={handleDateChange}
                maxDate={new Date()}
                minDate={new Date("2021-01-01")}
                className="twin-cal-custom-calendar"
                range={dateRange?.[0] && dateRange?.[1]}
              />
            </Box>
            <Box
              sx={{
                width: "100% !important",
                display: "flex",
                justifyContent: "center",
                gap: "2rem",
                //   mt: "1rem",
                alignItems: "center",
                mt: 4,
              }}
            >
              <Typography
                className="reset-filter"
                sx={{
                  // width:''
                  color: "#ffa700",
                  textDecoration: "underline",
                  marginLeft: "20px !important",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (fillDate === "start") {
                    setDateRange([null, dateRange[1]]);
                    setTempDateRange([null, tempDateRange[1]]);
                  } else if (fillDate === "end") {
                    setDateRange([dateRange[0], null]);
                    setTempDateRange([tempDateRange[0], null]);
                  }
                }}
                variant="h6"
              >
                Reset
              </Typography>

              <Button
                sx={{
                  borderRadius: "8px",
                  bgcolor: "#149247",
                  textTransform: "capitalize",
                  fontFamily: "Montserrat !important",
                  fontWeight: "800",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                  fontSize: {
                    xs: "13px",
                    sm: "14px",
                    md: "14px",
                    lg: "15px",
                    xl: "16px",
                  },
                  color: "#fff",
                }}
                onClick={() => {
                  handleLeavinginput();
                  setCalendarPopupClose();
                }}
              >
                Select
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default WalletFilterCalendar;
