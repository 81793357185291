import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EquipmentAPIs } from "../../../service/api-service";
import { IStoreValue } from "utils/types";
import {
  setalertMessagetype,
  setalertMessage,
  setLoginModalOpen,
  setLogInLoading,
} from "redux/redux-slice";
import {
  Box,
  CircularProgress,
  Link,
  Typography,
  Backdrop,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import arrowIcon from "../../../assets/images/buildacrew/Icon_Subs_Indent-Arrow.png";
import warningSign from "assets/images/warning-sign.png";
import RentSteps from "components/user-signup/signupPopup/RentSteps";

const Dashboard = ({ setActiveStep, reloadTabUpdate, handleListEquipment }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const AuthToken = useSelector(
    (state: IStoreValue) => state.equipmentReducer.accessToken
  );
  const AuthUserId = useSelector(
    (state: IStoreValue) => state.equipmentReducer.Authuser
  );

  const AuthProfileData = useSelector(
    (state: IStoreValue) => state.equipmentReducer.AuthProfileData
  );
  const [loading, setLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState([]);
  // const [completeProfile,setCompleteProfile] = useState<boolean>(false);
  // const handleCloseCompleteProfile = () => setCompleteProfile(false);
  // const handleListEquipment = (event) => {
  //   event.stopPropagation();
  //   if(parseFloat(AuthProfileData?.["is_profile_completed"])=== 0) {
  //     setCompleteProfile(true);
  //     return false;
  //   }
  //   window.location.href = "/listequipment";
  // };

  // const handleGps = () => {
  //     navigate("/myGps");
  //     setActiveStep(4);
  //     reloadTabUpdate();
  // }

  // const handleRentalYard = () => {
  //     navigate("/myRentalYard");
  //     setActiveStep(1);
  //     reloadTabUpdate();
  // }

  // const handleSalesYard = () => {
  //     navigate("/mySalesYard");
  //     setActiveStep(6);
  //     reloadTabUpdate();
  // }
  // const handleCrew = () => {
  //     navigate("/buildACrew");
  //     setActiveStep(7);
  //     reloadTabUpdate();
  // }

  const handleNavigation = (activeUrl: string, currentSteps: number) => {
    if (!AuthUserId) {
      dispatch(setLoginModalOpen(true));
      dispatch(setLogInLoading(false));
      return;
    }
    const [data] = dashboardData;
    const gpsTotal = data["gpsData"]?.find(
      ({ label, value }) => label?.includes("Total") && label?.includes("GPS")
    );
    if (activeUrl.includes("myGps") && gpsTotal) {
      const { value } = gpsTotal;
      if (!value) {
        navigate("/equipment-tracking");
        return false;
      }
    }
    navigate(activeUrl);
    setActiveStep(currentSteps);
    reloadTabUpdate();
  };

  useEffect(() => {
    const fetchData = async (userId: number) => {
      try {
        setLoading(true);
        const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
        const response = await EquipmentAPIs.getDashboardDetails(
          { user_id: userId.toString() },
          headers
        );
        if (response["type"] === "RXSUCCESS") {
          // console.log(response.data);
          setDashboardData([response.data]);
        } else {
          throw new Error(response["message"]);
        }
      } catch (error) {
        dispatch(setalertMessage(error["message"]));
        dispatch(setalertMessagetype("error"));
      } finally {
        setLoading(false);
      }
    };
    if (AuthUserId) {
      fetchData(AuthUserId);
    }
  }, [AuthUserId, AuthToken]);

  return (
    <>
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            {/* <CircularProgress color="inherit" /> */}
            <Typography variant="h5">Loading Your Dashboard...</Typography>
          </Backdrop>
        </div>
      )}
      {dashboardData.length > 0 ? (
        <>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "1rem",
              mt: 4,
              mb: 2,
            }}
          >
            <Button
              onClick={handleListEquipment}
              sx={{
                background: "#179346",
                fontFamily: "Montserrat !important",
                padding: "2.5px 10px !important",
                borderRadius: "5px",
                fontWeight: "800",
                color: "#fff",
                textTransform: "capitalize",
                fontSize: {
                  xs: "12px",
                  sm: "14px",
                  md: "14px",
                  lg: "16px",
                  xl: "16px",
                },
                "&:hover": {
                  background: "#077731",
                },
              }}
            >
              List Equipment
            </Button>
            {/* <Button
              sx={{
                background: "#179346",
                fontFamily: "Montserrat !important",
                padding: "2.5px 10px !important",
                borderRadius: "5px",
                fontWeight: "800",
                color: "#fff",
                textTransform: "capitalize",
                fontSize: {
                  xs: "12px",
                  sm: "14px",
                  md: "14px",
                  lg: "16px",
                  xl: "16px",
                },
                "&:hover": {
                  background: "#077731",
                },
              }}
              // onClick={() => handleNavigation("/myGps", 4)}
              onClick={() => navigate("/gemstore")}
            >
              Store
            </Button> */}
          </Box>
          {/* <Box
            sx={{
              width: "40%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            
          </Box> */}
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: {
                xs: "1fr",
                sm: "1fr",
                md: "repeat(2, 1fr)",
                lg: "repeat(2, 1fr)",
                xl: "repeat(2, 1fr)",
              },
              gap: "1rem",
            }}
          >
            <Box
              onClick={() => handleNavigation("/myRentalYard", 1)}
              sx={{
                background: "#47463E",
                cursor: "pointer",
                padding: "1rem",
                borderRadius: "10px",
                boxShadow: "0px 0px 20px 0px rgba(0,0,0,0.5)",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  color: "#faa61a !important",
                  fontFamily: "GothamBold !important",
                  mb: 1,
                }}
              >
                My Rental Yard
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "flex-start",
                  gap: "0.2rem",
                  flexDirection: "column",
                }}
              >
                {dashboardData[0]?.["myRentalYard"]?.map((element) => (
                  <>
                    {element?.label?.toLowerCase()?.includes("payout") ? (
                      <Box
                        key={element?.label}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "1rem",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                            paddingLeft: "5px",
                          }}
                        >
                          <Box
                            component="img"
                            src={arrowIcon}
                            alt="arrow-icon"
                            sx={{
                              width: {
                                xs: "11px",
                                sm: "12px",
                                md: "14px",
                                lg: "14px",
                                xl: "14px",
                              },
                              height: "auto",
                            }}
                          ></Box>
                          <Typography
                            sx={{
                              color: "#fff !important",
                              fontFamily: "Montserrat !important",
                              fontSize: {
                                xs: "13px",
                                sm: "13px",
                                md: "14px",
                                lg: "16px",
                                xl: "16px",
                              },
                            }}
                          >
                            {element.label}
                          </Typography>
                        </Box>
                        <Typography
                          sx={{
                            color: "#fff !important",
                            fontWeight: "800",
                            fontFamily: "Montserrat !important",
                            fontSize: {
                              xs: "13px",
                              sm: "13px",
                              md: "14px",
                              lg: "16px",
                              xl: "16px",
                            },
                          }}
                        >
                          ${element.value}
                        </Typography>
                      </Box>
                    ) : (
                      <Box
                        key={element?.label}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "1rem",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#fff !important",
                            fontFamily: "Montserrat !important",
                            fontSize: {
                              xs: "13px",
                              sm: "13px",
                              md: "14px",
                              lg: "16px",
                              xl: "16px",
                            },
                          }}
                        >
                          {element.label}
                        </Typography>
                        {element.value === "" ? (
                          <Typography
                            sx={{
                              color: "#fff !important",
                              fontWeight: "800",
                              fontFamily: "Montserrat !important",
                              fontSize: {
                                xs: "13px",
                                sm: "13px",
                                md: "14px",
                                lg: "16px",
                                xl: "16px",
                              },
                            }}
                          >
                            {"N/A"}
                          </Typography>
                        ) : (
                          <Typography
                            sx={{
                              color: "#fff !important",
                              fontWeight: "800",
                              fontFamily: "Montserrat !important",
                              fontSize: {
                                xs: "13px",
                                sm: "13px",
                                md: "14px",
                                lg: "16px",
                                xl: "16px",
                              },
                            }}
                          >
                            {element?.label?.toLowerCase()?.includes("earning")
                              ? "$"
                              : ""}
                            {element.value}
                          </Typography>
                        )}
                      </Box>
                    )}
                  </>
                ))}
              </Box>
            </Box>

            <Box
              sx={{
                background: "#47463E",
                cursor: "pointer",
                padding: "1rem",
                borderRadius: "10px",
                boxShadow: "0px 0px 20px 0px rgba(0,0,0,0.5)",
              }}
              onClick={() => handleNavigation("/equipmentOrders", 9)}
            >
              <Typography
                variant="h6"
                sx={{
                  color: "#faa61a !important",
                  fontFamily: "GothamBold !important",
                  mb: 1,
                }}
              >
                My Orders
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    gap: "0.2rem",
                    flexDirection: "column",
                  }}
                >
                  {dashboardData[0]?.["myOrders"]?.map((element) => (
                    <>
                      {element.label.includes("ending") ? (
                        <Box
                          key={element?.label}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1rem",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: "0.5rem",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#fff !important",
                                fontWeight: "400",
                                fontFamily: "Montserrat !important",
                                fontSize: {
                                  xs: "13px",
                                  sm: "13px",
                                  md: "14px",
                                  lg: "16px",
                                  xl: "16px",
                                },
                              }}
                            >
                              {element.label}
                            </Typography>
                            {/* <Box
                              component={"img"}
                              src={warningSign}
                              alt="..."
                              sx={{
                                width: {
                                  xs: "12px",
                                  sm: "13px",
                                  md: "16px",
                                  lg: "16px",
                                  xl: "16px",
                                },
                                height: "auto",
                              }}
                            /> */}
                          </Box>
                          <Typography
                            sx={{
                              color: "#fff !important",
                              fontWeight: "800",
                              fontFamily: "Montserrat !important",
                              fontSize: {
                                xs: "13px",
                                sm: "13px",
                                md: "14px",
                                lg: "16px",
                                xl: "16px",
                              },
                            }}
                          >
                            {element.value}
                          </Typography>
                        </Box>
                      ) : (
                        <Box
                          key={element?.label}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1rem",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#fff !important",
                              fontFamily: "Montserrat !important",
                              fontSize: {
                                xs: "13px",
                                sm: "13px",
                                md: "14px",
                                lg: "16px",
                                xl: "16px",
                              },
                            }}
                          >
                            {element.label}
                          </Typography>
                          <Typography
                            sx={{
                              color: "#fff !important",
                              fontWeight: "800",
                              fontFamily: "Montserrat !important",
                              fontSize: {
                                xs: "13px",
                                sm: "13px",
                                md: "14px",
                                lg: "16px",
                                xl: "16px",
                              },
                            }}
                          >
                            {element.value}
                          </Typography>
                        </Box>
                      )}
                    </>
                  ))}
                </Box>
              </Box>
            </Box>

            <Box
              onClick={() => handleNavigation("/myGps", 4)}
              sx={{
                background: "#47463E",
                cursor: "pointer",
                padding: "1rem",
                borderRadius: "10px",
                boxShadow: "0px 0px 20px 0px rgba(0,0,0,0.5)",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  color: "#faa61a !important",
                  fontFamily: "GothamBold !important",
                  mb: 1,
                }}
              >
                GPS
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    gap: "0.2rem",
                    flexDirection: "column",
                  }}
                >
                  {dashboardData[0]?.["gpsData"]?.map((element) => (
                    <>
                      {element.label.includes("signed") ? (
                        <Box
                          key={element?.label}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1rem",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                              paddingLeft: "5px",
                            }}
                          >
                            <Box
                              component="img"
                              src={arrowIcon}
                              alt="arrow-icon"
                              sx={{
                                width: {
                                  xs: "11px",
                                  sm: "12px",
                                  md: "14px",
                                  lg: "14px",
                                  xl: "14px",
                                },
                                height: "auto",
                              }}
                            ></Box>
                            <Typography
                              sx={{
                                color: "#fff !important",
                                fontFamily: "Montserrat !important",
                                fontSize: {
                                  xs: "13px",
                                  sm: "13px",
                                  md: "14px",
                                  lg: "16px",
                                  xl: "16px",
                                },
                              }}
                            >
                              {element.label}
                            </Typography>
                          </Box>
                          <Typography
                            sx={{
                              color: "#fff !important",
                              fontWeight: "800",
                              fontFamily: "Montserrat !important",
                              fontSize: {
                                xs: "13px",
                                sm: "13px",
                                md: "14px",
                                lg: "16px",
                                xl: "16px",
                              },
                            }}
                          >
                            {element.value}
                          </Typography>
                        </Box>
                      ) : (
                        <Box
                          key={element?.label}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1rem",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#fff !important",
                              fontFamily: "Montserrat !important",
                              fontSize: {
                                xs: "13px",
                                sm: "13px",
                                md: "14px",
                                lg: "16px",
                                xl: "16px",
                              },
                            }}
                          >
                            {element.label}
                          </Typography>
                          <Typography
                            sx={{
                              color: "#fff !important",
                              fontWeight: "800",
                              fontFamily: "Montserrat !important",
                              fontSize: {
                                xs: "13px",
                                sm: "13px",
                                md: "14px",
                                lg: "16px",
                                xl: "16px",
                              },
                            }}
                          >
                            {element.value}
                          </Typography>
                        </Box>
                      )}
                    </>
                  ))}
                </Box>
              </Box>
            </Box>

            <Box
              onClick={() => handleNavigation("/buildACrew", 7)}
              sx={{
                background: "#47463E",
                cursor: "pointer",
                padding: "1rem",
                borderRadius: "10px",
                boxShadow: "0px 0px 20px 0px rgba(0,0,0,0.5)",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  color: "#faa61a !important",
                  fontFamily: "GothamBold !important",
                  mb: 1,
                }}
              >
                Build a Crew
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "flex-start",
                  gap: "0.2rem",
                  flexDirection: "column",
                }}
              >
                {dashboardData[0]?.["buildCrewData"]?.map((element) => (
                  <>
                    {element?.label?.toLowerCase()?.includes("payout") ? (
                      <Box
                        key={element?.label}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "1rem",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                            paddingLeft: "5px",
                          }}
                        >
                          <Box
                            component="img"
                            src={arrowIcon}
                            alt="arrow-icon"
                            sx={{
                              width: {
                                xs: "11px",
                                sm: "12px",
                                md: "14px",
                                lg: "14px",
                                xl: "14px",
                              },
                              height: "auto",
                            }}
                          ></Box>
                          <Typography
                            sx={{
                              color: "#fff !important",
                              fontFamily: "Montserrat !important",
                              fontSize: {
                                xs: "13px",
                                sm: "13px",
                                md: "14px",
                                lg: "16px",
                                xl: "16px",
                              },
                            }}
                          >
                            {element.label}
                          </Typography>
                        </Box>
                        <Typography
                          sx={{
                            color: "#fff !important",
                            fontWeight: "800",
                            fontFamily: "Montserrat !important",
                            fontSize: {
                              xs: "13px",
                              sm: "13px",
                              md: "14px",
                              lg: "16px",
                              xl: "16px",
                            },
                          }}
                        >
                          ${element.value}
                        </Typography>
                      </Box>
                    ) : (
                      <Box
                        key={element?.label}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "1rem",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#fff !important",
                            fontFamily: "Montserrat !important",
                            fontSize: {
                              xs: "13px",
                              sm: "13px",
                              md: "14px",
                              lg: "16px",
                              xl: "16px",
                            },
                          }}
                        >
                          {element.label}
                        </Typography>
                        {element.value === "" ? (
                          <Typography
                            sx={{
                              color: "#fff !important",
                              fontWeight: "800",
                              fontFamily: "Montserrat !important",
                              fontSize: {
                                xs: "13px",
                                sm: "13px",
                                md: "14px",
                                lg: "16px",
                                xl: "16px",
                              },
                            }}
                          >
                            {"N/A"}
                          </Typography>
                        ) : (
                          <Typography
                            sx={{
                              color: "#fff !important",
                              fontWeight: "800",
                              fontFamily: "Montserrat !important",
                              fontSize: {
                                xs: "13px",
                                sm: "13px",
                                md: "14px",
                                lg: "16px",
                                xl: "16px",
                              },
                            }}
                          >
                            {element?.label?.toLowerCase()?.includes("earning")
                              ? "$"
                              : ""}
                            {element.value}
                          </Typography>
                        )}
                      </Box>
                    )}
                  </>
                ))}
              </Box>
            </Box>

            {dashboardData[0]?.["franchiseeRole"] && (
              <Box
                sx={{
                  background: "#47463E",
                  cursor: "pointer",
                  padding: "1rem",
                  borderRadius: "10px",
                  boxShadow: "0px 0px 20px 0px rgba(0,0,0,0.5)",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#faa61a !important",
                    fontFamily: "GothamBold !important",
                    // mb: 1,
                  }}
                  onClick={() => handleNavigation("/gem-pro-tools", 9)}
                >
                  GEM Pro Tools
                </Typography>
              </Box>
            )}
            {dashboardData[0]?.["shop"] && (
              <Box
                sx={{
                  background: "#47463E",
                  cursor: "pointer",
                  padding: "1rem",
                  borderRadius: "10px",
                  boxShadow: "0px 0px 20px 0px rgba(0,0,0,0.5)",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#faa61a !important",
                    fontFamily: "GothamBold !important",
                    // mb: 1,
                  }}
                  onClick={() => handleNavigation("/gemstore", 9)}
                >
                  {dashboardData[0]?.["shop"]?.[0]?.label}
                </Typography>
              </Box>
            )}
          </Box>
        </>
      ) : (
        <Box
          sx={{
            color: "#fff",
            textAlign: "center",
            paddingTop: "24px",
            fontFamily: "GothamBold !important",
          }}
        >
          No Record Found!
        </Box>
      )}
      {/* <RentSteps open={completeProfile} handleClose={handleCloseCompleteProfile} /> */}
    </>
  );
};

export default Dashboard;
