import React, { useCallback, useEffect, useState, useRef } from "react";
import { EquipmentAPIs } from "../../service/api-service";
import {
  Box,
  CircularProgress,
  Link,
  Typography,
  Backdrop,
  Container,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Modal,
  FormControl,
  Input,
  InputLabel,
  styled,
} from "@mui/material";
import EquipmentItem from "../equipment-item/EquipmentItem";

import InputAdornment from "@mui/material/InputAdornment";
import NumericInput from "material-ui-numeric-input";
import Filtericon from "assets/images/filter.png";
import { useDispatch, useSelector } from "react-redux";
import {
  setalertMessagetype,
  setalertMessage,
  setaccessToken,
  setLogInLoading,
  setSessionExpire,
  setAuthuser,
  setWalletBalance,
} from "redux/redux-slice";
import { IApiResponseRawData, IStoreValue } from "utils/types";
import CloseIcon from "@mui/icons-material/Close";

import "./styles.scss";
import moment from "moment";
import gemArrow from "assets/images/gem-arrow.png";

import depositIcon from "assets/images/Deposit.png";
import withdrawIcon from "assets/images/Withdrawal.png";

//import './newstyles.scss'
import MainLayout from "layouts/MainLayout";
import { AccountCircle, DisabledByDefaultOutlined } from "@mui/icons-material";

// by GD 23 feb 2024 for build a crew
import CrewPayoutModal from "./CrewPayoutModal";
import CrewTransactionsModal from "./CrewTransactionsModal";

import AddBalance from "../AddBalance/AddBalance";
import WalletFilterModal from "./WalletFilterModal";
import ClearIcon from "@mui/icons-material/Clear";
import CurrenyFormat from "components/common/CurrenyFormat";

const loginstyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  p: 4,
};

const NewCssTextField = styled(TextField)({
  "& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl": {
    fontFamily: "Gotham !important",
  },
  "& .MuiInput-underline:after": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiInput-underline:before": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiFilledInput-underline:after": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiFilledInput-underline:before": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },

  "& .MuiInputLabel-shrink.MuiInputLabel-filled": {
    color: "#2d2d2d",
  },
  "& .MuiInputBase-root": {
    color: "#2d2d2d",
    background: "#fff",
    fontFamily: "Gotham !important",
    fontSize: "clamp(12px, 3vw, 15px) !important",
    borderRadius: "10px !important",
    // border: "2px solid #faa61a",
  },
  "&. MuiInputLabel-shrink": {
    color: "red !important",
  },

  "&. MuiInputLabel-shrink.Mui-focused": {
    color: "red !important",
  },

  "& label.Mui-focused": {
    color: "#2d2d2d !important",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "2px solid #faa61a",
      backgroundColor: "#fff !important",
    },
    "&:hover fieldset": {
      borderColor: "#2d2d2d",
      border: "2px solid #faa61a",
      backgroundColor: "#fff !important",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#faa61a",
    },
  },
  "& .MuiInputBase-root-MuiFilledInput-root:before": {
    borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
  },
});

const NewCssNumericInput = styled(NumericInput)({
  "& .MuiInput-underline:after": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiInput-underline:before": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiFilledInput-underline:after": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiFilledInput-underline:before": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },

  "& .MuiInputBase-root": {
    color: "#2d2d2d",
    background: "#fff",
    fontFamily: "Gotham !important",
    fontSize: "clamp(12px, 3vw, 15px) !important",
    borderRadius: "10px !important",
    // border: "2px solid #faa61a",
  },
  "& label.Mui-focused": {
    color: "#2d2d2d",
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "2px solid #faa61a",
    },
    "&:hover fieldset": {
      borderColor: "#2d2d2d",
      border: "2px solid #faa61a",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#faa61a",
    },
  },
});

type Filters = {
  withdrawal: boolean;
  deposits: boolean;
  pending: boolean;
  completed: boolean;
};

const MyWallet = () => {
  const dispatch = useDispatch();
  const equipId = useRef();

  const AuthToken = useSelector(
    (state: IStoreValue) => state.equipmentReducer.accessToken
  );
  const UserId = useSelector(
    (state: IStoreValue) => state.equipmentReducer.Authuser
  );
  const [loading, setloading] = React.useState(false);

  const [WalletHistory, setWalletHistory] = React.useState(null);
  const [Transactions, setTransactions] = React.useState([]);
  const [Page, setPage] = React.useState(0);
  const [Balance, setBalance] = React.useState("0.00");
  const [WalletPendingBalance, setWalletPendingBalance] =
    React.useState("0.00");
  const [DisputedBalance, setDisputedBalance] = React.useState("0.00");
  const [HoldBalance, setHoldBalance] = React.useState(0);
  //Add Balance section start
  //const [WalletBalance, setWalletBalancee] = useState(0);
  const [LoadWalletBalance, setLoadWalletBalance] = useState(false);
  const [addbalancebox, setaddbalancebox] = React.useState(false);
  const [WithDrawalRequest, setWithDrawalRequest] = React.useState(false);
  const [balanceamount, setbalanceamount] = React.useState(null);
  const [balancecard, setbalancecard] = React.useState(null);
  const [balancecardholdername, setbalancecardholdername] =
    React.useState(null);

  const [balancecardexpmonth, setbalancecardexpmonth] = React.useState(null);
  const [balancecardexpyear, setbalancecardexpyear] = React.useState(null);
  const [balancecardcvv, setbalancecardcvv] = React.useState(null);

  // Withdrawal Section Start
  const [WithdrawalAmount, setWithdrawalAmount] = useState("");
  const [WithdrawalAccountHolderName, setWithdrawalAccountHolderName] =
    useState("");
  const [WithdrawalAccountNumber, setWithdrawalAccountNumber] = useState("");
  const [WithdrawalAccountConfirmNumber, setWithdrawalAccountConfirmNumber] =
    useState("");
  const [WithdrawalRoutingNumber, setWithdrawalRoutingNumber] = useState("");

  // by GD 23 Feb 2024 for build a crew transactions
  const [crewPaymentOpen, setcrewPaymentOpen] = useState(false);
  // by GD 15 mar 2024 for crew transactions show modal
  const [crewTransactionsOpen, setCrewTransactionsOpen] = useState(false);
  const [transactionsData, setTransactionsData] = useState<any>();

  const [filterModalOpen, setFilterModalOpen] = useState<boolean>(false);
  const handleFilterModalOpen = () => setFilterModalOpen(true);
  const handleFilterModalClose = () => setFilterModalOpen(false);
  const filterObjRef = useRef({
    keyword: "",
    fromDate: "",
    toDate: "",
    minAmount: null,
    maxAmount: null,
    withdrawal: false,
    deposits: false,
    pending: false,
    completed: false,
  });

  const [keywordInput, setKeywordInput] = useState<string>("");
  const searchApplied = useRef<boolean>(false);
  const filterApplied = useRef<boolean>(false);

  const [dateRange, setDateRange] = useState<[string, string]>([null, null]);
  const [amountRange, setAmountRange] = useState<
    [number | string, number | string]
  >(["", ""]);
  const [filters, setFilters] = useState<Filters>({
    withdrawal: false,
    deposits: false,
    pending: false,
    completed: false,
  });

  const [filteredTotal, setFilteredTotal] = useState<{
    cr: number;
    dr: number;
    total: number;
  }>({
    cr: 0,
    dr: 0,
    total: 0,
  });

  const [showFilteredTotal, setShowFilteredTotal] = useState<boolean>(false);

  const addbalancepopupClose = () => {
    setbalanceamount(null);
    setbalancecard(null);
    setbalancecardholdername(null);
    setbalancecardexpmonth(null);
    setbalancecardexpyear(null);
    setbalancecardcvv(null);
    setaddbalancebox(false);
  };

  const addBalanceAmount = (event) => {
    let val = event.target.value;
    if (val) {
      let newval = val.toString();
      //val = val.replace(/\D/g, "");
      //val = val.replace(/[^0-9.]/g, '')
      if (newval.lenght > 0) {
        newval = newval.replace(/(\..*?)\..*/g, "$1");
        setbalanceamount(newval);
      } else {
        setbalanceamount(val);
      }
    } else {
      setbalanceamount(null);
    }
  };

  const addBalanceCardNumber = (event) => {
    let val = event.target.value;
    val = val.replace(/\D/g, "");

    var foo = val.split("-").join("");
    if (foo.length > 0) {
      foo = foo.match(new RegExp(".{1,4}", "g")).join("-");
    }
    setbalancecard(foo);
  };
  const addBalanceCardholderName = (event) => {
    let name = event.target.value;
    var letters = name.replace(/[^A-Za-z ]+/g, "");
    setbalancecardholdername(letters);
  };

  const addBalanceCardexpmonth = (event) => {
    let val = event.target.value;
    val = val.replace(/\D/g, "");
    if (val > 12) {
      dispatch(setalertMessage("Invalid month"));
      dispatch(setalertMessagetype("error"));
    } else {
      setbalancecardexpmonth(val);
    }
  };
  const addBalanceCardexpyear = (event) => {
    let val = event.target.value;
    val = val.replace(/\D/g, "");
    setbalancecardexpyear(val);

    // const d = new Date();
    // const CurrentYear = moment(d).format("YY");
    // const CurrentMonth = moment(d).format("MM");
    // let month = balancecardexpmonth;
    // if (val.length == 2) {
    //   if (val == CurrentYear) {
    //     if(month != null) {
    //       if (month > CurrentMonth) {
    //         setbalancecardexpyear(val);
    //       } else {
    //         dispatch(setalertMessage("Invaid month and year"));
    //         dispatch(setalertMessagetype("error"));
    //         return false;
    //       }
    //     } else {
    //       setbalancecardexpyear(val);
    //     }

    //   } else if (val < CurrentYear) {
    //     dispatch(setalertMessage("Invaid year"));
    //     dispatch(setalertMessagetype("error"));
    //     return false;
    //   } else {
    //     setbalancecardexpyear(val);
    //   }
    // } else {
    //   setbalancecardexpyear(val);
    // }

    // let current_year = d.getFullYear();
    // if (current_year > val && val.length == 4) {
    //   dispatch(setalertMessage("Invaid year"));
    //   dispatch(setalertMessagetype("error"));
    // } else {
    //   setbalancecardexpyear(val);
    // }
  };

  const addBalanceCardcvv = (event) => {
    let val = event.target.value;
    val = val.replace(/\D/g, "");
    setbalancecardcvv(val);
  };

  let STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;
  let type = process.env.REACT_APP_PLATFORM;
  if (type == "Live") {
    STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY_LIVE;
  }

  //const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;
  const addBalance = async () => {
    let amount = balanceamount;
    if (amount == null || amount == "") {
      dispatch(setalertMessage("Please add amount to proceed"));
      dispatch(setalertMessagetype("error"));
      return false;
    }
    if (amount == "." || amount == "0") {
      dispatch(setalertMessage("Please enter valid amount to proceed"));
      dispatch(setalertMessagetype("error"));
      setloading(false);
      return false;
    }
    // if (amount < 49) {
    //   dispatch(setalertMessage("Amount should be greater than 50"));
    //   dispatch(setalertMessagetype("error"));
    //   return false;
    // }

    let card__number = balancecard;
    if (card__number == "" || card__number == null) {
      dispatch(setalertMessage("Please provide the card number"));
      dispatch(setalertMessagetype("error"));
      return false;
    }
    if (card__number.length < 19) {
      setbalancecard("");
      dispatch(setalertMessage("Please provide the valid card number"));
      dispatch(setalertMessagetype("error"));
      return false;
    }
    let name = balancecardholdername;
    if (name != null) {
      name = name.trim();
    }
    if (name == "" || name == null) {
      dispatch(setalertMessage("Please provide the card holder name"));
      dispatch(setalertMessagetype("error"));
      return false;
    }

    if (balancecardexpmonth == null || balancecardexpmonth == "") {
      dispatch(setalertMessage("Please provide the card Exp. month"));
      dispatch(setalertMessagetype("error"));
      return false;
    }

    if (balancecardexpyear == null || balancecardexpyear == "") {
      dispatch(setalertMessage("Please provide the card Exp. year"));
      dispatch(setalertMessagetype("error"));
      return false;
    }

    if (balancecardcvv == null || balancecardcvv == "") {
      dispatch(setalertMessage("Please provide the cvv number"));
      dispatch(setalertMessagetype("error"));
      return false;
    }

    card__number = card__number.split("-").join("");
    card__number = Number(card__number);
    let card__holder_name = balancecardholdername;
    let exp__month = Number(balancecardexpmonth);
    let exp__year = Number(balancecardexpyear);
    let card__cvv = Number(balancecardcvv);

    setloading(true);
    var data =
      "card%5Bnumber%5D=" +
      card__number +
      "&card%5Bexp_month%5D=" +
      exp__month +
      "&card%5Bexp_year%5D=" +
      exp__year +
      "&card%5Bcvc%5D=" +
      card__cvv;

    var xhr = new XMLHttpRequest();
    xhr.withCredentials = true;

    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === 4) {
        return false;
      }
    });

    xhr.open("POST", "https://api.stripe.com/v1/tokens");
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.setRequestHeader("Authorization", "Bearer " + STRIPE_KEY);
    xhr.onload = async function () {
      if (xhr.status === 200) {
        let result = JSON.parse(xhr.response);
        let stripe_token = result["id"];

        let headers = { Authorization: `${AuthToken}`, request_from: "Web" };
        const balanceresult: IApiResponseRawData =
          await EquipmentAPIs.addWalletBalance(
            {
              user_id: UserId.toString(),
              balance: amount,
              token: stripe_token,
              description: UserId + ", added_wallet",
            },
            headers
          );

        if (balanceresult["type"] == "RXSUCCESS") {
          viewWalletHistory(UserId, AuthToken, 0, "new");
          setloading(false);
          dispatch(setalertMessage(balanceresult["message"]));
          dispatch(setalertMessagetype("success"));
          setbalanceamount(null);
          setbalancecard(null);
          setbalancecardholdername(null);
          setbalancecardexpmonth(null);
          setbalancecardexpyear(null);
          setbalancecardcvv(null);
          setaddbalancebox(false);
        } else if (
          balanceresult["status"] == false &&
          balanceresult["slug"] == "Session Expired"
        ) {
          setloading(false);
          dispatch(setSessionExpire(true));
          return false;
        } else {
          setloading(false);
          setaddbalancebox(false);
          dispatch(setalertMessage(balanceresult["message"]));
          dispatch(setalertMessagetype("error"));
          return false;
        }
      } else {
        let results = JSON.parse(xhr.response);
        dispatch(setalertMessage(results.error.message));
        dispatch(setalertMessagetype("error"));
        setloading(true);
        setloading(false);

        return false;
      }
    };
    xhr.send(data);
  };
  // Add Balance section end

  const viewWalletHistory = async (
    user_id,
    token,
    new_page,
    from = "pager"
  ) => {
    try {
      setloading(true);
      const headers = { Authorization: `${token}`, request_from: "Web" };
      const { withdrawal, deposits, pending, completed, ...filteredFilters } =
        filterObjRef.current;
      const transactionTypes = ["deposit", "withdrawal"].filter((type) => {
        if (type === "deposit") {
          type = "deposits";
        }
        return filterObjRef.current[type];
      });
      const paymentStatus = ["pending", "completed"].filter(
        (status) => filterObjRef.current[status]
      );
      const filter = Object.values(filterObjRef.current)?.some(
        (value) => value
      );

      const WalletData = await EquipmentAPIs.MyWallet(
        {
          user_id: user_id.toString(),
          page: new_page,
          transactionTypes,
          paymentStatus,
          ...filteredFilters,
          filter: filter,
        },
        headers
      );
      // filterApplied.current = true;
      if (WalletData["type"] == "RXSUCCESS") {
        setShowFilteredTotal(filter);
        console.log("WalletData.transections: ", WalletData.transections);
        setFilteredTotal({ ...WalletData.filterAmount });
        setPage(new_page);
        setWalletHistory(WalletData);
        if (from != "pager") {
          setTransactions(WalletData.transections);
        } else {
          let old_transactions = [...Transactions];
          let new_trns = old_transactions.concat(WalletData.transections);
          setTransactions(new_trns);
        }
        let ball = WalletData.balance;

        // console.log('WalletData.transections',WalletData.transections);

        dispatch(setWalletBalance(ball));
        setBalance(
          Number(ball)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,")
        );
        setWalletPendingBalance(
          Number(WalletData.pending_balance)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,")
        );
        setDisputedBalance(
          Number(WalletData.disputed_balance)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,")
        );
        setHoldBalance(WalletData.holdingAmount);
        setloading(false);
      } else if (
        WalletData["status"] == false &&
        WalletData["slug"] == "Session Expired"
      ) {
        setloading(false);
        dispatch(setSessionExpire(true));
        return false;
      } else {
        setloading(false);
        dispatch(setalertMessage(WalletData["message"]));
        dispatch(setalertMessagetype("error"));
        return false;
      }
    } catch (err) {
      console.log(err);
      setloading(false);
    } finally {
      // setTimeout(() => {
      //   filterApplied.current = false;
      // }, 1000);
    }
  };

  const HandlePagination = () => {
    let new_page = Number(Page) + 1;
    viewWalletHistory(UserId, AuthToken, new_page);
  };

  // Withdrawal Section Start
  const HandleWithdrawalAmount = (event) => {
    // let val = event.target.value;
    // let newval = val.toString()
    // newval = newval.replace(/\D/g, "");
    // setWithdrawalAmount(newval);
    let val = event.target.value;
    if (val.length) {
      // let newval = val.toString()
      //val = val.replace(/\D/g, "");
      //val = val.replace(/[^0-9.]/g, '')
      // if (newval.lenght > 0) {
      //   newval = newval.replace(/(\..*?)\..*/g, '$1');
      //   setWithdrawalAmount(newval);
      // } else {
      //   setWithdrawalAmount(val);
      // }
      /**** above code commented by pp */
      /*** next line added by pp 01/04/2024 due to decimal precision changes in amount*/
      const regex = /\./g;
      const decimalMatches = val.match(regex);
      if (decimalMatches?.length > 1) {
        return false;
      }
      let newVal = val.replace(/[^\d.]/g, "").replace(/(\.\d\d).*/, "$1");
      newVal = newVal.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      setWithdrawalAmount(newVal);
    } else {
      setWithdrawalAmount(null);
    }
  };

  const HandleWithdrawalAccountHolderName = (event) => {
    let name = event.target.value;
    var letters = name.replace(/[^A-Za-z ]+/g, "");
    setWithdrawalAccountHolderName(letters);
  };

  const HandleWithdrawalAccount = (event) => {
    let val = event.target.value;
    setWithdrawalAccountNumber(val);
  };

  const HandleWithdrawalConfirmAccount = (event) => {
    let val = event.target.value;
    val = val.replace(/\D/g, "");
    setWithdrawalAccountConfirmNumber(val);
  };

  const HandleWithdrawalRountingNumber = (event) => {
    let val = event.target.value;
    val = val.replace(/\D/g, "");
    setWithdrawalRoutingNumber(val);
  };

  const WithdrawalRequestpopupClose = () => {
    setWithdrawalAmount("");
    setWithdrawalAccountHolderName("");
    setWithdrawalAccountNumber("");
    setWithdrawalAccountConfirmNumber("");
    setWithdrawalRoutingNumber("");
    setWithDrawalRequest(false);
  };

  const MakeWithdrawalRequest = async () => {
    try {
      setloading(true);
      // next line added by pp on 01/04/2024 due to decimal precision issues
      let withdrawalAmount = WithdrawalAmount;
      if (withdrawalAmount) {
        withdrawalAmount = withdrawalAmount.replaceAll(",", "");
        if (withdrawalAmount !== ".") {
          withdrawalAmount = parseFloat(withdrawalAmount).toString();
        }
      }
      // end by pp
      if (!withdrawalAmount || withdrawalAmount == "") {
        dispatch(setalertMessage("Please add amount to proceed"));
        dispatch(setalertMessagetype("error"));
        setloading(false);
        return false;
      }

      if (withdrawalAmount == "0" || withdrawalAmount == ".") {
        dispatch(setalertMessage("Please enter valid amount to proceed"));
        dispatch(setalertMessagetype("error"));
        setloading(false);
        return false;
      }

      if (WithdrawalAccountHolderName == "") {
        dispatch(setalertMessage("Please enter name to proceed"));
        dispatch(setalertMessagetype("error"));
        setloading(false);
        return false;
      }

      if (WithdrawalAccountNumber == "") {
        dispatch(setalertMessage("Please enter account number to proceed"));
        dispatch(setalertMessagetype("error"));
        setloading(false);
        return false;
      }
      if (WithdrawalAccountConfirmNumber == "") {
        dispatch(
          setalertMessage("Please enter confirm account number to proceed")
        );
        dispatch(setalertMessagetype("error"));
        setloading(false);
        return false;
      }

      if (WithdrawalAccountNumber != WithdrawalAccountConfirmNumber) {
        dispatch(setalertMessage("Confirm account number not match"));
        dispatch(setalertMessagetype("error"));
        setloading(false);
        return false;
      }

      if (WithdrawalRoutingNumber == "") {
        dispatch(setalertMessage("Please enter routing number to proceed"));
        dispatch(setalertMessagetype("error"));
        setloading(false);
        return false;
      }

      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const RequestStatus = await EquipmentAPIs.WithDrawalRequest(
        {
          account_number: WithdrawalAccountNumber,
          user_id: UserId.toString(),
          account_holder_name: WithdrawalAccountHolderName,
          routing_number: WithdrawalRoutingNumber,
          amount: withdrawalAmount.toString(),
        },
        headers
      );

      if (RequestStatus["type"] == "RXSUCCESS") {
        dispatch(setalertMessage(RequestStatus["message"]));
        dispatch(setalertMessagetype("success"));
        // viewWalletHistory(UserId, AuthToken, 0, "new");
        setDateRange([null, null]);
        setAmountRange([null, null]);
        setFilters({
          withdrawal: false,
          deposits: false,
          pending: false,
          completed: false,
        });
        handleApplyFilter({
          dateRange: ["", ""],
          amountRange: [null, null],
          withdrawal: false,
          deposits: false,
          pending: false,
          completed: false,
          searchClear: true,
        });
        WithdrawalRequestpopupClose();
        setloading(false);
      } else if (
        RequestStatus["status"] == false &&
        RequestStatus["slug"] == "Session Expired"
      ) {
        setloading(false);
        dispatch(setSessionExpire(true));
        return false;
      } else {
        setloading(false);
        dispatch(setalertMessage(RequestStatus["message"]));
        dispatch(setalertMessagetype("error"));
        return false;
      }
    } catch (err) {
      console.log(err);
      WithdrawalRequestpopupClose();
      setloading(false);
    }
  };

  const handleApplyFilter = ({
    dateRange,
    amountRange,
    withdrawal,
    deposits,
    pending,
    completed,
    searchClear = false,
  }: {
    dateRange: [string, string];
    amountRange: [number | string, number | string];
    withdrawal: boolean;
    deposits: boolean;
    pending: boolean;
    completed: boolean;
    searchClear?: boolean;
  }) => {
    let minAmount = amountRange[0];
    if (minAmount !== "" && typeof minAmount === "string") {
      minAmount = parseFloat(minAmount);
    }
    let maxAmount = amountRange[1];
    if (maxAmount !== "" && typeof maxAmount === "string") {
      maxAmount = parseFloat(maxAmount);
    }
    filterObjRef.current = {
      ...filterObjRef.current,
      fromDate: dateRange[0],
      toDate: dateRange[1],
      minAmount,
      maxAmount,
      deposits,
      withdrawal,
      pending,
      completed,
    };
    if (searchClear) {
      filterObjRef.current.keyword = ""; // Clear the keyword
      setKeywordInput(""); // Clear the input field
    }

    viewWalletHistory(UserId, AuthToken, 0, "new");
    handleFilterModalClose();
    // filterApplied.current = true;
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && filterObjRef.current.keyword) {
      viewWalletHistory(UserId, AuthToken, 0, "new");
      searchApplied.current = true;
    }
  };

  const handleKeyChange = (event) => {
    const { value } = event.target;

    // Allow only floating point numbers
    // if (value !== "" || !/^\d+(\.\d{0,2})?$/.test(value)) {
    //   return; // Prevent invalid input
    // }

    filterObjRef.current = {
      ...filterObjRef.current,
      keyword: value,
    };
    setKeywordInput(value);
    if (!value && searchApplied.current) {
      searchApplied.current = false;
      viewWalletHistory(UserId, AuthToken, 0, "new");
    }
    if (!searchApplied.current) {
      searchApplied.current = false;
    }
  };

  const handleBtnClick = () => {
    if (keywordInput) {
      searchApplied.current = true;
      viewWalletHistory(UserId, AuthToken, 0, "new");
    }
  };

  const handleClearInput = () => {
    setKeywordInput(""); // Clear input
    filterObjRef.current = { ...filterObjRef.current, keyword: "" };
    if (searchApplied.current) {
      viewWalletHistory(UserId, AuthToken, 0, "new");
      searchApplied.current = false;
    }
  };

  const handleClearHistory = (type) => {
    if (type === "date") {
      filterObjRef.current = {
        ...filterObjRef.current,
        fromDate: "",
        toDate: "",
      };
      setDateRange(["", ""]);
    } else if (type === "amount") {
      filterObjRef.current = {
        ...filterObjRef.current,
        minAmount: null,
        maxAmount: null,
      };
      setAmountRange([null, null]);
    } else if (
      ["withdrawal", "deposits", "pending", "completed"].includes(type)
    ) {
      filterObjRef.current = {
        ...filterObjRef.current,
        [type]: false,
      };
      setFilters((prev) => ({ ...prev, [type]: false }));
    }
    // else if (type === "withdrawal") {
    //   filterObjRef.current = {
    //     ...filterObjRef.current,
    //     withdrawal: false,
    //   };
    //   setFilters((prev) => ({ ...prev, withdrawal: false }));
    // } else if (type === "deposits") {
    //   filterObjRef.current = {
    //     ...filterObjRef.current,
    //     deposits: false,
    //   };
    //   setFilters((prev) => ({ ...prev, deposits: false }));
    // } else if (type === "pending") {
    //   filterObjRef.current = {
    //     ...filterObjRef.current,
    //     pending: false,
    //   };
    //   setFilters((prev) => ({ ...prev, pending: false }));
    // } else if (type === "completed") {
    //   filterObjRef.current = {
    //     ...filterObjRef.current,
    //     completed: false,
    //   };
    //   setFilters((prev) => ({ ...prev, completed: false }));
    // }
    // filterApplied.current = true;
    viewWalletHistory(UserId, AuthToken, 0, "new");
  };

  useEffect(() => {
    let auth_user = localStorage.getItem("token-info");
    if (auth_user != "undefined") {
      auth_user = JSON.parse(auth_user);
      if (auth_user != null && auth_user) {
        dispatch(setaccessToken(auth_user["auth_token"]));
        dispatch(setAuthuser(auth_user["user_id"]));
        viewWalletHistory(auth_user["user_id"], auth_user["auth_token"], Page);
      }
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const {
    minAmount,
    maxAmount,
    fromDate,
    toDate,
    withdrawal,
    deposits,
    pending,
    completed,
  } = filterObjRef.current;
  const selectedFilter = [
    { data: [fromDate, toDate], type: "date" },
    { data: [minAmount, maxAmount], type: "amount" },
    { data: withdrawal, type: "withdrawal" },
    { data: deposits, type: "deposits" },
    { data: pending, type: "pending" },
    { data: completed, type: "completed" },
  ];

  return (
    <>
      <MainLayout>
        {loading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}

        <Box className="rentyrd-main my-wallet-main">
          <Box className="rentyrd-inner">
            {/* <Box className="rentyrd-heading">
              <Typography>MyWallet</Typography>
            </Box> */}

            <Box className="rentyrd-grid wallet-grid">
              <Box className="rentyrd-grid-item rentyrd-right wallet-grid-item">
                <Box className="rentyrd-left-float">
                  <Box className="wallet-heading">
                    <Typography>Wallet</Typography>
                  </Box>
                  <Box className="equipment-details-heading wallet-amt-div">
                    <Typography className="total-bal-p">
                      <span className="total-balance-text">
                        Total Amount Available
                      </span>
                      <span className="total-balance-amt">${Balance}</span>
                    </Typography>
                    <Typography className="pending-balance">
                      Pending Balance: ${WalletPendingBalance}
                    </Typography>
                    <Typography className="pending-balance">
                      Disputed Balance: ${DisputedBalance}
                    </Typography>
                    {HoldBalance > 0 && (
                      <Typography className="pending-balance">
                        Credit Hold: $
                        {Number(HoldBalance)
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                      </Typography>
                    )}
                    {showFilteredTotal && (
                      <>
                        <Typography
                          className="pending-balance"
                          sx={{ fontSize: "1.5rem !important" }}
                        >
                          Total Credits (Filtered): $
                          {Number(filteredTotal?.cr)
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                        </Typography>
                        <Typography
                          className="pending-balance"
                          sx={{ fontSize: "1.5rem !important" }}
                        >
                          Total Debits (Filtered):{" "}
                          {Number(filteredTotal?.dr) > 0 ? "-" : ""}$
                          {Number(filteredTotal?.dr)
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                        </Typography>
                        <Typography
                          className="pending-balance"
                          sx={{ fontSize: "1.5rem !important" }}
                        >
                          Final Total (Filtered): {filteredTotal?.total}
                        </Typography>
                      </>
                    )}
                  </Box>

                  <Box className="mywallet-btn-grps">
                    <Box className="edit-listing deposit-btn-box">
                      <Link
                        className="edit-listing-btn deposit-btn"
                        onClick={() => {
                          setaddbalancebox(true);
                        }}
                      >
                        <img src={depositIcon} alt="" />
                      </Link>
                    </Box>
                    <Box className="edit-listing withdrawal-btn-box">
                      <Link
                        className="edit-listing-btn withdrawal-btn"
                        onClick={() => {
                          setWithDrawalRequest(true);
                        }}
                      >
                        <img src={withdrawIcon} alt="" />
                      </Link>
                    </Box>
                  </Box>
                  {/* by GD 23 Feb 2024 for build a crew */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mt: 2,
                    }}
                  >
                    <Link
                      onClick={() => setcrewPaymentOpen(true)}
                      sx={{
                        fontSize: "clamp(20px, 3vw ,28px)",
                        cursor: "pointer",
                        color: "#faa61a !important",
                        fontWeight: "600",
                        textDecorationColor: "#faa61a !important",
                        textDecorationLine: "underline",
                      }}
                    >
                      Next Crew Payout
                    </Link>
                  </Box>
                  {/* end by GD */}
                </Box>
              </Box>
              <Box className="rentyrd-grid-item rentyrd-left wallet-grid-item">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                    justifyContent: "center",
                    width: "100%",
                    mb: 2,
                  }}
                >
                  <Box
                    sx={{
                      width: {
                        lg: "450px",
                        md: "400px",
                        sm: "300px",
                        xs: "280px",
                      },
                      padding: "0.5rem",
                      borderRadius: "10px",
                      border: "3px solid #faa61a",
                      background: "#fff",
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                    }}
                  >
                    <TextField
                      placeholder="Search transactions"
                      variant="outlined"
                      fullWidth
                      sx={{
                        "& .MuiInputBase-input": {
                          border: "none",
                          backgroundColor: "transparent",
                          padding: "5px 0px 5px 15px",
                          fontFamily: "Montserrat !important",
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                          "&:hover fieldset": {
                            border: "none",
                          },
                          "&.Mui-focused fieldset": {
                            border: "none",
                          },
                        },
                        "& .MuiInputLabel-root": {
                          display: "none",
                        },
                        "& .MuiInputBase-input::placeholder": {
                          color: "#000",
                        },
                      }}
                      value={keywordInput}
                      onChange={handleKeyChange}
                      onKeyDown={handleKeyDown}
                    />
                    <Button
                      variant="contained"
                      sx={{
                        background: "#149247",
                        borderRadius: "8px",
                        textTransform: "capitalize",
                        fontFamily: "Montserrat !important",
                        "&:hover": {
                          background: "#0e7538",
                        },
                      }}
                      onClick={handleBtnClick}
                    >
                      Search
                    </Button>
                  </Box>
                  <Box
                    component={"img"}
                    src={Filtericon}
                    sx={{
                      width: {
                        xs: "25px",
                        sm: "30px",
                        md: "35px",
                        lg: "40px",
                        xl: "45px",
                      },
                      cursor: "pointer",
                    }}
                    onClick={handleFilterModalOpen}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                    flexWrap: "wrap",
                    mb: 2,
                  }}
                >
                  {selectedFilter?.map(({ data, type }) => {
                    let value: React.ReactNode = ""; // Updated type

                    if (Array.isArray(data)) {
                      if (type === "amount") {
                        value =
                          data[0] && data[1] ? (
                            <span>
                              <strong>Amount Range:</strong> ${data[0]} - $
                              {data[1]}
                            </span>
                          ) : (
                            ""
                          );
                      }

                      if (type === "date") {
                        value =
                          data[0] && data[1] ? (
                            <span>
                              <strong>Dates:</strong>{" "}
                              {moment(data[0])?.format("MM/DD/YYYY")} To{" "}
                              {moment(data[1])?.format("MM/DD/YYYY")}
                            </span>
                          ) : (
                            ""
                          );
                      }
                    } else if (data) {
                      value = type;
                    }

                    if (!value) {
                      return null;
                    }
                    return (
                      <Box
                        key={type}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                          background: "#faa61a",
                          borderRadius: "5px",
                          width: "fit-content",
                          padding: "5px 10px",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#fff",
                            fontWeight: "500",
                            fontFamily: "Montserrat !important",
                            textTransform: "capitalize",
                            fontSize: "0.9rem",
                          }}
                        >
                          {value}
                        </Typography>
                        <ClearIcon
                          sx={{
                            cursor: "pointer",
                            color: "#fff !important",
                            fontSize: "0.9rem",
                          }}
                          onClick={() => handleClearHistory(type)}
                        />
                      </Box>
                    );
                  })}
                </Box>

                <Box className="wallet-trnsc-history">Transaction History</Box>
                <hr className="gray-line-2" />
                {WalletHistory && (
                  <>
                    {Transactions.length > 0 ? (
                      <>
                        {Transactions.map((transactions, indx) => (
                          <Box
                            key={indx}
                            sx={{
                              cursor:
                                transactions.transaction_type ==
                                "crew_boss_commission"
                                  ? "pointer !important"
                                  : "default !important",
                            }}
                            onClick={() => {
                              if (
                                transactions.transaction_type ==
                                "crew_boss_commission"
                              ) {
                                console.log("in transaction if");
                                setTransactionsData(transactions);
                                setCrewTransactionsOpen(true);
                              }
                            }}
                          >
                            <Box className="rentyrd-card wallet-card">
                              <Box className="mywallet-card-grid">
                                <Typography className="mywallet-status">
                                  <span>
                                    {transactions.message
                                      .toLowerCase()
                                      .indexOf("deducted".toLowerCase()) > -1 ||
                                    (transactions?.transaction_type ===
                                      "gps_device_order" &&
                                      transactions?.payment_type === "DR")
                                      ? "-"
                                      : transactions.message
                                          .toLowerCase()
                                          .indexOf("withdrawal".toLowerCase()) >
                                        -1
                                      ? "-"
                                      : "+"}
                                    $
                                    {Number(transactions.amount)
                                      .toFixed(2)
                                      .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                                  </span>

                                  <span
                                    className={
                                      "status-span " +
                                      (transactions?.transaction_type ===
                                      "gps_device_order"
                                        ? "gps-payment"
                                        : transactions.payment_status ==
                                          "pending"
                                        ? "pending-status"
                                        : transactions.payment_status ==
                                          "completed"
                                        ? "complete-status"
                                        : transactions.payment_status ==
                                          "succeeded"
                                        ? "complete-status"
                                        : "pending-status")
                                    }
                                  >
                                    {transactions?.transaction_type ===
                                    "gps_device_order"
                                      ? "card payment"
                                      : transactions.payment_status}{" "}
                                  </span>
                                </Typography>
                                <Box className="mywallet-details-box">
                                  <Typography className="mywallet-trans">
                                    <span>Trans. Id: {transactions.id} </span>
                                    ,&nbsp;
                                    <span>
                                      {new Date(transactions.createdAt)
                                        .toString()
                                        .substring(0, 21)}
                                    </span>
                                  </Typography>
                                  <Typography className="mywallet-fees">
                                    {transactions.message}
                                    {"     "}
                                    {transactions.is_deleted == 1 && (
                                      <Typography
                                        style={{ color: "red", fontSize: 14 }}
                                      >
                                        ~ Item deleted
                                      </Typography>
                                    )}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                            {Transactions.length != indx + 1 && (
                              <hr className="gray-line-2" />
                            )}
                          </Box>
                        ))}

                        {WalletHistory.total > 0 &&
                          (Page + 1) * 10 < WalletHistory.total && (
                            <Box className="pagination-section">
                              <Box
                                className="btn-load-more"
                                sx={{ color: "#faa61a !important" }}
                                onClick={HandlePagination}
                              >
                                {"See More"}
                                <img
                                  src={gemArrow}
                                  alt="GoEquipMe"
                                  className="gem-arrow arrow-load-more"
                                />
                              </Box>
                            </Box>
                          )}
                      </>
                    ) : (
                      <>
                        <Box className="rentyrd-card wallet-card">
                          <Box className="mywallet-card-grid">
                            <Typography className="mywallet-status">
                              No transactions available
                            </Typography>
                          </Box>
                        </Box>
                      </>
                    )}
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Box>

        {/* Add Balance Section Start */}

        <Modal
          open={addbalancebox}
          onClose={addbalancepopupClose}
          className="addmoney-modal"
        >
          <Box
            className="download-popups"
            sx={{
              ...loginstyle,
              // width: matchesMobileX ? "calc(100vw - 100px)" : 800,
              padding: "0px",
            }}
          >
            <IconButton className="cross-btn" onClick={addbalancepopupClose}>
              <CloseIcon />
            </IconButton>

            <Box className="login__main">
              <Box
                className="login__main-div edit-eqp"
                // sx={{
                //   display: "flex",
                //   justifyContent: "center",
                // }}
              >
                <Box
                  className="login__main-div__form popup-cmmn popup-white edit-eqp-inner"
                  style={{ border: "5px solid #faa61a", borderRadius: "10px" }}
                  //component="form"
                  // onSubmit={login}
                >
                  <Typography
                    variant="h5"
                    style={{ color: "#faa61a", marginBottom: "0.5rem" }}
                  >
                    Add Funds to Wallet
                  </Typography>

                  {/* un commented by GD for live code 29 Dec */}
                  <AddBalance
                    onSuccess={(e) => {
                      if (e == "success") {
                        // viewWalletHistory(UserId, AuthToken, 0, "new");
                        setDateRange([null, null]);
                        setAmountRange([null, null]);
                        setFilters({
                          withdrawal: false,
                          deposits: false,
                          pending: false,
                          completed: false,
                        });
                        handleApplyFilter({
                          dateRange: ["", ""],
                          amountRange: [null, null],
                          withdrawal: false,
                          deposits: false,
                          pending: false,
                          completed: false,
                          searchClear: true,
                        });
                        setaddbalancebox(false);
                      }
                    }}
                  />

                  {/* <Box className="addmoney-modal-content modal-content wallet-modal-content">
                    {setloading && (
                      <Box>
                        <Backdrop
                          sx={{
                            color: "#fff",
                            zIndex: (theme) => theme.zIndex.drawer - 1,
                          }}
                          open={loading}
                        >
                          <CircularProgress color="inherit" />
                        </Backdrop>
                      </Box>
                    )}

                    <FormControl>
                      <NewCssNumericInput
                        prefix={'$'}
                        fullWidth
                        name="example"
                        precision={0}
                        decimalChar="."
                        thousandChar=","
                        placeholder={"100"}
                        label="Amount"
                        onChange={addBalanceAmount}
                        value={balanceamount}
                        variant="filled"
                        className='popup-input'
                        InputProps={{ disableUnderline: true }}
                        inputProps={{
                          startAdornment: (
                            <InputAdornment position="start" sx={{ color: "fff !important" }}>
                              $
                            </InputAdornment>
                          ),
                          maxLength: 15,
                        }}
                      />
                    </FormControl>

                    <FormControl>
                      <NewCssTextField
                        autoFocus
                        // margin="dense"
                        id="card-number"
                        label="Card Number"
                        type="text"
                        fullWidth
                        variant="filled"
                        className='popup-input'
                        onChange={addBalanceCardNumber}
                        value={balancecard}
                        placeholder={"XXXX-XXXX-XXXX-XXXX"}
                        inputProps={{
                          maxLength: 19,
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        InputProps={{ disableUnderline: true }}

                      />
                    </FormControl>

                    <FormControl>
                      <NewCssTextField
                        autoFocus
                        id="name"
                        label="Name"
                        type="text"
                        fullWidth
                        variant="filled"
                        className='popup-input'
                        onChange={addBalanceCardholderName}
                        inputProps={{
                          maxLength: 20,
                        }}
                        InputProps={{ disableUnderline: true }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        value={balancecardholdername}
                        placeholder={"Account holder name"}

                      />
                    </FormControl>    
                    
                    <Box className="addmoney-modal-textfields-grid payment-card-grid">
                      <FormControl>
                        <NewCssTextField
                          autoFocus
                          id="name"
                          label="Exp Month"
                          type="text"
                          placeholder={"MM"}
                          variant="filled"
                          className='popup-input'
                          onChange={addBalanceCardexpmonth}
                          inputProps={{
                            maxLength: 2,
                          }}
                          InputProps={{ disableUnderline: true }}
                          InputLabelProps={{
                            shrink: true
                          }}
                          value={balancecardexpmonth}

                        />
                      </FormControl>

                      <FormControl>
                        <NewCssTextField
                          autoFocus
                          id="name"
                          label="Exp Year"
                          type="text"

                          placeholder={"YY"}
                          variant="filled"
                          className='popup-input'
                          onChange={addBalanceCardexpyear}
                          InputProps={{ disableUnderline: true }}
                          inputProps={{
                            maxLength: 2,
                          }}
                          InputLabelProps={{
                            shrink: true
                          }}
                          value={balancecardexpyear}

                        />
                      </FormControl>
                      
                      

                    </Box>
                    <Box className="addmoney-modal-textfields-grid payment-card-grid">
                      <NewCssTextField
                        autoFocus
                        // margin="dense"
                        id="name"
                        label="CVV"
                        type="text"
                        placeholder={"XXX"}
                        variant="filled"
                        className='popup-input'
                        onChange={addBalanceCardcvv}
                        InputProps={{ disableUnderline: true }}
                        inputProps={{
                          maxLength: 4,
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        value={balancecardcvv}
                      />
                    </Box>
                    
                  </Box>
                  
                  <Button
                    variant="contained"
                    className="reset-cal-btn addmoney--checkout-btn new-money-btn transfer-btn"
                    onClickCapture={() => {
                      addBalance();
                    }}
                  >
                    Checkout
                  </Button> */}
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>

        <Modal
          keepMounted
          open={WithDrawalRequest}
          onClose={WithdrawalRequestpopupClose}
          className="addmoney-modal"
        >
          <Box
            className="download-popups"
            sx={{
              ...loginstyle,
              // width: matchesMobileX ? "calc(100vw - 100px)" : 800,
              padding: "0px",
            }}
          >
            <IconButton
              className="cross-btn"
              onClick={WithdrawalRequestpopupClose}
            >
              <CloseIcon />
            </IconButton>

            <Box className="login__main">
              <Box
                className="login__main-div edit-eqp"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box
                  className="login__main-div__form popup-cmmn popup-white edit-eqp-inner"
                  // style={{ border: "5px solid #faa61a", borderRadius: "10px" }}
                  //component="form"
                  // onSubmit={login}
                >
                  <Typography
                    variant="h5"
                    style={{ color: "#faa61a", marginBottom: "0.5rem" }}
                  >
                    Withdrawal Request
                  </Typography>

                  <Box className="addmoney-modal-content modal-content wallet-modal-content">
                    {setloading && (
                      <Box>
                        <Backdrop
                          sx={{
                            color: "#fff",
                            zIndex: (theme) => theme.zIndex.drawer - 1,
                          }}
                          open={loading}
                        >
                          <CircularProgress color="inherit" />
                        </Backdrop>
                      </Box>
                    )}
                    {/* next line commented by pp on 01/04/2024 */}
                    {/* <NewCssNumericInput
                      prefix={'$'}
                      fullWidth
                      name="example"
                      precision={0}
                      decimalChar="."
                      thousandChar=","
                      label="Amount"
                      placeholder="100"
                      onChange={HandleWithdrawalAmount}
                      value={WithdrawalAmount}
                      variant="filled"
                      className='popup-input'
                      InputProps={{ disableUnderline: true }}
                      inputProps={{
                        maxLength: 15,
                      }}
                    /> */}
                    {/* next line added by pp on 01/04/2024 due to decimal value changes */}
                    <NewCssTextField
                      autoFocus
                      id="amount-withdraw"
                      label="Amount"
                      type="text"
                      fullWidth
                      variant="filled"
                      className="popup-input"
                      onChange={HandleWithdrawalAmount}
                      value={WithdrawalAmount}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      inputProps={{
                        maxLength: 15,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="100"
                    />
                    <NewCssTextField
                      autoFocus
                      // margin="dense"
                      id="name"
                      label="Name"
                      type="text"
                      fullWidth
                      variant="filled"
                      className="popup-input"
                      onChange={HandleWithdrawalAccountHolderName}
                      value={WithdrawalAccountHolderName}
                      inputProps={{
                        maxLength: 20,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder={"Account holder name"}
                    />
                    <NewCssTextField
                      autoFocus
                      // margin="dense"
                      id="card-number"
                      label="Account Number"
                      type="text"
                      fullWidth
                      variant="filled"
                      className="popup-input"
                      onChange={HandleWithdrawalAccount}
                      value={WithdrawalAccountNumber}
                      placeholder={"1234567890123456"}
                      InputProps={{ disableUnderline: true }}
                      inputProps={{
                        maxLength: 19,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />

                    <NewCssTextField
                      autoFocus
                      // margin="dense"
                      id="card-number"
                      label="Confirm Account Number"
                      type="text"
                      fullWidth
                      variant="filled"
                      className="popup-input"
                      onChange={HandleWithdrawalConfirmAccount}
                      value={WithdrawalAccountConfirmNumber}
                      placeholder={"1234567890123456"}
                      inputProps={{
                        maxLength: 19,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <NewCssTextField
                      autoFocus
                      // margin="dense"
                      id="card-number"
                      label="Routing Number"
                      type="text"
                      fullWidth
                      variant="filled"
                      className="popup-input"
                      onChange={HandleWithdrawalRountingNumber}
                      value={WithdrawalRoutingNumber}
                      inputProps={{
                        maxLength: 19,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder={"12345"}
                    />
                  </Box>
                  <Button
                    variant="contained"
                    className="reset-cal-btn addmoney--checkout-btn new-money-btn transfer-btn"
                    onClickCapture={() => {
                      MakeWithdrawalRequest();
                    }}
                  >
                    TRANSFER TO MY BANK ACCOUNT
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>

        {/* by GD 23 Feb 2024 for build a crew */}
        {crewPaymentOpen && (
          <CrewPayoutModal
            crewPaymentOpen={crewPaymentOpen}
            setCrewPaymentOpen={setcrewPaymentOpen}
          />
        )}

        {/* by GD 15 mar 2024 for build a crew monthly payout transactions  */}
        {crewTransactionsOpen && (
          <CrewTransactionsModal
            crewTransactionsOpen={crewTransactionsOpen}
            setCrewTransactionsOpen={setCrewTransactionsOpen}
            txnData={transactionsData}
          />
        )}

        <WalletFilterModal
          onClose={handleFilterModalClose}
          isOpen={filterModalOpen}
          handleApplyFilter={handleApplyFilter}
          dateRange={dateRange}
          setDateRange={setDateRange}
          amountRange={amountRange}
          setAmountRange={setAmountRange}
          filters={filters}
          setFilters={setFilters}
        />
      </MainLayout>
    </>
  );
};
export default MyWallet;
