import {
  Backdrop,
  Box,
  CircularProgress,
  Container,
  Typography,
} from "@mui/material";
import AlertModal from "components/common/AlertModal";
import ReviewContent from "components/common/ReviewContent";
import MainLayout from "layouts/MainLayout";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setalertMessage, setalertMessagetype } from "redux/redux-slice";
import { EquipmentAPIs } from "service/api-service";
import { IStoreValue } from "utils/types";
interface ReviewFormType {
  q1_rating: number;
  q2_rating: number;
  q3_rating: number;
  review: string;
}
const reviewElementDefault = {
  q1_rating: null,
  q2_rating: null,
  q3_rating: null,
  review: "",
};
const maxCharacter = 500;
const RentalReview = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const AuthUserId = useSelector(
    (state: IStoreValue) => state.equipmentReducer.Authuser
  );
  const AuthToken = useSelector(
    (state: IStoreValue) => state.equipmentReducer.accessToken
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [formElement, setFormElement] = useState<ReviewFormType>({
    ...reviewElementDefault,
  });
  const characterLeft = useRef<number>(maxCharacter);
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
  const handleSuccessModalClose = () => {
    setOpenSuccessModal(false);
    navigate("/");
  };
  const [isLoggedInWithSame, setIsLoggedInWithSame] = useState<boolean>(false);
  const handleReviewSubmission = async (event) => {
    try {
      event.preventDefault();
      const validateRating = { ...formElement };
      delete validateRating["review"];
      if (!Object.values(validateRating).every((value) => value)) {
        dispatch(setalertMessage("Please provide a rating for all question."));
        dispatch(setalertMessagetype("error"));
        return;
      }
      if (formElement?.review?.trim().length > maxCharacter) {
        dispatch(
          setalertMessage(`Please provide review upto ${maxCharacter} characters.`)
        );
        dispatch(setalertMessagetype("error"));
        return;
      }
      const finalObject = {
        ...formElement,
        review: formElement?.review?.trim(),
        renter_id: AuthUserId,
        owner_id: params["ownerId"],
        order_id: params["orderId"],
        equip_id: params["equipId"],
      };
      console.log("finalObject", finalObject);
      setLoading(true);
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const rentalReviewRes = await EquipmentAPIs.postRentalReview(
        finalObject,
        headers
      );
      if (rentalReviewRes["type"] === "RXSUCCESS") {
        setOpenSuccessModal(true);
        setFormElement({ ...reviewElementDefault });
        dispatch(setalertMessage("Review submitted successfully."));
        dispatch(setalertMessagetype("success"));
      } else {
        throw new Error(rentalReviewRes["message"]);
      }
    } catch (error) {
      dispatch(
        setalertMessage(error.message || "An error occured! Please try again.")
      );
      dispatch(setalertMessagetype("error"));
    } finally {
      setLoading(false);
    }
  };
  const handleFormInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const rowName = event.target.name;
    let value: string | number = event.target.value;
    if (!isNaN(parseFloat(value))) {
      value = +value;
    }
    if (
      rowName === "review" &&
      typeof value === "string" &&
      value?.length > maxCharacter
    ) {
      return;
    } else if (rowName === "review" && typeof value === "string") {
      characterLeft.current = maxCharacter - value?.length;
    }
    setFormElement((prev) => ({
      ...prev,
      [rowName]: value,
    }));
  };
  useEffect(() => {
    setLoading(true);
    const renterId = +params["renterId"];
    if (AuthUserId && renterId && renterId === AuthUserId) {
      setIsLoggedInWithSame(true);
    }
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [AuthUserId]);
  // const isLoggedInWithSame = +params["ownerId"] === AuthUserId;
  return (
    <MainLayout>
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
      {isLoggedInWithSame ? (
        <Container maxWidth="sm">
          <ReviewContent
            formElement={formElement}
            handleReviewSubmission={handleReviewSubmission}
            handleFormInputChange={handleFormInputChange}
            characterLeft={characterLeft.current}
          />
        </Container>
      ) : !loading ? (
        <Box
          className="req-sum-main"
          sx={{ border: "5px solid #faa61a", borderRadius: "12px" }}
        >
          <Box
            className="req-msg"
            sx={{ backgroundColor: "#2e2e2e !important" }}
          >
            <Box
              className="req-msg-text"
              sx={{ backgroundColor: "#2e2e2e !important" }}
            >
              <Typography
                sx={{
                  color: "#fff !important",
                  textAlign: "center",
                  fontSize: "20px",
                }}
              >
                You must be logged in with the same credentials.
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : null}
      {/*  */}
      <AlertModal
        alertModalIsOpen={openSuccessModal}
        handleAlertModalClose={handleSuccessModalClose}
        message={"Review submitted successfully."}
      />
    </MainLayout>
  );
};

export default RentalReview;
